import { AppShell, Stack } from "@mantine/core";
import AppHeader from "./components/Header";
import FeatureSection from "./sections/FeatureSection";
import AppFooter, { AppFooterClip } from "./components/Footer";
import TelemetrySection from "./sections/TelemetrySection";
import FAQSection from "./sections/FAQSection";
import CTASection from "./sections/CTASection";

function App() {
  return (
    <>
      <AppShell
        padding="md"
        header={<AppHeader />}
        footer={<AppFooter />}
        styles={(theme) => ({
          main: {
            padding: theme.spacing.md,
            background: theme.colors.dark[7],
            zIndex: 10,
          },
        })}
      >
        <Stack spacing={300} mt={260} mb={300}>
          <FeatureSection />
          <TelemetrySection />
          <CTASection />
          <FAQSection />
        </Stack>
      </AppShell>
      <AppFooterClip />
    </>
  );
}

export default App;
