import { useMemo } from "react";
import { scaleLinear } from "d3-scale";
import { line } from "d3-shape";
import { axisBottom, axisLeft } from "d3-axis";
import filterTurnTicks from "../utils/filterTurnTicks";
import { formatYAxisType } from "../data/TelemetryData";

interface IGetAxis {
  data: number[];
  trackTicks: number[];
  width: number;
  height: number;
  axisPadding: number;
  yMin: number;
  yMax: number;
  formatYAxis?: formatYAxisType;
}

export default function useGetAxis({
  data,
  trackTicks,
  width,
  height = 200,
  axisPadding,
  yMin = 0,
  yMax = 300,
  formatYAxis,
}: IGetAxis) {
  return useMemo(() => {
    const length = data.length;
    const strokeWidth = height * 0.015;

    const walkX = scaleLinear()
      .domain([0, length])
      .range([axisPadding, width - strokeWidth]);

    const walkY = scaleLinear()
      .domain([yMin, yMax])
      .range([height - axisPadding, strokeWidth * 2]);

    const filteredTicks = filterTurnTicks(trackTicks, walkX);

    const bottomAxis = axisBottom(walkX)
      .tickValues(filteredTicks)
      .tickFormat(
        (num) => "T" + (trackTicks.findIndex((element) => element === num) + 1)
      );

    const xLength = width - strokeWidth - axisPadding;

    const leftAxis = axisLeft(walkY)
      .ticks(5)
      .tickSizeInner(-xLength)
      .tickPadding(10)
      .tickFormat((value, index) =>
        formatYAxis ? formatYAxis(value as number, index) : value + ""
      );

    const path = line()
      .x((d, i) => walkX(i))
      .y((d, i) => walkY(d as any));

    return { path, bottomAxis, leftAxis, strokeWidth };

    //eslint-disable-next-line
  }, [data, width, height]);
}
