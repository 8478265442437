export const GAS_DATA = [
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  0.9218499660491943, 0.6894500255584717, 0.44315001368522644,
  0.28734999895095825, 0.14190000295639038, 0.043200016021728516, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.17499999701976776,
  0.675000011920929, 0.606249988079071, 0.543749988079071, 0.37500008940696716,
  0, 0, 0, 0, 0, 0, 0, 0, 0.17499999701976776, 0.675000011920929,
  0.612500011920929, 0.550000011920929, 0.37500008940696716, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.26249998807907104,
  0.668749988079071, 0.6000000238418579, 0.543749988079071, 0.3124999403953552,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.699999988079071,
  0.637499988079071, 0.5687500238418579, 0.512499988079071, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0.021600008010864258, 0.028250008821487427, 0.03185001015663147,
  0.03600001335144043, 0.039050012826919556, 0.04010000824928284,
  0.045249998569488525, 0.051899999380111694, 0.06115001440048218,
  0.07610002160072327, 0.09560000896453857, 0.11515000462532043,
  0.15575000643730164, 0.20155000686645508, 0.23954999446868896,
  0.2775999903678894, 0.31310001015663147, 0.3490999937057495,
  0.3778499960899353, 0.41179999709129333, 0.4467499852180481,
  0.48019999265670776, 0.5100499987602234, 0.5367500185966492,
  0.5676000118255615, 0.5953500270843506, 0.6200500130653381,
  0.6462500095367432, 0.6699000000953674, 0.690500020980835, 0.7099999785423279,
  0.7382999658584595, 0.7588499784469604, 0.7768499851226807,
  0.7958999872207642, 0.8123500347137451, 0.8256999850273132,
  0.8395999670028687, 0.8544999957084656, 0.8698999881744385,
  0.8838000297546387, 0.8940999507904053, 0.9053999781608582,
  0.9161999821662903, 0.9254499673843384, 0.9357500076293945,
  0.9455000162124634, 0.9547500014305115, 0.9675999879837036,
  0.9788999557495117, 0.9876499772071838, 0.9979499578475952, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.9901999831199646, 0.9192999601364136,
  0.8467999696731567, 0.742900013923645, 0.6369999647140503, 0.5300999879837036,
  0.4426499903202057, 0.36500000953674316, 0.3095000088214874,
  0.26785001158714294, 0.23544999957084656, 0.20155000686645508,
  0.17375001311302185, 0.14239999651908875, 0.11360001564025879,
  0.0910000205039978, 0.07660001516342163, 0.06014999747276306,
  0.0426500141620636, 0.029799997806549072, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0.026200026273727417, 0.03545001149177551, 0.0504000186920166,
  0.07710000872612, 0.1023000180721283, 0.1254500150680542, 0.15935000777244568,
  0.18150001764297485, 0.20355001091957092, 0.22875002026557922,
  0.2570500075817108, 0.27195000648498535, 0.28224998712539673,
  0.2909500002861023, 0.3007500171661377, 0.303849995136261,
  0.30844998359680176, 0.31360000371932983, 0.33160001039505005,
  0.3536999821662903, 0.37835001945495605, 0.407150000333786,
  0.4328500032424927, 0.45704999566078186, 0.4873499870300293,
  0.5110499858856201, 0.526449978351593, 0.5450000166893005, 0.5598999857902527,
  0.5701999664306641, 0.583549976348877, 0.5892000198364258, 0.5928000211715698,
  0.5928000211715698, 0.5948500037193298, 0.6000000238418579, 0.609250009059906,
  0.6169499754905701, 0.6247000098228455, 0.6277499794960022,
  0.6287999749183655, 0.6287999749183655, 0.6287999749183655,
  0.6287999749183655, 0.6333999633789062, 0.6395999789237976,
  0.6441999673843384, 0.6473000049591064, 0.6519500017166138,
  0.6549999713897705, 0.6626999974250793, 0.67044997215271, 0.6797000169754028,
  0.6879000067710876, 0.694599986076355, 0.6987000107765198, 0.7017999887466431,
  0.7038500308990479, 0.7074499726295471, 0.7125999927520752,
  0.7197999954223633, 0.7274999618530273, 0.7351999878883362,
  0.7414000034332275, 0.7465000152587891, 0.7542499899864197, 0.760949969291687,
  0.7634999752044678, 0.764549970626831, 0.7655500173568726, 0.764549970626831,
  0.764549970626831, 0.764549970626831, 0.7655500173568726, 0.7665500044822693,
  0.7696499824523926, 0.7706999778747559, 0.7706999778747559,
  0.7706999778747559, 0.7706999778747559, 0.7737500071525574,
  0.7763500213623047, 0.7773499488830566, 0.7784000039100647, 0.781499981880188,
  0.7871500253677368, 0.7912499904632568, 0.7912499904632568,
  0.7912499904632568, 0.7912499904632568, 0.7912499904632568,
  0.7922999858856201, 0.7933000326156616, 0.7953499555587769,
  0.7973999977111816, 0.7973999977111816, 0.7973999977111816,
  0.7984499931335449, 0.8025499582290649, 0.8066999912261963,
  0.8112999796867371, 0.8148999810218811, 0.8205499649047852,
  0.8277499675750732, 0.829800009727478, 0.829800009727478, 0.8287999629974365,
  0.8287999629974365, 0.829800009727478, 0.829800009727478, 0.8308500051498413,
  0.8344500064849854, 0.8385499715805054, 0.8385499715805054,
  0.8374999761581421, 0.8364999890327454, 0.8364999890327454,
  0.8364999890327454, 0.8364999890327454, 0.8364999890327454,
  0.8354499936103821, 0.8354499936103821, 0.8354499936103821,
  0.8364999890327454, 0.8364999890327454, 0.8364999890327454,
  0.8374999761581421, 0.8374999761581421, 0.8374999761581421,
  0.8385499715805054, 0.8406000137329102, 0.8416500091552734,
  0.8416500091552734, 0.8406000137329102, 0.8395999670028687,
  0.8395999670028687, 0.8395999670028687, 0.8395999670028687,
  0.8395999670028687, 0.8395999670028687, 0.8406000137329102,
  0.8406000137329102, 0.8406000137329102, 0.8406000137329102,
  0.8406000137329102, 0.8406000137329102, 0.8416500091552734,
  0.8416500091552734, 0.8436999917030334, 0.8447499871253967,
  0.8467999696731567, 0.8488500118255615, 0.853950023651123, 0.8560500144958496,
  0.8560500144958496, 0.8570500016212463, 0.8570500016212463,
  0.8606500029563904, 0.8673499822616577, 0.8714499473571777,
  0.8739999532699585, 0.8739999532699585, 0.8730000257492065,
  0.8730000257492065, 0.8739999532699585, 0.8761000037193298,
  0.8806999921798706, 0.8874000310897827, 0.896649956703186, 0.9079499840736389,
  0.9233999848365784, 0.9403499960899353, 0.9506499767303467,
  0.9583500027656555, 0.9639999866485596, 0.9737499952316284,
  0.9855999946594238, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 0.8436999917030334, 0.6632499694824219, 0.5182499885559082,
  0.34290000796318054, 0.1964000165462494, 0.08945000171661377,
  0.03700000047683716, 0, 0, 0, 0, 0, 0, 0.08749999850988388,
  0.6812499761581421, 0.6187499761581421, 0.5562499761581421,
  0.4375000596046448, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0.612500011920929, 0.643750011920929, 0.581250011920929,
  0.5187499523162842, 0.062499940395355225, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.03805002570152283, 0.05810001492500305,
  0.0750499963760376, 0.08535000681877136, 0.09305000305175781,
  0.10125002264976501, 0.1156499981880188, 0.13265001773834229,
  0.14805001020431519, 0.164000004529953, 0.17990002036094666,
  0.1964000165462494, 0.21080002188682556, 0.23340001702308655,
  0.2549999952316284, 0.27195000648498535, 0.28790000081062317,
  0.30230000615119934, 0.3172000050544739, 0.3331499993801117,
  0.3624500036239624, 0.3917500078678131, 0.41385000944137573, 0.43545001745224,
  0.45500001311302185, 0.4688500165939331, 0.4786500036716461,
  0.48890000581741333, 0.4945499897003174, 0.5008000135421753,
  0.503849983215332, 0.5059000253677368, 0.5079500079154968, 0.5131000280380249,
  0.5162000060081482, 0.5208500027656555, 0.525950014591217, 0.5336999893188477,
  0.5418999791145325, 0.5542500019073486, 0.5619499683380127,
  0.5737999677658081, 0.5876500010490417, 0.6004999876022339,
  0.6123499870300293, 0.6277499794960022, 0.6426500082015991,
  0.6534500122070312, 0.6637499928474426, 0.6797000169754028, 0.694599986076355,
  0.7099999785423279, 0.7249500155448914, 0.7346999645233154,
  0.7459999918937683, 0.7583500146865845, 0.7722499966621399, 0.781499981880188,
  0.7912499904632568, 0.7994999885559082, 0.8066999912261963,
  0.8128499984741211, 0.8200500011444092, 0.8272500038146973,
  0.8344500064849854, 0.8431999683380127, 0.8478000164031982,
  0.8514000177383423, 0.853950023651123, 0.8570500016212463, 0.8590999841690063,
  0.8611999750137329, 0.8622000217437744, 0.8632500171661377,
  0.8668500185012817, 0.8725000023841858, 0.8786499500274658,
  0.8863499760627747, 0.8956500291824341, 0.9038499593734741, 0.911549985408783,
  0.9228500127792358, 0.933650016784668, 0.9455000162124634, 0.9563000202178955,
  0.9660500288009644, 0.976349949836731, 0.9860999584197998, 0.9917500019073486,
  0.998449981212616, 0.9994999766349792, 0.998449981212616, 0.998449981212616,
  0.9994999766349792, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.9783999919891357,
  0.8914999961853027, 0.805649995803833, 0.690500020980835, 0.5727499723434448,
  0.4528999924659729, 0.3490999937057495, 0.2786499857902527,
  0.21744999289512634, 0.15369999408721924, 0.11515000462532043,
  0.08380001783370972, 0.05395001173019409, 0.03855001926422119,
  0.03960001468658447, 0.04575002193450928, 0.06940001249313354,
  0.09714999794960022, 0.11465001106262207, 0.1306000053882599,
  0.14650002121925354, 0.16140002012252808, 0.1779000163078308,
  0.19484999775886536, 0.21694999933242798, 0.23855000734329224,
  0.2683500051498413, 0.2935500144958496, 0.31564998626708984,
  0.34290000796318054, 0.3727499842643738, 0.3978999853134155,
  0.4215500056743622, 0.4447000026702881, 0.4699000120162964,
  0.4853000044822693, 0.5095000267028809, 0.5362499952316284,
  0.5701999664306641, 0.5974500179290771, 0.6220999956130981,
  0.6509000062942505, 0.674049973487854, 0.697700023651123, 0.7141500115394592,
  0.7264499664306641, 0.7423999905586243, 0.7501000165939331, 0.760949969291687,
  0.7670999765396118, 0.7748000025749207, 0.7835500240325928,
  0.7922999858856201, 0.7969000339508057, 0.797950029373169, 0.797950029373169,
  0.7989499568939209, 0.7999999523162842, 0.8010499477386475,
  0.8010499477386475, 0.802049994468689, 0.8041000366210938, 0.807699978351593,
  0.809749960899353, 0.8107999563217163, 0.8154000043869019, 0.8236500024795532,
  0.8292999863624573, 0.8334000110626221, 0.8364999890327454,
  0.8374999761581421, 0.8385499715805054, 0.8385499715805054,
  0.8364999890327454, 0.8334000110626221, 0.8195499777793884,
  0.8071999549865723, 0.8107999563217163, 0.833899974822998, 0.8570500016212463,
  0.8678499460220337, 0.8761000037193298, 0.888949990272522, 0.896649956703186,
  0.896649956703186, 0.888949990272522, 0.8503999710083008, 0.7552499771118164,
  0.6693999767303467, 0.66839998960495, 0.6833000183105469, 0.722350001335144,
  0.7933000326156616, 0.8493499755859375, 0.8806999921798706,
  0.9002499580383301, 0.9182499647140503, 0.9290499687194824,
  0.9459999799728394, 0.9660500288009644, 0.996399998664856, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.8714499473571777,
  0.6421499848365784, 0.40665000677108765, 0.23235002160072327,
  0.12645000219345093, 0.03750002384185791, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0.612500011920929, 0.643750011920929, 0.581250011920929, 0.5187499523162842,
  0.062499940395355225, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0.020550012588500977, 0.027250021696090698,
  0.029300004243850708, 0.031350016593933105, 0.04575002193450928,
  0.06270000338554382, 0.07660001516342163, 0.09665000438690186,
  0.12700000405311584, 0.15729999542236328, 0.1892000138759613,
  0.21950000524520874, 0.2642500102519989, 0.30640000104904175,
  0.3352000117301941, 0.3696500062942505, 0.40665000677108765,
  0.4323500096797943, 0.44624999165534973, 0.46630001068115234,
  0.4837999939918518, 0.49869999289512634, 0.5100499987602234,
  0.522350013256073, 0.5393499732017517, 0.5522000193595886, 0.5630000233650208,
  0.5691499710083008, 0.5763499736785889, 0.5860999822616577,
  0.5958999991416931, 0.6067000031471252, 0.6169499754905701,
  0.6313499808311462, 0.6437000036239624, 0.6554999947547913,
  0.6673499941825867, 0.6817499995231628, 0.6951000094413757,
  0.7059000134468079, 0.7161999940872192, 0.7269999980926514,
  0.7434499859809875, 0.7583500146865845, 0.7675999999046326,
  0.7763500213623047, 0.7835500240325928, 0.7856000065803528,
  0.7856000065803528, 0.7865999937057495, 0.7927999496459961,
  0.7999999523162842, 0.8087500333786011, 0.8148999810218811,
  0.8205499649047852, 0.8256999850273132, 0.8323999643325806,
  0.8400999903678894, 0.8488500118255615, 0.8565499782562256,
  0.8606500029563904, 0.8626999855041504, 0.868399977684021, 0.8750499486923218,
  0.8827500343322754, 0.89205002784729, 0.8997499942779541, 0.9084999561309814,
  0.9182499647140503, 0.9259499907493591, 0.9315999746322632,
  0.9347000122070312, 0.9377999901771545, 0.9419000148773193,
  0.9527000188827515, 0.9639999866485596, 0.9768499732017517,
  0.9876499772071838, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 0.9419000148773193, 0.7999999523162842,
  0.5953500270843506, 0.38815000653266907, 0.22055000066757202,
  0.10180002450942993, 0.03600001335144043, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0.6937499642372131, 0.6312500238418579, 0.5687500238418579,
  0.5062500238418579, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0.024650007486343384, 0.05450001358985901,
  0.08535000681877136, 0.1156499981880188, 0.15320000052452087,
  0.19945001602172852, 0.23594999313354492, 0.2689000070095062,
  0.301800012588501, 0.33675000071525574, 0.36089998483657837,
  0.3758000135421753, 0.38865000009536743, 0.3989500105381012,
  0.40869998931884766, 0.4200499951839447, 0.43389999866485596,
  0.4487999975681305, 0.47040000557899475, 0.4873499870300293,
  0.5048999786376953, 0.5238999724388123, 0.5460000038146973,
  0.5676000118255615, 0.587149977684021, 0.6025500297546387, 0.6195499897003174,
  0.6359999775886536, 0.6488499641418457, 0.6549999713897705,
  0.6549999713897705, 0.6570500135421753, 0.6678500175476074,
  0.6801999807357788, 0.6899499893188477, 0.698199987411499, 0.7089999914169312,
  0.7202999591827393, 0.7315999865531921, 0.7418999671936035,
  0.7511500120162964, 0.7639999985694885, 0.7768499851226807,
  0.7876499891281128, 0.7969000339508057, 0.8071999549865723,
  0.8195499777793884, 0.829800009727478, 0.8374999761581421, 0.8442000150680542,
  0.8508999943733215, 0.8596500158309937, 0.8673499822616577,
  0.8766000270843506, 0.884850025177002, 0.8914999961853027, 0.9007499814033508,
  0.9059000015258789, 0.9105499982833862, 0.911549985408783, 0.9125999808311462,
  0.9146499633789062, 0.918749988079071, 0.9228500127792358, 0.9285500049591064,
  0.9305999875068665, 0.9305999875068665, 0.9315999746322632,
  0.9347000122070312, 0.9357500076293945, 0.9387999773025513, 0.940850019454956,
  0.9429000020027161, 0.9495999813079834, 0.9536999464035034,
  0.9572999477386475, 0.9665499925613403, 0.976349949836731, 0.9840499758720398,
  0.9943499565124512, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.8838000297546387, 0.6801999807357788,
  0.525950014591217, 0.326449990272522, 0.18455001711845398, 0.0889500081539154,
  0.027750015258789062, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0.3499999940395355, 0.6624999642372131, 0.6000000238418579,
  0.5375000238418579, 0.25, 0, 0, 0, 0, 0, 0, 0.17499999701976776,
  0.675000011920929, 0.612500011920929, 0.550000011920929, 0.37500008940696716,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.4375, 0.65625, 0.59375, 0.53125,
  0.18750005960464478, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.4375, 0.65625, 0.59375,
  0.53125, 0.18750005960464478, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0426500141620636,
  0.05705001950263977, 0.06630000472068787, 0.07045000791549683,
  0.07350000739097595, 0.07660001516342163, 0.08380001783370972,
  0.09510001540184021, 0.10385000705718994, 0.11105000972747803,
  0.1141500174999237, 0.11515000462532043, 0.1141500174999237,
  0.11310002207756042, 0.11310002207756042, 0.11310002207756042,
  0.11310002207756042, 0.11310002207756042, 0.11515000462532043,
  0.12800002098083496, 0.1701500117778778, 0.22210001945495605,
  0.30744999647140503, 0.3799000084400177, 0.45445001125335693,
  0.5249500274658203, 0.605650007724762, 0.6529499888420105, 0.6797000169754028,
  0.7017999887466431, 0.7249500155448914, 0.7414000034332275,
  0.7558000087738037, 0.764549970626831, 0.7737500071525574, 0.7840499877929688,
  0.7937999963760376, 0.8025499582290649, 0.8107999563217163,
  0.8205499649047852, 0.8323999643325806, 0.8390499949455261,
  0.8452500104904175, 0.8529499769210815, 0.8601499795913696,
  0.8689000010490417, 0.8755999803543091, 0.8775999546051025,
  0.8775999546051025, 0.8775999546051025, 0.8786499500274658,
  0.8806999921798706, 0.8817499876022339, 0.8827500343322754,
  0.8838000297546387, 0.884850025177002, 0.889449954032898, 0.892549991607666,
  0.8981999754905701, 0.9048999547958374, 0.911549985408783, 0.918749988079071,
  0.9285500049591064, 0.9351999759674072, 0.9429000020027161,
  0.9511500000953674, 0.9619499444961548, 0.9727499485015869,
  0.9840499758720398, 0.996399998664856, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 0.9650499820709229, 0.9099999666213989, 0.8467999696731567,
  0.7686499953269958, 0.72284996509552, 0.6714500188827515, 0.6498500108718872,
  0.6287999749183655, 0.6154000163078308, 0.603600025177002, 0.5866000056266785,
  0.5706999897956848, 0.5578500032424927, 0.5464999675750732,
  0.5378000140190125, 0.5321500301361084, 0.5270000100135803,
  0.5234000086784363, 0.522350013256073, 0.522350013256073, 0.522350013256073,
  0.522350013256073, 0.522350013256073, 0.5213500261306763, 0.5213500261306763,
  0.5213500261306763, 0.5213500261306763, 0.5213500261306763,
  0.5213500261306763, 0.522350013256073, 0.5234000086784363, 0.524399995803833,
  0.5254499912261963, 0.5254499912261963, 0.526449978351593, 0.5315999984741211,
  0.5450000166893005, 0.5665500164031982, 0.5943499803543091,
  0.6061499714851379, 0.6169499754905701, 0.6277499794960022,
  0.6421499848365784, 0.6467999815940857, 0.6509000062942505,
  0.6647999882698059, 0.6837999820709229, 0.698199987411499, 0.7049000263214111,
  0.7110499739646912, 0.7166999578475952, 0.7202999591827393,
  0.7233999967575073, 0.7295500040054321, 0.7398499846458435,
  0.7490999698638916, 0.7542499899864197, 0.7567999958992004, 0.760949969291687,
  0.7630000114440918, 0.7639999985694885, 0.7660499811172485,
  0.7681000232696533, 0.7722499966621399, 0.7768499851226807, 0.783050000667572,
  0.789199948310852, 0.7933000326156616, 0.7994999885559082, 0.8066999912261963,
  0.8159500360488892, 0.8220999836921692, 0.8272500038146973,
  0.8328999876976013, 0.8406000137329102, 0.8529499769210815,
  0.8745499849319458, 0.8997499942779541, 0.9203000068664551,
  0.9449999928474426, 0.9675999879837036, 0.9840499758720398,
  0.9958999752998352, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 0.9239000082015991, 0.6781499981880188, 0.4760499894618988,
  0.26785001158714294, 0.10795000195503235, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0.4375, 0.65625, 0.59375, 0.53125, 0.18750005960464478, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0.612500011920929, 0.643750011920929, 0.581250011920929,
  0.5187499523162842, 0.062499940395355225, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.025700002908706665,
  0.039050012826919556, 0.0504000186920166, 0.05910000205039978,
  0.0678500235080719, 0.07815000414848328, 0.08689999580383301,
  0.09305000305175781, 0.09820002317428589, 0.10950002074241638,
  0.11620000004768372, 0.11825001239776611, 0.11924999952316284,
  0.11924999952316284, 0.12905001640319824, 0.16089999675750732,
  0.23594999313354492, 0.3249000012874603, 0.42465001344680786,
  0.5295500159263611, 0.5727499723434448, 0.6139000058174133,
  0.6369999647140503, 0.6601499915122986, 0.6745499968528748,
  0.6915000081062317, 0.7079499959945679, 0.7259500026702881,
  0.7475500106811523, 0.7670999765396118, 0.7886999845504761, 0.809749960899353,
  0.8354499936103821, 0.8575500249862671, 0.8730000257492065, 0.89205002784729,
  0.9099999666213989, 0.9254499673843384, 0.9377999901771545,
  0.9491000175476074, 0.9635000228881836, 0.9815000295639038,
  0.9973999857902527, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.9300500154495239,
  0.8400999903678894, 0.6956499814987183, 0.518750011920929,
  0.35010001063346863, 0.22005000710487366, 0.1506499946117401,
  0.1002500057220459, 0.061700016260147095, 0.03085002303123474, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.039050012826919556,
  0.06685000658035278, 0.08275002241134644, 0.08995002508163452,
  0.09305000305175781, 0.09305000305175781, 0.09305000305175781,
  0.09305000305175781, 0.09305000305175781, 0.09200000762939453,
  0.0910000205039978, 0.09200000762939453, 0.09305000305175781,
  0.10385000705718994, 0.1295500099658966, 0.15885001420974731,
  0.1953500211238861, 0.22824999690055847, 0.26270002126693726,
  0.2966499924659729, 0.3208000063896179, 0.33775001764297485,
  0.3546999990940094, 0.3614000082015991, 0.36294999718666077,
  0.36294999718666077, 0.3670499920845032, 0.3896999955177307,
  0.41644999384880066, 0.43545001745224, 0.4498499929904938,
  0.45500001311302185, 0.4560000002384186, 0.45910000801086426,
  0.467849999666214, 0.48019999265670776, 0.4950999915599823,
  0.5063999891281128, 0.5218499898910522, 0.5398499965667725,
  0.5522000193595886, 0.5604000091552734, 0.5706999897956848,
  0.5799499750137329, 0.5820000171661377, 0.5809999704360962,
  0.5788999795913696, 0.5706999897956848, 0.5619499683380127,
  0.5619499683380127, 0.5619499683380127, 0.5630000233650208,
  0.5630000233650208, 0.5640000104904175, 0.5701999664306641,
  0.5856000185012817, 0.5928000211715698, 0.5958999991416931,
  0.5948500037193298, 0.5932999849319458, 0.5784000158309937,
  0.5300999879837036, 0.4853000044822693, 0.458050012588501,
  0.42054998874664307, 0.3871000111103058, 0.3968999981880188,
  0.45910000801086426, 0.5634999871253967, 0.6441999673843384,
  0.7161999940872192, 0.7696499824523926, 0.805150032043457, 0.8272500038146973,
  0.8421499729156494, 0.8534500002861023, 0.8652999997138977,
  0.8734999895095825, 0.8822499513626099, 0.8899499773979187,
  0.8971499800682068, 0.8981999754905701, 0.8981999754905701,
  0.8981999754905701, 0.8981999754905701, 0.9023000001907349, 0.909500002861023,
  0.9161999821662903, 0.9192999601364136, 0.920799970626831, 0.918749988079071,
  0.918749988079071, 0.918749988079071, 0.9203000068664551, 0.9203000068664551,
  0.9243999719619751, 0.9300500154495239, 0.9387999773025513,
  0.9465000033378601, 0.9536999464035034, 0.9603999853134155,
  0.9644999504089355, 0.9696500301361084, 0.9773499965667725,
  0.9871499538421631, 0.9958999752998352, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 0.8632500171661377, 0.6498500108718872,
  0.4415999948978424, 0.2955999970436096, 0.16350001096725464,
  0.07864999771118164, 0.03185001015663147, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0.612500011920929, 0.643750011920929, 0.581250011920929,
  0.5187499523162842, 0.062499940395355225, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0.08749999850988388, 0.6812499761581421, 0.6187499761581421,
  0.5562499761581421, 0.4375000596046448, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0406000018119812, 0.06735000014305115,
  0.09615001082420349, 0.11980000138282776, 0.14445000886917114,
  0.16140002012252808, 0.18610000610351562, 0.21744999289512634,
  0.24779999256134033, 0.2806999981403351, 0.3393000066280365,
  0.39430001378059387, 0.4313499927520752, 0.461650013923645,
  0.4899500012397766, 0.5238999724388123, 0.5408999919891357,
  0.5496000051498413, 0.5547500252723694, 0.56454998254776, 0.5670999884605408,
  0.5660499930381775, 0.5640000104904175, 0.5619499683380127, 0.560949981212616,
  0.5598999857902527, 0.5532000064849854, 0.5450000166893005,
  0.5372999906539917, 0.5270000100135803, 0.5156999826431274,
  0.5048999786376953, 0.49970000982284546, 0.494049996137619,
  0.4832499921321869, 0.4688500165939331, 0.4534499943256378,
  0.43799999356269836, 0.42925000190734863, 0.4282500147819519,
  0.42925000190734863, 0.4313499927520752, 0.43389999866485596,
  0.43595001101493835, 0.44315001368522644, 0.4575499892234802,
  0.4672999978065491, 0.4740000069141388, 0.48019999265670776,
  0.48225000500679016, 0.48225000500679016, 0.4812000095844269,
  0.48019999265670776, 0.4791499972343445, 0.4791499972343445,
  0.48019999265670776, 0.48019999265670776, 0.4812000095844269,
  0.48429998755455017, 0.4873499870300293, 0.4873499870300293,
  0.48429998755455017, 0.4760499894618988, 0.4637500047683716,
  0.43185001611709595, 0.3604000210762024, 0.29715001583099365,
  0.23235002160072327, 0.17430001497268677, 0.11204999685287476,
  0.0637499988079071, 0.04110002517700195, 0.023650020360946655, 0, 0, 0, 0,
  0.028800010681152344, 0.13210001587867737, 0.24420002102851868,
  0.36500000953674316, 0.4878999888896942, 0.5804499983787537,
  0.6570500135421753, 0.7279999852180481, 0.7727500200271606,
  0.8066999912261963, 0.8267499804496765, 0.8457499742507935,
  0.8570500016212463, 0.8689000010490417, 0.884850025177002, 0.8981999754905701,
  0.913100004196167, 0.9259499907493591, 0.9398499727249146, 0.9563000202178955,
  0.9716999530792236, 0.9860999584197998, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  0.9891999959945679, 0.8200500011444092, 0.6139000058174133,
  0.41075000166893005, 0.2667999863624573, 0.1712000072002411,
  0.10539999604225159, 0.05345001816749573, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0.17499999701976776, 0.675000011920929, 0.612500011920929, 0.550000011920929,
  0.37500008940696716, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0.020050019025802612, 0.0252000093460083, 0.033399999141693115,
  0.03805002570152283, 0.04110002517700195, 0.049850016832351685,
  0.0637499988079071, 0.07455000281333923, 0.08224999904632568,
  0.09200000762939453, 0.1023000180721283, 0.11000001430511475,
  0.11980000138282776, 0.1254500150680542, 0.12905001640319824,
  0.13005000352859497, 0.13109999895095825, 0.13109999895095825,
  0.13210001587867737, 0.13780000805854797, 0.14705002307891846,
  0.1547500193119049, 0.1578499972820282, 0.1578499972820282,
  0.15630000829696655, 0.1547500193119049, 0.14395001530647278,
  0.11980000138282776, 0.08380001783370972, 0.05450001358985901,
  0.02105000615119934, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0.04780000448226929, 0.1753000020980835, 0.27915000915527344,
  0.38609999418258667, 0.43185001611709595, 0.46320000290870667,
  0.4786500036716461, 0.49970000982284546, 0.527999997138977,
  0.5516499876976013, 0.5763499736785889, 0.6025500297546387,
  0.6267499923706055, 0.6503999829292297, 0.66839998960495, 0.6833000183105469,
  0.697700023651123, 0.7161999940872192, 0.7331500053405762, 0.7470499873161316,
  0.7573000192642212, 0.7634999752044678, 0.7717000246047974,
  0.7768499851226807, 0.7799500226974487, 0.781000018119812, 0.783050000667572,
  0.7871500253677368, 0.7948499917984009, 0.797950029373169, 0.801550030708313,
  0.8066999912261963, 0.8159500360488892, 0.8236500024795532,
  0.8319000005722046, 0.8395999670028687, 0.8467999696731567,
  0.8514000177383423, 0.8575500249862671, 0.8586000204086304,
  0.8586000204086304, 0.8596500158309937, 0.864799976348877, 0.8704500198364258,
  0.8766000270843506, 0.8797000050544739, 0.8822499513626099,
  0.8853499889373779, 0.8946000337600708, 0.9017999768257141,
  0.9084999561309814, 0.913100004196167, 0.9218499660491943, 0.9279999732971191,
  0.9290499687194824, 0.9300500154495239, 0.9311000108718872,
  0.9362499713897705, 0.9419000148773193, 0.9459999799728394,
  0.9459999799728394, 0.9459999799728394, 0.9459999799728394,
  0.9480500221252441, 0.9542499780654907, 0.9603999853134155,
  0.9675999879837036, 0.979949951171875, 0.9922999739646912, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1,
];
