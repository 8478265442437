import { MantineColor, createStyles } from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import { useEffect, useMemo, useRef } from "react";
import { select } from "d3-selection";
import useGetAxis from "../../hooks/useGetAxis";
import { formatYAxisType } from "../../data/TelemetryData";

const AXIS_PADDING = 200 * 0.15;

interface ITelemetryAxis {
  data: number[][];
  trackTicks: number[];
  yMin: number;
  yMax: number;
  color: MantineColor[];
  formatYAxis?: formatYAxisType;
}

const useStyle = createStyles((theme) => ({
  svg: {
    position: "relative",
    width: "100%",
    height: "100%",
    fill: "none",
    pointerEvents: "none",
  },

  axis: {
    position: "relative",
    fontSize: theme.fontSizes.xs,
  },
}));

export default function TelemetryAxis({
  data,
  trackTicks,
  yMin,
  yMax,
  color,
  formatYAxis,
}: ITelemetryAxis) {
  const { ref: refParent, width, height } = useElementSize();
  const yAxisRef = useRef(null);
  const xAxisRef = useRef(null);
  const { classes, theme } = useStyle();

  const { path, bottomAxis, leftAxis, strokeWidth } = useGetAxis({
    data: data[0],
    trackTicks,
    width,
    height,
    axisPadding: AXIS_PADDING,
    yMin,
    yMax,
    formatYAxis,
  });

  useEffect(() => {
    if (!yAxisRef) return;

    select(xAxisRef.current).call(bottomAxis as any);

    select(yAxisRef.current)
      .call(leftAxis as any)
      .selectAll(".tick line")
      .attr("opacity", 0.2);

    //eslint-disable-next-line
  }, [bottomAxis, yAxisRef]);

  const pathData = useMemo(
    () => data.map((dataElement) => path(dataElement as any) || ""),
    [path, data]
  );

  const svgPath = useMemo(
    () =>
      pathData.map((svgData, index) => {
        return (
          <path
            key={index}
            strokeWidth={strokeWidth}
            stroke={theme.colors[color[index]][7]}
            d={svgData}
          />
        );
      }),

    //eslint-disable-next-line
    [pathData]
  );

  return (
    <svg className={classes.svg} ref={refParent}>
      {svgPath}
      <g
        ref={yAxisRef}
        className={classes.axis}
        style={{
          transform: `translate(${AXIS_PADDING}px, 0)`,
        }}
      />
      <g
        ref={xAxisRef}
        className={classes.axis}
        style={{
          transform: `translate(0, calc(100% - ${AXIS_PADDING}px))`,
        }}
      />
    </svg>
  );
}
