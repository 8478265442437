export const RR_TEMP_DATA = [
  84.56914520263672, 84.56914520263672, 84.5685043334961, 84.56781005859375,
  84.56723022460938, 84.566650390625, 84.56605529785156, 84.56548309326172,
  84.56480407714844, 84.56400299072266, 84.56331634521484, 84.56267547607422,
  84.56221008300781, 84.5619888305664, 84.56189727783203, 84.56182098388672,
  84.56170654296875, 84.56126403808594, 84.56067657470703, 84.56009674072266,
  84.55961608886719, 84.55915069580078, 84.55875396728516, 84.558349609375,
  84.55793762207031, 84.5574722290039, 84.55697631835938, 84.55642700195312,
  84.5560073852539, 84.5556640625, 84.55538940429688, 84.5550765991211,
  84.55473327636719, 84.55422973632812, 84.55387878417969, 84.55359649658203,
  84.55350494384766, 84.5534439086914, 84.55318450927734, 84.55274963378906,
  84.55216979980469, 84.55143737792969, 84.55049896240234, 84.5497055053711,
  84.54883575439453, 84.54803466796875, 84.54720306396484, 84.54634094238281,
  84.54530334472656, 84.54426574707031, 84.5433349609375, 84.54246520996094,
  84.54180145263672, 84.5412368774414, 84.54073333740234, 84.54000091552734,
  84.53913879394531, 84.53822326660156, 84.53723907470703, 84.53618621826172,
  84.53527069091797, 84.53455352783203, 84.5338363647461, 84.53297424316406,
  84.53197479248047, 84.53101348876953, 84.53013610839844, 84.52936553955078,
  84.52885437011719, 84.5286865234375, 84.52843475341797, 84.5278091430664,
  84.52688598632812, 84.52576446533203, 84.5245590209961, 84.52340698242188,
  84.52226257324219, 84.52112579345703, 84.5199966430664, 84.51873779296875,
  84.51751708984375, 84.51629638671875, 84.51512145996094, 84.51396179199219,
  84.51285552978516, 84.51163482666016, 84.51031494140625, 84.50897979736328,
  84.50770568847656, 84.50633239746094, 84.50482177734375, 84.50328826904297,
  84.50167846679688, 84.5001220703125, 84.4985580444336, 84.49706268310547,
  84.49546813964844, 84.49385833740234, 84.49230194091797, 84.49069213867188,
  84.4890365600586, 84.48735809326172, 84.48587799072266, 84.48453521728516,
  84.48321533203125, 84.48188781738281, 84.48047637939453, 84.47903442382812,
  84.4775619506836, 84.4760513305664, 84.47449493408203, 84.47293090820312,
  84.4713363647461, 84.46974182128906, 84.4681396484375, 84.46656036376953,
  84.46507263183594, 84.4636459350586, 84.46214294433594, 84.46061706542969,
  84.45901489257812, 84.45740509033203, 84.45580291748047, 84.4541244506836,
  84.45240783691406, 84.45067596435547, 84.44898986816406, 84.44741821289062,
  84.44596099853516, 84.44447326660156, 84.44298553466797, 84.44148254394531,
  84.4399185180664, 84.43830108642578, 84.43658447265625, 84.43484497070312,
  84.43314361572266, 84.43136596679688, 84.42955017089844, 84.42781829833984,
  84.42616271972656, 84.42449951171875, 84.42273712158203, 84.42097473144531,
  84.41922760009766, 84.4174575805664, 84.41561889648438, 84.41375732421875,
  84.41189575195312, 84.40998840332031, 84.4080810546875, 84.40614318847656,
  84.40418243408203, 84.40228271484375, 84.40049743652344, 84.39864349365234,
  84.39676666259766, 84.39485931396484, 84.3929214477539, 84.39095306396484,
  84.38893127441406, 84.38692474365234, 84.38493347167969, 84.38296508789062,
  84.3809814453125, 84.37899780273438, 84.37698364257812, 84.3749771118164,
  84.37296295166016, 84.37092590332031, 84.36888122558594, 84.3668441772461,
  84.36481475830078, 84.3627700805664, 84.3607406616211, 84.35874938964844,
  84.35671997070312, 84.35469818115234, 84.35266876220703, 84.3505630493164,
  84.34846496582031, 84.34638977050781, 84.34426879882812, 84.34214782714844,
  84.34000396728516, 84.33785247802734, 84.3357162475586, 84.33356475830078,
  84.33138275146484, 84.32920837402344, 84.32701110839844, 84.32479858398438,
  84.32256317138672, 84.32028198242188, 84.3179931640625, 84.31568145751953,
  84.31334686279297, 84.3110122680664, 84.30862426757812, 84.30622863769531,
  84.30387878417969, 84.30160522460938, 84.29934692382812, 84.29703521728516,
  84.29475402832031, 84.29246520996094, 84.29013061523438, 84.28779602050781,
  84.28548431396484, 84.28312683105469, 84.28076171875, 84.27839660644531,
  84.2760238647461, 84.273681640625, 84.27134704589844, 84.26900482177734,
  84.26665496826172, 84.26431274414062, 84.261962890625, 84.25959777832031,
  84.2572250366211, 84.25486755371094, 84.25251007080078, 84.25017547607422,
  84.24784088134766, 84.2454605102539, 84.24305725097656, 84.24067687988281,
  84.23827362060547, 84.23591613769531, 84.23361206054688, 84.2313003540039,
  84.22901153564453, 84.22671508789062, 84.22438049316406, 84.22205352783203,
  84.21965789794922, 84.21724700927734, 84.21478271484375, 84.21231079101562,
  84.2098617553711, 84.20740509033203, 84.20500946044922, 84.20252227783203,
  84.20002746582031, 84.19755554199219, 84.19509887695312, 84.19266510009766,
  84.19026184082031, 84.18791198730469, 84.18561553955078, 84.18335723876953,
  84.18108367919922, 84.17881774902344, 84.17660522460938, 84.1744155883789,
  84.1722640991211, 84.17013549804688, 84.16802978515625, 84.16592407226562,
  84.16382598876953, 84.16166687011719, 84.15943145751953, 84.15731048583984,
  84.15538787841797, 84.15352630615234, 84.15180206298828, 84.15023040771484,
  84.14865112304688, 84.14707946777344, 84.14564514160156, 84.14397430419922,
  84.14197540283203, 84.14014434814453, 84.13843536376953, 84.13667297363281,
  84.13514709472656, 84.13392639160156, 84.13272857666016, 84.13134002685547,
  84.12977600097656, 84.12799072265625, 84.12635040283203, 84.12468719482422,
  84.1229248046875, 84.12145233154297, 84.12014770507812, 84.11876678466797,
  84.1171646118164, 84.11554718017578, 84.11392211914062, 84.11219787597656,
  84.11066436767578, 84.10932922363281, 84.10802459716797, 84.10678100585938,
  84.10548400878906, 84.10397338867188, 84.10230255126953, 84.10054779052734,
  84.09881591796875, 84.09772491455078, 84.0966567993164, 84.09597778320312,
  84.09524536132812, 84.09405517578125, 84.09272766113281, 84.09117889404297,
  84.08960723876953, 84.08808898925781, 84.08660125732422, 84.08512878417969,
  84.08382415771484, 84.08280944824219, 84.0818862915039, 84.08088684082031,
  84.07988739013672, 84.07909393310547, 84.0782470703125, 84.07720184326172,
  84.0759506225586, 84.0748291015625, 84.07391357421875, 84.0731201171875,
  84.07231140136719, 84.07146453857422, 84.0704574584961, 84.0693359375,
  84.06818389892578, 84.06707763671875, 84.06603240966797, 84.06501770019531,
  84.06380462646484, 84.06254577636719, 84.06132507324219, 84.06015014648438,
  84.05896759033203, 84.05779266357422, 84.05656433105469, 84.05518341064453,
  84.05372619628906, 84.05226135253906, 84.05091857910156, 84.04969787597656,
  84.04862213134766, 84.04747772216797, 84.04624938964844, 84.04492950439453,
  84.0435791015625, 84.04226684570312, 84.04112243652344, 84.04011535644531,
  84.03923797607422, 84.03832244873047, 84.03704071044922, 84.03553009033203,
  84.03396606445312, 84.03252410888672, 84.03134155273438, 84.03025817871094,
  84.02913665771484, 84.02791595458984, 84.02670288085938, 84.02550506591797,
  84.02435302734375, 84.02312469482422, 84.02182006835938, 84.02043914794922,
  84.01898956298828, 84.0175552368164, 84.01615905761719, 84.01490020751953,
  84.01372528076172, 84.01258850097656, 84.01134490966797, 84.01006317138672,
  84.0086669921875, 84.0071792602539, 84.00569915771484, 84.004150390625,
  84.00262451171875, 84.00137329101562, 84.00021362304688, 83.99913787841797,
  83.99803161621094, 83.9970703125, 83.99604797363281, 83.99501037597656,
  83.99384307861328, 83.99275970458984, 83.9916000366211, 83.9903335571289,
  83.98896789550781, 83.98762512207031, 83.98634338378906, 83.98516845703125,
  83.98409271240234, 83.98302459716797, 83.98194122314453, 83.9808120727539,
  83.97948455810547, 83.97795104980469, 83.97626495361328, 83.9745101928711,
  83.97274780273438, 83.97103881835938, 83.96949005126953, 83.968017578125,
  83.9665298461914, 83.96511840820312, 83.9637222290039, 83.96222686767578,
  83.9607162475586, 83.9592056274414, 83.9576644897461, 83.9560317993164,
  83.954345703125, 83.95275115966797, 83.95114135742188, 83.94947814941406,
  83.94782257080078, 83.94615936279297, 83.94448852539062, 83.94280242919922,
  83.94117736816406, 83.93956756591797, 83.93800354003906, 83.9363784790039,
  83.93468475341797, 83.93297576904297, 83.9312515258789, 83.92948913574219,
  83.9276123046875, 83.92579650878906, 83.92402648925781, 83.9222412109375,
  83.9205093383789, 83.918701171875, 83.91687774658203, 83.91507720947266,
  83.9132080078125, 83.91136932373047, 83.90950012207031, 83.90766143798828,
  83.90586853027344, 83.90398406982422, 83.90210723876953, 83.90023803710938,
  83.89835357666016, 83.8964614868164, 83.89459228515625, 83.89273071289062,
  83.89081573486328, 83.88887786865234, 83.886962890625, 83.88502502441406,
  83.88307189941406, 83.881103515625, 83.87913513183594, 83.87715148925781,
  83.8751449584961, 83.87313079833984, 83.87110137939453, 83.86906433105469,
  83.8670425415039, 83.86502075195312, 83.86299896240234, 83.86096954345703,
  83.85893249511719, 83.85688781738281, 83.85482025146484, 83.85273742675781,
  83.8506851196289, 83.84864044189453, 83.84657287597656, 83.8445053100586,
  83.8424301147461, 83.84033966064453, 83.83824920654297, 83.83616638183594,
  83.83407592773438, 83.83198547363281, 83.82989501953125, 83.82780456542969,
  83.8257064819336, 83.82359313964844, 83.82145690917969, 83.81932830810547,
  83.81719207763672, 83.81505584716797, 83.81292724609375, 83.81078338623047,
  83.80863189697266, 83.80647277832031, 83.80433654785156, 83.80220031738281,
  83.80004119873047, 83.7978515625, 83.79566192626953, 83.79348754882812,
  83.79132843017578, 83.7891616821289, 83.78697967529297, 83.78479766845703,
  83.78260040283203, 83.78036499023438, 83.77813720703125, 83.77593231201172,
  83.77373504638672, 83.77153015136719, 83.769287109375, 83.76702880859375,
  83.7647705078125, 83.76252746582031, 83.76028442382812, 83.7580337524414,
  83.75576782226562, 83.75349426269531, 83.75121307373047, 83.7489242553711,
  83.74662780761719, 83.74432373046875, 83.74201965332031, 83.73971557617188,
  83.73739624023438, 83.73507690429688, 83.73274993896484, 83.73041534423828,
  83.72808074951172, 83.72575378417969, 83.7234115600586, 83.72106170654297,
  83.71871185302734, 83.71636199951172, 83.71402740478516, 83.71170806884766,
  83.70939636230469, 83.7070541381836, 83.70469665527344, 83.70231628417969,
  83.69994354248047, 83.69757843017578, 83.69522094726562, 83.69287872314453,
  83.69054412841797, 83.6882095336914, 83.68586730957031, 83.68351745605469,
  83.68115997314453, 83.67882537841797, 83.67649841308594, 83.6741714477539,
  83.67183685302734, 83.66950225830078, 83.66716003417969, 83.66480255126953,
  83.66246032714844, 83.6601333618164, 83.6578140258789, 83.65550231933594,
  83.6531982421875, 83.65087890625, 83.64856719970703, 83.64623260498047,
  83.64390563964844, 83.64157104492188, 83.63924407958984, 83.63690948486328,
  83.63458251953125, 83.63226318359375, 83.62992858886719, 83.62760162353516,
  83.62528991699219, 83.62299346923828, 83.62071228027344, 83.61842346191406,
  83.61612701416016, 83.61383056640625, 83.61153411865234, 83.60923767089844,
  83.60698699951172, 83.60474395751953, 83.60249328613281, 83.60025787353516,
  83.59801483154297, 83.59576416015625, 83.59349822998047, 83.59122467041016,
  83.58894348144531, 83.58667755126953, 83.58444213867188, 83.58221435546875,
  83.57996368408203, 83.57769775390625, 83.57540130615234, 83.5731201171875,
  83.57085418701172, 83.568603515625, 83.56634521484375, 83.5640640258789,
  83.5617446899414, 83.55941009521484, 83.55709075927734, 83.55481719970703,
  83.55252838134766, 83.55022430419922, 83.54793548583984, 83.54566955566406,
  83.5434341430664, 83.5412368774414, 83.5390396118164, 83.53681945800781,
  83.53458404541016, 83.5323257446289, 83.53009033203125, 83.52790832519531,
  83.52572631835938, 83.5235366821289, 83.52136993408203, 83.51919555664062,
  83.51700592041016, 83.51480865478516, 83.51263427734375, 83.5104751586914,
  83.50828552246094, 83.50611877441406, 83.50403594970703, 83.50199890136719,
  83.49992370605469, 83.49783325195312, 83.4957046508789, 83.49357604980469,
  83.49147033691406, 83.48939514160156, 83.48735046386719, 83.48530578613281,
  83.48326110839844, 83.48123168945312, 83.47919464111328, 83.47714233398438,
  83.47511291503906, 83.47311401367188, 83.47115325927734, 83.46916198730469,
  83.4671401977539, 83.4651107788086, 83.46306610107422, 83.46102142333984,
  83.4590072631836, 83.45697784423828, 83.4549560546875, 83.45294952392578,
  83.450927734375, 83.44886016845703, 83.44676208496094, 83.44462585449219,
  83.44249725341797, 83.44046020507812, 83.43842315673828, 83.43632507324219,
  83.43421936035156, 83.43220520019531, 83.43019104003906, 83.42816925048828,
  83.42613983154297, 83.42411041259766, 83.42205810546875, 83.42002868652344,
  83.41804504394531, 83.41606903076172, 83.41407775878906, 83.41207885742188,
  83.41007995605469, 83.4080810546875, 83.4061279296875, 83.40419006347656,
  83.40225219726562, 83.40027618408203, 83.3983154296875, 83.3963394165039,
  83.39437103271484, 83.39239501953125, 83.39038848876953, 83.38839721679688,
  83.3864517211914, 83.384521484375, 83.38259887695312, 83.38066864013672,
  83.37877655029297, 83.3768310546875, 83.3748779296875, 83.37295532226562,
  83.37100219726562, 83.36909484863281, 83.36722564697266, 83.36537170410156,
  83.36349487304688, 83.36165618896484, 83.35985565185547, 83.35801696777344,
  83.35623168945312, 83.35446166992188, 83.35273742675781, 83.3509750366211,
  83.34918975830078, 83.3473892211914, 83.34545135498047, 83.3438949584961,
  83.34220886230469, 83.34053039550781, 83.33885955810547, 83.3371810913086,
  83.33546447753906, 83.33370208740234, 83.33202362060547, 83.33035278320312,
  83.32867431640625, 83.32701110839844, 83.32538604736328, 83.32381439208984,
  83.32225036621094, 83.32068634033203, 83.31905364990234, 83.31739044189453,
  83.31564331054688, 83.31420135498047, 83.3126449584961, 83.31120300292969,
  83.30984497070312, 83.30852508544922, 83.30702209472656, 83.30572509765625,
  83.3042221069336, 83.3027114868164, 83.30122375488281, 83.29975891113281,
  83.2983169555664, 83.29696655273438, 83.29566192626953, 83.29439544677734,
  83.2931137084961, 83.29187774658203, 83.29073333740234, 83.28968048095703,
  83.28844451904297, 83.28728485107422, 83.2862777709961, 83.28523254394531,
  83.28437042236328, 83.28364562988281, 83.28305053710938, 83.28239440917969,
  83.2814712524414, 83.2806396484375, 83.27970886230469, 83.2789077758789,
  83.27824401855469, 83.27776336669922, 83.2773666381836, 83.2771224975586,
  83.2768783569336, 83.27655029296875, 83.27618408203125, 83.27584075927734,
  83.27546691894531, 83.27523040771484, 83.27510070800781, 83.2750244140625,
  83.27491760253906, 83.2747802734375, 83.27458953857422, 83.27433776855469,
  83.27413177490234, 83.27387237548828, 83.27365112304688, 83.27349853515625,
  83.27333068847656, 83.27303314208984, 83.27263641357422, 83.27222442626953,
  83.27181243896484, 83.27137756347656, 83.27091217041016, 83.27046203613281,
  83.27005004882812, 83.26973724365234, 83.26959991455078, 83.26956176757812,
  83.26954650878906, 83.26946258544922, 83.2693099975586, 83.26910400390625,
  83.26885223388672, 83.26860046386719, 83.26837921142578, 83.26821899414062,
  83.26811218261719, 83.26803588867188, 83.2679672241211, 83.2679214477539,
  83.26789855957031, 83.26789855957031, 83.26795196533203, 83.2680435180664,
  83.26818084716797, 83.26837921142578, 83.26861572265625, 83.26886749267578,
  83.26913452148438, 83.26943969726562, 83.26976013183594, 83.27011108398438,
  83.27049255371094, 83.27088928222656, 83.27131652832031, 83.27177429199219,
  83.27223205566406, 83.2726821899414, 83.27313995361328, 83.27361297607422,
  83.27410888671875, 83.2746353149414, 83.27522277832031, 83.27581024169922,
  83.27643585205078, 83.27713012695312, 83.27790832519531, 83.27874755859375,
  83.27959442138672, 83.28042602539062, 83.28125, 83.28209686279297,
  83.28297424316406, 83.28388977050781, 83.28486633300781, 83.28589630126953,
  83.2869644165039, 83.2880859375, 83.28925323486328, 83.2904281616211,
  83.29159545898438, 83.29277801513672, 83.29396057128906, 83.29515838623047,
  83.29637145996094, 83.29757690429688, 83.2988510131836, 83.30018615722656,
  83.30149841308594, 83.30277252197266, 83.30406951904297, 83.30532836914062,
  83.30662536621094, 83.30795288085938, 83.30933380126953, 83.31075286865234,
  83.31224822998047, 83.31380462646484, 83.3154296875, 83.31705474853516,
  83.31868743896484, 83.32032775878906, 83.32196807861328, 83.3235855102539,
  83.32518768310547, 83.32672882080078, 83.32826232910156, 83.32979583740234,
  83.3314208984375, 83.3331527709961, 83.33501434326172, 83.3369369506836,
  83.3388671875, 83.34077453613281, 83.3426742553711, 83.3445816040039,
  83.3464584350586, 83.34833526611328, 83.3502197265625, 83.35218811035156,
  83.35423278808594, 83.35631561279297, 83.35843658447266, 83.36062622070312,
  83.36283874511719, 83.36505889892578, 83.3673095703125, 83.36957550048828,
  83.3718490600586, 83.37413024902344, 83.37640380859375, 83.37870788574219,
  83.38107299804688, 83.38346862792969, 83.38594818115234, 83.38854217529297,
  83.39115142822266, 83.39385986328125, 83.3966064453125, 83.39937591552734,
  83.40191650390625, 83.40446472167969, 83.4070053100586, 83.40982055664062,
  83.41278839111328, 83.41584777832031, 83.41897583007812, 83.42207336425781,
  83.42512512207031, 83.42802429199219, 83.43072509765625, 83.43336486816406,
  83.43603515625, 83.43880462646484, 83.44165802001953, 83.44465637207031,
  83.4478759765625, 83.45115661621094, 83.45443725585938, 83.45755767822266,
  83.46051025390625, 83.46336364746094, 83.46614074707031, 83.46885681152344,
  83.47156524658203, 83.47432708740234, 83.47720336914062, 83.48019409179688,
  83.4832534790039, 83.48634338378906, 83.48941802978516, 83.4924545288086,
  83.49543762207031, 83.4983901977539, 83.50130462646484, 83.50421905517578,
  83.50713348388672, 83.5101089477539, 83.51315307617188, 83.51619720458984,
  83.51921844482422, 83.5221939086914, 83.52515411376953, 83.52812957763672,
  83.53106689453125, 83.53397369384766, 83.53682708740234, 83.5396728515625,
  83.54251861572266, 83.54534149169922, 83.54814147949219, 83.5509033203125,
  83.55366516113281, 83.55638885498047, 83.55902099609375, 83.56158447265625,
  83.56413269042969, 83.56673431396484, 83.56934356689453, 83.57196807861328,
  83.57465362548828, 83.57740783691406, 83.58019256591797, 83.58292388916016,
  83.58574676513672, 83.58848571777344, 83.59109497070312, 83.59391021728516,
  83.59679412841797, 83.59962463378906, 83.60234832763672, 83.60491943359375,
  83.60746765136719, 83.61003875732422, 83.61265563964844, 83.6153335571289,
  83.61803436279297, 83.62069702148438, 83.62335205078125, 83.62602233886719,
  83.62872314453125, 83.63139343261719, 83.63426971435547, 83.63706970214844,
  83.63985443115234, 83.6424789428711, 83.6449966430664, 83.6475830078125,
  83.65022277832031, 83.65289306640625, 83.65557098388672, 83.65814208984375,
  83.6606216430664, 83.66316223144531, 83.66578674316406, 83.66844940185547,
  83.67113494873047, 83.67373657226562, 83.6764907836914, 83.6787338256836,
  83.68155670166016, 83.68384552001953, 83.68672180175781, 83.68914031982422,
  83.69171905517578, 83.6943359375, 83.69694519042969, 83.69953918457031,
  83.70235443115234, 83.70463562011719, 83.7073974609375, 83.709716796875,
  83.71231079101562, 83.71487426757812, 83.71751403808594, 83.72025299072266,
  83.72247314453125, 83.72515869140625, 83.72791290283203, 83.73066711425781,
  83.73335266113281, 83.73599243164062, 83.73863983154297, 83.7413558959961,
  83.7439956665039, 83.74658966064453, 83.74919891357422, 83.75186157226562,
  83.75457000732422, 83.75724029541016, 83.75989532470703, 83.76254272460938,
  83.76514434814453, 83.76770782470703, 83.7702407836914, 83.7727279663086,
  83.77521514892578, 83.77778625488281, 83.78036499023438, 83.78303527832031,
  83.78571319580078, 83.7883529663086, 83.79093933105469, 83.79349517822266,
  83.79593658447266, 83.79830169677734, 83.80070495605469, 83.80321502685547,
  83.80577087402344, 83.808349609375, 83.81090545654297, 83.81340789794922,
  83.81568908691406, 83.81787872314453, 83.82012939453125, 83.82249450683594,
  83.82489013671875, 83.82736206054688, 83.82978820800781, 83.83216857910156,
  83.834716796875, 83.8371810913086, 83.839599609375, 83.84220886230469,
  83.84468078613281, 83.84690856933594, 83.84906005859375, 83.85113525390625,
  83.8532943725586, 83.8555908203125, 83.85785675048828, 83.8602066040039,
  83.86271667480469, 83.8651351928711, 83.86750793457031, 83.86979675292969,
  83.87200164794922, 83.87442016601562, 83.876708984375, 83.87862396240234,
  83.88053894042969, 83.88245391845703, 83.8846435546875, 83.886962890625,
  83.8892822265625, 83.89158630371094, 83.89389038085938, 83.89615631103516,
  83.89837646484375, 83.9005355834961, 83.90269470214844, 83.90495300292969,
  83.90730285644531, 83.90975189208984, 83.91230010986328, 83.91485595703125,
  83.9173583984375, 83.9197998046875, 83.92216491699219, 83.92444610595703,
  83.92674255371094, 83.92911529541016, 83.93158721923828, 83.9342041015625,
  83.93685913085938, 83.93949127197266, 83.94198608398438, 83.9443130493164,
  83.94642639160156, 83.94851684570312, 83.95063781738281, 83.95287322998047,
  83.95519256591797, 83.95752716064453, 83.95986938476562, 83.96222686767578,
  83.96459197998047, 83.96695709228516, 83.96926879882812, 83.97157287597656,
  83.97386932373047, 83.97613525390625, 83.9783935546875, 83.98065185546875,
  83.9829330444336, 83.98529815673828, 83.98773956298828, 83.99018859863281,
  83.99260711669922, 83.99500274658203, 83.99742126464844, 83.99984741210938,
  84.00234985351562, 84.00487518310547, 84.0074462890625, 84.010009765625,
  84.0125503540039, 84.0151138305664, 84.01766204833984, 84.02027130126953,
  84.02293395996094, 84.02555847167969, 84.02815246582031, 84.03065490722656,
  84.03311920166016, 84.0356216430664, 84.03829193115234, 84.04112243652344,
  84.04408264160156, 84.04701232910156, 84.04984283447266, 84.0524673461914,
  84.05499267578125, 84.05750274658203, 84.0601577758789, 84.06298065185547,
  84.06586456298828, 84.06880187988281, 84.07172393798828, 84.0746078491211,
  84.07748413085938, 84.080322265625, 84.08314514160156, 84.08600616455078,
  84.08895111083984, 84.09190368652344, 84.09484100341797, 84.09780883789062,
  84.10079193115234, 84.10381317138672, 84.10684967041016, 84.1098861694336,
  84.11299133300781, 84.1161117553711, 84.1192398071289, 84.12236785888672,
  84.12544250488281, 84.12850952148438, 84.13159942626953, 84.13473510742188,
  84.13790893554688, 84.14115142822266, 84.14437866210938, 84.14762115478516,
  84.15088653564453, 84.15410614013672, 84.15730285644531, 84.16050720214844,
  84.1637191772461, 84.16696166992188, 84.17023468017578, 84.17356872558594,
  84.17696380615234, 84.18041229248047, 84.18390655517578, 84.18738555908203,
  84.19083404541016, 84.19429016113281, 84.19778442382812, 84.20124816894531,
  84.2047348022461, 84.20826721191406, 84.21183013916016, 84.21540832519531,
  84.21894836425781, 84.22248840332031, 84.22605895996094, 84.22967529296875,
  84.23335266113281, 84.23705291748047, 84.24076080322266, 84.24445343017578,
  84.24813842773438, 84.2518310546875, 84.25550842285156, 84.25918579101562,
  84.26289367675781, 84.26663970947266, 84.27042388916016, 84.27417755126953,
  84.27789306640625, 84.28158569335938, 84.28522491455078, 84.28883361816406,
  84.29244232177734, 84.29610443115234, 84.29979705810547, 84.30355834960938,
  84.3073501586914, 84.31119537353516, 84.31509399414062, 84.31897735595703,
  84.32283020019531, 84.32669067382812, 84.3309326171875, 84.33445739746094,
  84.33844757080078, 84.34256744384766, 84.34676361083984, 84.3509521484375,
  84.3551254272461, 84.35929870605469, 84.36344146728516, 84.36747741699219,
  84.37141418457031, 84.375244140625, 84.37899780273438, 84.38285827636719,
  84.38688659667969, 84.39105224609375, 84.39532470703125, 84.39965057373047,
  84.4040756225586, 84.40849304199219, 84.41292572021484, 84.41740417480469,
  84.42188262939453, 84.42642974853516, 84.43103790283203, 84.43573760986328,
  84.44053649902344, 84.44539642333984, 84.4503173828125, 84.45523071289062,
  84.46012878417969, 84.46501922607422, 84.46998596191406, 84.47504425048828,
  84.48019409179688, 84.48535919189453, 84.49053955078125, 84.49576568603516,
  84.50106048583984, 84.50643920898438, 84.51187896728516, 84.51734161376953,
  84.52289581298828, 84.52854919433594, 84.53421783447266, 84.53987884521484,
  84.54549407958984, 84.55108642578125, 84.55674743652344, 84.56255340576172,
  84.5685806274414, 84.57475280761719, 84.58101654052734, 84.58731842041016,
  84.59363555908203, 84.5999526977539, 84.60627746582031, 84.61266326904297,
  84.61906433105469, 84.62549591064453, 84.63201904296875, 84.63859558105469,
  84.64527130126953, 84.65203857421875, 84.65885925292969, 84.66572570800781,
  84.672607421875, 84.67951965332031, 84.68648529052734, 84.69351196289062,
  84.7005844116211, 84.70774841308594, 84.7149658203125, 84.72222900390625,
  84.72952270507812, 84.73686218261719, 84.7442398071289, 84.75167846679688,
  84.7591781616211, 84.7667465209961, 84.7743911743164, 84.7820816040039,
  84.78983306884766, 84.79766082763672, 84.80559539794922, 84.81363677978516,
  84.82157897949219, 84.8293685913086, 84.83708953857422, 84.84478759765625,
  84.8525390625, 84.86034393310547, 84.86839294433594, 84.87663269042969,
  84.88492584228516, 84.89292907714844, 84.90092468261719, 84.908935546875,
  84.91697692871094, 84.92503356933594, 84.93309783935547, 84.94113159179688,
  84.94906616210938, 84.95680236816406, 84.96463012695312, 84.9725341796875,
  84.98048400878906, 84.98847198486328, 84.99639892578125, 85.00416564941406,
  85.01197052001953, 85.0198974609375, 85.02780151367188, 85.03562927246094,
  85.04341888427734, 85.05123138427734, 85.05899810791016, 85.0667724609375,
  85.07452392578125, 85.08219146728516, 85.08975219726562, 85.09736633300781,
  85.1050796508789, 85.11270904541016, 85.12017822265625, 85.12764739990234,
  85.1351318359375, 85.14258575439453, 85.15015411376953, 85.1577377319336,
  85.16523742675781, 85.17266082763672, 85.18006896972656, 85.18742370605469,
  85.19475555419922, 85.20217895507812, 85.20966339111328, 85.2170639038086,
  85.22435760498047, 85.23167419433594, 85.23904418945312, 85.2464370727539,
  85.25382232666016, 85.26123046875, 85.26862335205078, 85.27598571777344,
  85.2833023071289, 85.29055786132812, 85.29777526855469, 85.30492401123047,
  85.3120346069336, 85.31912994384766, 85.32621002197266, 85.333251953125,
  85.34025573730469, 85.34727478027344, 85.35442352294922, 85.36164855957031,
  85.3688735961914, 85.37606811523438, 85.38318634033203, 85.39006042480469,
  85.39680480957031, 85.40351104736328, 85.41019439697266, 85.4168930053711,
  85.42369079589844, 85.4305419921875, 85.43811798095703, 85.4443359375,
  85.4511947631836, 85.45803833007812, 85.46544647216797, 85.47142791748047,
  85.47817993164062, 85.48503875732422, 85.49198913574219, 85.49891662597656,
  85.50577545166016, 85.51258087158203, 85.5193099975586, 85.52595520019531,
  85.53251647949219, 85.53973388671875, 85.54571533203125, 85.55311584472656,
  85.55918884277344, 85.56591033935547, 85.57333374023438, 85.57939910888672,
  85.58612823486328, 85.59281921386719, 85.59944152832031, 85.60598754882812,
  85.61253356933594, 85.6190414428711, 85.62542724609375, 85.63180541992188,
  85.63819122314453, 85.64456939697266, 85.65091705322266, 85.65725708007812,
  85.66368103027344, 85.67076873779297, 85.67656707763672, 85.68299865722656,
  85.6893539428711, 85.69570922851562, 85.70204162597656, 85.7083511352539,
  85.7146224975586, 85.72089385986328, 85.72711181640625, 85.73331451416016,
  85.73951721191406, 85.74579620361328, 85.75200653076172, 85.75820922851562,
  85.76438903808594, 85.7705307006836, 85.77667999267578, 85.78278350830078,
  85.78890228271484, 85.79496002197266, 85.80101776123047, 85.80706024169922,
  85.81306457519531, 85.81908416748047, 85.82505798339844, 85.83106231689453,
  85.83708953857422, 85.84309387207031, 85.84906005859375, 85.85501861572266,
  85.86094665527344, 85.86683654785156, 85.87277221679688, 85.8786849975586,
  85.88459014892578, 85.89046478271484, 85.89630889892578, 85.90208435058594,
  85.90824890136719, 85.9133071899414, 85.91966247558594, 85.92475128173828,
  85.93034362792969, 85.93592071533203, 85.94156646728516, 85.9471206665039,
  85.95266723632812, 85.95825958251953, 85.96383666992188, 85.96932220458984,
  85.97476959228516, 85.98025512695312, 85.9857177734375, 85.99122619628906,
  85.99669647216797, 86.00216674804688, 86.00764465332031, 86.01301574707031,
  86.01846313476562, 86.02397918701172, 86.02952575683594, 86.03504943847656,
  86.04041290283203, 86.04571533203125, 86.0510025024414, 86.05632781982422,
  86.06159973144531, 86.06682586669922, 86.07218170166016, 86.07728576660156,
  86.08232116699219, 86.08741760253906, 86.09249877929688, 86.09750366210938,
  86.10259246826172, 86.10747528076172, 86.11244201660156, 86.11752319335938,
  86.12261962890625, 86.12757110595703, 86.13233184814453, 86.13716125488281,
  86.14202880859375, 86.14691162109375, 86.15180206298828, 86.15670013427734,
  86.16146850585938, 86.16615295410156, 86.17091369628906, 86.1756591796875,
  86.18043518066406, 86.18534088134766, 86.19025421142578, 86.19518280029297,
  86.19999694824219, 86.20465850830078, 86.20927429199219, 86.2138900756836,
  86.21857452392578, 86.2232666015625, 86.22792053222656, 86.23260498046875,
  86.23716735839844, 86.24168395996094, 86.24618530273438, 86.25080871582031,
  86.25543212890625, 86.25999450683594, 86.26451110839844, 86.26908874511719,
  86.27362060546875, 86.2782211303711, 86.28282928466797, 86.28724670410156,
  86.29155731201172, 86.29594421386719, 86.30038452148438, 86.30477142333984,
  86.30917358398438, 86.31365966796875, 86.31819152832031, 86.32262420654297,
  86.32701873779297, 86.33126831054688, 86.33560180664062, 86.33998107910156,
  86.34449005126953, 86.34886932373047, 86.35321044921875, 86.35752868652344,
  86.36177825927734, 86.36605834960938, 86.37044525146484, 86.37483215332031,
  86.37913513183594, 86.38345336914062, 86.38771057128906, 86.39192199707031,
  86.39620208740234, 86.4004135131836, 86.40459442138672, 86.40876770019531,
  86.41289520263672, 86.41690826416016, 86.42079162597656, 86.42464447021484,
  86.42855072021484, 86.4324951171875, 86.43647003173828, 86.44041442871094,
  86.44425964355469, 86.4480972290039, 86.45198059082031, 86.45582580566406,
  86.45977020263672, 86.46366119384766, 86.4673843383789, 86.47106170654297,
  86.47461700439453, 86.47808837890625, 86.48162078857422, 86.48536682128906,
  86.48916625976562, 86.49286651611328, 86.49649810791016, 86.50004577636719,
  86.50365447998047, 86.50736999511719, 86.5110855102539, 86.51480102539062,
  86.51841735839844, 86.52200317382812, 86.52552032470703, 86.52908325195312,
  86.53258514404297, 86.53608703613281, 86.53956604003906, 86.54299926757812,
  86.54642486572266, 86.5499496459961, 86.55352783203125, 86.55702209472656,
  86.56041717529297, 86.56366729736328, 86.5668716430664, 86.57002258300781,
  86.57322692871094, 86.57650756835938, 86.579833984375, 86.58313751220703,
  86.58643341064453, 86.58971405029297, 86.59294891357422, 86.59605407714844,
  86.59909057617188, 86.60205841064453, 86.60494995117188, 86.6078872680664,
  86.61087036132812, 86.61398315429688, 86.61711120605469, 86.62025451660156,
  86.6233901977539, 86.62665557861328, 86.63004302978516, 86.63333129882812,
  86.63646697998047, 86.63928985595703, 86.64225006103516, 86.64512634277344,
  86.64786529541016, 86.65071868896484, 86.6533432006836, 86.6558609008789,
  86.65868377685547, 86.66178894042969, 86.66496276855469, 86.6681900024414,
  86.67124938964844, 86.6740493774414, 86.67664337158203, 86.67913818359375,
  86.68161010742188, 86.68417358398438, 86.68685913085938, 86.68964385986328,
  86.69249725341797, 86.69532775878906, 86.69816589355469, 86.70096588134766,
  86.70381164550781, 86.70653533935547, 86.70915985107422, 86.711669921875,
  86.71407318115234, 86.7164535522461, 86.7188720703125, 86.72122192382812,
  86.72364807128906, 86.72616577148438, 86.72880554199219, 86.73148345947266,
  86.7341537475586, 86.7368392944336, 86.739501953125, 86.74214935302734,
  86.74459075927734, 86.74703979492188, 86.74949645996094, 86.75202178955078,
  86.75448608398438, 86.75697326660156, 86.75950622558594, 86.76211547851562,
  86.76476287841797, 86.76744842529297, 86.7700424194336, 86.7725830078125,
  86.77507019042969, 86.77758026123047, 86.78013610839844, 86.78260040283203,
  86.78506469726562, 86.78759765625, 86.79007720947266, 86.79231262207031,
  86.7944107055664, 86.79654693603516, 86.79878234863281, 86.80110931396484,
  86.80352020263672, 86.80602264404297, 86.8084487915039, 86.81080627441406,
  86.81317138671875, 86.81559753417969, 86.81813049316406, 86.82073211669922,
  86.82345581054688, 86.82632446289062, 86.8293228149414, 86.83235168457031,
  86.83529663085938, 86.838134765625, 86.84086608886719, 86.84355926513672,
  86.84619903564453, 86.84868621826172, 86.85102081298828, 86.85337829589844,
  86.8558578491211, 86.85859680175781, 86.86168670654297, 86.8648681640625,
  86.86795043945312, 86.87088775634766, 86.87373352050781, 86.87650299072266,
  86.87918090820312, 86.88176727294922, 86.8842544555664, 86.8868179321289,
  86.88961029052734, 86.89250183105469, 86.89551544189453, 86.8985824584961,
  86.90171813964844, 86.90485382080078, 86.90799713134766, 86.91097259521484,
  86.91387939453125, 86.9167709350586, 86.91979217529297, 86.92278289794922,
  86.92570495605469, 86.92851257324219, 86.93125915527344, 86.93399047851562,
  86.936767578125, 86.93968963623047, 86.94274139404297, 86.94578552246094,
  86.94868469238281, 86.95136260986328, 86.9539794921875, 86.95635223388672,
  86.95853424072266, 86.96083068847656, 86.96315002441406, 86.96532440185547,
  86.96764373779297, 86.97005462646484, 86.97259521484375, 86.97526550292969,
  86.97786712646484, 86.98019409179688, 86.98233032226562, 86.9843521118164,
  86.98629760742188, 86.98815155029297, 86.98992156982422, 86.99175262451172,
  86.99341583251953, 86.99500274658203, 86.99658203125, 86.99813079833984,
  86.9996337890625, 87.00109100341797, 87.00255584716797, 87.00394439697266,
  87.00529479980469, 87.00662994384766, 87.00798797607422, 87.0094223022461,
  87.01079559326172, 87.01211547851562, 87.013427734375, 87.01478576660156,
  87.01608276367188, 87.01734161376953, 87.01862335205078, 87.01988983154297,
  87.0211410522461, 87.02235412597656, 87.02356719970703, 87.02474975585938,
  87.02590942382812, 87.02706909179688, 87.02826690673828, 87.0294418334961,
  87.03053283691406, 87.03166198730469, 87.03277587890625, 87.03392791748047,
  87.03507995605469, 87.03621673583984, 87.03739166259766, 87.03854370117188,
  87.03974151611328, 87.04086303710938, 87.0419692993164, 87.04305267333984,
  87.04411315917969, 87.0451889038086, 87.04631042480469, 87.04739379882812,
  87.04843139648438, 87.04949951171875, 87.05059814453125, 87.05162811279297,
  87.05261993408203, 87.0536117553711, 87.05455017089844, 87.055419921875,
  87.05623626708984, 87.0570297241211, 87.057861328125, 87.05873107910156,
  87.05964660644531, 87.06048583984375, 87.06128692626953, 87.06211853027344,
  87.06294250488281, 87.0637435913086, 87.0645523071289, 87.06539154052734,
  87.06625366210938, 87.06709289550781, 87.06793212890625, 87.06877136230469,
  87.06954956054688, 87.07028198242188, 87.07098388671875, 87.07162475585938,
  87.07229614257812, 87.07298278808594, 87.07368469238281, 87.0743637084961,
  87.07503509521484, 87.07568359375, 87.07630920410156, 87.07695007324219,
  87.07754516601562, 87.078125, 87.07869720458984, 87.0792465209961,
  87.07979583740234, 87.08036041259766, 87.08100891113281, 87.0815200805664,
  87.08208465576172, 87.08251953125, 87.08299255371094, 87.0833969116211,
  87.08379364013672, 87.0842056274414, 87.08455657958984, 87.08499145507812,
  87.08533477783203, 87.08568572998047, 87.08601379394531, 87.08628845214844,
  87.08660125732422, 87.08689880371094, 87.08720397949219, 87.08746337890625,
  87.08769226074219, 87.08792114257812, 87.08815002441406, 87.08833312988281,
  87.0885238647461, 87.08870697021484, 87.08890533447266, 87.08906555175781,
  87.08927154541016, 87.08949279785156, 87.08970642089844, 87.08995056152344,
  87.09017944335938, 87.09042358398438, 87.0905990600586, 87.09080505371094,
  87.09097290039062, 87.09114837646484, 87.09133911132812, 87.0915756225586,
  87.09183502197266, 87.09207916259766, 87.0923080444336, 87.092529296875,
  87.09273529052734, 87.09291076660156, 87.09307861328125, 87.09323120117188,
  87.09336853027344, 87.09353637695312, 87.09371948242188, 87.09394836425781,
  87.09420776367188, 87.09444427490234, 87.09465789794922, 87.09485626220703,
  87.09503936767578, 87.09522247314453, 87.09540557861328, 87.09567260742188,
  87.09593200683594, 87.09614562988281, 87.09638977050781, 87.09661102294922,
  87.0968017578125, 87.09695434570312, 87.09712982177734, 87.09735107421875,
  87.09760284423828, 87.09786224365234, 87.09810638427734, 87.098388671875,
  87.09868621826172, 87.09907531738281, 87.0994644165039, 87.09977722167969,
  87.10005950927734, 87.10039520263672, 87.10066986083984, 87.10091400146484,
  87.10118103027344, 87.10148620605469, 87.10165405273438, 87.10191345214844,
  87.1022720336914, 87.10245513916016, 87.10269165039062, 87.10298156738281,
  87.10321044921875, 87.10343933105469, 87.10369873046875, 87.10394287109375,
  87.10418701171875, 87.10440826416016, 87.10457611083984, 87.10472869873047,
  87.1048355102539, 87.10494232177734, 87.10501861572266, 87.1051025390625,
  87.10517883300781, 87.10528564453125, 87.10547637939453, 87.10559844970703,
  87.10562896728516, 87.10566711425781, 87.10578918457031, 87.10588836669922,
  87.10588073730469, 87.10586547851562, 87.10578155517578, 87.1056900024414,
  87.10560607910156, 87.10557556152344, 87.10554504394531, 87.10545349121094,
  87.10531616210938, 87.10520935058594, 87.10525512695312, 87.10527038574219,
  87.105224609375, 87.10511016845703, 87.10501861572266, 87.10504913330078,
  87.10499572753906, 87.1048583984375, 87.10481262207031, 87.10484313964844,
  87.10476684570312, 87.10466003417969, 87.10452270507812, 87.10437774658203,
  87.10418701171875, 87.10401153564453, 87.10395812988281, 87.10407257080078,
  87.10420989990234, 87.10417175292969, 87.10409545898438, 87.10405731201172,
  87.10401153564453, 87.10401916503906, 87.10409545898438, 87.1041488647461,
  87.10416412353516, 87.10406494140625, 87.10383605957031, 87.10368347167969,
  87.10356903076172, 87.10346984863281, 87.10334014892578, 87.103271484375,
  87.10323333740234, 87.10315704345703, 87.10309600830078, 87.10303497314453,
  87.1029052734375, 87.10271453857422, 87.10253143310547, 87.10236358642578,
  87.10226440429688, 87.1021728515625, 87.10208892822266, 87.10198974609375,
  87.10187530517578, 87.10176849365234, 87.10167694091797, 87.10150909423828,
  87.10140228271484, 87.10124206542969, 87.10115051269531, 87.10103607177734,
  87.1009292602539, 87.10087585449219, 87.1008071899414, 87.10066986083984,
  87.10042572021484, 87.1002197265625, 87.10003662109375, 87.0998764038086,
  87.09974670410156, 87.09965515136719, 87.09959411621094, 87.09928894042969,
  87.09896087646484, 87.0987319946289, 87.0987319946289, 87.09871673583984,
  87.09859466552734, 87.09839630126953, 87.09818267822266, 87.09797668457031,
  87.09779357910156, 87.09752655029297, 87.0973892211914, 87.0972900390625,
  87.09716033935547, 87.0969467163086, 87.09671783447266, 87.09664154052734,
  87.0965576171875, 87.09645080566406, 87.09627532958984, 87.09590148925781,
  87.0953369140625, 87.09465789794922, 87.09386444091797, 87.09295654296875,
  87.0921630859375, 87.09156036376953, 87.09109497070312, 87.09064483642578,
  87.09012603759766, 87.08946990966797, 87.08875274658203, 87.08800506591797,
  87.08734893798828, 87.08674621582031, 87.0860824584961, 87.08541870117188,
  87.08473205566406, 87.08403015136719, 87.08333587646484, 87.08258819580078,
  87.08171081542969, 87.08080291748047, 87.07987976074219, 87.07891845703125,
  87.07798767089844, 87.07704162597656, 87.07610321044922, 87.07513427734375,
  87.07412719726562, 87.07305908203125, 87.07196807861328, 87.07093811035156,
  87.0699691772461, 87.06900024414062, 87.06796264648438, 87.06682586669922,
  87.06566619873047, 87.0645523071289, 87.06346893310547, 87.06239318847656,
  87.06131744384766, 87.06021881103516, 87.05909729003906, 87.05792999267578,
  87.05674743652344, 87.05553436279297, 87.05428314208984, 87.05300903320312,
  87.05171966552734, 87.05043029785156, 87.04920196533203, 87.04804992675781,
  87.04693603515625, 87.04579162597656, 87.04454040527344, 87.04320526123047,
  87.04180908203125, 87.04039764404297, 87.03897857666016, 87.0375747680664,
  87.03623962402344, 87.0349349975586, 87.03367614746094, 87.03241729736328,
  87.0311508178711, 87.02979278564453, 87.02832794189453, 87.02680206298828,
  87.02525329589844, 87.023681640625, 87.02210998535156, 87.02071380615234,
  87.01942443847656, 87.01814270019531, 87.01679992675781, 87.01520538330078,
  87.01348114013672, 87.0118408203125, 87.01058959960938, 87.00940704345703,
  87.0081787109375, 87.00672912597656, 87.0050048828125, 87.0031967163086,
  87.0013656616211, 86.99950408935547, 86.99797821044922, 86.99655151367188,
  86.99520874023438, 86.993896484375, 86.99224090576172, 86.99047088623047,
  86.98870849609375, 86.987060546875, 86.98552703857422, 86.98404693603516,
  86.98253631591797, 86.98090362548828, 86.97916412353516, 86.97737884521484,
  86.97562408447266, 86.97389221191406, 86.97217559814453, 86.97051239013672,
  86.9689712524414, 86.96746063232422, 86.96591186523438, 86.96427154541016,
  86.9627914428711, 86.96136474609375, 86.95985412597656, 86.95818328857422,
  86.95641326904297, 86.95462799072266, 86.95281982421875, 86.95108032226562,
  86.94949340820312, 86.94802856445312, 86.94658660888672, 86.94505310058594,
  86.94332885742188, 86.94147491455078, 86.93968200683594, 86.93792724609375,
  86.93620300292969, 86.93452453613281, 86.93292236328125, 86.93135833740234,
  86.92984771728516, 86.92829132080078, 86.92668914794922, 86.92506408691406,
  86.92337799072266, 86.9216537475586, 86.91991424560547, 86.91822052001953,
  86.91661071777344, 86.91502380371094, 86.91341400146484, 86.91178131103516,
  86.9101791381836, 86.9085922241211, 86.90695190429688, 86.90523529052734,
  86.90348052978516, 86.90176391601562, 86.90013122558594, 86.89855194091797,
  86.89700317382812, 86.89549255371094, 86.89395904541016, 86.89237213134766,
  86.8907699584961, 86.88912963867188, 86.8874740600586, 86.88578796386719,
  86.88414001464844, 86.8824462890625, 86.88070678710938, 86.87897491455078,
  86.877197265625, 86.87542724609375, 86.87367248535156, 86.87194061279297,
  86.87024688720703, 86.86857604980469, 86.86687469482422, 86.86510467529297,
  86.86334228515625, 86.86161041259766, 86.85987091064453, 86.85818481445312,
  86.85655212402344, 86.85494995117188, 86.85330200195312, 86.85163116455078,
  86.84993743896484, 86.84822845458984, 86.8465576171875, 86.84494018554688,
  86.84346008300781, 86.84205627441406, 86.84056854248047, 86.83903503417969,
  86.83744812011719, 86.83578491210938, 86.8339614868164, 86.83248901367188,
  86.83086395263672, 86.82923126220703, 86.82769012451172, 86.82605743408203,
  86.82431030273438, 86.82241821289062, 86.82017517089844, 86.81832885742188,
  86.81641387939453, 86.81470489501953, 86.81314849853516, 86.81147003173828,
  86.80970764160156, 86.80799102783203, 86.80635070800781, 86.80469512939453,
  86.8029556274414, 86.8011703491211, 86.79940795898438, 86.79766082763672,
  86.79594421386719, 86.7942123413086, 86.79241943359375, 86.79069519042969,
  86.78890991210938, 86.7872543334961, 86.78568267822266, 86.78398132324219,
  86.78215789794922, 86.7802963256836, 86.7784194946289, 86.77658081054688,
  86.77483367919922, 86.77315521240234, 86.77159881591797, 86.77003479003906,
  86.76838684082031, 86.76673889160156, 86.7651138305664, 86.76361083984375,
  86.76181030273438, 86.75985717773438, 86.7578353881836, 86.75590515136719,
  86.7540512084961, 86.75218963623047, 86.75050354003906, 86.74886322021484,
  86.747314453125, 86.74578857421875, 86.7442398071289, 86.74266052246094,
  86.74107360839844, 86.73936462402344, 86.73761749267578, 86.73603057861328,
  86.73456573486328, 86.73302459716797, 86.73133850097656, 86.72986602783203,
  86.72859191894531, 86.72727966308594, 86.72608184814453, 86.72473907470703,
  86.72358703613281, 86.72229766845703, 86.72095489501953, 86.71955871582031,
  86.71821594238281, 86.71692657470703, 86.71566772460938, 86.7145004272461,
  86.71337890625, 86.71222686767578, 86.71138000488281, 86.71046447753906,
  86.70964813232422, 86.7088851928711, 86.70801544189453, 86.70695495605469,
  86.70606994628906, 86.705078125, 86.70401000976562, 86.70296478271484,
  86.70175170898438, 86.70071411132812, 86.69950103759766, 86.69847106933594,
  86.69735717773438, 86.69627380371094, 86.69514465332031, 86.69410705566406,
  86.69313049316406, 86.6921615600586, 86.69123077392578, 86.69029235839844,
  86.68928527832031, 86.6884994506836, 86.68754577636719, 86.68659210205078,
  86.68559265136719, 86.68457794189453, 86.68354797363281, 86.68262481689453,
  86.68159484863281, 86.680419921875, 86.67919921875, 86.677978515625,
  86.67678833007812, 86.67570495605469, 86.6747055053711, 86.67373657226562,
  86.67274475097656, 86.67169189453125, 86.67052459716797, 86.66925811767578,
  86.66795349121094, 86.66664123535156, 86.66533660888672, 86.6640625,
  86.662841796875, 86.6616439819336, 86.6605224609375, 86.659423828125,
  86.65829467773438, 86.65719604492188, 86.65606689453125, 86.65486145019531,
  86.65361785888672, 86.65229797363281, 86.65092468261719, 86.64958953857422,
  86.6483154296875, 86.64708709716797, 86.64588928222656, 86.64479064941406,
  86.64373779296875, 86.64276885986328, 86.64191436767578, 86.64100646972656,
  86.63998413085938, 86.63886260986328, 86.6376724243164, 86.63639068603516,
  86.63495635986328, 86.6334228515625, 86.63184356689453, 86.6302719116211,
  86.62883758544922, 86.62753295898438, 86.626220703125, 86.62482452392578,
  86.62335205078125, 86.62178039550781, 86.62020111083984, 86.61858367919922,
  86.61688995361328, 86.61524963378906, 86.61360931396484, 86.6119384765625,
  86.61022186279297, 86.60855102539062, 86.6069564819336, 86.60528564453125,
  86.6036605834961, 86.60200500488281, 86.60029602050781, 86.59851837158203,
  86.59671020507812, 86.59489440917969, 86.59310150146484, 86.59127044677734,
  86.58943176269531, 86.587646484375, 86.58580780029297, 86.58394622802734,
  86.58209228515625, 86.5802001953125, 86.57820129394531, 86.57608795166016,
  86.57391357421875, 86.57174682617188, 86.56964111328125, 86.56764221191406,
  86.56571197509766, 86.5638198852539, 86.56188201904297, 86.55989074707031,
  86.55789947509766, 86.55587005615234, 86.55377197265625, 86.55160522460938,
  86.54932403564453, 86.54702758789062, 86.54476928710938, 86.54256439208984,
  86.54044342041016, 86.5383529663086, 86.53629302978516, 86.53426361083984,
  86.53223419189453, 86.5301742553711, 86.52799224853516, 86.52579498291016,
  86.52350616455078, 86.5211410522461, 86.51876831054688, 86.5164566040039,
  86.5142822265625, 86.51214599609375, 86.50981903076172, 86.5074462890625,
  86.5052719116211, 86.5030288696289, 86.50094604492188, 86.4985580444336,
  86.4964370727539, 86.4947280883789, 86.49227905273438, 86.49002075195312,
  86.48745727539062, 86.48485565185547, 86.48230743408203, 86.48053741455078,
  86.478515625, 86.47657775878906, 86.47430419921875, 86.4716796875,
  86.46903228759766, 86.46607208251953, 86.463623046875, 86.46086883544922,
  86.45780181884766, 86.4549789428711, 86.45240783691406, 86.44922637939453,
  86.44630432128906, 86.44403839111328, 86.44161224365234, 86.4394760131836,
  86.43792724609375, 86.43598175048828, 86.43363952636719, 86.4307632446289,
  86.42802429199219, 86.425537109375, 86.4224624633789, 86.41991424560547,
  86.41705322265625, 86.4141616821289, 86.41094207763672, 86.40833282470703,
  86.40611267089844, 86.40360260009766, 86.40217590332031, 86.40032958984375,
  86.39818572998047, 86.39601135253906, 86.3930892944336, 86.39038848876953,
  86.38766479492188, 86.38518524169922, 86.38239288330078, 86.37957763671875,
  86.37672424316406, 86.37383270263672, 86.37114715576172, 86.36894989013672,
  86.36670684814453, 86.36500549316406, 86.36319732666016, 86.36103820800781,
  86.35843658447266, 86.3560562133789, 86.35309600830078, 86.35063934326172,
  86.34791564941406, 86.3452377319336, 86.34319305419922, 86.34063720703125,
  86.33856964111328, 86.33634185791016, 86.33391571044922, 86.3319320678711,
  86.32949829101562, 86.3272933959961, 86.32527923583984, 86.32296752929688,
  86.32039642333984, 86.31804656982422, 86.31597137451172, 86.31365203857422,
  86.31135559082031, 86.30905151367188, 86.3067855834961, 86.30433654785156,
  86.30232238769531, 86.30007934570312, 86.29779052734375, 86.29551696777344,
  86.29322052001953, 86.2909164428711, 86.28864288330078, 86.28636932373047,
  86.2842025756836, 86.28203582763672, 86.27987670898438, 86.27769470214844,
  86.27552032470703, 86.27340698242188, 86.27130889892578, 86.26921844482422,
  86.26712036132812, 86.26500701904297, 86.26296997070312, 86.26100158691406,
  86.259033203125, 86.25708770751953, 86.2551498413086, 86.25318908691406,
  86.25115966796875, 86.24930572509766, 86.24745178222656, 86.24563598632812,
  86.24398803710938, 86.24224090576172, 86.2403335571289, 86.23871612548828,
  86.23692321777344, 86.23514556884766, 86.23336791992188, 86.23141479492188,
  86.2298355102539, 86.22809600830078, 86.22632598876953, 86.22472381591797,
  86.22306823730469, 86.22136688232422, 86.21964263916016, 86.21781921386719,
  86.2160415649414, 86.2143325805664, 86.21269226074219, 86.21115112304688,
  86.20965576171875, 86.20813751220703, 86.20665740966797, 86.20521545410156,
  86.20347595214844, 86.20201873779297, 86.20036315917969, 86.19868469238281,
  86.19701385498047, 86.19544982910156, 86.19387817382812, 86.1924057006836,
  86.1909408569336, 86.1894302368164, 86.1878890991211, 86.18622589111328,
  86.18441009521484, 86.18257904052734, 86.18077850341797, 86.1789779663086,
  86.17704772949219, 86.17495727539062, 86.17282104492188, 86.170654296875,
  86.16853332519531, 86.16639709472656, 86.16419982910156, 86.16202545166016,
  86.15995025634766, 86.15788269042969, 86.15580749511719, 86.15374755859375,
  86.1517333984375, 86.14970397949219, 86.1476821899414, 86.14566040039062,
  86.14374542236328, 86.14179229736328, 86.13978576660156, 86.1376953125,
  86.13558959960938, 86.13353729248047, 86.13153839111328, 86.12957763671875,
  86.12770080566406, 86.1259536743164, 86.1242904663086, 86.1225814819336,
  86.12081146240234, 86.1189956665039, 86.11721801757812, 86.11544036865234,
  86.11368560791016, 86.11189270019531, 86.11011505126953, 86.10838317871094,
  86.10672760009766, 86.10517120361328, 86.10368347167969, 86.10224151611328,
  86.10081481933594, 86.09937286376953, 86.09785461425781, 86.09627532958984,
  86.09465789794922, 86.09310913085938, 86.09162902832031, 86.09019470214844,
  86.08882904052734, 86.0875473022461, 86.08634185791016, 86.08516693115234,
  86.083984375, 86.08283233642578, 86.08169555664062, 86.08055114746094,
  86.07948303222656, 86.07853698730469, 86.07767486572266, 86.0767822265625,
  86.07584381103516, 86.07489776611328, 86.07398986816406, 86.07319641113281,
  86.07260131835938, 86.07211303710938, 86.07158660888672, 86.07096099853516,
  86.07032012939453, 86.0698013305664, 86.06935119628906, 86.06891632080078,
  86.06841278076172, 86.06776428222656, 86.06704711914062, 86.06629180908203,
  86.0656509399414, 86.06527709960938, 86.06514739990234, 86.0651626586914,
  86.06523132324219, 86.06526947021484, 86.06511688232422, 86.06490325927734,
  86.06481170654297, 86.06499481201172, 86.06526947021484, 86.06550598144531,
  86.06564331054688, 86.06562042236328, 86.06546020507812, 86.0651626586914,
  86.06483459472656, 86.06477355957031, 86.0650634765625, 86.0656967163086,
  86.06661224365234, 86.0675048828125, 86.06810760498047, 86.06843566894531,
  86.06864166259766, 86.06869506835938, 86.06881713867188, 86.06916809082031,
  86.0696792602539, 86.07024383544922, 86.07084655761719, 86.0713882446289,
  86.07177734375, 86.07217407226562, 86.07247924804688, 86.0728759765625,
  86.07335662841797, 86.07389068603516, 86.07452392578125, 86.07515716552734,
  86.07579803466797, 86.07645416259766, 86.0770263671875, 86.07763671875,
  86.07823944091797, 86.07888793945312, 86.07958221435547, 86.080322265625,
  86.08106231689453, 86.08180236816406, 86.08256530761719, 86.0833511352539,
  86.08424377441406, 86.08538818359375, 86.08661651611328, 86.0876693725586,
  86.08843231201172, 86.08918762207031, 86.09001922607422, 86.09107971191406,
  86.09230041503906, 86.0936279296875, 86.09497833251953, 86.09635925292969,
  86.09770965576172, 86.0990219116211, 86.09977722167969, 86.10044860839844,
  86.10108947753906, 86.10213470458984, 86.10350036621094, 86.10516357421875,
  86.10706329345703, 86.10891723632812, 86.11050415039062, 86.11189270019531,
  86.11306762695312, 86.11405944824219, 86.1151351928711, 86.116455078125,
  86.11804962158203, 86.11991119384766, 86.12205505371094, 86.12415313720703,
  86.12592315673828, 86.12744903564453, 86.12889862060547, 86.13017272949219,
  86.13130187988281, 86.13227081298828, 86.13320922851562, 86.13543701171875,
  86.13721466064453, 86.13883209228516, 86.1400146484375, 86.14131164550781,
  86.14259338378906, 86.14395904541016, 86.14530944824219, 86.14665985107422,
  86.14791870117188, 86.14915466308594, 86.15052795410156, 86.15205383300781,
  86.15363311767578, 86.15521240234375, 86.15681457519531, 86.15840148925781,
  86.15995025634766, 86.16143035888672, 86.16300964355469, 86.16423797607422,
  86.16559600830078, 86.16694641113281, 86.16828918457031, 86.16974639892578,
  86.17115020751953, 86.17252349853516, 86.17425537109375, 86.1756362915039,
  86.17707824707031, 86.1784439086914, 86.17969512939453, 86.18085479736328,
  86.18202209472656, 86.18331146240234, 86.18479919433594, 86.18608856201172,
  86.18751525878906, 86.18888092041016, 86.19019317626953, 86.19158172607422,
  86.19297790527344, 86.19434356689453, 86.19567108154297, 86.19706726074219,
  86.19830322265625, 86.19960021972656, 86.20079803466797, 86.20220947265625,
  86.2033920288086, 86.20479583740234, 86.2059555053711, 86.20743560791016,
  86.20865631103516, 86.21011352539062, 86.2112808227539, 86.21244049072266,
  86.21355438232422, 86.2147216796875, 86.2159423828125, 86.21720123291016,
  86.21854400634766, 86.21991729736328, 86.22129821777344, 86.22261047363281,
  86.2238540649414, 86.2249984741211, 86.22611999511719, 86.22721862792969,
  86.22834777832031, 86.22948455810547, 86.23062896728516, 86.23178100585938,
  86.23294067382812, 86.23409271240234, 86.23522186279297, 86.23625183105469,
  86.23736572265625, 86.23854064941406, 86.2397689819336, 86.24099731445312,
  86.24225616455078, 86.2434310913086, 86.24453735351562, 86.2455825805664,
  86.2466049194336, 86.24761199951172, 86.24858093261719, 86.24958801269531,
  86.25056457519531, 86.25165557861328, 86.25276947021484, 86.25391387939453,
  86.2549819946289, 86.25601196289062, 86.25704956054688, 86.25801849365234,
  86.25904846191406, 86.2600326538086, 86.2609634399414, 86.26185607910156,
  86.26280212402344, 86.26374816894531, 86.26471710205078, 86.26573181152344,
  86.26677703857422, 86.26777648925781, 86.26878356933594, 86.26971435546875,
  86.27059173583984, 86.27143859863281, 86.2722396850586, 86.27310943603516,
  86.27397918701172, 86.27482604980469, 86.27571105957031, 86.27664184570312,
  86.27760314941406, 86.27861785888672, 86.27955627441406, 86.28044891357422,
  86.2812271118164, 86.28199768066406, 86.28272247314453, 86.283447265625,
  86.28426361083984, 86.28512573242188, 86.28609466552734, 86.287109375,
  86.2880630493164, 86.28881072998047, 86.2894515991211, 86.29020690917969,
  86.2911376953125, 86.29217529296875, 86.29316711425781, 86.29402923583984,
  86.29475402832031, 86.29541778564453, 86.29607391357422, 86.29679870605469,
  86.29765319824219, 86.29850769042969, 86.29930114746094, 86.30006408691406,
  86.30081176757812, 86.3015365600586, 86.30216979980469, 86.3028335571289,
  86.3034896850586, 86.30413818359375, 86.30470275878906, 86.30533599853516,
  86.30599212646484, 86.30667877197266, 86.3071517944336, 86.3076400756836,
  86.30836486816406, 86.30931091308594, 86.31034088134766, 86.31129455566406,
  86.31207275390625, 86.31266784667969, 86.31314849853516, 86.3135757446289,
  86.31410217285156, 86.31476593017578, 86.31547546386719, 86.31620788574219,
  86.31710815429688, 86.31807708740234, 86.3187255859375, 86.31925964355469,
  86.31978607177734, 86.32012939453125, 86.32051849365234, 86.32096099853516,
  86.32145690917969, 86.32203674316406, 86.32263946533203, 86.32331848144531,
  86.32379150390625, 86.32410430908203, 86.32487487792969, 86.3257827758789,
  86.32672882080078, 86.32738494873047, 86.32803344726562, 86.3288345336914,
  86.32929992675781, 86.32981872558594, 86.33023071289062, 86.33073425292969,
  86.33141326904297, 86.3321762084961, 86.33312225341797, 86.33414459228516,
  86.33516693115234, 86.33612060546875, 86.33717346191406, 86.33827209472656,
  86.33921813964844, 86.3399429321289, 86.34025573730469, 86.34051513671875,
  86.34062194824219, 86.34078216552734, 86.34112548828125, 86.3416748046875,
  86.34256744384766, 86.3434829711914, 86.344482421875, 86.34526824951172,
  86.34603118896484, 86.34705352783203, 86.34815979003906, 86.34906005859375,
  86.34949493408203, 86.3497314453125, 86.34968566894531, 86.34989929199219,
  86.35033416748047, 86.35111999511719, 86.35202026367188, 86.35302734375,
  86.35384368896484, 86.35444641113281, 86.3548583984375, 86.35517883300781,
  86.35547637939453, 86.35578155517578, 86.35603332519531, 86.35633087158203,
  86.35662841796875, 86.35685729980469, 86.35707092285156, 86.35723876953125,
  86.35739135742188, 86.35752868652344, 86.35765075683594, 86.35778045654297,
  86.35784149169922, 86.35797882080078, 86.35814666748047, 86.35826110839844,
  86.3582763671875, 86.35819244384766, 86.35792541503906, 86.35762023925781,
  86.35734558105469, 86.35714721679688, 86.35691833496094, 86.35658264160156,
  86.35633850097656, 86.35613250732422, 86.35580444335938, 86.35531616210938,
  86.35491180419922, 86.35459899902344, 86.35437774658203, 86.35415649414062,
  86.3538818359375, 86.3535385131836, 86.35318756103516, 86.35283660888672,
  86.35240936279297, 86.35198974609375, 86.35152435302734, 86.35108947753906,
  86.35066986083984, 86.35030364990234, 86.34992218017578, 86.3494644165039,
  86.3490219116211, 86.34856414794922, 86.3480453491211, 86.3475341796875,
  86.34703826904297, 86.34654235839844, 86.34608459472656, 86.3455810546875,
  86.34506225585938, 86.34449005126953, 86.34390258789062, 86.34326934814453,
  86.3426284790039, 86.34203338623047, 86.34130859375, 86.340576171875,
  86.33985137939453, 86.3391342163086, 86.33848571777344, 86.33780670166016,
  86.33705139160156, 86.33625793457031, 86.33547973632812, 86.3347396850586,
  86.333984375, 86.33319854736328, 86.33238220214844, 86.33155059814453,
  86.33069610595703, 86.32984161376953, 86.3290023803711, 86.32814025878906,
  86.32722473144531, 86.32628631591797, 86.32535552978516, 86.3244400024414,
  86.32351684570312, 86.32259368896484, 86.32167053222656, 86.32070922851562,
  86.31973266601562, 86.31876373291016, 86.31776428222656, 86.31674194335938,
  86.3156967163086, 86.31465148925781, 86.31360626220703, 86.31257629394531,
  86.31156921386719, 86.310546875, 86.30946350097656, 86.30836486816406,
  86.3072509765625, 86.3061294555664, 86.30499267578125, 86.30387115478516,
  86.30276489257812, 86.30164337158203, 86.30049896240234, 86.29934692382812,
  86.2981948852539, 86.2970199584961, 86.29584503173828, 86.29463195800781,
  86.29340362548828, 86.29219055175781, 86.29100799560547, 86.28981018066406,
  86.28860473632812, 86.28742218017578, 86.28623962402344, 86.28504943847656,
  86.28385925292969, 86.28269958496094, 86.28155517578125, 86.2803726196289,
  86.27920532226562, 86.27806091308594, 86.27690124511719, 86.2757339477539,
  86.27458953857422, 86.2734146118164, 86.27222442626953, 86.27104949951172,
  86.2699203491211, 86.26880645751953, 86.26766204833984, 86.26644897460938,
  86.26517486572266, 86.26392364501953, 86.26275634765625, 86.2616195678711,
  86.26051330566406, 86.25939178466797, 86.25824737548828, 86.257080078125,
  86.25590515136719, 86.25473022460938, 86.25359344482422, 86.25248718261719,
  86.25141143798828, 86.2503662109375, 86.24934387207031, 86.24832153320312,
  86.24735260009766, 86.24633026123047, 86.24530792236328, 86.24430084228516,
  86.24334716796875, 86.24243927001953, 86.24156188964844, 86.24056243896484,
  86.23958587646484, 86.23857879638672, 86.23763275146484, 86.23674011230469,
  86.23588562011719, 86.23500061035156, 86.23406982421875, 86.23308563232422,
  86.23210906982422, 86.23114776611328, 86.23020935058594, 86.2293472290039,
  86.22852325439453, 86.22764587402344, 86.22675323486328, 86.22583770751953,
  86.22488403320312, 86.22394561767578, 86.22300720214844, 86.22212219238281,
  86.22122192382812, 86.22041320800781, 86.21955871582031, 86.21870422363281,
  86.21775817871094, 86.21676635742188, 86.21577453613281, 86.21479797363281,
  86.21389770507812, 86.21292114257812, 86.2119369506836, 86.21096801757812,
  86.2099838256836, 86.20901489257812, 86.20809936523438, 86.20720672607422,
  86.20626068115234, 86.20536804199219, 86.20441436767578, 86.20344543457031,
  86.20243072509766, 86.20140075683594, 86.20037841796875, 86.19940948486328,
  86.19847869873047, 86.19761657714844, 86.19676208496094, 86.19596862792969,
  86.1951904296875, 86.19439697265625, 86.19356536865234, 86.19266510009766,
  86.19182586669922, 86.19103240966797, 86.19020080566406, 86.18934631347656,
  86.18856048583984, 86.18769836425781, 86.18685150146484, 86.18604278564453,
  86.18535614013672, 86.18468475341797, 86.18399810791016, 86.18336486816406,
  86.18274688720703, 86.18206787109375, 86.18140411376953, 86.1807632446289,
  86.1800308227539, 86.17935943603516, 86.17868041992188, 86.17790985107422,
  86.17717742919922, 86.17654418945312, 86.17594909667969, 86.17536163330078,
  86.17472839355469, 86.17415618896484, 86.1734848022461, 86.17277526855469,
  86.17207336425781, 86.17131805419922, 86.17049407958984, 86.16967010498047,
  86.16873168945312, 86.1678466796875, 86.16706848144531, 86.1663589477539,
  86.16551208496094, 86.1646499633789, 86.16385650634766, 86.16300201416016,
  86.16193389892578, 86.1609878540039, 86.16010284423828, 86.15922546386719,
  86.15824127197266, 86.15728759765625, 86.1562728881836, 86.15525817871094,
  86.1542739868164, 86.15326690673828, 86.15225219726562, 86.15120697021484,
  86.15010833740234, 86.1491470336914, 86.14820861816406, 86.14726257324219,
  86.14628601074219, 86.14527130126953, 86.14427947998047, 86.14331817626953,
  86.14232635498047, 86.14139556884766, 86.14044189453125, 86.13948059082031,
  86.1385498046875, 86.13758850097656, 86.13648223876953, 86.13533020019531,
  86.13411712646484, 86.13301086425781, 86.13199615478516, 86.13111877441406,
  86.13025665283203, 86.12940979003906, 86.12855529785156, 86.12762451171875,
  86.12664794921875, 86.12564086914062, 86.12456512451172, 86.1234130859375,
  86.12216186523438, 86.1209716796875, 86.11990356445312, 86.11890411376953,
  86.11792755126953, 86.11687469482422, 86.11576843261719, 86.11463165283203,
  86.11357879638672, 86.11251831054688, 86.11141204833984, 86.11036682128906,
  86.10942077636719, 86.10855865478516, 86.1076431274414, 86.10663604736328,
  86.10562896728516, 86.10460662841797, 86.10356140136719, 86.1024398803711,
  86.1013412475586, 86.10022735595703, 86.09912109375, 86.09803009033203,
  86.09695434570312, 86.09596252441406, 86.09490966796875, 86.09369659423828,
  86.09236907958984, 86.09112548828125, 86.09012603759766, 86.08908081054688,
  86.08809661865234, 86.08718872070312, 86.08639526367188, 86.08552551269531,
  86.08444213867188, 86.08333587646484, 86.08202362060547, 86.08102416992188,
  86.0799789428711, 86.0788803100586, 86.07777404785156, 86.07679748535156,
  86.075927734375, 86.07486724853516, 86.07371520996094, 86.07262420654297,
  86.07160186767578, 86.07052612304688, 86.06948852539062, 86.06846618652344,
  86.06758117675781, 86.0667953491211, 86.06596374511719, 86.06507110595703,
  86.06426239013672, 86.06343841552734, 86.0626220703125, 86.0617446899414,
  86.06079864501953, 86.059814453125, 86.05879211425781, 86.05769348144531,
  86.056640625, 86.05577850341797, 86.05502319335938, 86.05430603027344,
  86.05352783203125, 86.05247497558594, 86.05130767822266, 86.05010223388672,
  86.04898834228516, 86.04788970947266, 86.04673767089844, 86.04578399658203,
  86.0450668334961, 86.04454803466797, 86.04398345947266, 86.04325866699219,
  86.04237365722656, 86.0412826538086, 86.03993225097656, 86.03850555419922,
  86.0371322631836, 86.0360336303711, 86.03509521484375, 86.0340576171875,
  86.03292846679688, 86.03170013427734, 86.03048706054688, 86.02953338623047,
  86.02861022949219, 86.02791595458984, 86.02721405029297, 86.02662658691406,
  86.0259780883789, 86.02490997314453, 86.02366638183594, 86.02232360839844,
  86.02092742919922, 86.01953887939453, 86.01801300048828, 86.01659393310547,
  86.01561737060547, 86.0147476196289, 86.01394653320312, 86.01278686523438,
  86.01136016845703, 86.00988006591797, 86.00840759277344, 86.00706481933594,
  86.00578308105469, 86.00453186035156, 86.00341796875, 86.00241088867188,
  86.00143432617188, 86.00047302246094, 85.9993667602539, 85.99806213378906,
  85.9967269897461, 85.99535369873047, 85.99400329589844, 85.9926986694336,
  85.99148559570312, 85.99038696289062, 85.98931121826172, 85.98825073242188,
  85.98728942871094, 85.9864273071289, 85.98564147949219, 85.98495483398438,
  85.9844741821289, 85.984130859375, 85.9836654663086, 85.98310852050781,
  85.98258972167969, 85.9819107055664, 85.98101043701172, 85.980224609375,
  85.97956085205078, 85.97906494140625, 85.97840881347656, 85.97765350341797,
  85.97674560546875, 85.97571563720703, 85.97450256347656, 85.97315979003906,
  85.9717788696289, 85.97051239013672, 85.96952819824219, 85.96880340576172,
  85.96826171875, 85.96782684326172, 85.96739196777344, 85.96690368652344,
  85.96662139892578, 85.96627807617188, 85.96569061279297, 85.96487426757812,
  85.96410369873047, 85.96332550048828, 85.96253967285156,
];
