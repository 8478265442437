export const FL_TEMP_DATA = [
  92.36751556396484, 92.36751556396484, 92.36509704589844, 92.36294555664062,
  92.3605728149414, 92.3584213256836, 92.35588073730469, 92.35308837890625,
  92.35035705566406, 92.34784698486328, 92.34545135498047, 92.34330749511719,
  92.3414077758789, 92.33953094482422, 92.33757781982422, 92.3350830078125,
  92.33231353759766, 92.32974243164062, 92.32740020751953, 92.32490539550781,
  92.3221664428711, 92.31938934326172, 92.31673431396484, 92.31400299072266,
  92.31098175048828, 92.30815887451172, 92.30548095703125, 92.30290222167969,
  92.3003921508789, 92.2977523803711, 92.29489135742188, 92.29196166992188,
  92.28926086425781, 92.28659057617188, 92.28401184082031, 92.28143310546875,
  92.27885437011719, 92.2760238647461, 92.27299499511719, 92.26980590820312,
  92.26637268066406, 92.26300811767578, 92.25963592529297, 92.25625610351562,
  92.25286102294922, 92.24931335449219, 92.24567413330078, 92.24209594726562,
  92.23875427246094, 92.23536682128906, 92.23204040527344, 92.2288818359375,
  92.22586059570312, 92.2227554321289, 92.21939086914062, 92.21592712402344,
  92.2124252319336, 92.20887756347656, 92.20532989501953, 92.20191955566406,
  92.19871520996094, 92.19532012939453, 92.19170379638672, 92.1881103515625,
  92.18467712402344, 92.18135833740234, 92.17826843261719, 92.17586517333984,
  92.17325592041016, 92.16993713378906, 92.16614532470703, 92.16250610351562,
  92.15919494628906, 92.15616607666016, 92.15315246582031, 92.14998626708984,
  92.1468734741211, 92.14364624023438, 92.1404800415039, 92.137451171875,
  92.13468933105469, 92.13212585449219, 92.12972259521484, 92.12721252441406,
  92.12474060058594, 92.12248229980469, 92.12059783935547, 92.11881256103516,
  92.1168212890625, 92.11457061767578, 92.1122055053711, 92.10993957519531,
  92.10783386230469, 92.10576629638672, 92.10379028320312, 92.1019058227539,
  92.10012817382812, 92.0983657836914, 92.09663391113281, 92.09504699707031,
  92.09373474121094, 92.09281921386719, 92.09208679199219, 92.09162139892578,
  92.09130096435547, 92.09093475341797, 92.09056091308594, 92.08990478515625,
  92.08906555175781, 92.08816528320312, 92.08733367919922, 92.0866470336914,
  92.08615112304688, 92.0859603881836, 92.08599090576172, 92.086181640625,
  92.08628845214844, 92.08635711669922, 92.08648681640625, 92.08667755126953,
  92.0869140625, 92.08708190917969, 92.08724212646484, 92.08749389648438,
  92.08795166015625, 92.08876037597656, 92.08980560302734, 92.09103393554688,
  92.09230041503906, 92.09371185302734, 92.09495544433594, 92.09598541259766,
  92.09678649902344, 92.09762573242188, 92.0986557006836, 92.09955596923828,
  92.10045623779297, 92.10163116455078, 92.10308074951172, 92.1045913696289,
  92.10607147216797, 92.1075668334961, 92.1091079711914, 92.11066436767578,
  92.11209869384766, 92.11354064941406, 92.11497497558594, 92.11640167236328,
  92.11784362792969, 92.11929321289062, 92.1208724975586, 92.12264251708984,
  92.12454223632812, 92.12648010253906, 92.12844848632812, 92.13036346435547,
  92.13226318359375, 92.1340560913086, 92.13573455810547, 92.13750457763672,
  92.1393814086914, 92.14137268066406, 92.14341735839844, 92.14555358886719,
  92.14769744873047, 92.14996337890625, 92.15225982666016, 92.1545181274414,
  92.15685272216797, 92.15922546386719, 92.1616439819336, 92.16411590576172,
  92.166748046875, 92.16942596435547, 92.17210388183594, 92.17474365234375,
  92.17733001708984, 92.17994689941406, 92.18262481689453, 92.18533325195312,
  92.18803405761719, 92.19066619873047, 92.19335174560547, 92.19608306884766,
  92.19892883300781, 92.20177459716797, 92.20458984375, 92.2073745727539,
  92.21013641357422, 92.21292114257812, 92.21566772460938, 92.2183609008789,
  92.22105407714844, 92.22370147705078, 92.22630310058594, 92.22888946533203,
  92.23148345947266, 92.234130859375, 92.23698425292969, 92.23997497558594,
  92.2430191040039, 92.24607849121094, 92.24915313720703, 92.25214385986328,
  92.25518798828125, 92.2582778930664, 92.26131439208984, 92.26425170898438,
  92.26716613769531, 92.27011108398438, 92.27305603027344, 92.27603912353516,
  92.2790298461914, 92.28204345703125, 92.28504180908203, 92.28805541992188,
  92.29105377197266, 92.2940444946289, 92.29705047607422, 92.300048828125,
  92.3031005859375, 92.3061752319336, 92.3092041015625, 92.31216430664062,
  92.31513977050781, 92.31816864013672, 92.3212890625, 92.3244857788086,
  92.32772827148438, 92.33101654052734, 92.33432006835938, 92.33758544921875,
  92.34085083007812, 92.3440933227539, 92.34725952148438, 92.3503646850586,
  92.3534927368164, 92.3565902709961, 92.35965728759766, 92.36286163330078,
  92.3660659790039, 92.36921691894531, 92.37237548828125, 92.37564849853516,
  92.37899780273438, 92.38240051269531, 92.38589477539062, 92.38943481445312,
  92.39313507080078, 92.39684295654297, 92.40052032470703, 92.40434265136719,
  92.40812683105469, 92.4119644165039, 92.41602325439453, 92.4200668334961,
  92.42420196533203, 92.42830657958984, 92.43219757080078, 92.43582916259766,
  92.4394302368164, 92.44361877441406, 92.44820404052734, 92.45294952392578,
  92.45761108398438, 92.4627685546875, 92.46806335449219, 92.47354125976562,
  92.47904968261719, 92.48404693603516, 92.48844146728516, 92.49337005615234,
  92.49871826171875, 92.50413513183594, 92.50997924804688, 92.51615142822266,
  92.52208709716797, 92.52754974365234, 92.53287506103516, 92.53817749023438,
  92.5433349609375, 92.54835510253906, 92.55355834960938, 92.55931854248047,
  92.5649642944336, 92.57005310058594, 92.57472229003906, 92.57958984375,
  92.5845718383789, 92.58949279785156, 92.59463500976562, 92.6001205444336,
  92.6054916381836, 92.61060333251953, 92.615478515625, 92.62012481689453,
  92.6246566772461, 92.62921905517578, 92.63396453857422, 92.63955688476562,
  92.64535522460938, 92.65068817138672, 92.6553726196289, 92.65963745117188,
  92.66392517089844, 92.66880798339844, 92.67384338378906, 92.67861938476562,
  92.68290710449219, 92.68693542480469, 92.69110107421875, 92.69561767578125,
  92.70016479492188, 92.70457458496094, 92.70896911621094, 92.71372985839844,
  92.7184829711914, 92.722900390625, 92.72695922851562, 92.7308578491211,
  92.73526763916016, 92.74005126953125, 92.7447280883789, 92.74903106689453,
  92.75312805175781, 92.75728607177734, 92.76161193847656, 92.76607513427734,
  92.77054595947266, 92.77474975585938, 92.77859497070312, 92.78230285644531,
  92.78645324707031, 92.7907943725586, 92.79505920410156, 92.7990493774414,
  92.8027572631836, 92.80622100830078, 92.80960845947266, 92.8130874633789,
  92.816650390625, 92.82023620605469, 92.82374572753906, 92.82717895507812,
  92.83056640625, 92.83393096923828, 92.83724975585938, 92.84052276611328,
  92.84405517578125, 92.84764862060547, 92.8511962890625, 92.85486602783203,
  92.85789489746094, 92.860595703125, 92.86347198486328, 92.86681365966797,
  92.87039947509766, 92.87370300292969, 92.87672424316406, 92.87979125976562,
  92.88284301757812, 92.88611602783203, 92.88951873779297, 92.8927230834961,
  92.89566040039062, 92.89848327636719, 92.90135192871094, 92.9040756225586,
  92.90680694580078, 92.90977478027344, 92.91272735595703, 92.91558074951172,
  92.91831970214844, 92.92098999023438, 92.9235610961914, 92.92601013183594,
  92.92839813232422, 92.93065643310547, 92.93279266357422, 92.93517303466797,
  92.93761444091797, 92.94001770019531, 92.9424819946289, 92.94508361816406,
  92.94764709472656, 92.95014190673828, 92.95268249511719, 92.95511627197266,
  92.95735168457031, 92.95934295654297, 92.96131896972656, 92.96324157714844,
  92.96527099609375, 92.9672622680664, 92.9692153930664, 92.97128295898438,
  92.9734115600586, 92.9754409790039, 92.97734832763672, 92.979248046875,
  92.98141479492188, 92.98392486572266, 92.98641967773438, 92.98894500732422,
  92.99150848388672, 92.99398040771484, 92.9966049194336, 92.99944305419922,
  93.00245666503906, 93.00543975830078, 93.00838470458984, 93.01141357421875,
  93.01423645019531, 93.01688385009766, 93.01961517333984, 93.02257537841797,
  93.0254898071289, 93.02835083007812, 93.03125762939453, 93.0343246459961,
  93.0374526977539, 93.04061889648438, 93.04386901855469, 93.0472183227539,
  93.05056762695312, 93.05384826660156, 93.05717468261719, 93.06059265136719,
  93.06405639648438, 93.06739044189453, 93.07080841064453, 93.07432556152344,
  93.07794189453125, 93.08159637451172, 93.08517456054688, 93.08882141113281,
  93.0925064086914, 93.09626770019531, 93.10015106201172, 93.10398864746094,
  93.10790252685547, 93.11177062988281, 93.11563110351562, 93.11956024169922,
  93.12347412109375, 93.12744140625, 93.13145446777344, 93.13553619384766,
  93.1396713256836, 93.14378356933594, 93.1479263305664, 93.15206146240234,
  93.15625, 93.16051483154297, 93.16482543945312, 93.16919708251953,
  93.17350769042969, 93.17781829833984, 93.18212127685547, 93.18647766113281,
  93.19085693359375, 93.19528198242188, 93.19974517822266, 93.20423889160156,
  93.208740234375, 93.21326446533203, 93.21782684326172, 93.22241973876953,
  93.22704315185547, 93.231689453125, 93.23635864257812, 93.24104309082031,
  93.24569702148438, 93.2503662109375, 93.25507354736328, 93.25984191894531,
  93.26464080810547, 93.26941680908203, 93.27420806884766, 93.2790298461914,
  93.28386688232422, 93.28866577148438, 93.29348754882812, 93.29833984375,
  93.30323028564453, 93.3081283569336, 93.31299591064453, 93.31787872314453,
  93.3227767944336, 93.32772064208984, 93.33271789550781, 93.33771514892578,
  93.34268188476562, 93.34758758544922, 93.35249328613281, 93.35743713378906,
  93.36244201660156, 93.36749267578125, 93.37252044677734, 93.37750244140625,
  93.38243865966797, 93.38735961914062, 93.39229583740234, 93.39727020263672,
  93.40226745605469, 93.40727233886719, 93.4122314453125, 93.41716003417969,
  93.42208862304688, 93.42704772949219, 93.4320297241211, 93.43701934814453,
  93.44200897216797, 93.44697570800781, 93.4519271850586, 93.45687866210938,
  93.46183013916016, 93.46681213378906, 93.47180938720703, 93.47681427001953,
  93.48179626464844, 93.48676300048828, 93.4917221069336, 93.49671173095703,
  93.5017318725586, 93.50675201416016, 93.51176452636719, 93.51677703857422,
  93.52179718017578, 93.52682495117188, 93.53187561035156, 93.53692626953125,
  93.54196166992188, 93.54698181152344, 93.55195617675781, 93.556884765625,
  93.56181335449219, 93.56678771972656, 93.57182312011719, 93.57688903808594,
  93.58192443847656, 93.58688354492188, 93.59180450439453, 93.59672546386719,
  93.60166931152344, 93.60663604736328, 93.61164855957031, 93.61669158935547,
  93.62171173095703, 93.6266860961914, 93.63162231445312, 93.63655853271484,
  93.64151000976562, 93.64651489257812, 93.65151977539062, 93.65652465820312,
  93.66151428222656, 93.66650390625, 93.67149353027344, 93.67647552490234,
  93.68144989013672, 93.68641662597656, 93.6913833618164, 93.69637298583984,
  93.70137786865234, 93.70635986328125, 93.71134185791016, 93.7163314819336,
  93.7213363647461, 93.72635650634766, 93.73135375976562, 93.73634338378906,
  93.74132537841797, 93.74627685546875, 93.751220703125, 93.75618743896484,
  93.76118469238281, 93.76617431640625, 93.7711181640625, 93.77603149414062,
  93.7809066772461, 93.7857666015625, 93.7906494140625, 93.79553985595703,
  93.80042266845703, 93.8053207397461, 93.81023406982422, 93.81514739990234,
  93.82001495361328, 93.82483673095703, 93.82963562011719, 93.83447265625,
  93.83937072753906, 93.84428405761719, 93.84918212890625, 93.85401153564453,
  93.85881042480469, 93.86359405517578, 93.868408203125, 93.87325286865234,
  93.87806701660156, 93.8829116821289, 93.88776397705078, 93.89261627197266,
  93.89746856689453, 93.90237426757812, 93.90730285644531, 93.91218566894531,
  93.9169692993164, 93.92172241210938, 93.92652893066406, 93.93141174316406,
  93.93630981445312, 93.94119262695312, 93.94605255126953, 93.95084381103516,
  93.9555435180664, 93.96025085449219, 93.96501159667969, 93.9698257446289,
  93.974609375, 93.97943115234375, 93.98432159423828, 93.98924255371094,
  93.99413299560547, 93.99894714355469, 94.003662109375, 94.00834655761719,
  94.01301574707031, 94.01774597167969, 94.0225601196289, 94.0273208618164,
  94.03204345703125, 94.03671264648438, 94.0413589477539, 94.04598236083984,
  94.05062866210938, 94.0553207397461, 94.06004333496094, 94.06473541259766,
  94.06930541992188, 94.07381439208984, 94.0782699584961, 94.08273315429688,
  94.08721923828125, 94.0917739868164, 94.0963363647461, 94.10081481933594,
  94.10523986816406, 94.10966491699219, 94.11407470703125, 94.11847686767578,
  94.12287139892578, 94.12728881835938, 94.13175201416016, 94.13628387451172,
  94.1407699584961, 94.14510345458984, 94.1494369506836, 94.15385437011719,
  94.15825653076172, 94.16260528564453, 94.16686248779297, 94.17102813720703,
  94.17520904541016, 94.17943572998047, 94.1836929321289, 94.18794250488281,
  94.19213104248047, 94.1962661743164, 94.20039367675781, 94.20453643798828,
  94.20867156982422, 94.21282196044922, 94.21691131591797, 94.22095489501953,
  94.22498321533203, 94.22899627685547, 94.23297119140625, 94.23689270019531,
  94.24082946777344, 94.2447738647461, 94.24873352050781, 94.25269317626953,
  94.25659942626953, 94.26044464111328, 94.26438903808594, 94.26834869384766,
  94.27223205566406, 94.27605438232422, 94.27986907958984, 94.28367614746094,
  94.2874755859375, 94.291259765625, 94.29499816894531, 94.29866790771484,
  94.3023452758789, 94.3060302734375, 94.30970001220703, 94.3133544921875,
  94.31689453125, 94.32029724121094, 94.32373809814453, 94.32723999023438,
  94.3306655883789, 94.33403778076172, 94.33772277832031, 94.34061431884766,
  94.34381866455078, 94.3470458984375, 94.35015869140625, 94.3532485961914,
  94.35633850097656, 94.35936737060547, 94.36231994628906, 94.36521911621094,
  94.36817169189453, 94.37109375, 94.37397003173828, 94.37675476074219,
  94.37950134277344, 94.38226318359375, 94.38499450683594, 94.38774871826172,
  94.39073181152344, 94.39313507080078, 94.3957748413086, 94.39837646484375,
  94.40087127685547, 94.40327453613281, 94.40591430664062, 94.40806579589844,
  94.41047668457031, 94.41284942626953, 94.4151611328125, 94.41744995117188,
  94.41970825195312, 94.42192077636719, 94.42408752441406, 94.4262466430664,
  94.42832946777344, 94.43040466308594, 94.43243408203125, 94.43437194824219,
  94.43648529052734, 94.4384536743164, 94.44021606445312, 94.4422607421875,
  94.4442367553711, 94.44607543945312, 94.44774627685547, 94.44937896728516,
  94.45123291015625, 94.45286560058594, 94.45470428466797, 94.45650482177734,
  94.45820617675781, 94.45988464355469, 94.46157836914062, 94.46292877197266,
  94.46440887451172, 94.46587371826172, 94.46739196777344, 94.468994140625,
  94.47077178955078, 94.47217559814453, 94.47371673583984, 94.47516632080078,
  94.47664642333984, 94.47814178466797, 94.47974395751953, 94.48147583007812,
  94.48294830322266, 94.48458099365234, 94.4862289428711, 94.48795318603516,
  94.4897689819336, 94.4917984008789, 94.49402618408203, 94.49629974365234,
  94.4984359741211, 94.50041198730469, 94.50235748291016, 94.50432586669922,
  94.50634002685547, 94.50838470458984, 94.51046752929688, 94.51248168945312,
  94.51445770263672, 94.51651000976562, 94.51861572265625, 94.520751953125,
  94.52295684814453, 94.52523803710938, 94.52757263183594, 94.52995300292969,
  94.53240203857422, 94.53491973876953, 94.53746032714844, 94.5400619506836,
  94.54266357421875, 94.5452880859375, 94.54792022705078, 94.55058288574219,
  94.55327606201172, 94.55595397949219, 94.55863952636719, 94.56133270263672,
  94.56403350830078, 94.56674194335938, 94.56941986083984, 94.57209014892578,
  94.57473754882812, 94.57734680175781, 94.5799331665039, 94.58251190185547,
  94.58507537841797, 94.587646484375, 94.59025573730469, 94.5928955078125,
  94.59552001953125, 94.59813690185547, 94.60070037841797, 94.60328674316406,
  94.60588073730469, 94.6083755493164, 94.61073303222656, 94.6130142211914,
  94.61534118652344, 94.61771392822266, 94.62008666992188, 94.62239837646484,
  94.62464904785156, 94.62689208984375, 94.62909698486328, 94.63126373291016,
  94.63343048095703, 94.63558197021484, 94.63768768310547, 94.6397705078125,
  94.64185333251953, 94.6439437866211, 94.6460189819336, 94.6480712890625,
  94.65010070800781, 94.65216827392578, 94.65412902832031, 94.65607452392578,
  94.65789794921875, 94.6598129272461, 94.66159057617188, 94.66348266601562,
  94.66531372070312, 94.66714477539062, 94.6689224243164, 94.6707534790039,
  94.67252349853516, 94.67418670654297, 94.67587280273438, 94.67760467529297,
  94.6792984008789, 94.68093872070312, 94.68253326416016, 94.68409729003906,
  94.68560791015625, 94.6871109008789, 94.68863677978516, 94.69020080566406,
  94.6917724609375, 94.69329071044922, 94.69473266601562, 94.69613647460938,
  94.69750213623047, 94.69886016845703, 94.70014190673828, 94.70140075683594,
  94.70263671875, 94.70384979248047, 94.70508575439453, 94.706298828125,
  94.70745849609375, 94.70862579345703, 94.70975494384766, 94.71084594726562,
  94.71188354492188, 94.71292877197266, 94.71392822265625, 94.71488952636719,
  94.71585845947266, 94.71684265136719, 94.71781921386719, 94.7187728881836,
  94.71967315673828, 94.72052001953125, 94.72132873535156, 94.72206115722656,
  94.72274780273438, 94.72339630126953, 94.72401428222656, 94.72462463378906,
  94.7253189086914, 94.7260513305664, 94.72674560546875, 94.72737121582031,
  94.72798156738281, 94.72850799560547, 94.72895812988281, 94.72933959960938,
  94.7297134399414, 94.73011779785156, 94.73082733154297, 94.7313461303711,
  94.73189544677734, 94.73239135742188, 94.7331771850586, 94.73357391357422,
  94.7339096069336, 94.73414611816406, 94.73450469970703, 94.73495483398438,
  94.73543548583984, 94.73583221435547, 94.73609924316406, 94.73631286621094,
  94.73657989501953, 94.7369155883789, 94.73729705810547, 94.73764038085938,
  94.73792266845703, 94.73814392089844, 94.73833465576172, 94.73847198486328,
  94.73857879638672, 94.7386703491211, 94.73873138427734, 94.73876953125,
  94.73876190185547, 94.73872375488281, 94.7386474609375, 94.73855590820312,
  94.73846435546875, 94.73846435546875, 94.73860168457031, 94.73836517333984,
  94.7382583618164, 94.73812103271484, 94.7379379272461, 94.73809814453125,
  94.73780822753906, 94.73765563964844, 94.73760223388672, 94.73734283447266,
  94.73709106445312, 94.73663330078125, 94.73625183105469, 94.73592376708984,
  94.73572540283203, 94.7353286743164, 94.73492431640625, 94.73453521728516,
  94.73421478271484, 94.73389434814453, 94.73361206054688, 94.73334503173828,
  94.7330093383789, 94.73267364501953, 94.73223876953125, 94.731689453125,
  94.73106384277344, 94.73027038574219, 94.72946166992188, 94.72877502441406,
  94.72803497314453, 94.7272720336914, 94.7264633178711, 94.72561645507812,
  94.72474670410156, 94.72388458251953, 94.72306823730469, 94.72237396240234,
  94.72161102294922, 94.7207260131836, 94.71969604492188, 94.71861267089844,
  94.71762084960938, 94.71675109863281, 94.71581268310547, 94.71481323242188,
  94.71368408203125, 94.71246337890625, 94.71134948730469, 94.71026611328125,
  94.70915222167969, 94.70801544189453, 94.70670318603516, 94.7053451538086,
  94.70399475097656, 94.70271301269531, 94.70137023925781, 94.70023345947266,
  94.69872283935547, 94.69741821289062, 94.6958236694336, 94.6944808959961,
  94.69310760498047, 94.69168853759766, 94.69012451171875, 94.68852233886719,
  94.68675231933594, 94.68527221679688, 94.6834716796875, 94.6819076538086,
  94.68009185791016, 94.6783447265625, 94.67657470703125, 94.67471313476562,
  94.67316436767578, 94.67133331298828, 94.66940307617188, 94.6674575805664,
  94.66551971435547, 94.66363525390625, 94.66169738769531, 94.65972137451172,
  94.65776062011719, 94.65577697753906, 94.65377807617188, 94.65170288085938,
  94.64961242675781, 94.64754486083984, 94.64543151855469, 94.64328002929688,
  94.64102172851562, 94.63877868652344, 94.63667297363281, 94.63452911376953,
  94.63237762451172, 94.63021850585938, 94.62793731689453, 94.62565612792969,
  94.62332916259766, 94.62104797363281, 94.6187744140625, 94.61629486083984,
  94.61395263671875, 94.61166381835938, 94.60941314697266, 94.6071548461914,
  94.60480499267578, 94.60247039794922, 94.60012817382812, 94.59766387939453,
  94.59528350830078, 94.5929183959961, 94.5905532836914, 94.58805084228516,
  94.58561706542969, 94.58311462402344, 94.58064270019531, 94.5780258178711,
  94.57567596435547, 94.57341003417969, 94.5707778930664, 94.56797790527344,
  94.56507873535156, 94.56226348876953, 94.55955505371094, 94.55686950683594,
  94.55420684814453, 94.55140686035156, 94.54850769042969, 94.54560852050781,
  94.5428237915039, 94.54013061523438, 94.53742980957031, 94.5346450805664,
  94.53163146972656, 94.52832794189453, 94.52503967285156, 94.52163696289062,
  94.5185317993164, 94.51560974121094, 94.51262664794922, 94.50958251953125,
  94.50642395019531, 94.50331115722656, 94.50004577636719, 94.49688720703125,
  94.49362182617188, 94.49028015136719, 94.48687744140625, 94.48351287841797,
  94.48018646240234, 94.4768295288086, 94.47338104248047, 94.46983337402344,
  94.4661865234375, 94.46249389648438, 94.45874786376953, 94.45506286621094,
  94.45145416259766, 94.44782257080078, 94.44407653808594, 94.44031524658203,
  94.43648529052734, 94.43266296386719, 94.42914581298828, 94.42597961425781,
  94.42314910888672, 94.42058563232422, 94.41810607910156, 94.4154052734375,
  94.41264343261719, 94.40986633300781, 94.40721130371094, 94.40459442138672,
  94.40198516845703, 94.39935302734375, 94.39665222167969, 94.39392852783203,
  94.39118957519531, 94.38850402832031, 94.38585662841797, 94.38341522216797,
  94.38108825683594, 94.37877655029297, 94.37632751464844, 94.37377166748047,
  94.37122344970703, 94.36881256103516, 94.36650848388672, 94.36431121826172,
  94.36219787597656, 94.3601303100586, 94.35810852050781, 94.35617065429688,
  94.3543472290039, 94.35267639160156, 94.35111999511719, 94.349609375,
  94.34806823730469, 94.34657287597656, 94.34517669677734, 94.34394073486328,
  94.34302520751953, 94.34233093261719, 94.34165954589844, 94.34085845947266,
  94.3399429321289, 94.33895874023438, 94.33799743652344, 94.33724212646484,
  94.33673858642578, 94.33631134033203, 94.33575439453125, 94.3350830078125,
  94.33441162109375, 94.33378601074219, 94.33319091796875, 94.33269500732422,
  94.33233642578125, 94.33202362060547, 94.33164978027344, 94.33122253417969,
  94.33078002929688, 94.330322265625, 94.32989501953125, 94.32949829101562,
  94.3291244506836, 94.32876586914062, 94.32840728759766, 94.32804870605469,
  94.32762145996094, 94.32720184326172, 94.32679748535156, 94.32644653320312,
  94.32615661621094, 94.32584381103516, 94.32553100585938, 94.32518768310547,
  94.3248291015625, 94.3244400024414, 94.32410430908203, 94.32372283935547,
  94.32328796386719, 94.32283782958984, 94.32239532470703, 94.32195281982422,
  94.3215103149414, 94.321044921875, 94.320556640625, 94.320068359375,
  94.31951141357422, 94.31890869140625, 94.31829833984375, 94.31768798828125,
  94.31709289550781, 94.31648254394531, 94.31584167480469, 94.31519317626953,
  94.31455993652344, 94.3138198852539, 94.31304168701172, 94.31227111816406,
  94.31151580810547, 94.3108139038086, 94.31011199951172, 94.30934143066406,
  94.3084945678711, 94.30762481689453, 94.30677032470703, 94.30596160888672,
  94.30514526367188, 94.30436706542969, 94.30360412597656, 94.30279541015625,
  94.30194091796875, 94.301025390625, 94.3000259399414, 94.29898834228516,
  94.29798889160156, 94.29700469970703, 94.29600524902344, 94.29499053955078,
  94.29401397705078, 94.29303741455078, 94.29200744628906, 94.29093933105469,
  94.28986358642578, 94.28876495361328, 94.28764343261719, 94.28653717041016,
  94.28546905517578, 94.28435516357422, 94.2830581665039, 94.28207397460938,
  94.28099060058594, 94.27978515625, 94.27851867675781, 94.27729797363281,
  94.27606201171875, 94.27466583251953, 94.273193359375, 94.27169036865234,
  94.2701644897461, 94.2686767578125, 94.2673568725586, 94.26603698730469,
  94.26453399658203, 94.26297760009766, 94.26142120361328, 94.25984191894531,
  94.2582778930664, 94.25672149658203, 94.25518798828125, 94.253662109375,
  94.25211334228516, 94.25055694580078, 94.24898529052734, 94.24739837646484,
  94.24574279785156, 94.24401092529297, 94.24225616455078, 94.24050903320312,
  94.23877716064453, 94.23707580566406, 94.23534393310547, 94.2334976196289,
  94.23165130615234, 94.22981262207031, 94.22793579101562, 94.2260513305664,
  94.22418212890625, 94.22230529785156, 94.22041320800781, 94.21846008300781,
  94.21646118164062, 94.21436309814453, 94.21223449707031, 94.21007537841797,
  94.20797729492188, 94.20598602294922, 94.20402526855469, 94.20198822021484,
  94.1998519897461, 94.1976547241211, 94.19544219970703, 94.19322967529297,
  94.19097900390625, 94.18873596191406, 94.1865005493164, 94.18423461914062,
  94.18193817138672, 94.17962646484375, 94.17729949951172, 94.17498779296875,
  94.17266082763672, 94.17027282714844, 94.16786193847656, 94.1654281616211,
  94.16299438476562, 94.16055297851562, 94.15812683105469, 94.15571594238281,
  94.15323638916016, 94.15066528320312, 94.14806365966797, 94.14547729492188,
  94.14291381835938, 94.14034271240234, 94.13775634765625, 94.13517761230469,
  94.13259887695312, 94.13005828857422, 94.1275405883789, 94.12509155273438,
  94.12264251708984, 94.12002563476562, 94.1172103881836, 94.11434936523438,
  94.1114501953125, 94.10852813720703, 94.1055908203125, 94.10281372070312,
  94.10015869140625, 94.09740447998047, 94.09455108642578, 94.09159088134766,
  94.08853912353516, 94.08547973632812, 94.08253479003906, 94.07965850830078,
  94.07673645019531, 94.07366180419922, 94.07054138183594, 94.0675048828125,
  94.06464385986328, 94.06184387207031, 94.0589599609375, 94.05598449707031,
  94.05303192138672, 94.05009460449219, 94.04715728759766, 94.04430389404297,
  94.04141998291016, 94.038330078125, 94.0352783203125, 94.03237915039062,
  94.02944946289062, 94.0263442993164, 94.02320861816406, 94.02003479003906,
  94.01688385009766, 94.01377868652344, 94.01061248779297, 94.0073471069336,
  94.00395965576172, 94.00062561035156, 93.9974136352539, 93.99425506591797,
  93.9910659790039, 93.98783111572266, 93.9843978881836, 93.98101043701172,
  93.97769165039062, 93.974365234375, 93.9710464477539, 93.96782684326172,
  93.96472930908203, 93.96131896972656, 93.957763671875, 93.95433044433594,
  93.95099639892578, 93.94770050048828, 93.9444580078125, 93.94107055664062,
  93.9375991821289, 93.93416595458984, 93.9306640625, 93.92723083496094,
  93.9236831665039, 93.92002868652344, 93.91637420654297, 93.91271209716797,
  93.90885925292969, 93.90505981445312, 93.90138244628906, 93.89778900146484,
  93.89413452148438, 93.8905029296875, 93.88689422607422, 93.88314819335938,
  93.87942504882812, 93.87556457519531, 93.87156677246094, 93.86766815185547,
  93.86377716064453, 93.85981750488281, 93.85588836669922, 93.85197448730469,
  93.8480453491211, 93.84400939941406, 93.83960723876953, 93.83598327636719,
  93.8319320678711, 93.82799530029297, 93.82376861572266, 93.82034301757812,
  93.81646728515625, 93.81241607666016, 93.80836486816406, 93.8043441772461,
  93.80034637451172, 93.79621887207031, 93.7920150756836, 93.78789520263672,
  93.78380584716797, 93.77932739257812, 93.77562713623047, 93.77104949951172,
  93.76734161376953, 93.76327514648438, 93.75881958007812, 93.75517272949219,
  93.75099182128906, 93.74677276611328, 93.7424545288086, 93.73817443847656,
  93.73383331298828, 93.72937774658203, 93.72502899169922, 93.72056579589844,
  93.71601867675781, 93.7115707397461, 93.70720672607422, 93.702880859375,
  93.69852447509766, 93.69364929199219, 93.68965911865234, 93.68523406982422,
  93.68083953857422, 93.67636108398438, 93.6718521118164, 93.66725158691406,
  93.66266632080078, 93.65796661376953, 93.65335845947266, 93.64884185791016,
  93.6443099975586, 93.63968658447266, 93.63501739501953, 93.6302490234375,
  93.62553405761719, 93.6208267211914, 93.61611938476562, 93.611328125,
  93.60649871826172, 93.6017074584961, 93.59703826904297, 93.59225463867188,
  93.58728790283203, 93.58243560791016, 93.57771301269531, 93.57301330566406,
  93.56819915771484, 93.56330871582031, 93.55843353271484, 93.55357360839844,
  93.5488052368164, 93.54399871826172, 93.53907012939453, 93.53411865234375,
  93.52920532226562, 93.52420806884766, 93.51911926269531, 93.51409912109375,
  93.50869750976562, 93.5042953491211, 93.49868774414062, 93.494140625,
  93.48908996582031, 93.48418426513672, 93.47926330566406, 93.47425842285156,
  93.4692153930664, 93.464111328125, 93.45909881591797, 93.4542007446289,
  93.44927978515625, 93.44437408447266, 93.43952941894531, 93.43466186523438,
  93.42984008789062, 93.42505645751953, 93.42034149169922, 93.41568756103516,
  93.41104125976562, 93.40617370605469, 93.40118408203125, 93.39616394042969,
  93.39115905761719, 93.38629913330078, 93.38137817382812, 93.37635803222656,
  93.37125396728516, 93.36613464355469, 93.36104583740234, 93.35604858398438,
  93.35106658935547, 93.34596252441406, 93.34068298339844, 93.33544921875,
  93.33027648925781, 93.32535552978516, 93.32038116455078, 93.31522369384766,
  93.30989837646484, 93.3046646118164, 93.29955291748047, 93.29444885253906,
  93.28927612304688, 93.28407287597656, 93.27888488769531, 93.27371978759766,
  93.26858520507812, 93.26350402832031, 93.25843048095703, 93.2533950805664,
  93.24849700927734, 93.24356079101562, 93.23857116699219, 93.23336791992188,
  93.22813415527344, 93.22300720214844, 93.2179946899414, 93.21285247802734,
  93.2076187133789, 93.20237731933594, 93.1971435546875, 93.19187927246094,
  93.1866683959961, 93.18150329589844, 93.1763916015625, 93.17122650146484,
  93.1659927368164, 93.16079711914062, 93.15567779541016, 93.15058135986328,
  93.1455078125, 93.14012145996094, 93.1346664428711, 93.12931823730469,
  93.12399291992188, 93.11857604980469, 93.11311340332031, 93.10783386230469,
  93.10261535644531, 93.09722137451172, 93.09166717529297, 93.0860824584961,
  93.08058166503906, 93.07525634765625, 93.06983947753906, 93.06422424316406,
  93.05847930908203, 93.05277252197266, 93.047119140625, 93.04158782958984,
  93.0359878540039, 93.0303955078125, 93.0246353149414, 93.01890563964844,
  93.01316833496094, 93.00743103027344, 93.00174713134766, 92.99616241455078,
  92.99041748046875, 92.98463439941406, 92.97883605957031, 92.97303009033203,
  92.96714782714844, 92.96123504638672, 92.955322265625, 92.94943237304688,
  92.94351196289062, 92.93753814697266, 92.93159484863281, 92.92566680908203,
  92.91974639892578, 92.91376495361328, 92.90782165527344, 92.90186309814453,
  92.89575958251953, 92.8895492553711, 92.8833236694336, 92.876953125,
  92.8707046508789, 92.86465454101562, 92.85865020751953, 92.85254669189453,
  92.84638214111328, 92.84001922607422, 92.83368682861328, 92.82774353027344,
  92.8218765258789, 92.81591033935547, 92.80976867675781, 92.80350494384766,
  92.7973403930664, 92.79119110107422, 92.7850341796875, 92.77864837646484,
  92.7723617553711, 92.76608276367188, 92.75979614257812, 92.75358581542969,
  92.74754333496094, 92.74129486083984, 92.73492431640625, 92.72853088378906,
  92.72210693359375, 92.7156753540039, 92.70930480957031, 92.70297241210938,
  92.6966323852539, 92.69009399414062, 92.6834945678711, 92.6769790649414,
  92.67057037353516, 92.6640853881836, 92.65750122070312, 92.65074920654297,
  92.6438217163086, 92.63699340820312, 92.63019561767578, 92.62358856201172,
  92.61695098876953, 92.61027526855469, 92.60368347167969, 92.59725189208984,
  92.59090423583984, 92.58441925048828, 92.57796478271484, 92.57112884521484,
  92.5643539428711, 92.55767822265625, 92.55077362060547, 92.5438003540039,
  92.53666687011719, 92.52936553955078, 92.5224609375, 92.51583099365234,
  92.5092544555664, 92.50267791748047, 92.49602508544922, 92.48915100097656,
  92.48210906982422, 92.47498321533203, 92.46784210205078, 92.46073150634766,
  92.45368957519531, 92.44671630859375, 92.4397964477539, 92.43292236328125,
  92.42606353759766, 92.41923522949219, 92.41239929199219, 92.40553283691406,
  92.3984375, 92.39122772216797, 92.3840103149414, 92.37676239013672,
  92.36946868896484, 92.3622055053711, 92.35490417480469, 92.34773254394531,
  92.34065246582031, 92.33366394042969, 92.32665252685547, 92.31970977783203,
  92.31278228759766, 92.30570983886719, 92.29853820800781, 92.29141998291016,
  92.28424072265625, 92.2770767211914, 92.26985168457031, 92.26262664794922,
  92.25559997558594, 92.24868774414062, 92.24171447753906, 92.23473358154297,
  92.22762298583984, 92.22052764892578, 92.21343231201172, 92.206298828125,
  92.19920349121094, 92.19212341308594, 92.1849365234375, 92.17781829833984,
  92.17060852050781, 92.16326904296875, 92.15559387207031, 92.1480941772461,
  92.14077758789062, 92.13353729248047, 92.12628936767578, 92.11902618408203,
  92.11177062988281, 92.1044692993164, 92.09724426269531, 92.0901107788086,
  92.08306884765625, 92.07615661621094, 92.06914520263672, 92.06246948242188,
  92.05599212646484, 92.04959869384766, 92.04304504394531, 92.03641510009766,
  92.02967834472656, 92.02283477783203, 92.01602172851562, 92.00910186767578,
  92.00188446044922, 91.99459075927734, 91.98746490478516, 91.98077392578125,
  91.97444915771484, 91.96821594238281, 91.96192932128906, 91.9554672241211,
  91.94884490966797, 91.94212341308594, 91.93536376953125, 91.92854309082031,
  91.9216537475586, 91.91474914550781, 91.90821075439453, 91.90172576904297,
  91.8951416015625, 91.888671875, 91.88249969482422, 91.87651062011719,
  91.87031555175781, 91.86387634277344, 91.85733795166016, 91.85065460205078,
  91.8442153930664, 91.8379135131836, 91.83158111572266, 91.8250961303711,
  91.81837463378906, 91.81172180175781, 91.80514526367188, 91.79876708984375,
  91.79255676269531, 91.78640747070312, 91.78003692626953, 91.77342987060547,
  91.76668548583984, 91.75992584228516, 91.75293731689453, 91.74586486816406,
  91.73884582519531, 91.73179626464844, 91.72484588623047, 91.71808624267578,
  91.71141052246094, 91.70494079589844, 91.69857788085938, 91.69190979003906,
  91.68508911132812, 91.6783676147461, 91.6719741821289, 91.66594696044922,
  91.66009521484375, 91.65435791015625, 91.64857482910156, 91.64278411865234,
  91.63694763183594, 91.63118743896484, 91.62565612792969, 91.62017059326172,
  91.6146469116211, 91.60923767089844, 91.60403442382812, 91.59908294677734,
  91.59445190429688, 91.59007263183594, 91.58573913574219, 91.58140563964844,
  91.57722473144531, 91.57316589355469, 91.56927490234375, 91.56547546386719,
  91.56175231933594, 91.5580825805664, 91.55438232421875, 91.5506591796875,
  91.54701232910156, 91.5434341430664, 91.5399398803711, 91.53666687011719,
  91.53349304199219, 91.53034210205078, 91.52715301513672, 91.52400970458984,
  91.52101135253906, 91.51821899414062, 91.51563262939453, 91.51318359375,
  91.5108642578125, 91.50875091552734, 91.5066909790039, 91.50466918945312,
  91.50263977050781, 91.50066375732422, 91.49880981445312, 91.4970474243164,
  91.49537658691406, 91.49376678466797, 91.49223327636719, 91.4908676147461,
  91.48965454101562, 91.48847198486328, 91.4872055053711, 91.48594665527344,
  91.48468017578125, 91.48344421386719, 91.4822006225586, 91.48096466064453,
  91.47992706298828, 91.47911071777344, 91.4783706665039, 91.47764587402344,
  91.47697448730469, 91.47644805908203, 91.4760513305664, 91.47566986083984,
  91.47537231445312, 91.47518157958984, 91.47505187988281, 91.47496795654297,
  91.4748764038086, 91.47486114501953, 91.47476959228516, 91.47454071044922,
  91.4743423461914, 91.47415161132812, 91.47403717041016, 91.47396850585938,
  91.47389221191406, 91.47380065917969, 91.4737548828125, 91.4737548828125,
  91.4738540649414, 91.47400665283203, 91.47412109375, 91.47416687011719,
  91.4742431640625, 91.474365234375, 91.47457122802734, 91.47490692138672,
  91.47531127929688, 91.47563934326172, 91.47589111328125, 91.47608184814453,
  91.47638702392578, 91.47663116455078, 91.47692108154297, 91.47724151611328,
  91.47761535644531, 91.47808074951172, 91.47840118408203, 91.47885131835938,
  91.4792709350586, 91.47975158691406, 91.48031616210938, 91.48091888427734,
  91.48145294189453, 91.48197937011719, 91.48246002197266, 91.48289489746094,
  91.48336029052734, 91.48381042480469, 91.4842529296875, 91.4847412109375,
  91.4852523803711, 91.48578643798828, 91.48632049560547, 91.4869155883789,
  91.48757934570312, 91.4882583618164, 91.48894500732422, 91.48965454101562,
  91.4903335571289, 91.49100494384766, 91.49169158935547, 91.49247741699219,
  91.49320983886719, 91.4941635131836, 91.49517822265625, 91.49616241455078,
  91.49710845947266, 91.49803924560547, 91.49895477294922, 91.4998550415039,
  91.50081634521484, 91.50176239013672, 91.50270080566406, 91.50365447998047,
  91.504638671875, 91.50570678710938, 91.50679779052734, 91.50786590576172,
  91.50887298583984, 91.50990295410156, 91.51094818115234, 91.51200103759766,
  91.51304626464844, 91.51409149169922, 91.51510620117188, 91.51602172851562,
  91.51695251464844, 91.51786041259766, 91.51870727539062, 91.51956176757812,
  91.52043151855469, 91.52131652832031, 91.52212524414062, 91.52291107177734,
  91.523681640625, 91.52452087402344, 91.52544403076172, 91.5263671875,
  91.52716064453125, 91.52784729003906, 91.52851867675781, 91.52924346923828,
  91.52999877929688, 91.53059387207031, 91.53107452392578, 91.53150939941406,
  91.53189086914062, 91.53227233886719, 91.53277587890625, 91.53321838378906,
  91.53357696533203, 91.533935546875, 91.53422546386719, 91.5345687866211,
  91.53498840332031, 91.53524780273438, 91.53557586669922, 91.5357894897461,
  91.53597259521484, 91.53617095947266, 91.53632354736328, 91.5364761352539,
  91.53655242919922, 91.53649139404297, 91.53649139404297, 91.53655242919922,
  91.53668212890625, 91.53672790527344, 91.53662872314453, 91.53656768798828,
  91.53669738769531, 91.53675842285156, 91.53656768798828, 91.53636169433594,
  91.53617095947266, 91.53605651855469, 91.53604125976562, 91.53604125976562,
  91.53591918945312, 91.53564453125, 91.53543853759766, 91.53544616699219,
  91.53559112548828, 91.53570556640625, 91.53565979003906, 91.5355224609375,
  91.53543853759766, 91.5354232788086, 91.53518676757812, 91.53483581542969,
  91.53457641601562, 91.5344467163086, 91.53428649902344, 91.53397369384766,
  91.53350067138672, 91.53299713134766, 91.53243255615234, 91.53182220458984,
  91.53145599365234, 91.5312728881836, 91.53089904785156, 91.53034210205078,
  91.52965545654297, 91.52911376953125, 91.52860260009766, 91.52808380126953,
  91.52751922607422, 91.52680969238281, 91.52606201171875, 91.5252685546875,
  91.52448272705078, 91.52375793457031, 91.52301025390625, 91.52212524414062,
  91.52108764648438, 91.52007293701172, 91.51920318603516, 91.51828002929688,
  91.51725006103516, 91.51617431640625, 91.51509857177734, 91.51398468017578,
  91.51288604736328, 91.51176452636719, 91.51060485839844, 91.50946807861328,
  91.50831604003906, 91.50717163085938, 91.50601196289062, 91.5048599243164,
  91.50370025634766, 91.50247955322266, 91.50122833251953, 91.49996185302734,
  91.49871826171875, 91.49745178222656, 91.49612426757812, 91.49480438232422,
  91.4935531616211, 91.49217987060547, 91.49055480957031, 91.48898315429688,
  91.48744201660156, 91.4858627319336, 91.48431396484375, 91.48279571533203,
  91.48120880126953, 91.47948455810547, 91.4776611328125, 91.47604370117188,
  91.47455596923828, 91.47296905517578, 91.47122955322266, 91.46940612792969,
  91.46765899658203, 91.46598815917969, 91.46419525146484, 91.46235656738281,
  91.4605712890625, 91.45881652832031, 91.45703887939453, 91.45518493652344,
  91.45329284667969, 91.45145416259766, 91.44969940185547, 91.4478988647461,
  91.4459457397461, 91.44385528564453, 91.44178009033203, 91.44007110595703,
  91.43866729736328, 91.43714904785156, 91.43572235107422, 91.43447875976562,
  91.4333724975586, 91.43228149414062, 91.43126678466797, 91.4302749633789,
  91.42923736572266, 91.42839050292969, 91.42771911621094, 91.42720031738281,
  91.42677307128906, 91.42636108398438, 91.426025390625, 91.42585754394531,
  91.42584228515625, 91.42572021484375, 91.4255142211914, 91.42523956298828,
  91.42501831054688, 91.42494201660156, 91.42495727539062, 91.4250717163086,
  91.42523956298828, 91.4254150390625, 91.42562866210938, 91.42586517333984,
  91.42605590820312, 91.42633819580078, 91.42671203613281, 91.427001953125,
  91.42715454101562, 91.42723083496094, 91.42736053466797, 91.42765808105469,
  91.42804718017578, 91.42838287353516, 91.42875671386719, 91.42908477783203,
  91.4293441772461, 91.42965698242188, 91.43008422851562, 91.43052673339844,
  91.43086242675781, 91.43122863769531, 91.43167877197266, 91.43225860595703,
  91.4330062866211, 91.43392944335938, 91.43485260009766, 91.43571472167969,
  91.43640899658203, 91.43706512451172, 91.4376449584961, 91.43817901611328,
  91.43878173828125, 91.43946838378906, 91.44026184082031, 91.44122314453125,
  91.44221496582031, 91.44330596923828, 91.4443588256836, 91.44528198242188,
  91.44608306884766, 91.44682312011719, 91.44756317138672, 91.44841766357422,
  91.44943237304688, 91.45059204101562, 91.45183563232422, 91.45301818847656,
  91.45417022705078, 91.45537567138672, 91.45665740966797, 91.45791625976562,
  91.45902252197266, 91.45989990234375, 91.46062469482422, 91.4613265991211,
  91.46229553222656, 91.46369171142578, 91.46533966064453, 91.46687316894531,
  91.4679946899414, 91.46871185302734, 91.46925354003906, 91.46969604492188,
  91.47029113769531, 91.47139739990234, 91.4728775024414, 91.47466278076172,
  91.4764175415039, 91.47785186767578, 91.4788589477539, 91.47957611083984,
  91.48020935058594, 91.48088836669922, 91.48172760009766, 91.48284912109375,
  91.48404693603516, 91.48543548583984, 91.48697662353516, 91.4885025024414,
  91.48993682861328, 91.49148559570312, 91.49313354492188, 91.49472045898438,
  91.49608612060547, 91.4973373413086, 91.49848175048828, 91.49955749511719,
  91.50067901611328, 91.50199127197266, 91.50364685058594, 91.50538635253906,
  91.50702667236328, 91.50846099853516, 91.50955963134766, 91.51056671142578,
  91.51167297363281, 91.51287841796875, 91.51414489746094, 91.5155029296875,
  91.51691436767578, 91.51834869384766, 91.51982116699219, 91.5213394165039,
  91.5229263305664, 91.5245132446289, 91.5259780883789, 91.52741241455078,
  91.52892303466797, 91.53047943115234, 91.5319595336914, 91.53358459472656,
  91.53524017333984, 91.53689575195312, 91.53844451904297, 91.54007720947266,
  91.54173278808594, 91.5431900024414, 91.54460906982422, 91.54618072509766,
  91.54794311523438, 91.54960632324219, 91.55126953125, 91.5531005859375,
  91.55504608154297, 91.55664825439453, 91.55833435058594, 91.56022644042969,
  91.56207275390625, 91.56393432617188, 91.56571197509766, 91.5672378540039,
  91.56863403320312, 91.57007598876953, 91.57160186767578, 91.57315826416016,
  91.57479858398438, 91.5765151977539, 91.57825469970703, 91.57997131347656,
  91.58163452148438, 91.58323669433594, 91.58491516113281, 91.58666229248047,
  91.58844757080078, 91.5902328491211, 91.59210205078125, 91.59394073486328,
  91.59564971923828, 91.59732818603516, 91.59901428222656, 91.60074615478516,
  91.60255432128906, 91.60440826416016, 91.60627746582031, 91.60808563232422,
  91.60977172851562, 91.61143493652344, 91.61308288574219, 91.61469268798828,
  91.6163101196289, 91.61785125732422, 91.6194839477539, 91.62084197998047,
  91.62234497070312, 91.62371826171875, 91.62495422363281, 91.62605285644531,
  91.62700653076172, 91.62796783447266, 91.6292724609375, 91.63059997558594,
  91.63200378417969, 91.63329315185547, 91.63446807861328, 91.63592529296875,
  91.63758850097656, 91.63921356201172, 91.64063262939453, 91.64199829101562,
  91.64339447021484, 91.64485931396484, 91.64632415771484, 91.647705078125,
  91.64892578125, 91.65010070800781, 91.65132141113281, 91.65264892578125,
  91.65406036376953, 91.65538024902344, 91.65641784667969, 91.6573257446289,
  91.65830993652344, 91.659423828125, 91.66062927246094, 91.66175079345703,
  91.66291809082031, 91.6640853881836, 91.6652603149414, 91.66635131835938,
  91.66768646240234, 91.6690902709961, 91.66993713378906, 91.6705551147461,
  91.6712417602539, 91.67204284667969, 91.67283630371094, 91.67359161376953,
  91.67446899414062, 91.6754379272461, 91.67652130126953, 91.67762756347656,
  91.67877960205078, 91.6799087524414, 91.6809310913086, 91.68180847167969,
  91.68265533447266, 91.68355560302734, 91.6844482421875, 91.68523406982422,
  91.6860122680664, 91.6868896484375, 91.68783569335938, 91.68868255615234,
  91.68952941894531, 91.69044494628906, 91.6913070678711, 91.69218444824219,
  91.69285583496094, 91.6935043334961, 91.69412994384766, 91.69477844238281,
  91.69547271728516, 91.69619750976562, 91.69694519042969, 91.69768524169922,
  91.69841003417969, 91.69942474365234, 91.70033264160156, 91.70135498046875,
  91.7023696899414, 91.70315551757812, 91.70392608642578, 91.70470428466797,
  91.70538330078125, 91.70616149902344, 91.70687866210938, 91.7075424194336,
  91.70828247070312, 91.70889282226562, 91.70973205566406, 91.71051025390625,
  91.71137237548828, 91.71221923828125, 91.71322631835938, 91.71428680419922,
  91.71549224853516, 91.7167739868164, 91.71807861328125, 91.71957397460938,
  91.72103118896484, 91.72222900390625, 91.72364044189453, 91.72515106201172,
  91.7266616821289, 91.72830200195312, 91.72980499267578, 91.73102569580078,
  91.73219299316406, 91.73350524902344, 91.73494720458984, 91.73665618896484,
  91.73851776123047, 91.74044799804688, 91.7423095703125, 91.74407958984375,
  91.74580383300781, 91.74748229980469, 91.74900817871094, 91.7505111694336,
  91.75200653076172, 91.7535400390625, 91.75524139404297, 91.75711822509766,
  91.75912475585938, 91.7611083984375, 91.76323699951172, 91.7653579711914,
  91.76739501953125, 91.76930236816406, 91.7710952758789, 91.77288055419922,
  91.77449798583984, 91.776123046875, 91.77782440185547, 91.77958679199219,
  91.78141784667969, 91.78326416015625, 91.78521728515625, 91.78726959228516,
  91.78952026367188, 91.79173278808594, 91.79383087158203, 91.7958755493164,
  91.79782104492188, 91.79972076416016, 91.8015365600586, 91.80337524414062,
  91.80546569824219, 91.8078384399414, 91.8104248046875, 91.81330871582031,
  91.81627655029297, 91.81903076171875, 91.82173919677734, 91.82431030273438,
  91.82701873779297, 91.82954406738281, 91.83216857910156, 91.83488464355469,
  91.83776092529297, 91.84054565429688, 91.84333801269531, 91.84646606445312,
  91.84990692138672, 91.8533706665039, 91.8568115234375, 91.86013793945312,
  91.8634033203125, 91.86666107177734, 91.87001037597656, 91.87354278564453,
  91.87715148925781, 91.88069152832031, 91.8841552734375, 91.88764953613281,
  91.89112091064453, 91.89454650878906, 91.89800262451172, 91.9013671875,
  91.90435791015625, 91.9072036743164, 91.9101333618164, 91.91313171386719,
  91.91632843017578, 91.91970825195312, 91.92326354980469, 91.92691802978516,
  91.9306640625, 91.93444061279297, 91.93822479248047, 91.94187927246094,
  91.94529724121094, 91.94841766357422, 91.95143127441406, 91.95451354980469,
  91.95772552490234, 91.96109771728516, 91.96456146240234, 91.96825408935547,
  91.97209167480469, 91.97604370117188, 91.98006439208984, 91.98397064208984,
  91.98776245117188, 91.99130249023438, 91.99464416503906, 91.99790954589844,
  92.00118255615234, 92.00463104248047, 92.00824737548828, 92.01187133789062,
  92.01537322998047, 92.01896667480469, 92.02272033691406, 92.02653503417969,
  92.03041076660156, 92.03436279296875, 92.03865814208984, 92.04304504394531,
  92.04730987548828, 92.05094909667969, 92.05461883544922, 92.05843353271484,
  92.06233978271484, 92.06599426269531, 92.0697250366211, 92.07327270507812,
  92.07669067382812, 92.08021545410156, 92.08426666259766, 92.0885009765625,
  92.0924072265625, 92.0961685180664, 92.09898376464844, 92.10199737548828,
  92.10525512695312, 92.1082763671875, 92.11126708984375, 92.115234375,
  92.11908721923828, 92.12278747558594, 92.12751007080078, 92.13203430175781,
  92.13614654541016, 92.14059448242188, 92.14472198486328, 92.14884185791016,
  92.15217590332031, 92.15498352050781, 92.15841674804688, 92.16138458251953,
  92.16502380371094, 92.16895294189453, 92.1736068725586, 92.17740631103516,
  92.1812744140625, 92.18522644042969, 92.1884536743164, 92.19264221191406,
  92.19673919677734, 92.2004623413086, 92.20478820800781, 92.20835876464844,
  92.21178436279297, 92.21491241455078, 92.21851348876953, 92.22224426269531,
  92.22613525390625, 92.23014068603516, 92.2342529296875, 92.23839569091797,
  92.24247741699219, 92.24671936035156, 92.25109100341797, 92.25560760498047,
  92.26050567626953, 92.26422119140625, 92.26837921142578, 92.271484375,
  92.2747802734375, 92.2784423828125, 92.28173828125, 92.28617858886719,
  92.29006958007812, 92.29454803466797, 92.29965209960938, 92.30374908447266,
  92.3084945678711, 92.31255340576172, 92.31607055664062, 92.3199691772461,
  92.3243408203125, 92.3283920288086, 92.33206176757812, 92.33627319335938,
  92.3405532836914, 92.3448715209961, 92.34915924072266, 92.353759765625,
  92.3575210571289, 92.3617172241211, 92.36589050292969, 92.37004852294922,
  92.37413024902344, 92.37826538085938, 92.38246154785156, 92.38655090332031,
  92.39048767089844, 92.39441680908203, 92.39842224121094, 92.4024658203125,
  92.40650177001953, 92.41038513183594, 92.41412353515625, 92.41773223876953,
  92.42137145996094, 92.42503356933594, 92.42847442626953, 92.43193817138672,
  92.43541717529297, 92.43900299072266, 92.44247436523438, 92.44581604003906,
  92.44934844970703, 92.45246124267578, 92.45558166503906, 92.45872497558594,
  92.4616470336914, 92.46471405029297, 92.46798706054688, 92.47066497802734,
  92.47364807128906, 92.47660827636719, 92.47953033447266, 92.48269653320312,
  92.48531341552734, 92.4883041381836, 92.4912338256836, 92.4940185546875,
  92.49669647216797, 92.49935913085938, 92.5019760131836, 92.50463104248047,
  92.50725555419922, 92.50994110107422, 92.5125732421875, 92.51510620117188,
  92.51753234863281, 92.52003479003906, 92.52250671386719, 92.5248794555664,
  92.5274429321289, 92.52950286865234, 92.53181457519531, 92.53425598144531,
  92.53673553466797, 92.5392074584961, 92.54178619384766, 92.54427337646484,
  92.54654693603516, 92.54891204833984, 92.55110931396484, 92.55337524414062,
  92.55575561523438, 92.5582046508789, 92.56059265136719, 92.56306457519531,
  92.56572723388672, 92.56853485107422, 92.57133483886719, 92.57408142089844,
  92.57672119140625, 92.5793685913086, 92.58222961425781, 92.58515167236328,
  92.58810424804688, 92.59127807617188, 92.59437561035156, 92.59744262695312,
  92.6004638671875, 92.60348510742188, 92.60661315917969, 92.60965728759766,
  92.61249542236328, 92.61526489257812, 92.61808776855469, 92.62100982666016,
  92.62399291992188, 92.62702941894531, 92.63023376464844, 92.63353729248047,
  92.63688659667969, 92.64015197753906, 92.64319610595703, 92.64627838134766,
  92.64942932128906, 92.6526107788086, 92.65580749511719, 92.65904235839844,
  92.66232299804688, 92.66569519042969, 92.66922760009766, 92.67285919189453,
  92.67652893066406, 92.68024444580078, 92.68399047851562, 92.68771362304688,
  92.69140625, 92.69511413574219, 92.69888305664062, 92.70280456542969,
  92.70690155029297, 92.71099090576172, 92.7150650024414, 92.71919250488281,
  92.72330474853516, 92.72726440429688, 92.73110961914062, 92.73497772216797,
  92.73883819580078, 92.74266052246094, 92.74652862548828, 92.75039672851562,
  92.75413513183594, 92.75774383544922, 92.76130676269531, 92.76490020751953,
  92.76853942871094, 92.77230072021484, 92.77617645263672, 92.78012084960938,
  92.78382110595703, 92.78722381591797, 92.79059600830078, 92.7947006225586,
  92.79829406738281, 92.80219268798828, 92.80567932128906, 92.80904388427734,
  92.81230926513672, 92.8155288696289, 92.8187255859375, 92.82251739501953,
  92.82600402832031, 92.82963562011719, 92.83290100097656, 92.83602142333984,
  92.83909606933594, 92.84213256835938, 92.84519958496094, 92.84879302978516,
  92.85225677490234, 92.85523223876953, 92.85816192626953, 92.8610610961914,
  92.8639144897461, 92.86672973632812, 92.86951446533203, 92.87239837646484,
  92.87577056884766, 92.87918090820312, 92.88253784179688, 92.88553619384766,
  92.8883056640625, 92.8909912109375, 92.89371490478516, 92.89662170410156,
  92.899658203125, 92.90265655517578, 92.90557861328125, 92.90837860107422,
  92.9111328125, 92.91409301757812, 92.9167251586914, 92.91941833496094,
  92.921875, 92.92491149902344, 92.92742919921875, 92.93018341064453,
  92.93289947509766, 92.93550109863281, 92.93831634521484, 92.94084930419922,
  92.94330596923828, 92.94573974609375, 92.9478988647461, 92.95028686523438,
  92.95270538330078, 92.9550552368164, 92.95738983154297, 92.95967864990234,
  92.96196746826172, 92.96428680419922, 92.96659851074219, 92.96881103515625,
  92.9709701538086, 92.9730453491211, 92.97509765625, 92.97724151611328,
  92.97950744628906, 92.98180389404297, 92.9840087890625, 92.98607635498047,
  92.98802185058594, 92.98990631103516, 92.99178314208984, 92.99363708496094,
  92.99543762207031, 92.99718475341797, 92.99889373779297, 93.00055694580078,
  93.00218200683594, 93.00376892089844, 93.00531768798828, 93.0068359375,
  93.00831604003906, 93.00975799560547, 93.01116943359375, 93.01254272460938,
  93.01388549804688, 93.01519012451172, 93.01647186279297, 93.01811981201172,
  93.01972198486328, 93.02111053466797, 93.02229309082031, 93.02342987060547,
  93.02452850341797, 93.02559661865234, 93.02664947509766, 93.02783966064453,
  93.02910614013672, 93.0302734375, 93.03125762939453, 93.03221130371094,
  93.0333023071289, 93.03472900390625, 93.03610229492188, 93.03760528564453,
  93.03897857666016, 93.04023742675781, 93.04132080078125, 93.04239654541016,
  93.04368591308594, 93.04508972167969, 93.04647827148438, 93.04783630371094,
  93.04914855957031, 93.05046844482422, 93.05172729492188, 93.05293273925781,
  93.05402374267578, 93.0550765991211, 93.0561752319336, 93.05703735351562,
  93.05799865722656, 93.05897521972656, 93.06002807617188, 93.06116485595703,
  93.06214141845703, 93.06287384033203, 93.06367492675781, 93.06427001953125,
  93.06490325927734, 93.06555938720703, 93.06623840332031, 93.06693267822266,
  93.06758117675781, 93.06815338134766, 93.06874084472656, 93.06922149658203,
  93.0698013305664, 93.07046508789062, 93.07109069824219, 93.07157135009766,
  93.07194519042969, 93.07231140136719, 93.07268524169922, 93.07315063476562,
  93.0736083984375, 93.07393646240234, 93.07417297363281, 93.0744400024414,
  93.07472229003906, 93.07512664794922, 93.0754623413086, 93.07573699951172,
  93.07584381103516, 93.07584381103516, 93.07586669921875, 93.0759506225586,
  93.07610321044922, 93.0762710571289, 93.0763931274414, 93.07649993896484,
  93.07664489746094, 93.07666778564453, 93.07666015625, 93.07650756835938,
  93.07634735107422, 93.0761947631836, 93.07598876953125, 93.07575225830078,
  93.07550048828125, 93.07524108886719, 93.07490539550781, 93.07467651367188,
  93.0743408203125, 93.07392883300781, 93.07353210449219, 93.07328033447266,
  93.07302856445312, 93.07269287109375, 93.07241821289062, 93.07193756103516,
  93.07142639160156, 93.07085418701172, 93.07029724121094, 93.06969451904297,
  93.06903839111328, 93.06825256347656, 93.0676040649414, 93.0670166015625,
  93.06642150878906, 93.06574249267578, 93.06497955322266, 93.064208984375,
  93.06343841552734, 93.06272888183594, 93.06190490722656, 93.06103515625,
  93.06011199951172, 93.05915832519531, 93.05815887451172, 93.05721282958984,
  93.05626678466797, 93.05540466308594, 93.05439758300781, 93.05352783203125,
  93.052490234375, 93.05137634277344, 93.05021667480469, 93.04904174804688,
  93.04778289794922, 93.04658508300781, 93.04535675048828, 93.04415130615234,
  93.04295349121094, 93.04177856445312, 93.04065704345703, 93.03945922851562,
  93.03811645507812, 93.03670501708984, 93.0351791381836, 93.03367614746094,
  93.03217315673828, 93.0306396484375, 93.02913665771484, 93.02762603759766,
  93.02617645263672, 93.0247802734375, 93.02330780029297, 93.0215072631836,
  93.01976013183594, 93.01817321777344, 93.01676177978516, 93.01532745361328,
  93.01373291015625, 93.01190948486328, 93.00993347167969, 93.00800323486328,
  93.0061264038086, 93.00435638427734, 93.0026626586914, 93.00083923339844,
  92.99884796142578, 92.99679565429688, 92.99482727050781, 92.99286651611328,
  92.99088287353516, 92.98880767822266, 92.9866714477539, 92.98455810546875,
  92.9822998046875, 92.98002624511719, 92.97785186767578, 92.97575378417969,
  92.9732894897461, 92.97075653076172, 92.96858978271484, 92.9666519165039,
  92.96470642089844, 92.962646484375, 92.96037292480469, 92.95792388916016,
  92.95542907714844, 92.95284271240234, 92.95040893554688, 92.94807434082031,
  92.94570922851562, 92.94320678710938, 92.94083404541016, 92.93860626220703,
  92.9357681274414, 92.93293762207031, 92.93028259277344, 92.92759704589844,
  92.92460632324219, 92.92169189453125, 92.91876983642578, 92.91582489013672,
  92.9130859375, 92.91015625, 92.9069595336914, 92.90397644042969,
  92.9014892578125, 92.89910888671875, 92.89646911621094, 92.89348602294922,
  92.89061737060547, 92.88787078857422, 92.88520812988281, 92.88241577148438,
  92.87953186035156, 92.87651062011719, 92.87361145019531, 92.8708724975586,
  92.8682632446289, 92.8658218383789, 92.86320495605469, 92.86054992675781,
  92.85813903808594, 92.85581970214844, 92.85330200195312, 92.85026550292969,
  92.84675598144531, 92.84327697753906, 92.84000396728516, 92.8369140625,
  92.83373260498047, 92.83050537109375, 92.82743072509766, 92.82469177246094,
  92.82176208496094, 92.81892395019531, 92.81611633300781, 92.81373596191406,
  92.81165313720703, 92.80909729003906, 92.80559539794922, 92.80158233642578,
  92.7975082397461, 92.79403686523438, 92.79096984863281, 92.78817749023438,
  92.78551483154297, 92.78276062011719, 92.77989959716797, 92.77696990966797,
  92.77408599853516, 92.77107238769531, 92.76793670654297, 92.7647476196289,
  92.76182556152344, 92.75931549072266, 92.7570571899414, 92.75486755371094,
  92.75267028808594, 92.75042724609375, 92.74816131591797, 92.7458267211914,
  92.74365234375, 92.74152374267578, 92.73926544189453, 92.737548828125,
  92.73594665527344, 92.73406982421875, 92.73210144042969, 92.73013305664062,
  92.72801971435547, 92.72595977783203, 92.7243881225586, 92.72309875488281,
  92.72164916992188, 92.72010040283203, 92.7187728881836, 92.71761322021484,
  92.71651458740234, 92.71528625488281, 92.71424865722656, 92.7135009765625,
  92.71289825439453, 92.71247100830078, 92.71205139160156, 92.71159362792969,
  92.71111297607422, 92.71047973632812, 92.709716796875, 92.70892333984375,
  92.70822143554688, 92.70772552490234, 92.70753479003906, 92.70740509033203,
  92.70733642578125, 92.7072525024414, 92.70720672607422, 92.70723724365234,
  92.70735931396484, 92.70756530761719, 92.7077865600586, 92.70816040039062,
  92.70857238769531, 92.70895385742188, 92.70931243896484, 92.70963287353516,
  92.70996856689453, 92.71041107177734, 92.71089935302734, 92.7113265991211,
  92.71170043945312, 92.71210479736328, 92.71263885498047, 92.71332550048828,
  92.7140121459961, 92.71467590332031, 92.71530151367188, 92.71595764160156,
  92.7167739868164, 92.71765899658203, 92.71853637695312, 92.71935272216797,
  92.72016143798828, 92.72100830078125, 92.72196960449219, 92.7229995727539,
  92.72399139404297, 92.72490692138672, 92.72578430175781, 92.72671508789062,
  92.72772216796875, 92.72874450683594, 92.72969818115234, 92.73062896728516,
  92.73159790039062, 92.73252868652344, 92.73344421386719, 92.73435974121094,
  92.73529815673828, 92.73616790771484, 92.73703002929688, 92.7379379272461,
  92.73886108398438, 92.73981475830078, 92.74077606201172, 92.74171447753906,
  92.74261474609375, 92.7435531616211, 92.74451446533203, 92.74546813964844,
  92.7464370727539, 92.7474136352539, 92.74839782714844, 92.74935150146484,
  92.75032043457031, 92.7513427734375, 92.75240325927734, 92.75340270996094,
  92.75439453125, 92.75537872314453, 92.75637817382812, 92.75743103027344,
  92.75853729248047, 92.75961303710938, 92.76071166992188, 92.76182556152344,
  92.76294708251953, 92.76404571533203, 92.76515197753906, 92.7662582397461,
  92.76740264892578, 92.76853942871094, 92.76966094970703, 92.77086639404297,
  92.77205657958984, 92.77320861816406, 92.77434539794922, 92.77548217773438,
  92.7766342163086, 92.7778549194336, 92.77913665771484, 92.78028869628906,
  92.7813720703125, 92.78235626220703, 92.78337097167969, 92.78445434570312,
  92.7856216430664, 92.7868423461914, 92.78804016113281, 92.7891616821289,
  92.79027557373047, 92.7914047241211, 92.79261016845703, 92.79389190673828,
  92.79525756835938, 92.79658508300781, 92.7978515625, 92.79914855957031,
  92.8005142211914, 92.80195617675781, 92.80341339111328, 92.80484008789062,
  92.80628967285156, 92.80780792236328, 92.8094253540039, 92.81097412109375,
  92.8124771118164, 92.81391906738281, 92.81533813476562, 92.81678009033203,
  92.8183364868164, 92.82003784179688, 92.82172393798828, 92.8232650756836,
  92.82466888427734, 92.82606506347656, 92.82752990722656, 92.82915496826172,
  92.83087158203125, 92.83260345458984, 92.8343505859375, 92.83600616455078,
  92.83763122558594, 92.83924865722656, 92.8409652709961, 92.84269714355469,
  92.84443664550781, 92.84618377685547, 92.84795379638672, 92.84979248046875,
  92.8516616821289, 92.8535385131836, 92.85528564453125, 92.85698699951172,
  92.85867309570312, 92.86042785644531, 92.86225891113281, 92.86394500732422,
  92.86563873291016, 92.86737060546875, 92.869140625, 92.8709716796875,
  92.8729476928711, 92.87487030029297, 92.87677001953125, 92.87870025634766,
  92.88063049316406, 92.88245391845703, 92.88422393798828, 92.88609313964844,
  92.88803100585938, 92.89004516601562, 92.89200592041016, 92.89402770996094,
  92.8961181640625, 92.89836120605469, 92.90058898925781, 92.90276336669922,
  92.90481567382812, 92.9068603515625, 92.9089584350586, 92.91114044189453,
  92.91312408447266, 92.91509246826172, 92.91708374023438, 92.91907501220703,
  92.92110443115234, 92.92312622070312, 92.92515563964844, 92.9273681640625,
  92.92962646484375, 92.93192291259766, 92.9341049194336, 92.936279296875,
  92.93846893310547, 92.94066619873047, 92.94278717041016, 92.94486999511719,
  92.94686889648438, 92.94886779785156, 92.95092010498047, 92.95313262939453,
  92.95536041259766, 92.95758056640625, 92.959716796875, 92.9618911743164,
  92.96400451660156, 92.9660873413086, 92.96817016601562, 92.97025299072266,
  92.97228240966797, 92.97425842285156, 92.97624969482422, 92.97833251953125,
  92.9803695678711, 92.98237609863281, 92.98423767089844, 92.98603057861328,
  92.98802947998047, 92.99006652832031, 92.99200439453125, 92.9939193725586,
  92.99588775634766, 92.99774932861328, 92.9994888305664, 93.00119018554688,
  93.00299835205078, 93.00487518310547, 93.00682067871094, 93.00872039794922,
  93.0104751586914, 93.0121841430664, 93.01395416259766, 93.01589965820312,
  93.01792907714844, 93.0198745727539, 93.02178955078125, 93.023681640625,
  93.02558135986328, 93.0275650024414, 93.02951049804688, 93.0314712524414,
  93.03333282470703, 93.03504180908203, 93.03681182861328, 93.03865814208984,
  93.04031372070312, 93.04176330566406, 93.04309844970703, 93.04460906982422,
  93.04625701904297, 93.04806518554688, 93.04976654052734, 93.0514907836914,
  93.05322265625, 93.05487823486328, 93.05648040771484, 93.05807495117188,
  93.05950164794922, 93.06060791015625, 93.06169891357422, 93.06285858154297,
  93.06428527832031, 93.06573486328125, 93.06710815429688, 93.06825256347656,
  93.06942749023438, 93.07081604003906, 93.07230377197266, 93.07350158691406,
  93.07469177246094, 93.07595825195312, 93.07726287841797, 93.07866668701172,
  93.08011627197266, 93.08141326904297, 93.08279418945312, 93.0840072631836,
  93.0849609375, 93.08597564697266, 93.08710479736328, 93.08810424804688,
  93.08907318115234, 93.09017181396484, 93.09115600585938, 93.09223937988281,
  93.09336853027344, 93.0940933227539, 93.0947494506836, 93.09554290771484,
  93.09657287597656, 93.09762573242188, 93.0986557006836, 93.09973907470703,
  93.10089111328125, 93.10203552246094, 93.10301208496094, 93.10375213623047,
  93.10454559326172, 93.10542297363281, 93.10627746582031, 93.10697174072266,
  93.10768127441406, 93.10842895507812, 93.109375, 93.11018371582031,
  93.11090087890625, 93.11165618896484, 93.11241149902344, 93.11302947998047,
  93.11357116699219, 93.11425018310547, 93.11518096923828, 93.11595916748047,
  93.11680603027344, 93.11756896972656, 93.11836242675781, 93.11912536621094,
  93.119873046875, 93.12052154541016, 93.12108612060547, 93.12150573730469,
  93.12178802490234, 93.12200164794922, 93.12223815917969, 93.12258911132812,
  93.12297058105469, 93.12336730957031, 93.1236343383789, 93.12364959716797,
  93.12350463867188, 93.12333679199219, 93.12324523925781, 93.12311553955078,
  93.12275695800781, 93.12254333496094, 93.12268829345703, 93.12300109863281,
  93.12332916259766, 93.12332916259766, 93.12316131591797, 93.12284088134766,
  93.12224578857422, 93.12159729003906, 93.12102508544922, 93.1205062866211,
  93.12022399902344, 93.11982727050781, 93.11912536621094, 93.11834716796875,
  93.11775970458984, 93.11744689941406, 93.11736297607422, 93.11714172363281,
  93.11705017089844, 93.11695861816406, 93.11683654785156, 93.11638641357422,
  93.11571502685547, 93.11505889892578, 93.11442565917969, 93.11366271972656,
  93.11247253417969, 93.11124420166016, 93.11068725585938, 93.11027526855469,
  93.1097640991211, 93.10901641845703, 93.10787963867188, 93.1068344116211,
  93.10586547851562, 93.10494232177734, 93.10394287109375, 93.10289764404297,
  93.10196685791016, 93.10118103027344, 93.10055541992188, 93.09982299804688,
  93.09899139404297, 93.09791564941406, 93.09680938720703, 93.09565734863281,
  93.09456634521484, 93.09337615966797, 93.09229278564453, 93.09125518798828,
  93.09016418457031, 93.08911895751953, 93.088134765625, 93.0873031616211,
  93.08651733398438, 93.08584594726562, 93.08545684814453, 93.0850830078125,
  93.0845947265625, 93.08380889892578, 93.08318328857422, 93.0823974609375,
  93.08108520507812, 93.08003234863281, 93.07910919189453, 93.07816314697266,
  93.07706451416016, 93.07591247558594, 93.07445526123047, 93.07294464111328,
  93.07117462158203, 93.06916809082031, 93.06717681884766, 93.06523895263672,
  93.06359100341797, 93.06221771240234, 93.06114196777344, 93.05987548828125,
  93.05854797363281, 93.05725860595703, 93.05628967285156, 93.05540466308594,
  93.05412292480469, 93.05271911621094, 93.05097961425781, 93.04935455322266,
  93.04782104492188, 93.0461654663086, 93.0441665649414,
];
