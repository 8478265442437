import { Global } from "@mantine/core";
import outlier from "../assets/fonts/Outlier.ttf";
import bahnschrift from "../assets/fonts/Bahnschrift.ttf";

export default function CustomFonst() {
  return (
    <Global
      styles={[
        {
          "@font-face": {
            fontFamily: "Outlier",
            src: `url('${outlier}') format("truetype")`,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Bahnschrift",
            src: `url(${bahnschrift}) format("truetype)`,
            fontStyle: "normal",
          },
        },
      ]}
    ></Global>
  );
}
