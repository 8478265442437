import { Container, Text, createStyles, rem } from "@mantine/core";
import { forwardRef } from "react";
import { Icon } from "tabler-icons-react";
import CardWrapper from "./Card";

const useStyles = createStyles((theme) => ({
  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: rem(45),
      height: rem(2),
      marginTop: theme.spacing.sm,
    },
  },
}));

interface FeatureCardProps {
  title: string;
  description: string;
  Icon: Icon;
  style?: React.CSSProperties;
}

const FeatureCard = forwardRef<HTMLDivElement, FeatureCardProps>(
  ({ title, description, Icon, style }: FeatureCardProps, ref) => {
    const { classes, theme } = useStyles();

    return (
      <CardWrapper style={style} ref={ref}>
        <Container
          px={0}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <Icon size={rem(50)} color={theme.fn.primaryColor()} />
          <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
            {title}
          </Text>
        </Container>

        <Text fz="sm" c="dimmed" mt="sm">
          {description}
        </Text>
      </CardWrapper>
    );
  }
);

export default FeatureCard;
