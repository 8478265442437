import { useMemo } from "react";

interface IGetBounds {
  data: number[][];
  yMin?: number;
  yMax?: number;
  yPadding?: number;
}

interface IGetBoundsReturn {
  min: number;
  max: number;
}

export default function useGetBounds({
  data,
  yMin,
  yMax,
  yPadding = 0,
}: IGetBounds): IGetBoundsReturn {
  return useMemo(() => {
    const min = yMin || Math.min(...data.flat(1)) - yPadding;
    const max = yMax || Math.max(...data.flat(1)) + yPadding;

    return { min, max };

    //eslint-disable-next-line
  }, [data]);
}
