import { Container, SimpleGrid, createStyles } from "@mantine/core";
import FeatureCard from "../components/FeatureCard";
import {
  DeviceDesktopAnalytics,
  BrowserPlus,
  Gauge,
  AntennaOff,
} from "tabler-icons-react";
import SlideInComponent from "../components/SlideInComponent";
import { useMemo, useRef } from "react";
import SectionTitle from "../components/SectionTitle";

const useStyles = createStyles((theme) => ({
  grid: {
    width: "55%",
    margin: "auto",

    [theme.fn.smallerThan("xl")]: {
      width: "75%",
    },

    [theme.fn.smallerThan("md")]: {
      width: "100%",
    },
  },
}));

const FEATURE_DATA = [
  {
    title: "Overlays",
    description:
      "Display additional ingame information or replace existing overlays provided by Assetto Corsa Competizione",
    Icon: BrowserPlus,
  },
  {
    title: "Telemetry",
    description: "Record and analyse your telemetry to improve your laptimes",
    Icon: DeviceDesktopAnalytics,
  },
  {
    title: "Performance",
    description:
      "Lockup Telemetry provides performance settings to match your needs",
    Icon: Gauge,
  },
  {
    title: "Offline",
    description:
      "Use Lockup Telemetry anywhere, anytime! No internet connection needed",
    Icon: AntennaOff,
  },
];

export default function FeatureSection() {
  const { classes } = useStyles();
  const root = useRef(null);

  const features = useMemo(() => {
    return FEATURE_DATA.map((data, index) => (
      <SlideInComponent
        key={index}
        target={root}
        delay={index * 100}
        component={(props) => <FeatureCard {...data} {...props} />}
        styleCleanup={(styles) =>
          styles.display === "none"
            ? {
                opacity: 0,
                transform: "translateY(100%)",
              }
            : { ...styles }
        }
      />
    ));
  }, []);

  return (
    <Container fluid ref={root}>
      <SectionTitle title="Overlays and Telemetry under one roof" />
      <SimpleGrid
        className={classes.grid}
        cols={4}
        spacing="xl"
        breakpoints={[
          { maxWidth: "xl", cols: 2 },
          { maxWidth: "md", cols: 1 },
        ]}
      >
        {features}
      </SimpleGrid>
    </Container>
  );
}
