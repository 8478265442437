import { ITelemetryDataElement } from "../../data/TelemetryData";
import TitleCard from "../TitleCard";
import TelemetryAxis from "./TelemetryAxis";
import useGetBounds from "../../hooks/useGetBounds";
import { CSSProperties } from "react";
import { createStyles } from "@mantine/core";

const useStyle = createStyles((theme) => ({
  container: {
    minWidth: "700px",
    height: "250px",
    width: "50vw",

    [theme.fn.smallerThan("md")]: {
      minWidth: "0px",
      width: "100%",
    },
  },
}));

export default function TelemetryGraph({
  data,
  trackTicks,
  title,
  description,
  color,
  yMin,
  yMax,
  yPadding,
  style,
  formatYAxis,
}: ITelemetryDataElement & { style?: CSSProperties }) {
  const { min, max } = useGetBounds({ data: data, yMin, yMax, yPadding });

  const { classes } = useStyle();

  return (
    <TitleCard
      title={title}
      description={description}
      style={{ ...style }}
      className={classes.container}
    >
      <TelemetryAxis
        data={data}
        trackTicks={trackTicks}
        yMin={min}
        yMax={max}
        color={color}
        formatYAxis={formatYAxis}
      />
    </TitleCard>
  );
}
