import { Text, Title, createStyles, rem } from "@mantine/core";

interface ISectionTitle {
  title: string;
  description?: string;
}

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: rem(34),
    fontWeight: 900,

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(24),
    },
  },

  seperator: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: rem(70),
      height: rem(2),
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

export default function SectionTitle({ title, description }: ISectionTitle) {
  const { classes, cx } = useStyles();

  return (
    <>
      <Title
        order={2}
        className={cx(classes.title, { [classes.seperator]: !description })}
        ta="center"
        mb={description ? 0 : 50}
      >
        {title}
      </Title>
      {description && (
        <Text ta="center" color="dimmed" className={classes.seperator} mb={50}>
          {description}
        </Text>
      )}
    </>
  );
}
