import { Stack, Text, Title, createStyles } from "@mantine/core";
import { modals } from "@mantine/modals";

const useStyle = createStyles((theme) => ({
  text: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export default function LegalInfo() {
  const { classes } = useStyle();

  function handleClick() {
    modals.open({
      title: <Title ml="sm">Legal Notice</Title>,
      autoFocus: false,
      size: "xl",
      centered: true,
      radius: "md",
      closeOnEscape: true,
      closeOnClickOutside: true,
      closeButtonProps: { style: { outline: 0 } },
      children: (
        <>
          <Title ta="center" mt="md" order={2}>
            Service Provider
          </Title>
          <Stack spacing="sm" my="xl">
            <Text ta="center">Philipp Dietrich</Text>
            <Text ta="center">Moorbachweg 23</Text>
            <Text ta="center">31535 Neustadt</Text>
            <Text ta="center">Email: ph.dietrich@web.de</Text>
          </Stack>
        </>
      ),
    });
  }

  return (
    <Text c="dimmed" ta="center" className={classes.text} onClick={handleClick}>
      Legal notice
    </Text>
  );
}
