import { Card } from "@mantine/core";
import { CSSProperties, ReactNode, forwardRef } from "react";

interface ICardWrapper {
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
}

const CardWrapper = forwardRef<HTMLDivElement, ICardWrapper>(
  ({ children, style, className }: ICardWrapper, ref) => {
    return (
      <Card
        shadow="md"
        padding="xl"
        radius="md"
        style={style}
        ref={ref}
        className={className}
      >
        {children}
      </Card>
    );
  }
);

export default CardWrapper;
