export const FR_TEMP_DATA = [
  80.48350524902344, 80.48350524902344, 80.47970581054688, 80.47591400146484,
  80.4718017578125, 80.46765899658203, 80.46354675292969, 80.45948791503906,
  80.45572662353516, 80.45201873779297, 80.4483642578125, 80.44474029541016,
  80.44120025634766, 80.43753051757812, 80.433837890625, 80.4300765991211,
  80.4261703491211, 80.42223358154297, 80.41827392578125, 80.41423797607422,
  80.41033935546875, 80.40652465820312, 80.40274810791016, 80.39888763427734,
  80.39501190185547, 80.39111328125, 80.3872299194336, 80.38336181640625,
  80.37943267822266, 80.37552642822266, 80.37171936035156, 80.36775207519531,
  80.36386108398438, 80.36007690429688, 80.35650634765625, 80.3530502319336,
  80.34931182861328, 80.3452377319336, 80.3410415649414, 80.33684539794922,
  80.33242797851562, 80.32823181152344, 80.32396697998047, 80.31968688964844,
  80.31536102294922, 80.31103515625, 80.30658721923828, 80.30203247070312,
  80.29754638671875, 80.29312896728516, 80.28893280029297, 80.2849349975586,
  80.28091430664062, 80.27665710449219, 80.27215576171875, 80.26770782470703,
  80.2633056640625, 80.25883483886719, 80.25434875488281, 80.25015258789062,
  80.24597930908203, 80.24150085449219, 80.23680114746094, 80.23219299316406,
  80.2278060913086, 80.22356414794922, 80.21929931640625, 80.21542358398438,
  80.2115707397461, 80.20738220214844, 80.20298767089844, 80.19862365722656,
  80.1941909790039, 80.18974304199219, 80.18525695800781, 80.18075561523438,
  80.17637634277344, 80.17193603515625, 80.16751098632812, 80.16309356689453,
  80.15876770019531, 80.15432739257812, 80.14991760253906, 80.14533233642578,
  80.14076232910156, 80.13641357421875, 80.13253784179688, 80.1287612915039,
  80.1249008178711, 80.12095642089844, 80.11685180664062, 80.11271667480469,
  80.10853576660156, 80.10450744628906, 80.10047912597656, 80.09645080566406,
  80.09274291992188, 80.08914947509766, 80.08545684814453, 80.08164978027344,
  80.07809448242188, 80.07496643066406, 80.07219696044922, 80.06965637207031,
  80.06704711914062, 80.06433868408203, 80.0615005493164, 80.05864715576172,
  80.05582427978516, 80.05313873291016, 80.0506362915039, 80.04830932617188,
  80.04609680175781, 80.04389953613281, 80.04178619384766, 80.0397720336914,
  80.03775787353516, 80.03572082519531, 80.03363037109375, 80.0316162109375,
  80.02970886230469, 80.02774810791016, 80.0257339477539, 80.0237045288086,
  80.02182006835938, 80.02024841308594, 80.0188217163086, 80.01732635498047,
  80.01569366455078, 80.01388549804688, 80.011962890625, 80.01007080078125,
  80.00825500488281, 80.00666809082031, 80.00528717041016, 80.00377655029297,
  80.00224304199219, 80.00083923339844, 79.9996109008789, 79.99835968017578,
  79.99697875976562, 79.99563598632812, 79.99440002441406, 79.9932632446289,
  79.99211883544922, 79.99102020263672, 79.98986053466797, 79.98863983154297,
  79.98741912841797, 79.98624420166016, 79.98518371582031, 79.98445892333984,
  79.9837646484375, 79.98284912109375, 79.98178100585938, 79.98056030273438,
  79.97929382324219, 79.97801208496094, 79.97689819335938, 79.9760513305664,
  79.975341796875, 79.9746322631836, 79.97376251220703, 79.97278594970703,
  79.97179412841797, 79.9708480834961, 79.9699935913086, 79.96916198730469,
  79.96833038330078, 79.96746826171875, 79.96656799316406, 79.9656753540039,
  79.96484375, 79.9639663696289, 79.96305084228516, 79.96212768554688,
  79.96111297607422, 79.96009063720703, 79.95915985107422, 79.95820617675781,
  79.95723724365234, 79.95628356933594, 79.95535278320312, 79.9544448852539,
  79.95350646972656, 79.95249938964844, 79.95150756835938, 79.95055389404297,
  79.94955444335938, 79.9485092163086, 79.94740295410156, 79.94625854492188,
  79.94508361816406, 79.94388580322266, 79.94268035888672, 79.94142150878906,
  79.94014739990234, 79.9389419555664, 79.93782806396484, 79.93669128417969,
  79.93543243408203, 79.93415832519531, 79.93291473388672, 79.93157196044922,
  79.93021392822266, 79.92892456054688, 79.92761993408203, 79.92625427246094,
  79.92486572265625, 79.92349243164062, 79.92212677001953, 79.92076873779297,
  79.91932678222656, 79.9178466796875, 79.91634368896484, 79.91482543945312,
  79.91329193115234, 79.91175079345703, 79.91023254394531, 79.90868377685547,
  79.90715789794922, 79.90560913085938, 79.90396881103516, 79.90225219726562,
  79.90055084228516, 79.89884185791016, 79.89715576171875, 79.8955307006836,
  79.8938217163086, 79.89205932617188, 79.89028930664062, 79.88848876953125,
  79.88671112060547, 79.88493347167969, 79.88314819335938, 79.88130187988281,
  79.87946319580078, 79.87770080566406, 79.8759536743164, 79.87421417236328,
  79.87240600585938, 79.87042236328125, 79.86846923828125, 79.86656951904297,
  79.86471557617188, 79.86289978027344, 79.86106872558594, 79.85913848876953,
  79.85713958740234, 79.85507202148438, 79.85295104980469, 79.85092163085938,
  79.8489990234375, 79.8470687866211, 79.84507751464844, 79.84300994873047,
  79.84087371826172, 79.8387680053711, 79.8368148803711, 79.83491516113281,
  79.83306121826172, 79.83138275146484, 79.82979583740234, 79.82820129394531,
  79.82673645019531, 79.82521057128906, 79.82360076904297, 79.82221984863281,
  79.82090759277344, 79.81938171386719, 79.8176498413086, 79.81636810302734,
  79.81513977050781, 79.81356048583984, 79.81220245361328, 79.81085205078125,
  79.80912780761719, 79.80712890625, 79.80509948730469, 79.80317687988281,
  79.80196380615234, 79.80084991455078, 79.79976654052734, 79.798828125,
  79.79749298095703, 79.79573822021484, 79.79389190673828, 79.7923355102539,
  79.79094696044922, 79.78950500488281, 79.78811645507812, 79.78665161132812,
  79.78507232666016, 79.78362274169922, 79.78218841552734, 79.7806396484375,
  79.77899169921875, 79.77727508544922, 79.77562713623047, 79.77479553222656,
  79.77413940429688, 79.77326202392578, 79.77178192138672, 79.7699966430664,
  79.7681655883789, 79.76666259765625, 79.76535034179688, 79.763916015625,
  79.76227569580078, 79.760498046875, 79.7589111328125, 79.75759887695312,
  79.7562484741211, 79.75474548339844, 79.7533950805664, 79.75239562988281,
  79.75132751464844, 79.7500991821289, 79.74881744384766, 79.74784851074219,
  79.74690246582031, 79.74578094482422, 79.744384765625, 79.74287414550781,
  79.74137115478516, 79.73992156982422, 79.7384033203125, 79.73675537109375,
  79.735107421875, 79.73357391357422, 79.73200225830078, 79.73046112060547,
  79.72888946533203, 79.72716522216797, 79.72533416748047, 79.72357940673828,
  79.72186279296875, 79.72001647949219, 79.7180404663086, 79.716064453125,
  79.7142105102539, 79.71258544921875, 79.7110824584961, 79.70942687988281,
  79.70760345458984, 79.7056655883789, 79.70374298095703, 79.70191192626953,
  79.70028686523438, 79.69873046875, 79.6971664428711, 79.69538879394531,
  79.6932373046875, 79.6909408569336, 79.68883514404297, 79.68704223632812,
  79.68533325195312, 79.68350219726562, 79.681396484375, 79.6792221069336,
  79.67717742919922, 79.67522430419922, 79.67333984375, 79.67130279541016,
  79.66913604736328, 79.66688537597656, 79.66460418701172, 79.66238403320312,
  79.6601333618164, 79.65800476074219, 79.65595245361328, 79.65381622314453,
  79.65151977539062, 79.64920806884766, 79.64686584472656, 79.64447784423828,
  79.64216613769531, 79.63975524902344, 79.63741302490234, 79.6353530883789,
  79.63333129882812, 79.63130187988281, 79.62911224365234, 79.62710571289062,
  79.62503051757812, 79.62285614013672, 79.62051391601562, 79.61833953857422,
  79.61610412597656, 79.61375427246094, 79.61128997802734, 79.60885620117188,
  79.60647583007812, 79.6042709350586, 79.60212707519531, 79.59988403320312,
  79.59759521484375, 79.59534454345703, 79.59310150146484, 79.59093475341797,
  79.58895874023438, 79.58720397949219, 79.58548736572266, 79.58370971679688,
  79.58213806152344, 79.58069610595703, 79.57936096191406, 79.57833099365234,
  79.5773696899414, 79.57625579833984, 79.57504272460938, 79.57382202148438,
  79.57259368896484, 79.57125854492188, 79.56999206542969, 79.56892395019531,
  79.56785583496094, 79.56678771972656, 79.56586456298828, 79.56510925292969,
  79.56441497802734, 79.56373596191406, 79.56312561035156, 79.5625991821289,
  79.5621337890625, 79.5615463256836, 79.56095886230469, 79.56046295166016,
  79.5600357055664, 79.55946350097656, 79.55889129638672, 79.55844116210938,
  79.55799102783203, 79.5576400756836, 79.55730438232422, 79.55697631835938,
  79.55675506591797, 79.55646514892578, 79.55619812011719, 79.555908203125,
  79.5556640625, 79.55549621582031, 79.55522155761719, 79.55500030517578,
  79.55481719970703, 79.55464172363281, 79.554443359375, 79.55431365966797,
  79.55424499511719, 79.55415344238281, 79.55416870117188, 79.55426788330078,
  79.55441284179688, 79.55462646484375, 79.55493927001953, 79.55534362792969,
  79.55577087402344, 79.55620574951172, 79.55668640136719, 79.55720520019531,
  79.55779266357422, 79.55840301513672, 79.55902099609375, 79.55963897705078,
  79.56026458740234, 79.5609130859375, 79.5615463256836, 79.56219482421875,
  79.56289672851562, 79.5635986328125, 79.56427764892578, 79.5649642944336,
  79.5656509399414, 79.56633758544922, 79.56702423095703, 79.5677261352539,
  79.56842803955078, 79.56912994384766, 79.56983947753906, 79.57054901123047,
  79.5712661743164, 79.57196044921875, 79.5726547241211, 79.57334899902344,
  79.57405090332031, 79.57477569580078, 79.57549285888672, 79.5761947631836,
  79.57689666748047, 79.57762908935547, 79.5783920288086, 79.5791244506836,
  79.57980346679688, 79.58045959472656, 79.58113098144531, 79.58182525634766,
  79.58251953125, 79.58318328857422, 79.58383178710938, 79.58446502685547,
  79.58505249023438, 79.58563232421875, 79.58624267578125, 79.58686065673828,
  79.58746337890625, 79.58800506591797, 79.58853149414062, 79.58908081054688,
  79.58965301513672, 79.59022521972656, 79.59076690673828, 79.5912857055664,
  79.59180450439453, 79.59233093261719, 79.59285736083984, 79.5933609008789,
  79.5938491821289, 79.5943374633789, 79.59483337402344, 79.59530639648438,
  79.59577178955078, 79.59622192382812, 79.5966567993164, 79.59709930419922,
  79.5975341796875, 79.59794616699219, 79.59835052490234, 79.59873962402344,
  79.59913635253906, 79.59952545166016, 79.59992218017578, 79.60029602050781,
  79.60063171386719, 79.6009292602539, 79.60122680664062, 79.60152435302734,
  79.6018295288086, 79.60212707519531, 79.60242462158203, 79.60272979736328,
  79.60302734375, 79.6032943725586, 79.6035385131836, 79.60376739501953,
  79.60399627685547, 79.60421752929688, 79.60443115234375, 79.60462951660156,
  79.60482788085938, 79.60499572753906, 79.60515594482422, 79.6053237915039,
  79.60548400878906, 79.60563659667969, 79.60577392578125, 79.60590362548828,
  79.60601806640625, 79.60613250732422, 79.6062240600586, 79.6063003540039,
  79.60637664794922, 79.60643005371094, 79.6064682006836, 79.60651397705078,
  79.60658264160156, 79.60661315917969, 79.60662841796875, 79.60665130615234,
  79.60667419433594, 79.606689453125, 79.60667419433594, 79.60664367675781,
  79.60660552978516, 79.60655212402344, 79.60649108886719, 79.6064682006836,
  79.6064453125, 79.60638427734375, 79.6063003540039, 79.60621643066406,
  79.6061019897461, 79.60596466064453, 79.60582733154297, 79.60567474365234,
  79.60551452636719, 79.60539245605469, 79.60529327392578, 79.60517120361328,
  79.60501861572266, 79.6048355102539, 79.60465240478516, 79.6044921875,
  79.60433959960938, 79.60417175292969, 79.60397338867188, 79.60372924804688,
  79.60347747802734, 79.60321807861328, 79.60298156738281, 79.60273742675781,
  79.6024169921875, 79.60208892822266, 79.60177612304688, 79.60147094726562,
  79.60116577148438, 79.60084533691406, 79.60050964355469, 79.60014343261719,
  79.59973907470703, 79.59931945800781, 79.59890747070312, 79.59849548339844,
  79.5980453491211, 79.59757995605469, 79.59713745117188, 79.59669494628906,
  79.59622192382812, 79.59573364257812, 79.59526824951172, 79.59478759765625,
  79.59423828125, 79.59367370605469, 79.59317016601562, 79.59266662597656,
  79.59209442138672, 79.59149169921875, 79.59086608886719, 79.59022521972656,
  79.58956909179688, 79.58891296386719, 79.5882797241211, 79.58765411376953,
  79.5870132446289, 79.58636474609375, 79.58568572998047, 79.5849380493164,
  79.58418273925781, 79.5834732055664, 79.58282470703125, 79.58216094970703,
  79.58142852783203, 79.58067321777344, 79.57990264892578, 79.57910919189453,
  79.57835388183594, 79.57763671875, 79.57689666748047, 79.57613372802734,
  79.5753402709961, 79.57449340820312, 79.57362365722656, 79.57275390625,
  79.57186889648438, 79.5710220336914, 79.57028198242188, 79.56946563720703,
  79.56858825683594, 79.56771850585938, 79.5668716430664, 79.56598663330078,
  79.5650863647461, 79.5641860961914, 79.56324768066406, 79.56226348876953,
  79.56128692626953, 79.56031036376953, 79.55931854248047, 79.55830383300781,
  79.55729675292969, 79.55628967285156, 79.5552749633789, 79.55429077148438,
  79.55329132080078, 79.55225372314453, 79.55116271972656, 79.55007934570312,
  79.54898071289062, 79.54788970947266, 79.54681396484375, 79.5456771850586,
  79.54454040527344, 79.54344177246094, 79.54232788085938, 79.54118347167969,
  79.54000091552734, 79.53882598876953, 79.5375747680664, 79.53632354736328,
  79.5351333618164, 79.53389739990234, 79.53269958496094, 79.53154754638672,
  79.53034973144531, 79.529052734375, 79.52779388427734, 79.52659606933594,
  79.52530670166016, 79.52409362792969, 79.52290344238281, 79.52175903320312,
  79.52053833007812, 79.51925659179688, 79.51795959472656, 79.51673889160156,
  79.51557159423828, 79.5143814086914, 79.5130615234375, 79.51197052001953,
  79.51073455810547, 79.50946807861328, 79.50817108154297, 79.50694274902344,
  79.50579071044922, 79.5046157836914, 79.50334930419922, 79.50206756591797,
  79.50083923339844, 79.49971771240234, 79.49867248535156, 79.4975814819336,
  79.49636840820312, 79.4952392578125, 79.49417114257812, 79.49309539794922,
  79.49193572998047, 79.490966796875, 79.4898452758789, 79.48878479003906,
  79.48768615722656, 79.48658752441406, 79.48538970947266, 79.48445129394531,
  79.48336029052734, 79.4822006225586, 79.48101806640625, 79.4798812866211,
  79.47879028320312, 79.47772216796875, 79.47669219970703, 79.47583770751953,
  79.47515106201172, 79.4744873046875, 79.47370910644531, 79.47286987304688,
  79.47201538085938, 79.471435546875, 79.4711685180664, 79.47099304199219,
  79.47087097167969, 79.47054290771484, 79.4699935913086, 79.4693832397461,
  79.46880340576172, 79.4684829711914, 79.46829986572266, 79.46827697753906,
  79.46830749511719, 79.46834564208984, 79.46832275390625, 79.46829223632812,
  79.46836853027344, 79.46856689453125, 79.46876525878906, 79.468994140625,
  79.46935272216797, 79.46963500976562, 79.46981048583984, 79.46985626220703,
  79.4698257446289, 79.46988677978516, 79.47004699707031, 79.47017669677734,
  79.47025299072266, 79.47025299072266, 79.47016906738281, 79.4700927734375,
  79.47023010253906, 79.47071075439453, 79.4715347290039, 79.47257995605469,
  79.4736328125, 79.47454071044922, 79.475341796875, 79.47623443603516,
  79.47745513916016, 79.47891235351562, 79.48054504394531, 79.48224639892578,
  79.48391723632812, 79.485595703125, 79.48725891113281, 79.48888397216797,
  79.49050903320312, 79.4921875, 79.4939193725586, 79.49571228027344,
  79.49760437011719, 79.49957275390625, 79.5015869140625, 79.50367736816406,
  79.50585174560547, 79.50811767578125, 79.51046752929688, 79.51287841796875,
  79.51536560058594, 79.51789093017578, 79.52049255371094, 79.5231704711914,
  79.52593231201172, 79.52877044677734, 79.53166961669922, 79.53461456298828,
  79.53761291503906, 79.54061889648438, 79.54364013671875, 79.54668426513672,
  79.54975128173828, 79.55282592773438, 79.55594635009766, 79.55911254882812,
  79.5622787475586, 79.56543731689453, 79.56861114501953, 79.57184600830078,
  79.5751724243164, 79.57852935791016, 79.58183288574219, 79.58506774902344,
  79.58830261230469, 79.59163665771484, 79.59510803222656, 79.59866333007812,
  79.60224151611328, 79.60578918457031, 79.60932922363281, 79.61289978027344,
  79.61643981933594, 79.6199722290039, 79.62350463867188, 79.62701416015625,
  79.6305160522461, 79.63399505615234, 79.63745880126953, 79.64097595214844,
  79.64452362060547, 79.64802551269531, 79.65155029296875, 79.65511322021484,
  79.65867614746094, 79.6622543334961, 79.6658706665039, 79.66954803466797,
  79.67327880859375, 79.67706298828125, 79.68087005615234, 79.68476104736328,
  79.68868255615234, 79.69261932373047, 79.696533203125, 79.70044708251953,
  79.7043685913086, 79.70828247070312, 79.712158203125, 79.71598052978516,
  79.71978759765625, 79.72361755371094, 79.7275161743164, 79.73152160644531,
  79.735595703125, 79.73965454101562, 79.74365997314453, 79.74765014648438,
  79.75167846679688, 79.75575256347656, 79.7599105834961, 79.76411437988281,
  79.76835632324219, 79.77256774902344, 79.77671813964844, 79.7808609008789,
  79.7850341796875, 79.78923797607422, 79.79348754882812, 79.79779815673828,
  79.80213928222656, 79.80647277832031, 79.8107681274414, 79.81502532958984,
  79.8193130493164, 79.82357025146484, 79.82770538330078, 79.83185577392578,
  79.8360824584961, 79.84034729003906, 79.84468841552734, 79.84922790527344,
  79.8538818359375, 79.85847473144531, 79.86277770996094, 79.86697387695312,
  79.87103271484375, 79.8753662109375, 79.87995147705078, 79.88471221923828,
  79.88951873779297, 79.89431762695312, 79.89897918701172, 79.90325927734375,
  79.90733337402344, 79.91140747070312, 79.9155044555664, 79.91970825195312,
  79.92410278320312, 79.9288558959961, 79.93367004394531, 79.93826293945312,
  79.94254302978516, 79.94670104980469, 79.95093536376953, 79.95524597167969,
  79.95954895019531, 79.96375274658203, 79.96783447265625, 79.9718246459961,
  79.97579956054688, 79.97981262207031, 79.98387908935547, 79.98796081542969,
  79.99201202392578, 79.99601745605469, 80.00003051757812, 80.00407409667969,
  80.00811767578125, 80.01216125488281, 80.01630401611328, 80.02050018310547,
  80.02458953857422, 80.02860260009766, 80.03260040283203, 80.03663635253906,
  80.04070281982422, 80.04476165771484, 80.04872131347656, 80.05257415771484,
  80.05634307861328, 80.06005859375, 80.06383514404297, 80.06755828857422,
  80.0711898803711, 80.07475280761719, 80.07829284667969, 80.08190155029297,
  80.08551788330078, 80.0891342163086, 80.09268188476562, 80.09613800048828,
  80.09954833984375, 80.10292053222656, 80.10626220703125, 80.1095962524414,
  80.11287689208984, 80.1161117553711, 80.11931610107422, 80.12226104736328,
  80.1253890991211, 80.1288070678711, 80.1322021484375, 80.13553619384766,
  80.13870239257812, 80.14177703857422, 80.14490509033203, 80.14817810058594,
  80.15153503417969, 80.15484619140625, 80.15795135498047, 80.1609878540039,
  80.16405487060547, 80.1672134399414, 80.17034149169922, 80.17366027832031,
  80.17707824707031, 80.18037414550781, 80.18352508544922, 80.1864242553711,
  80.18932342529297, 80.1923599243164, 80.19544219970703, 80.19849395751953,
  80.20136260986328, 80.20398712158203, 80.2065658569336, 80.20935821533203,
  80.2122802734375, 80.2153091430664, 80.21826934814453, 80.22103118896484,
  80.22361755371094, 80.22625732421875, 80.22926330566406, 80.23164367675781,
  80.23482513427734, 80.23733520507812, 80.2402114868164, 80.24258422851562,
  80.24519348144531, 80.2478256225586, 80.25042724609375, 80.25299072265625,
  80.25581359863281, 80.25819396972656, 80.26102447509766, 80.26335144042969,
  80.26591491699219, 80.26819610595703, 80.27062225341797, 80.27354431152344,
  80.27601623535156, 80.2786865234375, 80.28119659423828, 80.28367614746094,
  80.2862777709961, 80.28905487060547, 80.29167175292969, 80.29413604736328,
  80.29656982421875, 80.2990951538086, 80.30171966552734, 80.30431365966797,
  80.30687713623047, 80.30936431884766, 80.31178283691406, 80.314208984375,
  80.31658172607422, 80.3188247680664, 80.321044921875, 80.32328033447266,
  80.32550048828125, 80.32788848876953, 80.3302993774414, 80.33264923095703,
  80.33491516113281, 80.33710479736328, 80.33924102783203, 80.3413314819336,
  80.34347534179688, 80.34574127197266, 80.34793853759766, 80.3500747680664,
  80.35221862792969, 80.35431671142578, 80.35617065429688, 80.35796356201172,
  80.35987854003906, 80.36187744140625, 80.36375427246094, 80.365478515625,
  80.3670883178711, 80.3687515258789, 80.37078857421875, 80.3727035522461,
  80.37456512451172, 80.37672424316406, 80.37857055664062, 80.38013458251953,
  80.38166046142578, 80.3830795288086, 80.3845443725586, 80.38601684570312,
  80.38739776611328, 80.38902282714844, 80.39092254638672, 80.39270782470703,
  80.39439392089844, 80.39582061767578, 80.39717102050781, 80.39896392822266,
  80.40054321289062, 80.4016342163086, 80.40278625488281, 80.40404510498047,
  80.4056167602539, 80.40721130371094, 80.40856170654297, 80.40974426269531,
  80.41100311279297, 80.4123764038086, 80.41376495361328, 80.41497039794922,
  80.41613006591797, 80.41741943359375, 80.41886901855469, 80.42044067382812,
  80.42204284667969, 80.42357635498047, 80.42498016357422, 80.4263916015625,
  80.42782592773438, 80.42924499511719, 80.4306640625, 80.43206787109375,
  80.43350219726562, 80.43502807617188, 80.43656921386719, 80.43812561035156,
  80.439697265625, 80.44141387939453, 80.44320678710938, 80.44528198242188,
  80.44770050048828, 80.45033264160156, 80.45301055908203, 80.45551300048828,
  80.45794677734375, 80.4604721069336, 80.46320343017578, 80.46611022949219,
  80.46900177001953, 80.47189331054688, 80.47478485107422, 80.47769165039062,
  80.4806137084961, 80.48358917236328, 80.48661041259766, 80.4897689819336,
  80.49312591552734, 80.49661254882812, 80.5001449584961, 80.5037612915039,
  80.50743865966797, 80.51114654541016, 80.51490020751953, 80.51868438720703,
  80.52257537841797, 80.52651977539062, 80.53050231933594, 80.53451538085938,
  80.53864288330078, 80.54290771484375, 80.54727172851562, 80.55170440673828,
  80.55601501464844, 80.56024932861328, 80.56446075439453, 80.56883239746094,
  80.57347106933594, 80.5784912109375, 80.58366394042969, 80.58885192871094,
  80.59384155273438, 80.5986099243164, 80.60327911376953, 80.60811614990234,
  80.61326599121094, 80.61853790283203, 80.62383270263672, 80.62908935546875,
  80.63427734375, 80.63955688476562, 80.64486694335938, 80.65023803710938,
  80.6557388305664, 80.66139221191406, 80.66703796386719, 80.67256927490234,
  80.67802429199219, 80.68345642089844, 80.6889877319336, 80.69461822509766,
  80.70038604736328, 80.706298828125, 80.71224975585938, 80.71819305419922,
  80.7240219116211, 80.72975158691406, 80.73548126220703, 80.7413558959961,
  80.7474365234375, 80.75364685058594, 80.75990295410156, 80.76602935791016,
  80.77216339111328, 80.77823638916016, 80.78428649902344, 80.79041290283203,
  80.79654693603516, 80.80266571044922, 80.80874633789062, 80.81486511230469,
  80.82105255126953, 80.8272705078125, 80.83352661132812, 80.83979797363281,
  80.84602355957031, 80.85221862792969, 80.85847473144531, 80.86471557617188,
  80.87094116210938, 80.87721252441406, 80.88351440429688, 80.88983917236328,
  80.8960952758789, 80.90233612060547, 80.9085922241211, 80.91487884521484,
  80.92123413085938, 80.92766571044922, 80.93413543701172, 80.9405517578125,
  80.9468765258789, 80.953125, 80.95933532714844, 80.96556854248047,
  80.97186279296875, 80.97824096679688, 80.984619140625, 80.99090576171875,
  80.99705505371094, 81.003173828125, 81.00926971435547, 81.01536560058594,
  81.02149963378906, 81.02775573730469, 81.0340576171875, 81.04035186767578,
  81.04658508300781, 81.05274200439453, 81.0588607788086, 81.06500244140625,
  81.07112121582031, 81.07736206054688, 81.08366394042969, 81.08997344970703,
  81.09630584716797, 81.10264587402344, 81.1096420288086, 81.1153564453125,
  81.12168884277344, 81.12803649902344, 81.13440704345703, 81.14073944091797,
  81.14701080322266, 81.15326690673828, 81.1594467163086, 81.16561126708984,
  81.17183685302734, 81.1781234741211, 81.18445587158203, 81.19087219238281,
  81.19729614257812, 81.20376586914062, 81.21016693115234, 81.21664428710938,
  81.22318267822266, 81.22969818115234, 81.23623657226562, 81.2427749633789,
  81.24931335449219, 81.2558364868164, 81.26240539550781, 81.26903533935547,
  81.275634765625, 81.28218078613281, 81.28873443603516, 81.29537200927734,
  81.30206298828125, 81.30883026123047, 81.31556701660156, 81.322265625,
  81.32894134521484, 81.3355941772461, 81.3422622680664, 81.34893035888672,
  81.35557556152344, 81.36235046386719, 81.36925506591797, 81.37613677978516,
  81.38292694091797, 81.3895034790039, 81.39601135253906, 81.40263366699219,
  81.40945434570312, 81.41644287109375, 81.42352294921875, 81.4305648803711,
  81.4375228881836, 81.44441223144531, 81.4513168334961, 81.45826721191406,
  81.46526336669922, 81.47222900390625, 81.47918701171875, 81.48612976074219,
  81.49305725097656, 81.50001525878906, 81.5069580078125, 81.513916015625,
  81.52091217041016, 81.52786254882812, 81.53485870361328, 81.5418930053711,
  81.54885864257812, 81.55579376220703, 81.562744140625, 81.56969451904297,
  81.57664489746094, 81.58360290527344, 81.59053802490234, 81.59747314453125,
  81.60441589355469, 81.61136627197266, 81.61830139160156, 81.62522888183594,
  81.63218688964844, 81.63921356201172, 81.64624786376953, 81.6531753540039,
  81.65987396240234, 81.66633605957031, 81.67274475097656, 81.67925262451172,
  81.68595886230469, 81.69275665283203, 81.6995849609375, 81.70649719238281,
  81.71339416503906, 81.719970703125, 81.72635650634766, 81.73284912109375,
  81.73961639404297, 81.74651336669922, 81.75344848632812, 81.76029205322266,
  81.7671127319336, 81.77374267578125, 81.78011322021484, 81.78658294677734,
  81.79320526123047, 81.79984283447266, 81.80638122558594, 81.81269073486328,
  81.81884765625, 81.8251724243164, 81.83161163330078, 81.83800506591797,
  81.84431457519531, 81.85055541992188, 81.85677337646484, 81.86295318603516,
  81.86920166015625, 81.87543487548828, 81.88150024414062, 81.88746643066406,
  81.89356994628906, 81.89978790283203, 81.90583038330078, 81.91167449951172,
  81.9175033569336, 81.92330932617188, 81.92906951904297, 81.9349594116211,
  81.94086456298828, 81.94660949707031, 81.95220947265625, 81.95782470703125,
  81.9634017944336, 81.9689712524414, 81.97457122802734, 81.98014831542969,
  81.98563385009766, 81.9909896850586, 81.99635314941406, 82.00178527832031,
  82.00727081298828, 82.01274108886719, 82.01820373535156, 82.02359771728516,
  82.0289306640625, 82.03424072265625, 82.03955078125, 82.04485321044922,
  82.0500717163086, 82.0551986694336, 82.06034088134766, 82.06546020507812,
  82.07048797607422, 82.07539367675781, 82.08025360107422, 82.08531188964844,
  82.0904769897461, 82.09561157226562, 82.10073852539062, 82.10578155517578,
  82.11060333251953, 82.11526489257812, 82.12003326416016, 82.12483978271484,
  82.12964630126953, 82.13443756103516, 82.13916778564453, 82.14380645751953,
  82.14845275878906, 82.15304565429688, 82.15809631347656, 82.16215515136719,
  82.16651916503906, 82.1708984375, 82.17591094970703, 82.18006896972656,
  82.18470001220703, 82.1891860961914, 82.19356536865234, 82.19792175292969,
  82.20219421386719, 82.20650482177734, 82.21063995361328, 82.21487426757812,
  82.21916198730469, 82.22383880615234, 82.22759246826172, 82.23218536376953,
  82.23600769042969, 82.24026489257812, 82.24488830566406, 82.24858856201172,
  82.25259399414062, 82.2566146850586, 82.26061248779297, 82.26451110839844,
  82.2683334350586, 82.27213287353516, 82.27593994140625, 82.27976989746094,
  82.2835464477539, 82.28731536865234, 82.2911605834961, 82.29492950439453,
  82.29869842529297, 82.30274200439453, 82.30599212646484, 82.3096694946289,
  82.31327819824219, 82.3168716430664, 82.32042694091797, 82.32402038574219,
  82.3275375366211, 82.33101654052734, 82.33451080322266, 82.33795928955078,
  82.34130859375, 82.34464263916016, 82.34797668457031, 82.35137939453125,
  82.35479736328125, 82.35814666748047, 82.36144256591797, 82.36466979980469,
  82.36787414550781, 82.37103271484375, 82.37419891357422, 82.37738037109375,
  82.38052368164062, 82.3836898803711, 82.38683319091797, 82.38984680175781,
  82.39281463623047, 82.39579772949219, 82.39879608154297, 82.40178680419922,
  82.40481567382812, 82.40779876708984, 82.41077423095703, 82.41372680664062,
  82.41664123535156, 82.41943359375, 82.42204284667969, 82.4247817993164,
  82.42792510986328, 82.43024444580078, 82.4329605102539, 82.4351806640625,
  82.4377670288086, 82.44017791748047, 82.44264221191406, 82.44523620605469,
  82.44779968261719, 82.45021057128906, 82.45255279541016, 82.45489501953125,
  82.45718383789062, 82.45955657958984, 82.46183776855469, 82.46417236328125,
  82.46649169921875, 82.46865844726562, 82.47098541259766, 82.47344207763672,
  82.47589111328125, 82.4782485961914, 82.48033905029297, 82.48246002197266,
  82.48468780517578, 82.48701477050781, 82.48932647705078, 82.49164581298828,
  82.49406433105469, 82.49606323242188, 82.4979476928711, 82.49986267089844,
  82.50174713134766, 82.50366973876953, 82.50569915771484, 82.50743103027344,
  82.50927734375, 82.51122283935547, 82.51313018798828, 82.5147933959961,
  82.5162582397461, 82.5179443359375, 82.519775390625, 82.5216064453125,
  82.52340698242188, 82.52511596679688, 82.52655029296875, 82.52788543701172,
  82.52935028076172, 82.53083801269531, 82.53242492675781, 82.53411865234375,
  82.53575897216797, 82.537353515625, 82.53878021240234, 82.54008483886719,
  82.54142761230469, 82.54283142089844, 82.54434967041016, 82.54582214355469,
  82.54719543457031, 82.5485610961914, 82.5497817993164, 82.55097961425781,
  82.55220794677734, 82.55366516113281, 82.55508422851562, 82.55633544921875,
  82.55746459960938, 82.55865478515625, 82.55986785888672, 82.56122589111328,
  82.56257629394531, 82.56363677978516, 82.56454467773438, 82.56558990478516,
  82.5667953491211, 82.56790924072266, 82.56895446777344, 82.57003021240234,
  82.57118225097656, 82.57223510742188, 82.57328796386719, 82.57417297363281,
  82.5750732421875, 82.57593536376953, 82.57695007324219, 82.57786560058594,
  82.57878112792969, 82.57979583740234, 82.58060455322266, 82.58132934570312,
  82.58216857910156, 82.58309936523438, 82.58399200439453, 82.58489990234375,
  82.58576202392578, 82.5865249633789, 82.58736419677734, 82.58814239501953,
  82.58885955810547, 82.58961486816406, 82.5903549194336, 82.59089660644531,
  82.59121704101562, 82.59147644042969, 82.59183502197266, 82.59228515625,
  82.59278869628906, 82.59320831298828, 82.59335327148438, 82.59349060058594,
  82.59373474121094, 82.59404754638672, 82.59452056884766, 82.59489440917969,
  82.5949935913086, 82.5950698852539, 82.59503936767578, 82.59496307373047,
  82.59487915039062, 82.59503173828125, 82.59517669677734, 82.59504699707031,
  82.59486389160156, 82.59471893310547, 82.59479522705078, 82.59494018554688,
  82.59491729736328, 82.5947036743164, 82.59446716308594, 82.59432220458984,
  82.59423828125, 82.59418487548828, 82.59402465820312, 82.59390258789062,
  82.59376525878906, 82.59358978271484, 82.59329223632812, 82.59307861328125,
  82.59300231933594, 82.59283447265625, 82.59252166748047, 82.5921401977539,
  82.59176635742188, 82.59138488769531, 82.59104919433594, 82.59073638916016,
  82.59036254882812, 82.58992767333984, 82.5895004272461, 82.5891342163086,
  82.58863067626953, 82.58792114257812, 82.58712768554688, 82.58634185791016,
  82.5855484008789, 82.58480072021484, 82.58406066894531, 82.58336639404297,
  82.5826644897461, 82.58194732666016, 82.58123779296875, 82.58061218261719,
  82.58027648925781, 82.57980346679688, 82.57915496826172, 82.57807159423828,
  82.57710266113281, 82.57621765136719, 82.57510375976562, 82.57417297363281,
  82.57308959960938, 82.57170867919922, 82.57058715820312, 82.56988525390625,
  82.56916809082031, 82.56851196289062, 82.5677261352539, 82.56666564941406,
  82.56534576416016, 82.56403350830078, 82.56271362304688, 82.56145477294922,
  82.56022644042969, 82.5590591430664, 82.55791473388672, 82.55674743652344,
  82.55558776855469, 82.5544204711914, 82.5533447265625, 82.55220031738281,
  82.55091857910156, 82.54951477050781, 82.54804229736328, 82.54647827148438,
  82.54496765136719, 82.54335021972656, 82.5417251586914, 82.5401840209961,
  82.53885650634766, 82.53756713867188, 82.53617095947266, 82.53486633300781,
  82.533447265625, 82.5321273803711, 82.53062438964844, 82.52903747558594,
  82.5274429321289, 82.52599334716797, 82.52462005615234, 82.52312469482422,
  82.52154541015625, 82.51995849609375, 82.5184555053711, 82.51712036132812,
  82.51582336425781, 82.51437377929688, 82.51280212402344, 82.51118469238281,
  82.50968933105469, 82.50813293457031, 82.50648498535156, 82.50493621826172,
  82.50344848632812, 82.50175476074219, 82.49976348876953, 82.49781799316406,
  82.49593353271484, 82.49409484863281, 82.49230194091797, 82.49071502685547,
  82.48914337158203, 82.48748016357422, 82.4857406616211, 82.48408508300781,
  82.48257446289062, 82.48117065429688, 82.47986602783203, 82.4786605834961,
  82.47756958007812, 82.47650146484375, 82.47543334960938, 82.47428894042969,
  82.4729995727539, 82.47162628173828, 82.47018432617188, 82.4686050415039,
  82.46681213378906, 82.46507263183594, 82.46348571777344, 82.46209716796875,
  82.46100616455078, 82.46002197265625, 82.45901489257812, 82.45787048339844,
  82.45659637451172, 82.4554214477539, 82.45411682128906, 82.45270538330078,
  82.45105743408203, 82.44924926757812, 82.4476318359375, 82.44630432128906,
  82.44519805908203, 82.44426727294922, 82.443359375, 82.44232940673828,
  82.44123840332031, 82.44015502929688, 82.43909454345703, 82.43803405761719,
  82.43712615966797, 82.43627166748047, 82.43515014648438, 82.43380737304688,
  82.43250274658203, 82.43115234375, 82.42987823486328, 82.42868041992188,
  82.4276123046875, 82.4266357421875, 82.42549896240234, 82.42407989501953,
  82.42259216308594, 82.42083740234375, 82.41876220703125, 82.41666412353516,
  82.41500091552734, 82.41315460205078, 82.41109466552734, 82.40911102294922,
  82.40724182128906, 82.40557861328125, 82.40401458740234, 82.40223693847656,
  82.40025329589844, 82.3983154296875, 82.39663696289062, 82.39522552490234,
  82.39386749267578, 82.39288330078125, 82.39196014404297, 82.39083862304688,
  82.3896713256836, 82.3884506225586, 82.38719940185547, 82.38593292236328,
  82.38484954833984, 82.38383483886719, 82.38282012939453, 82.38176727294922,
  82.38068389892578, 82.37980651855469, 82.37896728515625, 82.3781509399414,
  82.3774185180664, 82.37683868408203, 82.37612915039062, 82.3752670288086,
  82.37442779541016, 82.37358856201172, 82.37279510498047, 82.3720703125,
  82.3714828491211, 82.37100219726562, 82.37049102783203, 82.36998748779297,
  82.36951446533203, 82.36907196044922, 82.36859130859375, 82.36823272705078,
  82.36801147460938, 82.36805725097656, 82.3682632446289, 82.36852264404297,
  82.36888122558594, 82.36927795410156, 82.36985778808594, 82.37051391601562,
  82.37126922607422, 82.37211608886719, 82.3730697631836, 82.3742446899414,
  82.37567901611328, 82.37712860107422, 82.3785171508789, 82.37995910644531,
  82.38140869140625, 82.38282012939453, 82.38433074951172, 82.38595581054688,
  82.38755798339844, 82.38907623291016, 82.39051818847656, 82.3919677734375,
  82.3935546875, 82.39527130126953, 82.39695739746094, 82.3984146118164,
  82.39987182617188, 82.40143585205078, 82.40311431884766, 82.40481567382812,
  82.40654754638672, 82.40825653076172, 82.40987396240234, 82.41139221191406,
  82.41294860839844, 82.41448211669922, 82.4159927368164, 82.41754150390625,
  82.41895294189453, 82.42034912109375, 82.42180633544922, 82.42326354980469,
  82.42466735839844, 82.42601776123047, 82.42731475830078, 82.42860412597656,
  82.42991638183594, 82.43123626708984, 82.43254852294922, 82.43385314941406,
  82.43511962890625, 82.43639373779297, 82.43765258789062, 82.43890380859375,
  82.44012451171875, 82.44129943847656, 82.44255065917969, 82.44358825683594,
  82.44483947753906, 82.44583129882812, 82.44694519042969, 82.44808959960938,
  82.44929504394531, 82.45059204101562, 82.45156860351562, 82.45266723632812,
  82.45355987548828, 82.4546127319336, 82.45575714111328, 82.45677947998047,
  82.457763671875, 82.45863342285156, 82.45947265625, 82.46024322509766,
  82.46109771728516, 82.4619369506836, 82.4627456665039, 82.46353149414062,
  82.46426391601562, 82.46501922607422, 82.4658203125, 82.46654510498047,
  82.46725463867188, 82.46795654296875, 82.4686279296875, 82.46924591064453,
  82.4698486328125, 82.47048950195312, 82.47110748291016, 82.47177124023438,
  82.47232055664062, 82.47291564941406, 82.47344970703125, 82.47393798828125,
  82.47441864013672, 82.47494506835938, 82.47547149658203, 82.47596740722656,
  82.47637176513672, 82.47669982910156, 82.47705841064453, 82.47747802734375,
  82.47796630859375, 82.47850799560547, 82.47891998291016, 82.47921752929688,
  82.4794692993164, 82.4797134399414, 82.4800033569336, 82.4803237915039,
  82.4806900024414, 82.48106384277344, 82.48128509521484, 82.48149871826172,
  82.48170471191406, 82.48187255859375, 82.48200225830078, 82.48218536376953,
  82.48242950439453, 82.48269653320312, 82.48294067382812, 82.48307800292969,
  82.48318481445312, 82.48333740234375, 82.48355102539062, 82.4838638305664,
  82.48411560058594, 82.48422241210938, 82.48434448242188, 82.48443603515625,
  82.48448181152344, 82.4845962524414, 82.48482513427734, 82.48501586914062,
  82.48505401611328, 82.48528289794922, 82.48534393310547, 82.48526763916016,
  82.48538970947266, 82.4854965209961, 82.48552703857422, 82.48556518554688,
  82.4856185913086, 82.48563385009766, 82.48564147949219, 82.48560333251953,
  82.48548126220703, 82.48529052734375, 82.48506164550781, 82.48484802246094,
  82.48463439941406, 82.4844741821289, 82.48428344726562, 82.48411560058594,
  82.48394775390625, 82.48355865478516, 82.48311614990234, 82.4827880859375,
  82.48259735107422, 82.48234558105469, 82.48194885253906, 82.48152923583984,
  82.48104095458984, 82.48055267333984, 82.48002624511719, 82.47956848144531,
  82.47907257080078, 82.47847747802734, 82.47782897949219, 82.47726440429688,
  82.47683715820312, 82.4762191772461, 82.47547912597656, 82.47464752197266,
  82.47396850585938, 82.47351837158203, 82.4729232788086, 82.47225952148438,
  82.47171783447266, 82.47107696533203, 82.47022247314453, 82.46936798095703,
  82.46856689453125, 82.46788024902344, 82.46719360351562, 82.4664535522461,
  82.46571350097656, 82.46510314941406, 82.46446228027344, 82.46357727050781,
  82.46282196044922, 82.46217346191406, 82.46147155761719, 82.46072387695312,
  82.45995330810547, 82.45915985107422, 82.45841979980469, 82.45760345458984,
  82.4566421508789, 82.45578002929688, 82.45496368408203, 82.45417785644531,
  82.45329284667969, 82.45233917236328, 82.45132446289062, 82.4502944946289,
  82.44937896728516, 82.44855499267578, 82.44761657714844, 82.44654083251953,
  82.4454345703125, 82.44438934326172, 82.44351196289062, 82.442626953125,
  82.44164276123047, 82.44051361083984, 82.43933868408203, 82.4382095336914,
  82.43710327148438, 82.43590545654297, 82.43474578857422, 82.4334716796875,
  82.4322738647461, 82.4310302734375, 82.42979431152344, 82.42861938476562,
  82.4273910522461, 82.4260482788086, 82.42459106445312, 82.4232406616211,
  82.4220199584961, 82.42086029052734, 82.4196548461914, 82.41839599609375,
  82.41707611083984, 82.41543579101562, 82.41382598876953, 82.41241455078125,
  82.41131591796875, 82.4101333618164, 82.40869903564453, 82.4071273803711,
  82.40555572509766, 82.4040756225586, 82.40267181396484, 82.40110778808594,
  82.399658203125, 82.398193359375, 82.39661407470703, 82.3948974609375,
  82.39320373535156, 82.39177703857422, 82.39035034179688, 82.38884735107422,
  82.38722229003906, 82.38545227050781, 82.3836898803711, 82.38214111328125,
  82.38085174560547, 82.37957763671875, 82.37847137451172, 82.37764739990234,
  82.37696838378906, 82.37629699707031, 82.37557983398438, 82.37481689453125,
  82.37403869628906, 82.37336730957031, 82.37295532226562, 82.37261962890625,
  82.3721694946289, 82.37173461914062, 82.37135314941406, 82.37105560302734,
  82.3708267211914, 82.37046813964844, 82.3699722290039, 82.3695297241211,
  82.36911010742188, 82.3687973022461, 82.36857604980469, 82.3684310913086,
  82.36827087402344, 82.36808013916016, 82.36782836914062, 82.36751556396484,
  82.3672103881836, 82.36697387695312, 82.36669921875, 82.36632537841797,
  82.36579895019531, 82.36521911621094, 82.36469268798828, 82.36418914794922,
  82.36369323730469, 82.36317443847656, 82.36258697509766, 82.36199188232422,
  82.36135864257812, 82.36068725585938, 82.35999298095703, 82.35926818847656,
  82.35852813720703, 82.35779571533203, 82.35710144042969, 82.35653686523438,
  82.35606384277344, 82.35555267333984, 82.35490417480469, 82.35407257080078,
  82.3531265258789, 82.35215759277344, 82.35123443603516, 82.3503646850586,
  82.34952545166016, 82.34872436523438, 82.3478775024414, 82.34698486328125,
  82.3460922241211, 82.34513854980469, 82.34408569335938, 82.34294891357422,
  82.34181213378906, 82.34075164794922, 82.33978271484375, 82.3388900756836,
  82.3382339477539, 82.33750915527344, 82.33660125732422, 82.33547973632812,
  82.33423614501953, 82.33296966552734, 82.33167266845703, 82.3303451538086,
  82.32923126220703, 82.32865142822266, 82.328125, 82.32756805419922,
  82.32636260986328, 82.32501220703125, 82.32363891601562, 82.32223510742188,
  82.32080841064453, 82.31950378417969, 82.31851959228516, 82.31754302978516,
  82.31621551513672, 82.31476593017578, 82.31327819824219, 82.31180572509766,
  82.31041717529297, 82.30908966064453, 82.30770874023438, 82.30615997314453,
  82.30455017089844, 82.30290985107422, 82.30133056640625, 82.2998275756836,
  82.29837036132812, 82.29692840576172, 82.29540252685547, 82.29369354248047,
  82.29193878173828, 82.29019927978516, 82.28865814208984, 82.28716278076172,
  82.28548431396484, 82.28365325927734, 82.28179168701172, 82.27991485595703,
  82.27816772460938, 82.2766342163086, 82.275146484375, 82.2735366821289,
  82.27168273925781, 82.26972198486328, 82.26773071289062, 82.26571655273438,
  82.263671875, 82.26172637939453, 82.26007843017578, 82.25847625732422,
  82.25675201416016, 82.25489807128906, 82.25292205810547, 82.2508544921875,
  82.24880981445312, 82.24674987792969, 82.24467468261719, 82.24262237548828,
  82.2406234741211, 82.23871612548828, 82.23680877685547, 82.2347640991211,
  82.23261260986328, 82.23043060302734, 82.2282485961914, 82.22604370117188,
  82.22382354736328, 82.22156524658203, 82.21930694580078, 82.21710205078125,
  82.2149887084961, 82.21288299560547, 82.2106704711914, 82.20832824707031,
  82.20587158203125, 82.20337677001953, 82.20085144042969, 82.19841766357422,
  82.19612884521484, 82.19390106201172, 82.19159698486328, 82.1891098022461,
  82.18659210205078, 82.18408203125, 82.1815414428711, 82.17900848388672,
  82.17646789550781, 82.1739273071289, 82.17137908935547, 82.16880798339844,
  82.16615295410156, 82.16344451904297, 82.16078186035156, 82.15814208984375,
  82.15547943115234, 82.15284729003906, 82.1502456665039, 82.1476058959961,
  82.1448745727539, 82.14205169677734, 82.13919830322266, 82.13630676269531,
  82.13346862792969, 82.1307373046875, 82.12821960449219, 82.12567901611328,
  82.1230239868164, 82.12030792236328, 82.11752319335938, 82.11470794677734,
  82.11197662353516, 82.10926055908203, 82.10626220703125, 82.10379028320312,
  82.10108947753906, 82.09822082519531, 82.09523010253906, 82.09209442138672,
  82.08889770507812, 82.0858154296875, 82.08271026611328, 82.08033752441406,
  82.07765197753906, 82.07464599609375, 82.071533203125, 82.06861114501953,
  82.06584930419922, 82.06310272216797, 82.06027221679688, 82.05740356445312,
  82.05457305908203, 82.05174255371094, 82.04888916015625, 82.04593658447266,
  82.04290008544922, 82.03988647460938, 82.03683471679688, 82.03396606445312,
  82.03108978271484, 82.02796173095703, 82.02471923828125, 82.02155303955078,
  82.01841735839844, 82.0153579711914, 82.01241302490234, 82.00946044921875,
  82.00662994384766, 82.00372314453125, 82.00074005126953, 81.99775695800781,
  81.99483489990234, 81.99213409423828, 81.98900604248047, 81.9856948852539,
  81.98236846923828, 81.97918701171875, 81.97603607177734, 81.9727783203125,
  81.96965789794922, 81.96656036376953, 81.96355438232422, 81.96053314208984,
  81.95745849609375, 81.95439147949219, 81.95133972167969, 81.94813537597656,
  81.94489288330078, 81.9418716430664, 81.93898010253906, 81.93588256835938,
  81.93270111083984, 81.92960357666016, 81.9263687133789, 81.92366027832031,
  81.92134857177734, 81.91873168945312, 81.91590881347656, 81.91272735595703,
  81.91006469726562, 81.90711212158203, 81.90425872802734, 81.90142059326172,
  81.89852905273438, 81.89563751220703, 81.8927993774414, 81.8901138305664,
  81.88762664794922, 81.8849105834961, 81.88275909423828, 81.88036346435547,
  81.87779998779297, 81.87519836425781, 81.87262725830078, 81.86993408203125,
  81.86761474609375, 81.86499786376953, 81.86222839355469, 81.8593978881836,
  81.85639953613281, 81.85396575927734, 81.85094451904297, 81.84844207763672,
  81.8455581665039, 81.8427505493164, 81.840087890625, 81.83734130859375,
  81.83457946777344, 81.83171844482422, 81.82886505126953, 81.8260269165039,
  81.82288360595703, 81.82029724121094, 81.81737518310547, 81.81436920166016,
  81.81126403808594, 81.80826568603516, 81.80517578125, 81.8019790649414,
  81.79878234863281, 81.7956314086914, 81.79249572753906, 81.78947448730469,
  81.78652954101562, 81.78358459472656, 81.78058624267578, 81.77748107910156,
  81.77425384521484, 81.7708969116211, 81.7674789428711, 81.76412200927734,
  81.76084899902344, 81.75763702392578, 81.7544174194336, 81.75110626220703,
  81.74781036376953, 81.74449920654297, 81.74115753173828, 81.73787689208984,
  81.73458862304688, 81.73123168945312, 81.72784423828125, 81.72435760498047,
  81.72080993652344, 81.71730041503906, 81.7138671875, 81.71045684814453,
  81.70704650878906, 81.70372772216797, 81.700439453125, 81.69721984863281,
  81.69414520263672, 81.69104766845703, 81.68788146972656, 81.68461608886719,
  81.68136596679688, 81.67821502685547, 81.67513275146484, 81.67224884033203,
  81.66960906982422, 81.66709899902344, 81.6647720336914, 81.66256713867188,
  81.66036987304688, 81.65804290771484, 81.65564727783203, 81.6532211303711,
  81.65090942382812, 81.64864349365234, 81.64631652832031, 81.6441650390625,
  81.64203643798828, 81.63993072509766, 81.63787841796875, 81.635986328125,
  81.63418579101562, 81.63221740722656, 81.63026428222656, 81.62835693359375,
  81.62649536132812, 81.62467956542969, 81.62288665771484, 81.62110900878906,
  81.61932373046875, 81.61744689941406, 81.61553955078125, 81.61360931396484,
  81.61155700683594, 81.60940551757812, 81.60719299316406, 81.60491943359375,
  81.60256958007812, 81.60017395019531, 81.59789276123047, 81.59574127197266,
  81.59367370605469, 81.59160614013672, 81.58946228027344, 81.58724212646484,
  81.58487701416016, 81.58252716064453, 81.58023071289062, 81.57791137695312,
  81.57553100585938, 81.5730209350586, 81.5704345703125, 81.56790161132812,
  81.56548309326172, 81.56318664550781, 81.5609359741211, 81.5586166381836,
  81.55626678466797, 81.55384826660156, 81.55136108398438, 81.54878234863281,
  81.546142578125, 81.54354095458984, 81.54084777832031, 81.53823852539062,
  81.53569793701172, 81.53327941894531, 81.53085327148438, 81.5281982421875,
  81.52545166015625, 81.52294921875, 81.52064514160156, 81.51850128173828,
  81.51585388183594, 81.51343536376953, 81.51167297363281, 81.50927734375,
  81.50666046142578, 81.50385284423828, 81.50101470947266, 81.49815368652344,
  81.4952621459961, 81.49337005615234, 81.4917984008789, 81.49043273925781,
  81.48790740966797, 81.4850845336914, 81.48223876953125, 81.4793701171875,
  81.47647094726562, 81.47325134277344, 81.47058868408203, 81.46760559082031,
  81.46429443359375, 81.46125793457031, 81.45850372314453, 81.45510864257812,
  81.45259094238281, 81.45054626464844, 81.44812774658203, 81.44548034667969,
  81.44276428222656, 81.43971252441406, 81.43663024902344, 81.4332046508789,
  81.43006896972656, 81.4272232055664, 81.42399597167969, 81.42168426513672,
  81.41931915283203, 81.41697692871094, 81.41410827636719, 81.41144561767578,
  81.40831756591797, 81.40482330322266, 81.40199279785156, 81.39854431152344,
  81.39531707763672, 81.39237213134766, 81.38874816894531, 81.38542175292969,
  81.38209533691406, 81.3793716430664, 81.37654113769531, 81.37372589111328,
  81.37078857421875, 81.3676528930664, 81.36433410644531, 81.3609619140625,
  81.35755920410156, 81.35419464111328, 81.35093688964844, 81.34765625,
  81.34400177001953, 81.34101104736328, 81.33734893798828, 81.33448028564453,
  81.33129119873047, 81.32774353027344, 81.3248519897461, 81.32115936279297,
  81.31798553466797, 81.31440734863281, 81.31049346923828, 81.30734252929688,
  81.30349731445312, 81.3000259399414, 81.29690551757812, 81.29345703125,
  81.2896728515625, 81.28617095947266, 81.28298950195312, 81.2794418334961,
  81.27596282958984, 81.27250671386719, 81.26904296875, 81.26516723632812,
  81.26195526123047, 81.25840759277344, 81.25484466552734, 81.2513198852539,
  81.24779510498047, 81.2442626953125, 81.24079895019531, 81.23731994628906,
  81.23381805419922, 81.23027801513672, 81.22674560546875, 81.2232437133789,
  81.2197494506836, 81.2162857055664, 81.21285247802734, 81.20946502685547,
  81.20619201660156, 81.2029800415039, 81.1997299194336, 81.19639587402344,
  81.1929702758789, 81.18952941894531, 81.1862564086914, 81.18301391601562,
  81.17953491210938, 81.17642211914062, 81.17329406738281, 81.170166015625,
  81.1673355102539, 81.16413116455078, 81.16065216064453, 81.1578598022461,
  81.15480041503906, 81.15168762207031, 81.1485366821289, 81.14501190185547,
  81.14215087890625, 81.13915252685547, 81.13603210449219, 81.13282775878906,
  81.12958526611328, 81.12628173828125, 81.12310791015625, 81.12005615234375,
  81.11705017089844, 81.11405944824219, 81.11100006103516, 81.10789489746094,
  81.10494995117188, 81.10220336914062, 81.09943389892578, 81.09649658203125,
  81.09307098388672, 81.09017944335938, 81.08709716796875, 81.08418273925781,
  81.08129119873047, 81.07835388183594, 81.07524871826172, 81.07209014892578,
  81.06901550292969, 81.0660400390625, 81.06305694580078, 81.0600357055664,
  81.05704498291016, 81.05412292480469, 81.05119323730469, 81.04828643798828,
  81.04556274414062, 81.04303741455078, 81.04061889648438, 81.03803253173828,
  81.03527069091797, 81.03263092041016, 81.0302505493164, 81.02803039550781,
  81.02587890625, 81.02387237548828, 81.02198028564453, 81.02005767822266,
  81.01808166503906, 81.01620483398438, 81.01455688476562, 81.01290130615234,
  81.01123046875, 81.00946807861328, 81.00774383544922, 81.00615692138672,
  81.00465393066406, 81.00321960449219, 81.00196838378906, 81.00090789794922,
  80.99990844726562, 80.9988784790039, 80.99779510498047, 80.99681091308594,
  80.99592590332031, 80.99512481689453, 80.99425506591797, 80.9932861328125,
  80.9923324584961, 80.99148559570312, 80.9908447265625, 80.99039459228516,
  80.99007415771484, 80.98985290527344, 80.98968505859375, 80.989501953125,
  80.98932647705078, 80.98917388916016, 80.98912811279297, 80.98916625976562,
  80.98918914794922, 80.9891357421875, 80.98906707763672, 80.98907470703125,
  80.98921966552734, 80.98945617675781, 80.98971557617188, 80.98991394042969,
  80.99002075195312, 80.99020385742188, 80.99055480957031, 80.99107360839844,
  80.99163055419922, 80.99214172363281, 80.99262237548828, 80.99308776855469,
  80.99359130859375, 80.99420928955078, 80.9948959350586, 80.99562072753906,
  80.99630737304688, 80.99687194824219, 80.99732971191406, 80.9978256225586,
  80.99856567382812, 80.9994125366211, 81.00011444091797, 81.0006103515625,
  81.00098419189453, 81.0014419555664, 81.00212097167969, 81.00303649902344,
  81.0041275024414, 81.00540161132812, 81.00695037841797, 81.00847625732422,
  81.00990295410156, 81.0112533569336, 81.01254272460938, 81.01386260986328,
  81.01532745361328, 81.01690673828125, 81.01844024658203, 81.01995086669922,
  81.02155303955078, 81.02318572998047, 81.0247573852539, 81.02626037597656,
  81.02779388427734, 81.0295639038086, 81.03163146972656, 81.03395080566406,
  81.03633117675781, 81.03858184814453, 81.04061126708984, 81.04243469238281,
  81.04421997070312, 81.04607391357422, 81.04795837402344, 81.05011749267578,
  81.05243682861328, 81.05501556396484, 81.05729675292969, 81.05950927734375,
  81.06151580810547, 81.06396484375, 81.06595611572266, 81.06809997558594,
  81.07022094726562, 81.07234954833984, 81.07476043701172, 81.07696533203125,
  81.07917022705078, 81.08135223388672, 81.08328247070312, 81.0854263305664,
  81.08758544921875, 81.08971405029297, 81.0918197631836, 81.09391021728516,
  81.09602355957031, 81.09822845458984, 81.10055541992188, 81.10326385498047,
  81.10604858398438, 81.10858154296875, 81.11066436767578, 81.11262512207031,
  81.1146240234375, 81.11679077148438, 81.11914825439453, 81.12163543701172,
  81.12425231933594, 81.12688446044922, 81.12940216064453, 81.13182067871094,
  81.13422393798828, 81.1364517211914, 81.13858795166016, 81.14088439941406,
  81.14350891113281, 81.14642333984375, 81.14956665039062, 81.15264129638672,
  81.15534210205078, 81.15770721435547, 81.15994262695312, 81.16212463378906,
  81.16434478759766, 81.1667251586914, 81.16929626464844, 81.17203521728516,
  81.17504119873047, 81.17822265625, 81.1815185546875, 81.18470001220703,
  81.18753051757812, 81.18994140625, 81.19196319580078, 81.19476318359375,
  81.19718933105469, 81.19961547851562, 81.20193481445312, 81.20470428466797,
  81.20723724365234, 81.20965576171875, 81.21172332763672, 81.21382904052734,
  81.21578979492188, 81.21761322021484, 81.21943664550781, 81.2212905883789,
  81.2232666015625, 81.2253189086914, 81.22733306884766, 81.22936248779297,
  81.23136901855469, 81.23340606689453, 81.23542022705078, 81.23749542236328,
  81.23957824707031, 81.24161529541016, 81.24382781982422, 81.24556732177734,
  81.24742126464844, 81.249267578125, 81.25119018554688, 81.25337982177734,
  81.25537109375, 81.25712585449219, 81.25920104980469, 81.26077270507812,
  81.26238250732422, 81.26399230957031, 81.26570892333984, 81.26748657226562,
  81.26924133300781, 81.27096557617188, 81.272705078125, 81.2740478515625,
  81.27564239501953, 81.27739715576172, 81.27912902832031, 81.2807846069336,
  81.28231048583984, 81.28374481201172, 81.2851791381836, 81.28683471679688,
  81.28831481933594, 81.28972625732422, 81.29096221923828, 81.29244995117188,
  81.29366302490234, 81.29519653320312, 81.29644775390625, 81.29792022705078,
  81.29903411865234, 81.30026245117188, 81.30131530761719, 81.3025131225586,
  81.30369567871094, 81.30488586425781, 81.30606842041016, 81.3072509765625,
  81.30841064453125, 81.30951690673828, 81.31055450439453, 81.31158447265625,
  81.31260681152344, 81.3136215209961, 81.31463623046875, 81.31556701660156,
  81.31645202636719, 81.31732940673828, 81.31817626953125, 81.31903839111328,
  81.31980895996094, 81.320556640625, 81.32136535644531, 81.322265625,
  81.3231430053711, 81.32389068603516, 81.32466125488281, 81.32535552978516,
  81.32603454589844, 81.3266830444336, 81.32736206054688, 81.32794189453125,
  81.32848358154297, 81.32897186279297, 81.32942962646484, 81.33000946044922,
  81.3305892944336, 81.3311538696289, 81.33160400390625, 81.33201599121094,
  81.33251190185547, 81.33296966552734, 81.3334732055664, 81.33385467529297,
  81.33415222167969, 81.33443450927734, 81.33480834960938, 81.33515167236328,
  81.33551025390625, 81.33589172363281, 81.33618927001953, 81.33642578125,
  81.33671569824219, 81.3369369506836, 81.33712005615234, 81.3372802734375,
  81.33739471435547, 81.33759307861328, 81.3377914428711, 81.33795928955078,
  81.33818817138672, 81.3384780883789, 81.33879852294922, 81.33911895751953,
  81.3392333984375, 81.33924865722656, 81.33920288085938, 81.33917236328125,
  81.33906555175781, 81.33893585205078, 81.33887481689453, 81.33882141113281,
  81.33887481689453, 81.33891296386719, 81.33885192871094, 81.33849334716797,
  81.33809661865234, 81.33789825439453, 81.33789825439453, 81.33789825439453,
  81.3377456665039, 81.33746337890625, 81.3371353149414, 81.33686828613281,
  81.3365707397461, 81.33625793457031, 81.33601379394531, 81.33567810058594,
  81.3353042602539, 81.33493041992188, 81.33457946777344, 81.33417510986328,
  81.33363342285156, 81.33312225341797, 81.3326187133789, 81.33209228515625,
  81.3314437866211, 81.33086395263672, 81.33026885986328, 81.3296890258789,
  81.32884216308594, 81.32801818847656, 81.32752990722656, 81.32725524902344,
  81.32691955566406, 81.32638549804688, 81.32564544677734, 81.32485961914062,
  81.3240966796875, 81.32330322265625, 81.32256317138672, 81.32183074951172,
  81.32099914550781, 81.32011413574219, 81.31942749023438, 81.31900024414062,
  81.31832885742188, 81.3175277709961, 81.31678771972656, 81.3157958984375,
  81.31476593017578, 81.31380462646484, 81.31290435791016, 81.31202697753906,
  81.31116485595703, 81.3105239868164, 81.30963134765625, 81.30838012695312,
  81.30767059326172, 81.30711364746094, 81.306640625, 81.305908203125,
  81.30514526367188, 81.30469512939453, 81.30355834960938, 81.30237579345703,
  81.30101013183594, 81.2998275756836, 81.29894256591797, 81.29808807373047,
  81.29732513427734, 81.2966079711914, 81.2958984375, 81.29512023925781,
  81.29447937011719, 81.29393005371094, 81.29325866699219, 81.29244995117188,
  81.29105377197266, 81.28948211669922, 81.28795623779297, 81.28636169433594,
  81.28484344482422, 81.28353118896484, 81.28265380859375, 81.2818832397461,
  81.2811508178711, 81.28006744384766, 81.27882385253906, 81.27774047851562,
  81.27678680419922, 81.27570343017578, 81.27417755126953, 81.27245330810547,
  81.27091979980469, 81.26984405517578, 81.26850128173828, 81.26715850830078,
  81.26596069335938, 81.26496887207031, 81.26409149169922, 81.2632827758789,
  81.26228332519531, 81.26107025146484, 81.25977325439453, 81.2585678100586,
  81.25740051269531, 81.25652313232422, 81.25599670410156, 81.2555923461914,
  81.2552490234375, 81.25482177734375, 81.25431060791016, 81.25383758544922,
  81.25345611572266, 81.2532958984375, 81.25306701660156, 81.25279998779297,
  81.25257110595703, 81.25228118896484, 81.25201416015625, 81.25179290771484,
  81.25147247314453, 81.25115966796875, 81.25094604492188, 81.25083923339844,
  81.25070190429688, 81.25060272216797, 81.2508773803711, 81.25113677978516,
  81.25103759765625, 81.25080108642578, 81.25080108642578, 81.2511978149414,
  81.25179290771484, 81.25227355957031, 81.2524642944336, 81.25237274169922,
  81.25215911865234, 81.251953125, 81.25183868408203, 81.25196075439453,
  81.25225830078125, 81.25274658203125, 81.25334930419922, 81.25404357910156,
  81.25475311279297, 81.2555923461914, 81.25665283203125, 81.25777435302734,
  81.25884246826172, 81.25991821289062, 81.26100158691406, 81.2621841430664,
  81.26339721679688, 81.2646255493164, 81.2657699584961, 81.26688385009766,
  81.26797485351562, 81.26907348632812, 81.27030944824219, 81.27143096923828,
  81.27247619628906, 81.27361297607422, 81.27479553222656, 81.27606201171875,
  81.27732849121094, 81.2784652709961, 81.27957916259766, 81.28070831298828,
  81.28185272216797, 81.2829818725586, 81.28408813476562, 81.28520965576172,
  81.28636932373047, 81.2875747680664, 81.288818359375, 81.29005432128906,
  81.29124450683594, 81.2923355102539, 81.29339599609375, 81.29447174072266,
  81.29554748535156, 81.2966079711914, 81.29764556884766, 81.29866027832031,
  81.29961395263672, 81.3005142211914, 81.3013916015625, 81.30219268798828,
  81.30299377441406, 81.3038101196289, 81.3046646118164, 81.30555725097656,
  81.30643463134766, 81.30728149414062, 81.30804443359375, 81.30870819091797,
  81.30935668945312, 81.31002044677734, 81.31068420410156, 81.31134796142578,
  81.31200408935547, 81.3126449584961, 81.313232421875, 81.31376647949219,
  81.31428527832031, 81.3147964477539, 81.31527709960938, 81.31575012207031,
  81.31616973876953, 81.31658172607422, 81.31700897216797, 81.31743621826172,
  81.31782531738281, 81.31816864013672, 81.31849670410156, 81.31883239746094,
  81.31914520263672, 81.31942749023438, 81.3197250366211, 81.32001495361328,
  81.32023620605469, 81.32042694091797, 81.32062530517578, 81.32076263427734,
  81.32089233398438, 81.3210220336914, 81.32111358642578, 81.32115936279297,
  81.32122039794922, 81.3213119506836, 81.3213882446289, 81.32139587402344,
  81.32133483886719, 81.32121276855469, 81.32112884521484, 81.32115936279297,
  81.32118225097656, 81.3211898803711, 81.32112884521484, 81.32097625732422,
  81.32079315185547, 81.32060241699219, 81.32040405273438, 81.32025146484375,
  81.32013702392578, 81.32002258300781, 81.31990814208984, 81.31978607177734,
  81.31961822509766, 81.31951904296875, 81.31938171386719, 81.3191909790039,
  81.31903839111328, 81.31895446777344, 81.31890106201172, 81.31888580322266,
  81.31864929199219, 81.3183822631836, 81.31813049316406, 81.31793975830078,
  81.31780242919922, 81.31768035888672, 81.31745147705078, 81.31714630126953,
  81.31681060791016, 81.3165054321289, 81.3162841796875, 81.31604766845703,
  81.31581115722656, 81.31554412841797, 81.31520080566406, 81.3148193359375,
  81.31443786621094, 81.31404113769531, 81.31364440917969, 81.31328582763672,
  81.31293487548828, 81.31256866455078, 81.31216430664062, 81.31173706054688,
  81.31120300292969, 81.31059265136719, 81.30990600585938, 81.30928802490234,
  81.3087387084961, 81.30829620361328, 81.30780792236328, 81.30713653564453,
  81.3064193725586, 81.30571746826172, 81.3050308227539, 81.30442810058594,
  81.30389404296875, 81.30327606201172, 81.30255889892578, 81.30183410644531,
  81.30106353759766, 81.30034637451172, 81.29959106445312, 81.29878997802734,
  81.29798889160156, 81.29721069335938, 81.29644012451172, 81.29564666748047,
  81.29483032226562, 81.29400634765625, 81.29312896728516, 81.29224395751953,
  81.29127502441406, 81.2903060913086, 81.28943634033203, 81.28856658935547,
  81.2875747680664, 81.28656768798828, 81.28559875488281, 81.28453063964844,
  81.28353881835938, 81.28273010253906, 81.2820053100586, 81.28118896484375,
  81.28028106689453, 81.2793960571289, 81.27845001220703, 81.27751159667969,
  81.27664184570312, 81.27579498291016, 81.2748031616211, 81.27396392822266,
  81.27305603027344, 81.27201843261719, 81.27104187011719, 81.27015686035156,
  81.26922607421875, 81.26830291748047, 81.2673110961914, 81.26638793945312,
  81.26531219482422, 81.26425170898438, 81.26324462890625, 81.26219940185547,
  81.26114654541016, 81.25999450683594, 81.25865936279297, 81.2574691772461,
  81.25641632080078, 81.25533294677734, 81.25406646728516, 81.25282287597656,
  81.25162506103516, 81.25018310546875, 81.24855041503906, 81.2470932006836,
  81.24581146240234, 81.24447631835938, 81.24305725097656, 81.24161529541016,
  81.24005126953125, 81.23845672607422, 81.23684692382812, 81.23519134521484,
  81.23355865478516, 81.23188018798828, 81.23023223876953, 81.22875213623047,
  81.22722625732422, 81.22560119628906, 81.22386932373047, 81.22209930419922,
  81.22037506103516, 81.21868896484375, 81.21692657470703, 81.21520233154297,
  81.2135009765625, 81.2118911743164, 81.21029663085938, 81.20855712890625,
  81.2065658569336, 81.20454406738281, 81.20256042480469, 81.20082092285156,
  81.19917297363281, 81.19754791259766, 81.1957778930664, 81.19393157958984,
  81.1921157836914, 81.1903305053711, 81.18854522705078, 81.1867904663086,
  81.1850357055664, 81.18318176269531, 81.18122863769531, 81.17930603027344,
  81.17741394042969, 81.17549896240234, 81.173583984375, 81.171630859375,
  81.16959381103516, 81.16749572753906, 81.16549682617188, 81.16348266601562,
  81.16139221191406, 81.15943908691406, 81.1576156616211, 81.15576934814453,
  81.15373229980469, 81.1515121459961, 81.14930725097656, 81.14730072021484,
  81.14542388916016, 81.14347839355469, 81.14147186279297, 81.13931274414062,
  81.13709259033203, 81.13494110107422, 81.13288116455078, 81.13089752197266,
  81.12879180908203, 81.12645721435547, 81.12400817871094, 81.12173461914062,
  81.11976623535156, 81.11760711669922, 81.11540222167969, 81.11323547363281,
  81.11128234863281, 81.10928344726562, 81.10704040527344, 81.10478973388672,
  81.10243225097656, 81.10025024414062, 81.09794616699219, 81.09590911865234,
  81.09355926513672, 81.09138488769531, 81.0893783569336, 81.08706665039062,
  81.08454895019531, 81.08209991455078, 81.0798568725586, 81.07764434814453,
  81.07546997070312, 81.07316589355469, 81.07090759277344, 81.06880187988281,
  81.06661987304688, 81.06438446044922, 81.062255859375, 81.0601806640625,
  81.05809020996094, 81.05587005615234, 81.05345153808594, 81.051025390625,
  81.04861450195312, 81.0461196899414, 81.04365539550781, 81.04139709472656,
  81.03926086425781, 81.0371322631836, 81.03495788574219, 81.03240203857422,
  81.02975463867188, 81.02718353271484, 81.02476501464844, 81.02238464355469,
  81.01979064941406, 81.01726531982422, 81.01500701904297, 81.0129623413086,
  81.0108413696289, 81.00852966308594, 81.00617218017578, 81.00374603271484,
  81.00096130371094, 80.99809265136719, 80.99530792236328, 80.9927749633789,
  80.9903793334961, 80.98778533935547, 80.98500061035156, 80.9822006225586,
  80.9795150756836, 80.97711944580078, 80.97466278076172, 80.97232818603516,
  80.97001647949219, 80.96786499023438, 80.9658432006836, 80.96334838867188,
  80.9605484008789, 80.95772552490234, 80.95486450195312, 80.9521484375,
  80.94927978515625, 80.94631958007812, 80.94380950927734, 80.94143676757812,
  80.93888854980469, 80.9358139038086, 80.93251037597656, 80.9292221069336,
  80.9262466430664, 80.92345428466797, 80.9206314086914, 80.9176254272461,
  80.91461181640625, 80.91177368164062, 80.9090347290039, 80.90643310546875,
  80.90375518798828, 80.90082550048828, 80.89788818359375, 80.89485931396484,
  80.89183044433594, 80.88877868652344, 80.8857650756836, 80.88289642333984,
  80.88004302978516, 80.87721252441406, 80.87444305419922, 80.87181854248047,
  80.86927032470703, 80.86676788330078, 80.86437225341797, 80.86219787597656,
  80.85993957519531, 80.85761260986328, 80.85523986816406, 80.8528823852539,
  80.85003662109375, 80.84729766845703, 80.8448257446289, 80.84268188476562,
  80.84038543701172, 80.83786010742188, 80.8350830078125, 80.83218383789062,
  80.82921600341797, 80.82624053955078, 80.82317352294922, 80.82013702392578,
  80.81729125976562, 80.81452178955078, 80.81189727783203, 80.80937194824219,
  80.8070297241211, 80.80472564697266, 80.80265045166016, 80.80055236816406,
  80.79802703857422, 80.7950210571289, 80.79196166992188, 80.78910064697266,
  80.7861557006836, 80.78311920166016, 80.78006744384766,
];
