import { Transition } from "@mantine/core";
import { MantineTransitionName } from "@mantine/core/lib/Transition/transitions";
import { useDebouncedState, useIntersection } from "@mantine/hooks";
import {
  CSSProperties,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
} from "react";
import useViewportResizeObserver from "../hooks/useViewportResize";

type styleCleanupFunc = (stlye: CSSProperties) => CSSProperties;

interface ISlideInElement {
  style: CSSProperties;
  ref: Ref<HTMLDivElement>;
}

interface SlideInComponentProps {
  transition?: MantineTransitionName;
  target?: any;
  delay?: number;
  styleCleanup?: styleCleanupFunc | null;
  duration?: number;
  threshold?: number;
  component: (element: ISlideInElement) => ReactElement<any, any>;
}

export default function SlideInComponent({
  transition = "slide-up",
  delay = 0,
  duration = 300,
  styleCleanup = null,
  component,
  target = null,
  threshold = 0.7,
}: SlideInComponentProps) {
  const [isMounted, setIsMounted] = useDebouncedState<any>(null, delay);
  const { ref, entry } = useIntersection({
    root: document,
    threshold: threshold,
  });

  const onResize = useCallback(
    (rect: any) => {
      if (!entry || !isMounted) return;

      const { boundingClientRect } = entry;
      const { y } = boundingClientRect;

      setIsMounted(y);
    },

    //eslint-disable-next-line
    [entry, isMounted]
  );

  useViewportResizeObserver({
    onResize,
  });

  useEffect(() => {
    if (!entry) return;

    const { isIntersecting, boundingClientRect } = entry;
    const { y } = boundingClientRect;

    setIsMounted((prev: any) => {
      if (prev === null) {
        if (isIntersecting) return y;

        return null;
      }

      if (!isIntersecting) {
        if (y <= prev) return prev;

        return null;
      }

      return prev;
    });
    //eslint-disable-next-line
  }, [entry]);

  useEffect(() => {
    if (!target) return;

    ref(target.current);

    //eslint-disable-next-line
  }, [target]);

  return (
    <Transition
      transition={transition}
      mounted={isMounted !== null}
      keepMounted
      duration={duration}
    >
      {(styles) => {
        const newStyle = styleCleanup ? styleCleanup(styles) : styles;

        return component({
          style: newStyle,
          ref: target ? null : ref,
        });
      }}
    </Transition>
  );
}
