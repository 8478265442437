import {
  Overlay,
  Container,
  createStyles,
  rem,
  Text,
  Header,
  Image,
} from "@mantine/core";
import heroGif from "../assets/heroGif.gif";
import DownloadButton from "./DownloadButton";
import logo from "../assets/logoFontWhite.png";

const useStyles = createStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
    position: "relative",

    [theme.fn.smallerThan("sm")]: {
      height: rem(500),
    },
  },

  title: {
    color: theme.white,
    fontSize: rem(60),
    fontWeight: 900,
    lineHeight: 1.1,

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(40),
      lineHeight: 1.2,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
      lineHeight: 1.3,
    },
  },

  description: {
    maxWidth: 600,

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
      fontSize: theme.fontSizes.sm,
    },
  },
}));

export default function AppHeader() {
  const { classes, theme } = useStyles();

  return (
    <Header
      style={{
        position: "relative",
        backgroundImage: `url(${heroGif})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      height={rem(700)}
    >
      <Overlay
        gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
        blur={11}
        zIndex={0}
      />
      <Container className={classes.container}>
        <Image
          src={logo}
          alt="Lockup Telemetry Logo"
          styles={(theme) => ({
            imageWrapper: {
              width: "60%",
              margin: `0 auto ${theme.spacing.md} auto`,
            },
          })}
        />

        <Text
          className={classes.description}
          size="xl"
          mt="xl"
          mb={`calc(${theme.spacing.xl} * 5)`}
          color="dimmed"
          ta="center"
        >
          Display important ingame information and analyse your telemetry of
          Assetto Corsa Competizione using Lockup Telemetry!
        </Text>
        <DownloadButton />
      </Container>
    </Header>
  );
}
