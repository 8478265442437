export const SPEED_DATA = [
  191.71929931640625, 191.9775390625, 192.24400329589844, 192.55841064453125,
  192.8308563232422, 193.06411743164062, 193.31637573242188, 193.58364868164062,
  193.84091186523438, 194.1108856201172, 194.40191650390625, 194.64279174804688,
  194.8994598388672, 195.1864471435547, 195.41539001464844, 195.69900512695312,
  195.91786193847656, 196.17623901367188, 196.4315948486328, 196.6856689453125,
  196.9381866455078, 197.18692016601562, 197.44107055664062, 197.69900512695312,
  197.96949768066406, 198.21221923828125, 198.45574951171875, 198.6798858642578,
  198.92404174804688, 199.15768432617188, 199.41107177734375,
  199.65977478027344, 199.91343688964844, 200.16513061523438,
  200.38612365722656, 200.63311767578125, 200.8773193359375, 201.11825561523438,
  201.35647583007812, 201.58995056152344, 201.81314086914062,
  202.03639221191406, 202.25755310058594, 202.48902893066406,
  202.73312377929688, 202.98135375976562, 203.2269287109375, 203.42588806152344,
  203.67930603027344, 203.884765625, 204.10855102539062, 204.3273162841797,
  204.553466796875, 204.7734375, 204.99525451660156, 205.21917724609375,
  205.44439697265625, 205.65725708007812, 205.8765411376953, 206.10025024414062,
  206.32638549804688, 206.54428100585938, 206.75625610351562,
  206.96754455566406, 207.17359924316406, 207.38360595703125, 207.5917205810547,
  207.8150177001953, 208.0287322998047, 208.2354278564453, 208.4359130859375,
  208.6396026611328, 208.83627319335938, 209.04205322265625, 209.25245666503906,
  209.46185302734375, 209.65443420410156, 209.86138916015625,
  210.05917358398438, 210.26058959960938, 210.45904541015625, 210.6617431640625,
  210.85147094726562, 211.04159545898438, 211.24014282226562, 211.4368438720703,
  211.624267578125, 211.82205200195312, 212.0121612548828, 212.19351196289062,
  212.37803649902344, 212.56863403320312, 212.7520294189453, 212.9312744140625,
  213.1272430419922, 213.30532836914062, 213.4919891357422, 213.66697692871094,
  213.85072326660156, 214.02740478515625, 214.20330810546875, 214.3733673095703,
  214.54904174804688, 214.71963500976562, 214.89718627929688,
  215.07907104492188, 215.2543182373047, 215.42913818359375, 215.59201049804688,
  215.76031494140625, 215.66355895996094, 215.48036193847656, 215.4732666015625,
  215.76251220703125, 216.02169799804688, 216.11495971679688, 216.3333740234375,
  216.54092407226562, 216.73536682128906, 216.90621948242188,
  217.11148071289062, 217.29678344726562, 217.50286865234375,
  217.68270874023438, 217.89947509765625, 218.07533264160156,
  218.27586364746094, 218.4678955078125, 218.66854858398438, 218.8308868408203,
  219.00804138183594, 219.19622802734375, 219.40577697753906,
  219.57977294921875, 219.76673889160156, 219.9427032470703, 220.1501922607422,
  220.31297302246094, 220.49949645996094, 220.6856689453125, 220.87522888183594,
  221.0577850341797, 221.235595703125, 221.4145965576172, 221.59849548339844,
  221.77911376953125, 221.9611358642578, 222.13487243652344, 222.3096466064453,
  222.48779296875, 222.6710205078125, 222.85446166992188, 223.01876831054688,
  223.20333862304688, 223.37921142578125, 223.55821228027344, 223.7321014404297,
  223.90768432617188, 224.0792999267578, 224.2578887939453, 224.4287109375,
  224.5960235595703, 224.76641845703125, 224.93821716308594, 225.11717224121094,
  225.28965759277344, 225.45933532714844, 225.62677001953125, 225.7949676513672,
  225.95431518554688, 226.12806701660156, 226.30099487304688,
  226.46665954589844, 226.6344757080078, 226.8045196533203, 226.99636840820312,
  227.1498260498047, 227.31455993652344, 227.48484802246094, 227.63104248046875,
  227.81759643554688, 227.96246337890625, 228.13235473632812,
  228.29754638671875, 228.4589385986328, 228.61630249023438, 228.7779083251953,
  228.93540954589844, 229.0995635986328, 229.2675323486328, 229.4269561767578,
  229.58895874023438, 229.75462341308594, 229.93260192871094, 230.0792694091797,
  230.23585510253906, 230.37826538085938, 230.5336456298828, 230.68923950195312,
  230.8467559814453, 231.00668334960938, 231.1611328125, 231.32232666015625,
  231.4736328125, 231.62118530273438, 231.76304626464844, 231.92071533203125,
  232.07843017578125, 232.22808837890625, 232.3798370361328, 232.53147888183594,
  232.6767120361328, 232.82467651367188, 232.9684295654297, 233.12429809570312,
  233.29400634765625, 233.43431091308594, 233.57498168945312,
  233.70709228515625, 233.8604736328125, 234.01113891601562, 234.1569366455078,
  234.3094482421875, 234.45285034179688, 234.5888671875, 234.74319458007812,
  234.88705444335938, 235.02493286132812, 235.17430114746094,
  235.32220458984375, 235.47207641601562, 235.6090850830078, 235.751708984375,
  235.86582946777344, 236.007080078125, 236.15737915039062, 236.2902069091797,
  236.44261169433594, 236.57884216308594, 236.72300720214844,
  236.86036682128906, 236.99871826171875, 237.12313842773438,
  237.25277709960938, 237.39794921875, 237.52548217773438, 237.6585235595703,
  237.79678344726562, 237.92958068847656, 238.05557250976562,
  238.19325256347656, 238.31845092773438, 238.4706573486328, 238.61183166503906,
  238.73336791992188, 238.8658447265625, 239.00009155273438, 239.1298065185547,
  239.2694549560547, 239.39553833007812, 239.51803588867188, 239.647216796875,
  239.77391052246094, 239.89012145996094, 240.00390625, 240.13233947753906,
  240.276123046875, 240.41354370117188, 240.536865234375, 240.6636199951172,
  240.78794860839844, 240.9146728515625, 241.04989624023438, 241.1781005859375,
  241.31082153320312, 241.4127655029297, 241.516845703125, 241.62359619140625,
  241.73263549804688, 241.85821533203125, 241.9835968017578, 242.1127166748047,
  242.21702575683594, 242.32232666015625, 242.43771362304688,
  242.57078552246094, 242.69482421875, 242.80836486816406, 242.92245483398438,
  243.03506469726562, 243.14845275878906, 243.26976013183594, 243.3876190185547,
  243.483642578125, 243.57391357421875, 243.66876220703125, 243.76077270507812,
  243.87088012695312, 243.9806671142578, 244.07553100585938, 244.17918395996094,
  244.2847137451172, 244.3917694091797, 244.50311279296875, 244.6247100830078,
  244.74549865722656, 244.8671417236328, 244.94325256347656, 244.85391235351562,
  244.4698028564453, 243.70762634277344, 242.34130859375, 240.76304626464844,
  239.12416076660156, 237.6110076904297, 236.02488708496094, 234.26683044433594,
  232.46429443359375, 230.53704833984375, 228.5242919921875, 226.36412048339844,
  224.52481079101562, 223.03179931640625, 221.56724548339844, 219.9735565185547,
  218.2584228515625, 216.57843017578125, 214.9717254638672, 213.37135314941406,
  211.87449645996094, 210.1558380126953, 208.5184326171875, 206.5467987060547,
  204.59169006347656, 202.78021240234375, 201.10073852539062,
  199.56732177734375, 198.23402404785156, 197.208740234375, 195.80052185058594,
  194.11483764648438, 192.26365661621094, 190.5207977294922, 188.90672302246094,
  187.26654052734375, 185.637451171875, 183.9843292236328, 182.3665008544922,
  180.72598266601562, 179.2620086669922, 177.73666381835938, 176.25332641601562,
  174.80589294433594, 173.50192260742188, 172.0775604248047, 170.60643005371094,
  169.28761291503906, 167.83694458007812, 166.25523376464844, 164.6675567626953,
  163.0876922607422, 161.50379943847656, 159.9737091064453, 158.5366668701172,
  157.0386962890625, 155.35513305664062, 153.82757568359375, 152.29017639160156,
  150.73983764648438, 149.09812927246094, 147.48159790039062,
  145.89205932617188, 144.21096801757812, 142.73094177246094,
  141.31546020507812, 140.29551696777344, 139.22535705566406,
  138.14610290527344, 136.97686767578125, 135.75997924804688,
  134.28021240234375, 132.60836791992188, 130.8320770263672, 129.09268188476562,
  127.48487091064453, 125.86482238769531, 124.36913299560547,
  122.97230529785156, 121.59030151367188, 120.22907257080078,
  118.89411926269531, 117.54303741455078, 116.23155975341797,
  114.90753173828125, 113.43952178955078, 112.26110076904297,
  110.92692565917969, 109.62709045410156, 108.47637176513672,
  107.20491027832031, 106.19612121582031, 105.16175842285156,
  104.12522888183594, 102.95777130126953, 101.71766662597656,
  100.46487426757812, 99.29914855957031, 98.24568939208984, 97.15502166748047,
  95.98686218261719, 94.85409545898438, 93.83545684814453, 92.80833435058594,
  91.77445983886719, 90.74339294433594, 89.73475646972656, 88.78765869140625,
  87.87574768066406, 86.99493408203125, 86.11627960205078, 85.2578353881836,
  84.4426040649414, 83.66932678222656, 82.92002868652344, 82.19182586669922,
  81.48246002197266, 80.78585052490234, 80.10105895996094, 79.4496841430664,
  78.80058288574219, 78.17082977294922, 77.55058288574219, 76.94432830810547,
  76.3597412109375, 75.76176452636719, 75.17697143554688, 74.58940887451172,
  74.00656127929688, 73.44058227539062, 72.89698028564453, 72.37227630615234,
  71.85796356201172, 71.35391998291016, 70.88270568847656, 70.43014526367188,
  69.97924041748047, 69.51327514648438, 69.04829406738281, 68.59029388427734,
  68.13252258300781, 67.67472076416016, 67.21208190917969, 66.76201629638672,
  66.32916259765625, 65.91181182861328, 65.50846099853516, 65.1111831665039,
  64.71890258789062, 64.3427963256836, 63.97031784057617, 63.612300872802734,
  63.276180267333984, 62.96162033081055, 62.679080963134766, 62.39645004272461,
  62.12858200073242, 61.89204788208008, 61.64553451538086, 61.38167953491211,
  61.124473571777344, 60.870304107666016, 60.622928619384766,
  60.391273498535156, 60.18048095703125, 59.96411895751953, 59.745361328125,
  59.542606353759766, 59.363433837890625, 59.227725982666016,
  59.110679626464844, 59.05378341674805, 59.04009246826172, 59.075557708740234,
  59.143070220947266, 59.25380325317383, 59.38875198364258, 59.5415153503418,
  59.73506164550781, 59.95431900024414, 60.17823028564453, 60.44111251831055,
  60.70771408081055, 60.9820556640625, 61.24889373779297, 61.573726654052734,
  61.942626953125, 62.332847595214844, 62.70126724243164, 63.053226470947266,
  63.43521499633789, 63.80594253540039, 64.18522644042969, 64.5589828491211,
  64.93030548095703, 65.30906677246094, 65.7046127319336, 66.12454986572266,
  66.54914855957031, 66.99498748779297, 67.4677734375, 67.95587921142578,
  68.4466781616211, 68.93729400634766, 69.4424057006836, 69.9545669555664,
  70.45021057128906, 70.95121765136719, 71.44718933105469, 71.96978759765625,
  72.50361633300781, 73.05542755126953, 73.48744201660156, 74.01305389404297,
  74.44963073730469, 74.91862487792969, 75.36207580566406, 75.79684448242188,
  76.27161407470703, 76.81242370605469, 77.25011444091797, 77.77627563476562,
  78.28196716308594, 78.72772979736328, 79.24268341064453, 79.76053619384766,
  80.26598358154297, 80.75886535644531, 81.2335433959961, 81.72807312011719,
  82.252197265625, 82.79912567138672, 83.36203002929688, 83.9230728149414,
  84.48953247070312, 85.07162475585938, 85.66949462890625, 86.27976989746094,
  86.9100341796875, 87.55384063720703, 88.21070098876953, 88.87619018554688,
  89.5447769165039, 90.20675659179688, 90.8650131225586, 91.47722625732422,
  92.12016296386719, 92.760986328125, 93.41978454589844, 94.07969665527344,
  94.74982452392578, 95.41081237792969, 96.04296112060547, 96.67166137695312,
  97.25325012207031, 97.83088684082031, 98.41133117675781, 98.98297119140625,
  99.59259796142578, 100.20000457763672, 100.7981948852539, 101.3835678100586,
  101.97798919677734, 102.57542419433594, 103.16190338134766,
  103.73844909667969, 104.30242156982422, 104.8648452758789, 105.42638397216797,
  105.98103332519531, 106.53622436523438, 107.069580078125, 107.58349609375,
  108.09033966064453, 108.59256744384766, 109.08909606933594,
  109.58026885986328, 110.06639099121094, 110.54158020019531, 111.0185317993164,
  111.49163055419922, 111.95805358886719, 112.40631103515625,
  112.84252166748047, 113.26068115234375, 113.20492553710938,
  113.07112884521484, 113.3609848022461, 113.81950378417969, 114.37654876708984,
  114.79003143310547, 115.26100158691406, 115.70909118652344,
  116.20352935791016, 116.69029235839844, 117.14311981201172,
  117.59695434570312, 118.04530334472656, 118.50155639648438,
  118.97067260742188, 119.44918060302734, 119.92961120605469,
  120.40956115722656, 120.89192962646484, 121.36235046386719,
  121.83003997802734, 122.313232421875, 122.80162811279297, 123.30696105957031,
  123.81179809570312, 124.30741882324219, 124.79621124267578,
  125.28417205810547, 125.77088928222656, 126.24898529052734,
  126.72575378417969, 127.204345703125, 127.68305969238281, 128.15647888183594,
  128.61422729492188, 129.0474395751953, 129.45907592773438, 129.86624145507812,
  130.2643585205078, 130.66539001464844, 131.06689453125, 131.46514892578125,
  131.8556365966797, 132.2322998046875, 132.6034698486328, 132.93528747558594,
  133.1764373779297, 133.3142852783203, 133.3726806640625, 133.35873413085938,
  133.28282165527344, 133.1459503173828, 132.9665985107422, 132.7633056640625,
  132.52919006347656, 132.274658203125, 132.01327514648438, 131.749267578125,
  131.47296142578125, 131.19944763183594, 130.92088317871094,
  130.62191772460938, 130.31581115722656, 129.99957275390625,
  129.66305541992188, 129.29803466796875, 128.93804931640625, 128.568603515625,
  128.1888427734375, 127.80819702148438, 127.43315124511719, 127.05733489990234,
  126.67385864257812, 126.2951889038086, 125.92607116699219, 125.57221984863281,
  125.2313003540039, 124.88688659667969, 124.54209899902344, 124.19999694824219,
  123.8609619140625, 123.51173400878906, 123.16873931884766, 122.81802368164062,
  122.47249603271484, 122.13285064697266, 121.78999328613281,
  121.45088958740234, 121.10997009277344, 120.77689361572266,
  120.44540405273438, 120.11235809326172, 119.77745056152344,
  119.44306945800781, 119.11022186279297, 118.77188873291016,
  118.42865753173828, 118.08849334716797, 117.74276733398438,
  117.40731811523438, 117.08748626708984, 116.7780990600586, 116.47559356689453,
  116.1895523071289, 115.92384338378906, 115.6678466796875, 115.41929626464844,
  115.19562530517578, 114.98399353027344, 114.78482055664062,
  114.59838104248047, 114.42000579833984, 114.25196838378906,
  114.08869171142578, 113.92764282226562, 113.76631927490234, 113.6061782836914,
  113.4599838256836, 113.3242416381836, 113.20891571044922, 113.09855651855469,
  113.01306915283203, 112.9692153930664, 112.96781921386719, 113.00822448730469,
  113.0661849975586, 113.1214828491211, 113.17558288574219, 113.23169708251953,
  113.3032455444336, 113.39058685302734, 113.48932647705078, 113.58185577392578,
  113.66852569580078, 113.76123809814453, 113.8524398803711, 113.95578002929688,
  114.08602142333984, 114.23761749267578, 114.37512969970703,
  114.49720001220703, 114.59441375732422, 114.69193267822266,
  114.79511260986328, 114.92594146728516, 115.05986785888672,
  115.18001556396484, 115.29408264160156, 115.4231948852539, 115.56254577636719,
  115.6913070678711, 115.78501892089844, 115.92144012451172, 116.11711883544922,
  116.31646728515625, 116.49270629882812, 116.62674713134766,
  116.77091979980469, 116.92318725585938, 117.10820007324219,
  117.33119201660156, 117.54581451416016, 117.75057983398438,
  117.94513702392578, 118.1279525756836, 118.32296752929688, 118.53301239013672,
  118.75347137451172, 118.97197723388672, 119.17552185058594,
  119.38262939453125, 119.5925521850586, 119.80793762207031, 120.0389404296875,
  120.23371124267578, 120.4471664428711, 120.64744567871094, 120.84583282470703,
  121.05828094482422, 121.2818374633789, 121.49790954589844, 121.70692443847656,
  121.93038940429688, 122.09677124023438, 122.2911376953125, 122.5027847290039,
  122.70530700683594, 122.94685363769531, 123.13658905029297,
  123.33413696289062, 123.5351333618164, 123.74951934814453, 123.9623794555664,
  124.17903900146484, 124.34587097167969, 124.53549194335938,
  124.74725341796875, 124.9791030883789, 125.21918487548828, 125.44974517822266,
  125.67872619628906, 125.91321563720703, 126.14016723632812,
  126.36758422851562, 126.5929183959961, 126.8216781616211, 127.05421447753906,
  127.28355407714844, 127.50159454345703, 127.72454833984375, 127.958251953125,
  128.177001953125, 128.3973846435547, 128.6074981689453, 128.80870056152344,
  129.0258026123047, 129.2495880126953, 129.46347045898438, 129.6668243408203,
  129.8633575439453, 130.07159423828125, 130.28164672851562, 130.49700927734375,
  130.71475219726562, 130.92185974121094, 131.12451171875, 131.34027099609375,
  131.56272888183594, 131.7748260498047, 131.9851837158203, 132.19337463378906,
  132.4058837890625, 132.60879516601562, 132.79803466796875, 133.00381469726562,
  133.1898651123047, 133.37554931640625, 133.56753540039062, 133.73385620117188,
  133.9099578857422, 134.13385009765625, 134.307373046875, 134.4991912841797,
  134.67559814453125, 134.84425354003906, 135.02398681640625,
  135.21632385253906, 135.413818359375, 135.6208953857422, 135.78924560546875,
  135.9727020263672, 136.11962890625, 136.2854766845703, 136.47735595703125,
  136.67185974121094, 136.82679748535156, 137.00164794921875,
  137.13763427734375, 137.35647583007812, 137.55911254882812,
  137.73684692382812, 137.926025390625, 138.17234802246094, 138.4047393798828,
  138.612060546875, 138.81610107421875, 139.0478973388672, 139.30160522460938,
  139.5764617919922, 139.8831787109375, 140.2479248046875, 140.62832641601562,
  141.0420379638672, 141.46241760253906, 141.86631774902344, 142.38186645507812,
  142.8682098388672, 143.28822326660156, 143.72547912597656, 144.19705200195312,
  144.6726837158203, 145.1074981689453, 145.51467895507812, 145.9206085205078,
  146.33018493652344, 146.7572479248047, 147.174072265625, 147.60643005371094,
  147.71505737304688, 147.6907501220703, 147.9171905517578, 148.28387451171875,
  148.94039916992188, 149.45928955078125, 149.81105041503906,
  150.26075744628906, 150.77337646484375, 151.22503662109375, 151.6237335205078,
  152.05516052246094, 152.51370239257812, 152.9482879638672, 153.3610382080078,
  153.77256774902344, 154.19366455078125, 154.6219482421875, 155.03842163085938,
  155.40736389160156, 155.7964630126953, 156.15135192871094, 156.5241241455078,
  156.88731384277344, 157.24359130859375, 157.6029815673828, 157.9493408203125,
  158.32945251464844, 158.68814086914062, 159.04953002929688,
  159.41004943847656, 159.79644775390625, 160.1617431640625, 160.52586364746094,
  160.89389038085938, 161.2637176513672, 161.54917907714844, 161.62831115722656,
  161.56222534179688, 161.38204956054688, 161.079345703125, 160.46458435058594,
  159.41094970703125, 157.98245239257812, 156.40841674804688, 154.7622833251953,
  153.1969451904297, 151.5967254638672, 149.97216796875, 148.48129272460938,
  147.0128173828125, 145.57962036132812, 144.2188720703125, 142.94735717773438,
  141.65090942382812, 140.26461791992188, 138.80320739746094,
  137.40333557128906, 135.96182250976562, 134.4354705810547, 132.86355590820312,
  131.41441345214844, 130.0750732421875, 128.79464721679688, 127.55622100830078,
  126.35084533691406, 125.1514663696289, 123.9713134765625, 122.82875061035156,
  121.70773315429688, 120.5762939453125, 119.44253540039062, 118.3652572631836,
  117.25426483154297, 116.09800720214844, 115.02088928222656,
  114.00523376464844, 113.02056884765625, 112.0569076538086, 111.10442352294922,
  110.1226577758789, 109.05699157714844, 107.83212280273438, 106.8439712524414,
  105.68710327148438, 104.67943572998047, 103.76200103759766, 102.7586898803711,
  101.80654907226562, 100.86373138427734, 99.97388458251953, 99.1126708984375,
  98.278076171875, 97.41900634765625, 96.56948852539062, 95.76258087158203,
  94.97884368896484, 94.24008178710938, 93.4793472290039, 92.80453491210938,
  92.16712951660156, 91.59309387207031, 91.02653503417969, 90.4846420288086,
  89.93132019042969, 89.369384765625, 88.79603576660156, 88.27984619140625,
  87.8321304321289, 87.29519653320312, 86.74969482421875, 86.25753021240234,
  85.75521087646484, 85.20512390136719, 84.69043731689453, 84.11842346191406,
  83.5967025756836, 83.14888000488281, 82.67244720458984, 82.1672592163086,
  81.64517211914062, 81.1192626953125, 80.64948272705078, 80.22087860107422,
  79.83296203613281, 79.45533752441406, 79.10637664794922, 78.77645111083984,
  78.45184326171875, 78.11778259277344, 77.7913589477539, 77.48460388183594,
  77.19192504882812, 76.91963195800781, 76.63528442382812, 76.35094451904297,
  76.10043334960938, 75.85359954833984, 75.64456176757812, 75.46174621582031,
  75.25701904296875, 75.07730865478516, 74.927490234375, 74.7732162475586,
  74.62385559082031, 74.5257339477539, 74.39042663574219, 74.30686950683594,
  74.18561553955078, 74.14407348632812, 74.11434173583984, 74.23523712158203,
  74.22566986083984, 74.21334838867188, 74.21285247802734, 74.2275619506836,
  74.32048034667969, 74.37874603271484, 74.42730712890625, 74.48796844482422,
  74.58259582519531, 74.67498016357422, 74.75419616699219, 74.79403686523438,
  74.8326187133789, 74.88052368164062, 74.95085906982422, 75.04159545898438,
  75.13658905029297, 75.2339096069336, 75.30753326416016, 75.35163116455078,
  75.40852355957031, 75.49551391601562, 75.62699890136719, 75.8084716796875,
  75.99114227294922, 76.21635437011719, 76.385498046875, 76.54383850097656,
  76.82533264160156, 77.01837921142578, 77.20447540283203, 77.53157043457031,
  77.71284484863281, 77.87156677246094, 78.1925277709961, 78.42620086669922,
  78.70836639404297, 79.04393005371094, 79.38601684570312, 79.58602142333984,
  79.93780517578125, 80.2973861694336, 80.5230484008789, 80.87718963623047,
  81.33470916748047, 81.44929504394531, 81.78245544433594, 82.18164825439453,
  82.47244262695312, 82.7271957397461, 83.03612518310547, 83.39970397949219,
  83.63027954101562, 83.91695404052734, 84.22618103027344, 84.49626922607422,
  84.80852508544922, 85.13948059082031, 85.47167205810547, 85.8140869140625,
  86.15044403076172, 86.5044174194336, 86.8836669921875, 87.29208374023438,
  87.72083282470703, 88.16393280029297, 88.622802734375, 89.08889770507812,
  89.57545471191406, 90.06102752685547, 90.55826568603516, 91.06747436523438,
  91.56584930419922, 92.09469604492188, 92.62001037597656, 93.15133666992188,
  93.70319366455078, 94.27779388427734, 94.83422088623047, 95.41179656982422,
  95.96592712402344, 96.54312133789062, 97.10140228271484, 97.63829803466797,
  98.19917297363281, 98.75969696044922, 99.32588958740234, 99.89395141601562,
  100.45255279541016, 101.01062774658203, 101.57325744628906,
  102.13652801513672, 102.69264221191406, 103.24066925048828, 103.78662109375,
  104.34141540527344, 104.89424133300781, 105.44700622558594,
  105.98544311523438, 106.52056884765625, 107.07311248779297,
  107.51629638671875, 108.00420379638672, 108.50019073486328,
  109.00147247314453, 109.48026275634766, 109.93597412109375,
  110.38713073730469, 110.83143615722656, 111.29454803466797,
  111.72634887695312, 112.0810317993164, 112.33268737792969, 112.50077056884766,
  112.5704116821289, 112.58061981201172, 112.491455078125, 112.32025909423828,
  112.08402252197266, 111.78327941894531, 111.40568542480469,
  110.98703002929688, 110.52283477783203, 110.13164520263672, 109.7040786743164,
  109.31157684326172, 108.9598159790039, 108.64382934570312, 108.34465026855469,
  108.04132080078125, 107.75169372558594, 107.47662353515625, 107.2078628540039,
  106.95309448242188, 106.71206665039062, 106.48776245117188,
  106.27072143554688, 106.06747436523438, 105.89087677001953,
  105.75331115722656, 105.66355895996094, 105.56249237060547,
  105.45314025878906, 105.3277587890625, 105.22508239746094, 105.18860626220703,
  105.40410614013672, 105.48812103271484, 105.59614562988281,
  105.62165832519531, 105.59949493408203, 105.58863067626953,
  105.64722442626953, 105.76310729980469, 105.88055419921875,
  106.03963470458984, 106.16986083984375, 106.22669982910156,
  106.25859832763672, 106.36296844482422, 106.5191650390625, 106.67900085449219,
  106.84272766113281, 107.0300521850586, 107.15603637695312, 107.2052993774414,
  107.21587371826172, 107.23788452148438, 107.26158905029297,
  107.42768859863281, 107.61914825439453, 107.64408874511719,
  107.66885375976562, 107.71813201904297, 107.71392059326172,
  107.63409423828125, 107.53710174560547, 107.52442169189453,
  107.57221984863281, 107.65461730957031, 107.71954345703125,
  107.77656555175781, 107.79288482666016, 107.8144302368164, 107.8592758178711,
  107.91693115234375, 107.99250793457031, 108.0513687133789, 108.10460662841797,
  108.13807678222656, 108.1326904296875, 108.11742401123047, 108.13346862792969,
  108.18341827392578, 108.22240447998047, 108.22743225097656, 108.1951675415039,
  108.14605712890625, 108.10302734375, 108.1044692993164, 108.1460952758789,
  108.22367095947266, 108.35096740722656, 108.52251434326172, 108.7088623046875,
  108.86141204833984, 108.97135925292969, 109.07672119140625,
  109.25529479980469, 109.5634994506836, 109.91851043701172, 110.25201416015625,
  110.59737396240234, 110.98374938964844, 111.4176254272461, 111.88477325439453,
  112.37982177734375, 112.88917541503906, 113.43855285644531,
  114.00342559814453, 114.56272888183594, 115.10842895507812,
  115.66742706298828, 116.20349884033203, 116.6799545288086, 117.20124053955078,
  117.72410583496094, 118.24826049804688, 118.8091812133789, 119.39141845703125,
  119.91023254394531, 120.41865539550781, 120.89019775390625,
  121.46334075927734, 122.07112121582031, 122.66107177734375,
  123.18750762939453, 123.64187622070312, 124.17127990722656,
  124.68209075927734, 125.29013061523438, 125.78463745117188,
  126.33631896972656, 126.78365325927734, 127.29153442382812,
  127.81974792480469, 128.34042358398438, 128.84823608398438,
  129.34481811523438, 129.83758544921875, 130.346923828125, 130.85154724121094,
  131.40847778320312, 131.8399200439453, 132.34698486328125, 132.81031799316406,
  133.25686645507812, 133.74301147460938, 134.22239685058594, 134.6998748779297,
  135.17535400390625, 135.63307189941406, 136.08343505859375, 136.5381622314453,
  136.98922729492188, 137.4479217529297, 137.90501403808594, 138.3534698486328,
  138.8043212890625, 139.2560577392578, 139.6979217529297, 140.16171264648438,
  140.5574188232422, 140.98512268066406, 141.48486328125, 141.89002990722656,
  142.3514404296875, 142.68743896484375, 143.05377197265625, 143.50547790527344,
  143.91180419921875, 144.33544921875, 144.73019409179688, 145.10997009277344,
  145.50131225585938, 145.90223693847656, 146.0658721923828, 145.98751831054688,
  146.08688354492188, 146.4119415283203, 147.04794311523438, 147.4965057373047,
  147.80308532714844, 148.1716766357422, 148.7096405029297, 149.1071014404297,
  149.5224151611328, 149.87619018554688, 150.3231658935547, 150.76516723632812,
  151.1503448486328, 151.5334930419922, 151.95977783203125, 152.41798400878906,
  152.80308532714844, 153.2102508544922, 153.60205078125, 154.00367736816406,
  154.42044067382812, 154.82801818847656, 155.21951293945312,
  155.62228393554688, 156.0218963623047, 156.42723083496094, 156.8296356201172,
  157.2310333251953, 157.6282196044922, 158.0211181640625, 158.41163635253906,
  158.80064392089844, 159.18777465820312, 159.57858276367188,
  159.96604919433594, 160.36666870117188, 160.75526428222656, 161.123046875,
  161.50521850585938, 161.8981170654297, 162.2901153564453, 162.6725616455078,
  163.038330078125, 163.4036102294922, 163.77903747558594, 164.15403747558594,
  164.5349578857422, 164.9159393310547, 165.28231811523438, 165.6389617919922,
  166.00221252441406, 166.364990234375, 166.7311553955078, 167.09222412109375,
  167.4552764892578, 167.81072998046875, 168.1631317138672, 168.50904846191406,
  168.8531951904297, 169.19873046875, 169.55088806152344, 169.8988037109375,
  170.2451934814453, 170.5827178955078, 170.92486572265625, 171.25628662109375,
  171.5962371826172, 171.9276885986328, 172.26243591308594, 172.60084533691406,
  172.93186950683594, 173.25625610351562, 173.5748291015625, 173.9027557373047,
  174.2220001220703, 174.5472869873047, 174.859619140625, 175.1743927001953,
  175.48915100097656, 175.79180908203125, 176.09896850585938,
  176.40676879882812, 176.7121124267578, 177.02047729492188, 177.32424926757812,
  177.62100219726562, 177.91851806640625, 178.20994567871094, 178.5337677001953,
  178.7895050048828, 179.08023071289062, 179.3660125732422, 179.64683532714844,
  179.6055908203125, 179.45956420898438, 179.53660583496094, 179.9358367919922,
  180.38656616210938, 180.6060028076172, 180.8536376953125, 181.22235107421875,
  181.53378295898438, 181.78076171875, 182.11895751953125, 182.40267944335938,
  182.69276428222656, 182.9909210205078, 183.345703125, 183.64401245117188,
  183.882568359375, 184.20347595214844, 184.51063537597656, 184.7783966064453,
  185.06980895996094, 185.36708068847656, 185.67066955566406,
  185.94635009765625, 186.22964477539062, 186.52520751953125,
  186.81153869628906, 187.07899475097656, 187.36984252929688, 187.6595458984375,
  187.95042419433594, 188.27169799804688, 188.539794921875, 188.84156799316406,
  189.12501525878906, 189.3751983642578, 189.6480255126953, 189.95733642578125,
  190.2637176513672, 190.52432250976562, 190.7675323486328, 191.0333251953125,
  191.30357360839844, 191.58529663085938, 191.87258911132812,
  192.16531372070312, 192.44674682617188, 192.70474243164062, 192.9834747314453,
  193.2659454345703, 193.5442352294922, 193.8144989013672, 194.08236694335938,
  194.3439483642578, 194.60369873046875, 194.8678741455078, 195.13340759277344,
  195.40557861328125, 195.67626953125, 195.93856811523438, 196.1912078857422,
  196.45327758789062, 196.7122802734375, 196.96890258789062, 197.2211151123047,
  197.4829559326172, 197.750732421875, 198.01931762695312, 198.25750732421875,
  198.50352478027344, 198.7600555419922, 199.01644897460938, 199.25588989257812,
  199.4925079345703, 199.7103271484375, 199.949951171875, 200.21261596679688,
  200.4842987060547, 200.74398803710938, 200.9884490966797, 201.20973205566406,
  201.45358276367188, 201.69546508789062, 201.9453887939453, 202.18255615234375,
  202.42245483398438, 202.6702880859375, 202.90182495117188, 203.1236572265625,
  203.36209106445312, 203.6058349609375, 203.8513641357422, 204.07765197753906,
  204.31393432617188, 204.54812622070312, 204.77642822265625,
  205.00601196289062, 205.23623657226562, 205.46900939941406, 205.6993408203125,
  205.92138671875, 206.1484375, 206.3808135986328, 206.62525939941406,
  206.86122131347656, 207.08534240722656, 207.29345703125, 207.53012084960938,
  207.7495880126953, 207.97732543945312, 208.1870574951172, 208.41705322265625,
  208.62173461914062, 208.75625610351562, 208.68533325195312, 208.4028778076172,
  207.94017028808594, 207.14646911621094, 205.83157348632812,
  204.29518127441406, 202.5438232421875, 200.7300567626953, 199.01902770996094,
  197.41461181640625, 195.8268585205078, 194.23297119140625, 192.58331298828125,
  190.9308319091797, 189.3806610107422, 187.91641235351562, 186.59703063964844,
  185.13075256347656, 183.70806884765625, 182.13499450683594, 180.6215057373047,
  179.23704528808594, 177.6648712158203, 176.2284393310547, 174.6190185546875,
  172.9840850830078, 171.31768798828125, 169.7294921875, 168.1766357421875,
  166.72607421875, 165.40451049804688, 164.18165588378906, 162.97311401367188,
  161.81141662597656, 160.7329864501953, 159.72679138183594, 158.70440673828125,
  157.77967834472656, 156.86529541015625, 156.0078125, 155.17324829101562,
  154.40097045898438, 153.69525146484375, 152.9490509033203, 152.3445587158203,
  151.69813537597656, 151.05020141601562, 150.41110229492188, 149.7842559814453,
  149.16921997070312, 148.57867431640625, 147.99063110351562, 147.4288330078125,
  146.89096069335938, 146.3802490234375, 145.90594482421875, 145.48204040527344,
  145.09523010253906, 144.74490356445312, 144.42608642578125,
  144.14071655273438, 143.8665313720703, 143.63485717773438, 143.406982421875,
  143.18649291992188, 142.9708251953125, 142.74948120117188, 142.522705078125,
  142.2967529296875, 142.0532989501953, 141.78952026367188, 141.5314178466797,
  141.2652130126953, 140.98611450195312, 140.70545959472656, 140.41091918945312,
  140.1287841796875, 139.8555908203125, 139.58718872070312, 139.33580017089844,
  139.08297729492188, 138.82554626464844, 138.5453643798828, 138.27894592285156,
  138.01548767089844, 137.78372192382812, 137.58428955078125, 137.3837890625,
  137.16810607910156, 136.9590301513672, 136.80850219726562, 136.68772888183594,
  136.5945587158203, 136.45962524414062, 136.3360137939453, 136.23550415039062,
  136.19749450683594, 136.1390380859375, 136.0841827392578, 136.03085327148438,
  135.9962615966797, 135.9652099609375, 135.93785095214844, 135.9277801513672,
  135.92184448242188, 135.9163818359375, 135.9174041748047, 135.95506286621094,
  136.02151489257812, 136.10067749023438, 136.20184326171875,
  136.27525329589844, 136.3444366455078, 136.4387664794922, 136.52883911132812,
  136.66134643554688, 136.82559204101562, 136.99673461914062, 137.1956329345703,
  137.3987579345703, 137.6080780029297, 137.81857299804688, 138.00567626953125,
  138.18304443359375, 138.3822021484375, 138.6210174560547, 138.83639526367188,
  139.0352325439453, 139.25192260742188, 139.46810913085938, 139.68576049804688,
  139.8945770263672, 140.09744262695312, 140.35247802734375, 140.57032775878906,
  140.81985473632812, 141.01998901367188, 141.25357055664062,
  141.52389526367188, 141.74559020996094, 142.03150939941406,
  142.27850341796875, 142.56353759765625, 142.88107299804688,
  143.19485473632812, 143.51950073242188, 143.84912109375, 144.1888427734375,
  144.55108642578125, 144.93246459960938, 145.33580017089844,
  145.73089599609375, 146.17063903808594, 146.53257751464844, 146.9207305908203,
  147.33160400390625, 147.7386016845703, 148.13287353515625, 148.5700225830078,
  148.9134979248047, 149.32406616210938, 149.7410125732422, 150.13140869140625,
  150.5366973876953, 150.92637634277344, 151.33592224121094, 151.74081420898438,
  152.13662719726562, 152.54478454589844, 152.93695068359375,
  153.35028076171875, 153.7743377685547, 154.2062225341797, 154.63177490234375,
  155.06016540527344, 155.4969482421875, 155.93890380859375, 156.3678436279297,
  156.8010711669922, 157.240234375, 157.63816833496094, 157.9076385498047,
  157.96824645996094, 157.84507751464844, 157.6044158935547, 157.22894287109375,
  156.5498504638672, 155.46365356445312, 154.06451416015625, 152.46084594726562,
  150.8750457763672, 149.32240295410156, 147.8756866455078, 146.42160034179688,
  144.9980926513672, 143.61520385742188, 142.08743286132812, 140.55792236328125,
  139.03421020507812, 137.49905395507812, 135.99417114257812, 134.5326385498047,
  133.2181854248047, 132.00987243652344, 130.7372589111328, 129.4440155029297,
  128.04701232910156, 126.6465835571289, 125.36923217773438, 124.12696838378906,
  122.7521743774414, 121.49564361572266, 120.29656982421875, 119.09507751464844,
  117.95162963867188, 116.87574005126953, 115.87745666503906,
  114.93326568603516, 113.9614486694336, 112.96630096435547, 112.09770202636719,
  111.24006652832031, 110.41281127929688, 109.59589385986328,
  108.84393310546875, 108.15091705322266, 107.50072479248047,
  106.90126037597656, 106.27378845214844, 105.7208023071289, 105.1952896118164,
  104.65773010253906, 104.11663055419922, 103.58817291259766,
  103.04866027832031, 102.5252685546875, 102.01438903808594, 101.52336120605469,
  101.06256866455078, 100.61328125, 100.13822937011719, 99.6501235961914,
  99.17330169677734, 98.71574401855469, 98.2614974975586, 97.82071685791016,
  97.37834167480469, 96.90850067138672, 96.44329833984375, 96.0702133178711,
  95.7354736328125, 95.40924835205078, 95.10123443603516, 94.78395080566406,
  94.46199798583984, 94.17261505126953, 93.88732147216797, 93.60606384277344,
  93.33565521240234, 93.0918960571289, 92.8631820678711, 92.67213439941406,
  92.50259399414062, 92.35063171386719, 92.20843505859375, 92.05945587158203,
  91.9613265991211, 91.90406799316406, 91.8572006225586, 91.82685852050781,
  91.77643585205078, 91.76094055175781, 91.79917907714844, 91.83375549316406,
  91.87313079833984, 91.91009521484375, 91.958984375, 92.00434875488281,
  92.0662612915039, 92.16180419921875, 92.29802703857422, 92.4762954711914,
  92.70381164550781, 92.90460968017578, 93.08383178710938, 93.28640747070312,
  93.44729614257812, 93.65945434570312, 93.91352844238281, 94.13308715820312,
  94.40945434570312, 94.67420959472656, 94.92825317382812, 95.14004516601562,
  95.33563232421875, 95.53829193115234, 95.79800415039062, 96.10252380371094,
  96.4186782836914, 96.71478271484375, 97.02729797363281, 97.33812713623047,
  97.6405258178711, 97.96309661865234, 98.29039001464844, 98.6375961303711,
  98.9907455444336, 99.3582992553711, 99.73571014404297, 100.11246490478516,
  100.50128936767578, 100.89460754394531, 101.29509735107422, 101.6985855102539,
  102.11186218261719, 102.55244445800781, 102.98088073730469,
  103.46253967285156, 103.84264373779297, 104.28186798095703, 104.7136459350586,
  105.14225769042969, 105.56975555419922, 105.97760009765625,
  106.39603424072266, 106.8161392211914, 107.25170135498047, 107.70703125,
  108.14482116699219, 108.62749481201172, 109.0343017578125, 109.50634002685547,
  109.95906066894531, 110.42491912841797, 110.89006805419922,
  111.36644744873047, 111.84748840332031, 112.3393325805664, 112.82763671875,
  113.3142318725586, 113.79325866699219, 114.26832580566406, 114.73065185546875,
  115.21139526367188, 115.68052673339844, 116.12791442871094,
  116.59215545654297, 117.0578384399414, 117.5359115600586, 117.99821472167969,
  118.42644500732422, 118.86788177490234, 119.33047485351562,
  119.81403350830078, 120.30005645751953, 120.7666015625, 121.24019622802734,
  121.71973419189453, 122.17655181884766, 122.66670227050781,
  123.19414520263672, 123.75951385498047, 124.3163833618164, 124.86357116699219,
  125.36791229248047, 125.8037109375, 126.19056701660156, 126.56399536132812,
  126.97970581054688, 127.5064697265625, 128.02557373046875, 128.54489135742188,
  129.05157470703125, 129.54957580566406, 130.04994201660156,
  130.56761169433594, 131.09300231933594, 131.6253662109375, 132.13526916503906,
  132.63226318359375, 133.1418914794922, 133.66246032714844, 134.19239807128906,
  134.71929931640625, 135.23941040039062, 135.7563934326172, 136.28399658203125,
  136.79510498046875, 137.3110809326172, 137.81573486328125, 138.3314666748047,
  138.82821655273438, 139.32945251464844, 139.8221435546875, 140.31663513183594,
  140.80880737304688, 141.29379272460938, 141.7592010498047, 142.2283172607422,
  142.70542907714844, 143.17799377441406, 143.64210510253906,
  144.10147094726562, 144.55213928222656, 144.9972381591797, 145.44212341308594,
  145.88734436035156, 146.33074951171875, 146.7650146484375, 147.1930694580078,
  147.65301513671875, 148.01455688476562, 148.4161376953125, 148.86703491210938,
  149.21514892578125, 149.60446166992188, 149.64947509765625,
  149.58189392089844, 149.81561279296875, 150.40283203125, 151.0402069091797,
  151.4706573486328, 151.77505493164062, 152.234619140625, 152.7764129638672,
  153.2315216064453, 153.63023376464844, 154.08016967773438, 154.5580596923828,
  154.99729919433594, 155.46646118164062, 155.85824584960938,
  156.31143188476562, 156.75131225585938, 157.1666259765625, 157.5955352783203,
  158.04232788085938, 158.48052978515625, 158.91188049316406,
  159.33090209960938, 159.75843811035156, 160.18846130371094,
  160.60955810546875, 161.03829956054688, 161.4614715576172, 161.87228393554688,
  162.28431701660156, 162.69862365722656, 163.1136932373047, 163.5253143310547,
  163.93251037597656, 164.33668518066406, 164.7406768798828, 165.13888549804688,
  165.54367065429688, 165.93899536132812, 166.33645629882812,
  166.73829650878906, 167.1343994140625, 167.52684020996094, 167.90682983398438,
  168.29849243164062, 168.69497680664062, 169.07615661621094, 169.4444580078125,
  169.8126678466797, 170.1834259033203, 170.56056213378906, 170.9505615234375,
  171.31690979003906, 171.68118286132812, 172.04490661621094,
  172.40794372558594, 172.77609252929688, 173.14443969726562,
  173.50274658203125, 173.86117553710938, 174.21536254882812,
  174.55987548828125, 174.91111755371094, 175.26339721679688,
  175.61578369140625, 175.96644592285156, 176.3110809326172, 176.64508056640625,
  176.98309326171875, 177.3096160888672, 177.6405029296875, 177.96490478515625,
  178.2989044189453, 178.62205505371094, 178.95315551757812, 179.2744140625,
  179.62635803222656, 179.89483642578125, 180.2117156982422, 180.51321411132812,
  180.82659912109375, 181.1659698486328, 181.4384002685547, 181.73373413085938,
  182.03407287597656, 182.3330078125, 182.65536499023438, 182.9257049560547,
  183.21353149414062, 183.39749145507812, 183.2979278564453, 183.2964630126953,
  183.53025817871094, 184.03138732910156, 184.3914337158203, 184.6292266845703,
  184.97215270996094, 185.35499572753906, 185.6586456298828, 185.9532928466797,
  186.291015625, 186.62738037109375, 186.93414306640625, 187.2665252685547,
  187.59823608398438, 187.91566467285156, 188.22926330566406,
  188.55836486816406, 188.87876892089844, 189.2005157470703, 189.51600646972656,
  189.82809448242188, 190.14468383789062, 190.46070861816406, 190.7763214111328,
  191.0815887451172, 191.3946990966797, 191.69534301757812, 192.0100555419922,
  192.3195343017578, 192.6177978515625, 192.9253692626953, 193.22618103027344,
  193.5292510986328, 193.82376098632812, 194.1260986328125, 194.4299774169922,
  194.72508239746094, 195.0323028564453, 195.33935546875, 195.6433868408203,
  195.93287658691406, 196.22425842285156, 196.50332641601562,
  196.80563354492188, 197.0978546142578, 197.3781280517578, 197.665283203125,
  197.9535369873047, 198.236083984375, 198.50791931152344, 198.8058319091797,
  199.0786895751953, 199.37025451660156, 199.6580810546875, 199.94100952148438,
  200.22113037109375, 200.49620056152344, 200.75100708007812, 201.0415802001953,
  201.30181884765625, 201.5882568359375, 201.86302185058594, 202.12135314941406,
  202.3979949951172, 202.6610870361328, 202.9267120361328, 203.19229125976562,
  203.4484405517578, 203.7161865234375, 203.98141479492188, 204.25027465820312,
  204.49180603027344, 204.74246215820312, 205.0025634765625, 205.258056640625,
  205.51136779785156, 205.7643280029297, 206.01556396484375, 206.25869750976562,
  206.50218200683594, 206.75540161132812, 207.0063018798828, 207.23785400390625,
  207.4972381591797, 207.72718811035156, 207.98846435546875, 208.2164764404297,
  208.45603942871094, 208.7024688720703, 208.95118713378906, 209.1987762451172,
  209.42630004882812, 209.67697143554688, 209.89895629882812,
  210.14089965820312, 210.366943359375, 210.6044158935547, 210.8513946533203,
  211.0836639404297, 211.31884765625, 211.5522003173828, 211.79356384277344,
  211.99124145507812, 212.24234008789062, 212.40684509277344,
  212.67601013183594, 212.85659790039062, 213.07095336914062,
  213.28363037109375, 213.51499938964844, 213.75625610351562, 213.9799041748047,
  214.186279296875, 214.4005126953125, 214.60317993164062, 214.80923461914062,
  214.99192810058594, 215.18399047851562, 215.39459228515625, 215.5912628173828,
  215.78121948242188, 215.98570251464844, 216.2041778564453, 216.39756774902344,
  216.58123779296875, 216.77285766601562, 216.9191131591797, 216.7740478515625,
  216.6583709716797, 216.7682342529297, 217.1025390625, 217.3179473876953,
  217.48873901367188, 217.75277709960938, 217.94656372070312, 218.1549835205078,
  218.35594177246094, 218.5986328125, 218.79238891601562, 219.01014709472656,
  219.23837280273438, 219.47203063964844, 219.64187622070312, 219.8369598388672,
  220.04087829589844, 220.27357482910156, 220.48402404785156,
  220.71189880371094, 220.93704223632812, 221.11146545410156,
  221.32882690429688, 221.513671875, 221.71949768066406, 221.93141174316406,
  222.12997436523438, 222.3245849609375, 222.5329132080078, 222.72164916992188,
  222.94580078125, 223.11268615722656, 223.3102264404297, 223.4978790283203,
  223.7000274658203, 223.89871215820312, 224.11309814453125, 224.2858123779297,
  224.47764587402344, 224.66868591308594, 224.8650665283203, 225.0552520751953,
  225.18060302734375, 225.14878845214844, 225.03402709960938, 224.8566436767578,
  224.45494079589844, 223.64328002929688, 222.32357788085938, 220.557373046875,
  218.74778747558594, 216.9286651611328, 214.91749572753906, 213.30459594726562,
  211.5116424560547, 209.73800659179688, 208.04562377929688, 206.1751708984375,
  204.22207641601562, 202.43165588378906, 200.73370361328125,
  199.05050659179688, 197.29949951171875, 195.41964721679688, 193.7074432373047,
  192.01287841796875, 190.39874267578125, 188.78623962402344,
  187.22091674804688, 185.8217315673828, 184.3785858154297, 182.82249450683594,
  181.00941467285156, 179.3935546875, 177.81787109375, 176.1959228515625,
  174.6575164794922, 173.0286102294922, 171.3589630126953, 169.81423950195312,
  168.3399658203125, 166.8747100830078, 165.48666381835938, 163.98733520507812,
  162.32843017578125, 160.7051239013672, 159.0553741455078, 157.49681091308594,
  155.98568725585938, 154.47705078125, 153.0636444091797, 151.6401824951172,
  150.23582458496094, 148.8539276123047, 147.3861083984375, 146.1300048828125,
  144.8730010986328, 143.73822021484375, 142.8620147705078, 141.734375,
  140.5645751953125, 139.49464416503906, 138.32847595214844, 137.1840057373047,
  136.03395080566406, 134.93447875976562, 133.9033203125, 132.83590698242188,
  131.72926330566406, 130.62432861328125, 129.58877563476562, 128.5858917236328,
  127.57949829101562, 126.49272155761719, 125.47489166259766, 124.5920181274414,
  123.67289733886719, 122.80944061279297, 122.00422668457031, 121.111572265625,
  120.25293731689453, 119.46674346923828, 118.67119598388672,
  117.79371643066406, 116.93531799316406, 116.12439727783203,
  115.33839416503906, 114.55602264404297, 113.73594665527344,
  112.95357513427734, 112.18205261230469, 111.43628692626953, 110.706298828125,
  109.97802734375, 109.24508666992188, 108.58757019042969, 108.01139068603516,
  107.46168518066406, 106.89862060546875, 106.38900756835938, 105.8559341430664,
  105.25363159179688, 104.5090560913086, 103.74742889404297, 103.05668640136719,
  102.44651794433594, 101.8491439819336, 101.25091552734375, 100.6429443359375,
  100.04173278808594, 99.48402404785156, 98.9578628540039, 98.45018768310547,
  97.93637084960938, 97.42876434326172, 96.92010498046875, 96.43270874023438,
  95.9641342163086, 95.5218505859375, 95.07321166992188, 94.61886596679688,
  94.17388916015625, 93.73863220214844, 93.314453125, 92.85755920410156,
  92.39317321777344, 91.96232604980469, 91.55608367919922, 91.13108825683594,
  90.70574951171875, 90.27871704101562, 89.86148071289062, 89.41659545898438,
  88.95071411132812, 88.40477752685547, 87.76531982421875, 87.08013153076172,
  86.41645050048828, 85.80382537841797, 85.19808197021484, 84.61382293701172,
  84.04106903076172, 83.47413635253906, 83.02594757080078, 82.62276458740234,
  82.24895477294922, 81.82962036132812, 81.39710998535156, 80.9962387084961,
  80.59471893310547, 80.20238494873047, 79.79866790771484, 79.40078735351562,
  79.01795196533203, 78.64527130126953, 78.2646484375, 77.88200378417969,
  77.54974365234375, 77.31608581542969, 77.05924224853516, 76.75602722167969,
  76.418701171875, 76.10362243652344, 75.81388092041016, 75.55957794189453,
  75.3233413696289, 75.09101867675781, 74.83065795898438, 74.56031799316406,
  74.3315200805664, 74.10272216796875, 73.91959381103516, 73.67880249023438,
  73.41930389404297, 73.1578140258789, 72.91445922851562, 72.70780944824219,
  72.54366302490234, 72.44786834716797, 72.44695281982422, 72.51721954345703,
  72.68106079101562, 72.8868637084961, 73.08904266357422, 73.31111145019531,
  73.54901123046875, 73.80191040039062, 74.09334564208984, 74.4127426147461,
  74.77644348144531, 75.17115020751953, 75.56487274169922, 75.96709442138672,
  76.40863800048828, 76.889892578125, 77.38639831542969, 77.91080474853516,
  78.45539093017578, 78.99878692626953, 79.54056549072266, 80.10684204101562,
  80.66808319091797, 81.25728607177734, 81.85126495361328, 82.4706039428711,
  83.03617095947266, 83.57463836669922, 84.11344909667969, 84.65740966796875,
  85.18475341796875, 85.67029571533203, 86.14703369140625, 86.6451416015625,
  87.16094207763672, 87.71277618408203, 88.25651550292969, 88.8012466430664,
  89.34588623046875, 89.89893341064453, 90.46796417236328, 91.0608139038086,
  91.66302490234375, 92.29183197021484, 92.94426727294922, 93.58546447753906,
  94.21038055419922, 94.83857727050781, 95.46575927734375, 96.03480529785156,
  96.65675354003906, 97.28394317626953, 97.910888671875, 98.51823425292969,
  99.10686492919922, 99.68595123291016, 100.26934814453125, 100.83293914794922,
  101.39774322509766, 101.97584533691406, 102.56619262695312,
  103.16936492919922, 103.74868774414062, 104.31196594238281,
  104.83660125732422, 105.36508178710938, 105.88124084472656,
  106.41138458251953, 106.90343475341797, 107.37920379638672, 107.8885269165039,
  108.38053131103516, 108.86967468261719, 109.34163665771484,
  109.81234741210938, 110.26679992675781, 110.6476058959961, 110.5030746459961,
  110.45056915283203, 110.83130645751953, 111.4365234375, 111.87322998046875,
  112.20475006103516, 112.59455108642578, 113.09346771240234,
  113.60948944091797, 114.08955383300781, 114.53763580322266,
  114.99056243896484, 115.45813751220703, 115.9376449584961, 116.42233276367188,
  116.88849639892578, 117.34202575683594, 117.78851318359375,
  118.25802612304688, 118.74223327636719, 119.23721313476562, 119.7197036743164,
  120.17395782470703, 120.6556167602539, 121.09191131591797, 121.56304168701172,
  122.05316925048828, 122.53999328613281, 123.0150375366211, 123.488525390625,
  123.96772003173828, 124.45958709716797, 124.96456146240234,
  125.48155212402344, 125.9970474243164, 126.49109649658203, 126.94659423828125,
  127.39434051513672, 127.8719711303711, 128.38241577148438, 128.896728515625,
  129.38873291015625, 129.85609436035156, 130.32554626464844,
  130.81195068359375, 131.30857849121094, 131.7849578857422, 132.2440185546875,
  132.69566345214844, 133.16111755371094, 133.623046875, 134.08969116210938,
  134.56016540527344, 135.0181884765625, 135.48306274414062, 135.9323272705078,
  136.38116455078125, 136.82823181152344, 137.27732849121094, 137.7288055419922,
  138.1583709716797, 138.5895233154297, 139.02981567382812, 139.4669952392578,
  139.90423583984375, 140.33135986328125, 140.74993896484375,
  141.17662048339844, 141.5775604248047, 141.98260498046875, 142.38388061523438,
  142.8012237548828, 143.219482421875, 143.61460876464844, 143.9881134033203,
  144.3601837158203, 144.7368927001953, 145.12010192871094, 145.5201873779297,
  145.8907012939453, 146.2550506591797, 146.61557006835938, 146.94700622558594,
  147.31707763671875, 147.6864471435547, 148.02308654785156, 148.39414978027344,
  148.66195678710938, 148.95303344726562, 149.3032684326172, 149.6433868408203,
  149.96316528320312, 150.26483154296875, 150.38632202148438,
  150.25550842285156, 150.3283233642578, 150.575439453125, 151.18026733398438,
  151.66244506835938, 151.9143829345703, 152.22714233398438, 152.67300415039062,
  153.1133270263672, 153.412841796875, 153.77743530273438, 154.1724090576172,
  154.54896545410156, 154.91908264160156, 155.25650024414062,
  155.62454223632812, 156.00926208496094, 156.38201904296875,
  156.74832153320312, 157.09234619140625, 157.42478942871094, 157.7821807861328,
  158.14825439453125, 158.50624084472656, 158.85365295410156, 159.1967315673828,
  159.55274963378906, 159.88525390625, 160.21994018554688, 160.56468200683594,
  160.9234161376953, 161.26589965820312, 161.58433532714844, 161.920654296875,
  162.26373291015625, 162.6022186279297, 162.9228515625, 163.24557495117188,
  163.58065795898438, 163.90721130371094, 164.22161865234375,
  164.54226684570312, 164.85671997070312, 165.17771911621094,
  165.49986267089844, 165.80613708496094, 166.1187286376953, 166.4276123046875,
  166.73863220214844, 167.0543670654297, 167.3568878173828, 167.65084838867188,
  167.94735717773438, 168.25888061523438, 168.55459594726562,
  168.84939575195312, 169.1485595703125, 169.44723510742188, 169.7371063232422,
  170.01927185058594, 170.3101806640625, 170.5972442626953, 170.88525390625,
  171.17074584960938, 171.4457244873047, 171.735595703125, 172.0111846923828,
  172.2825469970703, 172.58480834960938, 172.81968688964844, 173.09854125976562,
  173.36016845703125, 173.63194274902344, 173.9011993408203, 174.16368103027344,
  174.43612670898438, 174.6854705810547, 174.987060546875, 175.222412109375,
  175.4618682861328, 175.7167205810547, 175.97572326660156, 176.2288055419922,
  176.471923828125, 176.71621704101562, 176.95452880859375, 177.1950225830078,
  177.4325714111328, 177.66629028320312, 177.90196228027344, 178.14332580566406,
  178.38673400878906, 178.5998077392578, 178.82154846191406, 179.04171752929688,
  179.2670135498047, 179.49176025390625, 179.715576171875, 179.93551635742188,
  180.1509552001953, 180.37286376953125, 180.59292602539062, 180.7996826171875,
  180.98875427246094, 181.20098876953125, 181.4172821044922, 181.6065216064453,
  181.8100128173828, 181.76553344726562, 181.5678253173828, 181.55227661132812,
  181.77308654785156, 182.1708221435547, 182.31129455566406, 182.49591064453125,
  182.791259765625, 183.0852508544922, 183.25222778320312, 183.45535278320312,
  183.732666015625, 183.9595947265625, 184.17552185058594, 184.41009521484375,
  184.62088012695312, 184.82557678222656, 185.02999877929688, 185.2572021484375,
  185.47732543945312, 185.70095825195312, 185.9008026123047, 186.10003662109375,
  186.29745483398438, 186.5046844482422, 186.6925048828125, 186.86878967285156,
  187.05686950683594, 187.23574829101562, 187.42050170898438,
  187.59043884277344, 187.7737274169922, 187.96945190429688, 188.1805419921875,
  188.4085235595703, 188.55178833007812, 188.67135620117188, 188.82357788085938,
  188.9813690185547, 189.11233520507812, 189.25503540039062, 189.4225616455078,
  189.62437438964844, 189.79196166992188, 189.90472412109375, 189.9673614501953,
  189.98818969726562, 189.95472717285156, 189.9077911376953, 189.83328247070312,
  189.75535583496094, 189.6506805419922, 189.53541564941406, 189.42227172851562,
  189.30609130859375, 189.1571502685547, 189.0104522705078, 188.81964111328125,
  188.66751098632812, 188.49566650390625, 188.30718994140625,
  188.16061401367188, 187.99058532714844, 187.84458923339844, 187.667724609375,
  187.48760986328125, 187.30368041992188, 187.09532165527344,
  186.92430114746094, 186.74244689941406, 186.56480407714844,
  186.34640502929688, 186.15255737304688, 185.92904663085938,
  185.70620727539062, 185.50018310546875, 185.30653381347656, 185.11279296875,
  184.959716796875, 184.76974487304688, 184.56541442871094, 184.39483642578125,
  184.21319580078125, 184.03392028808594, 183.8941192626953, 183.7474365234375,
  183.61944580078125, 183.49476623535156, 183.3833465576172, 183.26263427734375,
  183.17991638183594, 183.12109375, 183.0597381591797, 182.96963500976562,
  182.9034881591797, 182.83998107910156, 182.77455139160156, 182.7306671142578,
  182.68081665039062, 182.61473083496094, 182.53079223632812, 182.4364013671875,
  182.33209228515625, 182.2007293701172, 182.08140563964844, 181.9907684326172,
  181.91578674316406, 181.75714111328125, 181.69967651367188,
  181.66494750976562, 181.56283569335938, 181.46163940429688,
  181.31922912597656, 181.13926696777344, 181.04873657226562,
  181.03639221191406, 181.08554077148438, 181.1739501953125, 181.2505340576172,
  181.31796264648438, 181.3820037841797, 181.50596618652344, 181.62860107421875,
  181.7877655029297, 181.962646484375, 182.158447265625, 182.34474182128906,
  182.5227813720703, 182.71499633789062, 182.89065551757812, 183.06121826171875,
  183.24644470214844, 183.41458129882812, 183.58328247070312, 183.7522430419922,
  183.92881774902344, 184.10086059570312, 184.27328491210938,
  184.44769287109375, 184.61581420898438, 184.7990264892578, 184.9884033203125,
  185.1634521484375, 185.31015014648438, 185.48565673828125, 185.68228149414062,
  185.8764190673828, 186.054443359375, 186.25120544433594, 186.4438018798828,
  186.6225128173828, 186.84759521484375, 187.05674743652344, 187.24708557128906,
  187.4475860595703, 187.68801879882812, 187.9285888671875, 188.1536865234375,
  188.35983276367188, 188.53948974609375, 188.72467041015625,
  188.92860412597656, 189.10838317871094, 189.27853393554688, 189.4337158203125,
  189.6232147216797, 189.82455444335938, 190.0174102783203, 190.14959716796875,
  190.278564453125, 190.42984008789062, 190.57386779785156, 190.70811462402344,
  190.8330841064453, 190.98077392578125, 191.11209106445312, 191.2370147705078,
  191.39007568359375, 191.56988525390625, 191.7499542236328, 191.89756774902344,
  192.03347778320312, 192.19281005859375, 192.35475158691406, 192.4821319580078,
  192.59237670898438, 192.70779418945312, 192.82041931152344,
  192.95864868164062, 193.08779907226562, 193.2314910888672, 193.3710174560547,
  193.517578125, 193.66429138183594, 193.80392456054688, 193.9331512451172,
  194.067138671875, 194.196044921875, 194.33511352539062, 194.4783935546875,
  194.6326141357422, 194.80897521972656, 194.97589111328125, 195.12403869628906,
  195.29470825195312, 195.4687957763672, 195.62603759765625, 195.79840087890625,
  195.961669921875, 196.142578125, 196.31771850585938, 196.4799041748047,
  196.6472625732422, 196.81752014160156, 196.98475646972656, 197.14942932128906,
  197.30043029785156, 197.45126342773438, 197.59310913085938,
  197.73866271972656, 197.89138793945312, 198.08236694335938, 198.2671661376953,
  198.4564666748047, 198.6216278076172, 198.78341674804688, 198.9705047607422,
  199.16542053222656, 199.3220977783203, 199.50717163085938, 199.6531524658203,
  199.83140563964844, 200.02565002441406, 200.22064208984375, 200.4087371826172,
  200.58035278320312, 200.75445556640625, 200.9451141357422, 201.12225341796875,
  201.2852783203125, 201.45620727539062, 201.64190673828125, 201.8246307373047,
  202.00743103027344, 202.19033813476562, 202.36563110351562,
  202.53060913085938, 202.7050018310547, 202.8861846923828, 203.05783081054688,
  203.23513793945312, 203.41702270507812, 203.60377502441406,
  203.76715087890625, 203.93374633789062, 204.10992431640625,
  204.28057861328125, 204.44601440429688, 204.61331176757812,
  204.79954528808594, 204.97210693359375, 205.15542602539062,
  205.32928466796875, 205.50636291503906, 205.68289184570312, 205.8642120361328,
  206.0462188720703, 206.224365234375, 206.40003967285156, 206.57237243652344,
  206.74021911621094, 206.90704345703125, 207.0672607421875, 207.23841857910156,
  207.4023895263672, 207.57537841796875, 207.770263671875, 207.9475555419922,
  208.10806274414062, 208.2616424560547, 208.43345642089844, 208.6004180908203,
  208.77291870117188, 208.94015502929688, 209.09754943847656,
  209.27052307128906, 209.41384887695312, 209.60581970214844,
  209.75677490234375, 209.9241943359375, 210.09156799316406, 210.24688720703125,
  210.39743041992188, 210.54718017578125, 210.71792602539062,
  210.88157653808594, 211.04881286621094, 211.19912719726562,
  211.34771728515625, 211.50830078125, 211.68165588378906, 211.82472229003906,
  211.98321533203125, 212.12953186035156, 212.27516174316406,
  212.43508911132812, 212.5899658203125, 212.72900390625, 212.8914794921875,
  213.0373992919922, 213.18235778808594, 213.0230712890625, 212.80584716796875,
  212.78851318359375, 213.0459747314453, 213.27081298828125, 213.3507537841797,
  213.53680419921875, 213.73440551757812, 213.87933349609375,
  214.03256225585938, 214.2317657470703, 214.38250732421875, 214.5511932373047,
  214.741943359375, 214.8850555419922, 215.04208374023438, 215.2053680419922,
  215.36758422851562, 215.54769897460938, 215.6856689453125, 215.85018920898438,
  216.0138702392578, 216.1792755126953, 216.3391876220703, 216.50396728515625,
  216.66554260253906, 216.82550048828125, 216.9881134033203, 217.1498565673828,
  217.31617736816406, 217.47283935546875, 217.63958740234375, 217.7903289794922,
  217.94773864746094, 218.11155700683594, 218.2693328857422, 218.422119140625,
  218.5751190185547, 218.7310028076172, 218.87637329101562, 219.05331420898438,
  219.21286010742188, 219.37826538085938, 219.51837158203125, 219.6737060546875,
  219.81639099121094, 219.96969604492188, 220.1210479736328, 220.27786254882812,
  220.43341064453125, 220.59228515625, 220.7486572265625, 220.89878845214844,
  221.0630340576172, 221.1935272216797, 221.35572814941406, 221.4880828857422,
  221.63827514648438, 221.80059814453125, 221.96128845214844,
  222.08135986328125, 222.21876525878906, 222.3762664794922, 222.53421020507812,
  222.69061279296875, 222.8284149169922, 222.9643096923828, 223.12164306640625,
  223.28367614746094, 223.3992156982422, 223.55393981933594, 223.73397827148438,
  223.8752899169922, 223.98831176757812, 224.10035705566406, 224.22288513183594,
  224.34849548339844, 224.48670959472656, 224.6788787841797, 224.8526611328125,
  225.00367736816406, 225.1248321533203, 225.20736694335938, 225.36856079101562,
  225.5307159423828, 225.64447021484375, 225.7582550048828, 225.91546630859375,
  226.0706329345703, 226.224365234375, 226.388427734375, 226.54164123535156,
  226.6833038330078, 226.8045196533203, 226.932373046875, 227.01736450195312,
  226.95106506347656, 226.6229705810547, 225.8869171142578, 224.54791259765625,
  222.86488342285156, 221.1575469970703, 219.42796325683594, 217.65699768066406,
  215.97557067871094, 214.226318359375, 212.345947265625, 210.44683837890625,
  208.46835327148438, 206.43980407714844, 204.51742553710938, 202.7156219482422,
  201.07395935058594, 199.5408477783203, 198.01377868652344, 196.53236389160156,
  195.000244140625, 193.46600341796875, 192.0658416748047, 190.62355041503906,
  189.1143341064453, 187.48692321777344, 185.81919860839844, 184.17037963867188,
  182.4790496826172, 180.90269470214844, 179.37081909179688, 178.0550079345703,
  176.75579833984375, 175.4234161376953, 174.02113342285156, 172.47804260253906,
  171.06690979003906, 169.677978515625, 168.2884063720703, 166.8939971923828,
  165.5591278076172, 164.24685668945312, 162.9251251220703, 161.6144561767578,
  160.41127014160156, 159.2314453125, 158.06768798828125, 156.932373046875,
  155.77880859375, 154.6634979248047, 153.6319122314453, 152.61563110351562,
  151.5984344482422, 150.63059997558594, 149.67446899414062, 148.71234130859375,
  147.78985595703125, 146.86993408203125, 145.99720764160156,
  145.13670349121094, 144.24212646484375, 143.3853302001953, 142.59825134277344,
  141.85060119628906, 141.10911560058594, 140.377685546875, 139.67086791992188,
  138.96226501464844, 138.2965087890625, 137.69082641601562, 137.11109924316406,
  136.57948303222656, 136.0846405029297, 135.63633728027344, 135.20423889160156,
  134.7719268798828, 134.3667449951172, 133.98789978027344, 133.61544799804688,
  133.2439422607422, 132.86572265625, 132.4850616455078, 132.1204376220703,
  131.76351928710938, 131.42742919921875, 131.0916748046875, 130.74954223632812,
  130.4119415283203, 130.04229736328125, 129.66302490234375, 129.2672119140625,
  128.86004638671875, 128.47373962402344, 128.08470153808594,
  127.69959259033203, 127.31563568115234, 126.97490692138672,
  126.68366241455078, 126.3973617553711, 126.10406494140625, 125.7875747680664,
  125.46908569335938, 125.14181518554688, 124.8004379272461, 124.45836639404297,
  124.126953125, 123.75719451904297, 123.45149993896484, 123.1091079711914,
  122.72332763671875, 122.40951538085938, 122.06126403808594,
  121.72652435302734, 121.3821029663086, 121.04448699951172, 120.69707489013672,
  120.33548736572266, 119.97740173339844, 119.63562774658203,
  119.29774475097656, 118.97915649414062, 118.68077850341797,
  118.38481140136719, 118.0985336303711, 117.82318878173828, 117.54966735839844,
  117.27397918701172, 116.99015045166016, 116.71307373046875,
  116.45025634765625, 116.1873779296875, 115.9311752319336, 115.68739318847656,
  115.44570922851562, 115.2849349975586, 115.16057586669922, 115.099609375,
  115.10424041748047, 115.15552520751953, 115.23861694335938,
  115.32808685302734, 115.4255599975586, 115.52996063232422, 115.66092681884766,
  115.81998443603516, 116.00953674316406, 116.22333526611328,
  116.46077728271484, 116.71971130371094, 116.9828872680664, 117.23992156982422,
  117.48031616210938, 117.72661590576172, 118.04862976074219,
  118.40010833740234, 118.76993560791016, 119.1407241821289, 119.48970794677734,
  119.8594741821289, 120.23332977294922, 120.6302490234375, 121.03150939941406,
  121.42000579833984, 121.81522369384766, 122.21971130371094, 122.6303939819336,
  123.04232788085938, 123.43342590332031, 123.81204223632812,
  124.18746185302734, 124.59558868408203, 125.02178955078125,
  125.43737030029297, 125.82181549072266, 126.1996078491211, 126.60311889648438,
  127.0316390991211, 127.47684478759766, 127.89778900146484, 128.31393432617188,
  128.71649169921875, 129.10057067871094, 129.46688842773438,
  129.87765502929688, 130.30030822753906, 130.7733612060547, 131.188232421875,
  131.59408569335938, 131.97543334960938, 132.40057373046875,
  132.81045532226562, 133.2252655029297, 133.6577606201172, 134.07859802246094,
  134.5284423828125, 134.95547485351562, 135.39642333984375, 135.84652709960938,
  136.28970336914062, 136.73190307617188, 137.180419921875, 137.6304473876953,
  138.0744171142578, 138.5059051513672, 138.93031311035156, 139.3532257080078,
  139.7776336669922, 140.19985961914062, 140.6161651611328, 141.0201873779297,
  141.4192352294922, 141.81663513183594, 142.21099853515625, 142.6060333251953,
  142.98995971679688, 143.32752990722656, 143.6512908935547, 144.001953125,
  144.3544464111328, 144.70068359375, 145.029296875, 145.3491668701172,
  145.68264770507812, 146.02227783203125, 146.34951782226562,
  146.67575073242188, 147.0155792236328, 147.35562133789062, 147.6465301513672,
  147.94068908691406, 148.23448181152344, 148.5542449951172, 148.8901824951172,
  149.1968231201172, 149.53248596191406, 149.869384765625, 150.18133544921875,
  150.47723388671875, 150.76966857910156, 151.08624267578125, 151.4222412109375,
  151.74691772460938, 152.07510375976562, 152.4054412841797, 152.73223876953125,
  153.0535430908203, 153.3721466064453, 153.71881103515625, 154.0033721923828,
  154.2519989013672, 154.37933349609375, 154.35855102539062, 154.2050323486328,
  153.95309448242188, 153.62380981445312, 153.22891235351562,
  152.73683166503906, 152.11019897460938, 151.3780059814453, 150.5272674560547,
  149.6243438720703, 148.6748809814453, 147.65159606933594, 146.60545349121094,
  145.55543518066406, 144.50485229492188, 143.45713806152344,
  142.43316650390625, 141.3981475830078, 140.39370727539062, 139.43417358398438,
  138.50054931640625, 137.68698120117188, 136.9679412841797, 136.37387084960938,
  135.8220977783203, 135.33106994628906, 134.9142303466797, 134.55453491210938,
  134.25112915039062, 134.00106811523438, 133.78150939941406,
  133.56466674804688, 133.3509063720703, 133.14027404785156, 132.92398071289062,
  132.71377563476562, 132.4883270263672, 132.27423095703125, 132.06124877929688,
  131.84873962402344, 131.6347198486328, 131.41676330566406, 131.18592834472656,
  130.95201110839844, 130.72193908691406, 130.51431274414062,
  130.32582092285156, 130.1488037109375, 129.99508666992188, 129.86351013183594,
  129.7475128173828, 129.64804077148438, 129.54794311523438, 129.4505615234375,
  129.32449340820312, 129.2104034423828, 129.0858917236328, 128.9899444580078,
  128.93284606933594, 128.88185119628906, 128.8158721923828, 128.71279907226562,
  128.6364288330078, 128.62298583984375, 128.62762451171875, 128.6211395263672,
  128.58859252929688, 128.5506134033203, 128.5933380126953, 128.6630859375,
  128.69827270507812, 128.73040771484375, 128.7995147705078, 128.8813018798828,
  128.97100830078125, 129.02511596679688, 129.0871124267578, 129.17767333984375,
  129.2345428466797, 129.26988220214844, 129.27728271484375, 129.30770874023438,
  129.3549346923828, 129.38685607910156, 129.43214416503906, 129.5082244873047,
  129.60450744628906, 129.69729614257812, 129.7484893798828, 129.80284118652344,
  129.8478240966797, 129.88050842285156, 129.87733459472656, 129.84835815429688,
  129.79769897460938, 129.72726440429688, 129.65118408203125, 129.6173858642578,
  129.6614990234375, 129.76742553710938, 129.90548706054688, 130.08444213867188,
  130.31393432617188, 130.55165100097656, 130.78392028808594,
  131.01914978027344, 131.27259826660156, 131.53465270996094, 131.79248046875,
  132.05447387695312, 132.32913208007812, 132.6161346435547, 132.88885498046875,
  133.15359497070312, 133.4254913330078, 133.70831298828125, 134.0044403076172,
  134.3065643310547, 134.59861755371094, 134.8817901611328, 135.15097045898438,
  135.41024780273438, 135.6831817626953, 135.9578857421875, 136.23199462890625,
  136.5247039794922, 136.81813049316406, 137.12306213378906, 137.43775939941406,
  137.74862670898438, 138.0828399658203, 138.42764282226562, 138.77932739257812,
  139.12066650390625, 139.4495391845703, 139.78765869140625, 140.15789794921875,
  140.52378845214844, 140.87425231933594, 141.20445251464844,
  141.53875732421875, 141.8934326171875, 142.30642700195312, 142.67050170898438,
  143.01702880859375, 143.47479248046875, 143.86387634277344, 144.3245391845703,
  144.69161987304688, 145.11843872070312, 145.5454559326172, 145.98196411132812,
  146.43569946289062, 146.91323852539062, 147.39169311523438,
  147.85008239746094, 148.31263732910156, 148.7777557373047, 149.25062561035156,
  149.70346069335938, 150.1345977783203, 150.59786987304688, 151.0680694580078,
  151.5205078125, 151.97706604003906, 152.4259796142578, 152.8848419189453,
  153.34915161132812, 153.8031768798828, 154.25013732910156, 154.70556640625,
  155.15029907226562, 155.61012268066406, 156.06344604492188,
  156.51593017578125, 156.96527099609375, 157.40431213378906, 157.8424072265625,
  158.2870330810547, 158.7337188720703, 159.17819213867188, 159.61376953125,
  160.04583740234375, 160.4785614013672, 160.91526794433594, 161.3379669189453,
  161.76222229003906, 162.2013702392578, 162.62396240234375, 163.03878784179688,
  163.45118713378906, 163.86244201660156, 164.3341522216797, 164.7070770263672,
  165.1706085205078, 165.5323028564453, 165.9217987060547, 166.33853149414062,
  166.7572479248047, 167.16978454589844, 167.5606231689453, 167.94857788085938,
  168.3328857421875, 168.7225799560547, 169.0980987548828, 169.50941467285156,
  169.84339904785156, 170.2396240234375, 170.62542724609375, 171.0063934326172,
  171.3783721923828, 171.74801635742188, 172.1273193359375, 172.5105438232422,
  172.87542724609375, 173.23300170898438, 173.5870819091797, 173.945068359375,
  174.30702209472656, 174.66029357910156, 175.02841186523438, 175.3953399658203,
  175.75013732910156, 176.0216827392578, 176.3759765625, 176.73175048828125,
  177.0830535888672, 177.408447265625, 177.7340545654297, 178.06967163085938,
  178.39266967773438, 178.71669006347656, 179.04025268554688, 179.3746795654297,
  179.68869018554688, 179.98941040039062, 180.2898712158203, 180.6051025390625,
  180.91534423828125, 181.21681213378906, 181.1160888671875, 181.02915954589844,
  181.21409606933594, 181.65646362304688, 182.0896453857422, 182.33096313476562,
  182.63580322265625, 183.03480529785156, 183.36520385742188,
  183.66256713867188, 184.00741577148438, 184.34857177734375, 184.657470703125,
  184.9781494140625, 185.3018341064453, 185.62083435058594, 185.92922973632812,
  186.25192260742188, 186.5733642578125, 186.90419006347656, 187.20953369140625,
  187.5341796875, 187.8590850830078, 188.1776885986328, 188.4800567626953,
  188.77919006347656, 189.09262084960938, 189.3988494873047, 189.70895385742188,
  190.0106658935547, 190.3061981201172, 190.616943359375, 190.93304443359375,
  191.24281311035156, 191.5326690673828, 191.81752014160156, 192.1103515625,
  192.40122985839844, 192.7176971435547, 193.0341796875, 193.32313537597656,
  193.59136962890625, 193.88589477539062, 194.18875122070312,
  194.46658325195312, 194.7525634765625, 195.02542114257812, 195.30279541015625,
  195.57949829101562, 195.8888397216797, 196.1680145263672, 196.44912719726562,
  196.7314453125, 197.02978515625, 197.30123901367188, 197.57781982421875,
  197.85765075683594, 198.13511657714844, 198.4061279296875, 198.66221618652344,
  198.9230499267578, 199.1815948486328, 199.43994140625, 199.68896484375,
  199.9493865966797, 200.21812438964844, 200.48036193847656, 200.74742126464844,
  200.9963836669922, 201.24269104003906, 201.48219299316406, 201.72726440429688,
  201.97535705566406, 202.2278594970703, 202.4872589111328, 202.75271606445312,
  202.99989318847656, 203.2222900390625, 203.45706176757812, 203.7052459716797,
  203.95457458496094, 204.19744873046875, 204.44668579101562,
  204.68458557128906, 204.9071044921875, 205.1350860595703, 205.37013244628906,
  205.5909881591797, 205.81387329101562, 206.0296173095703, 206.25344848632812,
  206.4950714111328, 206.7351837158203, 206.97613525390625, 207.19473266601562,
  207.40914916992188, 207.62376403808594, 207.84744262695312,
  208.04881286621094, 208.26156616210938, 208.47201538085938,
  208.70852661132812, 208.91049194335938, 209.11106872558594,
  209.32119750976562, 209.55543518066406, 209.784423828125, 209.96536254882812,
  210.16526794433594, 210.344482421875, 210.54074096679688, 210.75286865234375,
  210.96856689453125, 211.18460083007812, 211.33609008789062, 211.512939453125,
  211.70191955566406, 211.90101623535156, 212.09149169921875,
  212.21591186523438, 212.01121520996094, 211.89564514160156, 211.9906005859375,
  212.3580780029297, 212.50804138183594, 212.6317138671875, 212.86265563964844,
  213.05104064941406, 213.20816040039062, 213.4071044921875, 213.62132263183594,
  213.80918884277344, 214.01129150390625, 214.20875549316406, 214.4027557373047,
  214.60594177246094, 214.796630859375, 214.9864044189453, 215.16030883789062,
  215.35147094726562, 215.52911376953125, 215.71327209472656,
  215.89894104003906, 216.078857421875, 216.2637176513672, 216.4436798095703,
  216.6232147216797, 216.79672241210938, 216.9716796875, 217.1617431640625,
  217.33258056640625, 217.51449584960938, 217.6869659423828, 217.85336303710938,
  218.0214385986328, 218.1975555419922, 218.38124084472656, 218.55569458007812,
  218.72378540039062, 218.88302612304688, 219.0395965576172, 219.19674682617188,
  219.34939575195312, 219.5081329345703, 219.64273071289062, 219.75762939453125,
  219.8833465576172, 220.0185089111328, 220.16201782226562, 220.3094024658203,
  220.43760681152344, 220.5592498779297, 220.6671905517578, 220.7991180419922,
  220.93179321289062, 221.0869598388672, 221.24526977539062, 221.37710571289062,
  221.52735900878906, 221.6521453857422, 221.7818603515625, 221.91111755371094,
  222.03338623046875, 222.174560546875, 222.30471801757812, 222.4273681640625,
  222.55247497558594, 222.6676788330078, 222.7775115966797, 222.8813018798828,
  222.98992919921875, 223.11862182617188, 223.24301147460938, 223.3644256591797,
  223.49063110351562, 223.60272216796875, 223.73617553710938,
  223.83868408203125, 223.96044921875, 224.05999755859375, 224.17337036132812,
  224.27859497070312, 224.394775390625, 224.50433349609375, 224.62777709960938,
  224.75376892089844, 224.8932342529297, 225.027587890625, 225.14083862304688,
  225.26051330566406, 225.38636779785156, 225.51513671875, 225.63980102539062,
  225.75889587402344, 225.84527587890625, 225.95445251464844,
  226.06773376464844, 226.17564392089844, 226.29830932617188, 226.4265594482422,
  226.56021118164062, 226.6894989013672, 226.82106018066406, 226.9376678466797,
  227.06663513183594, 227.18331909179688, 227.3090362548828, 227.44467163085938,
  227.56787109375, 227.68865966796875, 227.8060302734375, 227.92404174804688,
  228.04931640625, 228.18255615234375, 228.316650390625, 228.44923400878906,
  228.57330322265625, 228.70632934570312, 228.81204223632812,
  228.92703247070312, 229.0439453125, 229.1686553955078, 229.2949981689453,
  229.41732788085938, 229.54263305664062, 229.67755126953125, 229.7957000732422,
  229.9140167236328, 230.02993774414062, 230.14871215820312, 230.26275634765625,
  230.37342834472656, 230.48876953125, 230.6135711669922, 230.7355194091797,
  230.84999084472656, 230.9565887451172, 231.0847930908203, 231.2142791748047,
  231.3345184326172, 231.43470764160156, 231.53756713867188, 231.6529083251953,
  231.75856018066406, 231.8624725341797, 231.97666931152344, 232.09765625,
  232.2263946533203, 232.336669921875, 232.4556427001953, 232.5595703125,
  232.6823272705078, 232.79566955566406, 232.92100524902344, 233.03431701660156,
  233.13182067871094, 233.24053955078125, 233.34463500976562,
  233.45001220703125, 233.56591796875, 233.68113708496094, 233.77911376953125,
  233.89031982421875, 233.99781799316406, 234.1154327392578, 234.22213745117188,
  234.31285095214844, 234.40003967285156, 234.49179077148438,
  234.59800720214844, 234.7012481689453, 234.81793212890625, 234.9336395263672,
  235.0386199951172, 235.1476593017578, 235.24710083007812, 235.3368682861328,
  235.42462158203125, 235.51182556152344, 235.60086059570312,
  235.70440673828125, 235.80482482910156, 235.91159057617188, 235.9993133544922,
  236.094970703125, 236.18533325195312, 236.27366638183594, 236.3660888671875,
  236.45425415039062, 236.53469848632812, 236.61204528808594,
  236.70477294921875, 236.79798889160156, 236.88983154296875,
  236.97657775878906, 237.06402587890625, 237.1432647705078, 237.23837280273438,
  237.31814575195312, 237.40301513671875, 237.46890258789062,
  237.55174255371094, 237.63922119140625, 237.71585083007812,
  237.77706909179688, 237.845703125, 237.9246063232422, 238.0047607421875,
  238.0851287841797, 238.1485137939453, 238.2137451171875, 238.28224182128906,
  238.3609619140625, 238.43089294433594, 238.5011749267578, 238.5657501220703,
  238.64283752441406, 238.71249389648438, 238.79283142089844,
  238.86248779296875, 238.94851684570312, 239.01107788085938,
  239.08255004882812, 239.14524841308594, 239.23025512695312,
  239.31484985351562, 239.3957977294922, 239.46401977539062, 239.5173797607422,
  239.5723114013672, 239.63784790039062, 239.7117919921875, 239.78883361816406,
  239.88514709472656, 239.95384216308594, 240.02761840820312, 240.0895233154297,
  240.1425018310547, 240.20095825195312, 240.27378845214844, 240.33547973632812,
  240.39776611328125, 240.45860290527344, 240.51095581054688,
  240.57943725585938, 240.6388397216797, 240.70591735839844, 240.78578186035156,
  240.85678100585938, 240.91920471191406, 240.9647216796875, 241.02456665039062,
  241.0806884765625, 241.1365203857422, 241.1933135986328, 241.25465393066406,
  241.33114624023438, 241.3982696533203, 241.45169067382812, 241.4955291748047,
  241.53697204589844, 241.58836364746094, 241.64996337890625,
  241.70333862304688, 241.7667236328125, 241.82818603515625, 241.87582397460938,
  241.9225311279297, 241.9775848388672, 242.0266571044922, 242.08154296875,
  242.14857482910156, 242.20318603515625, 242.249267578125, 242.30593872070312,
  242.36183166503906, 242.4230194091797, 242.45989990234375, 242.50514221191406,
  242.53692626953125, 242.56460571289062, 242.61572265625, 242.65692138671875,
  242.7048797607422, 242.75804138183594, 242.80870056152344, 242.86398315429688,
  242.9086456298828, 242.94775390625, 242.9749755859375, 242.93646240234375,
  242.76840209960938, 242.51307678222656, 242.19786071777344,
  241.68658447265625, 240.77259826660156, 239.47288513183594, 237.6894989013672,
  235.72410583496094, 233.79991149902344, 231.9113311767578, 230.0843505859375,
  228.2952117919922, 226.50401306152344, 224.70921325683594, 222.8374786376953,
  220.84796142578125, 218.66085815429688, 216.8905487060547, 215.00088500976562,
  213.2717742919922, 211.5600128173828, 209.8761749267578, 208.33749389648438,
  206.642822265625, 204.8332061767578, 202.9104461669922, 200.998779296875,
  199.0557098388672, 197.1393280029297, 195.210693359375, 193.4334716796875,
  191.797607421875, 190.21035766601562, 188.6157684326172, 186.9659423828125,
  185.43374633789062, 183.97789001464844, 182.60498046875, 181.42523193359375,
  180.227294921875, 178.93934631347656, 177.70291137695312, 176.61569213867188,
  175.5481414794922, 174.4556121826172, 173.41400146484375, 172.42735290527344,
  171.4687042236328, 170.52880859375, 169.64193725585938, 168.80105590820312,
  167.98907470703125, 167.2328643798828, 166.44920349121094, 165.68727111816406,
  164.98316955566406, 164.24276733398438, 163.51856994628906,
  162.87306213378906, 162.31427001953125, 161.6974639892578, 161.09983825683594,
  160.5021514892578, 159.83935546875, 159.15072631835938, 158.50802612304688,
  157.9508819580078, 157.4522705078125, 156.95738220214844, 156.46533203125,
  156.0421600341797, 155.62615966796875, 155.1957244873047, 154.7427978515625,
  154.2776336669922, 153.8318328857422, 153.38319396972656, 152.9369659423828,
  152.47760009765625, 152.0022735595703, 151.56590270996094, 151.14990234375,
  150.76229858398438, 150.3946990966797, 150.0054473876953, 149.58583068847656,
  149.1387176513672, 148.7088165283203, 148.27906799316406, 147.87429809570312,
  147.49417114257812, 147.12489318847656, 146.78176879882812,
  146.48147583007812, 146.1848602294922, 145.90289306640625, 145.65382385253906,
  145.42022705078125, 145.2023468017578, 145.0142364501953, 144.86630249023438,
  144.7476348876953, 144.6418914794922, 144.53700256347656, 144.4518280029297,
  144.4117889404297, 144.40542602539062, 144.3953094482422, 144.37342834472656,
  144.3230743408203, 144.2940216064453, 144.2870330810547, 144.3061065673828,
  144.3367919921875, 144.373291015625, 144.4149627685547, 144.3824462890625,
  144.42530822753906, 144.41006469726562, 144.2586212158203, 144.29360961914062,
  144.24790954589844, 144.24606323242188, 144.23802185058594, 144.255126953125,
  144.23207092285156, 144.03245544433594, 143.77749633789062,
  143.62655639648438, 143.5496063232422, 143.54762268066406, 143.42991638183594,
  143.2657012939453, 143.1065673828125, 142.97647094726562, 142.85952758789062,
  142.79249572753906, 142.9222412109375, 142.94189453125, 142.80288696289062,
  142.6579132080078, 142.45388793945312, 142.39846801757812, 142.35345458984375,
  142.30003356933594, 142.23040771484375, 142.20608520507812,
  142.14903259277344, 142.00949096679688, 141.9178466796875, 141.82752990722656,
  141.73666381835938, 141.65380859375, 141.5908203125, 141.47715759277344,
  141.2983856201172, 141.05528259277344, 140.79266357421875, 140.4957733154297,
  140.16189575195312, 139.82273864746094, 139.47552490234375, 139.1304168701172,
  138.74942016601562, 138.44760131835938, 138.09400939941406,
  137.72950744628906, 137.33306884765625, 137.03021240234375, 136.7480926513672,
  136.50631713867188, 136.36924743652344, 136.29586791992188, 136.295654296875,
  136.33700561523438, 136.39149475097656, 136.46340942382812,
  136.55612182617188, 136.65919494628906, 136.7870330810547, 136.91616821289062,
  137.04640197753906, 137.1925506591797, 137.3487548828125, 137.51950073242188,
  137.69061279296875, 137.8558807373047, 138.01902770996094, 138.1905059814453,
  138.38299560546875, 138.6022186279297, 138.77877807617188, 138.9661407470703,
  139.1355743408203, 139.3148956298828, 139.49208068847656, 139.67453002929688,
  139.86538696289062, 140.08897399902344, 140.34107971191406,
  140.62794494628906, 140.9490966796875, 141.28854370117188, 141.65000915527344,
  142.02557373046875, 142.3950653076172, 142.73416137695312, 143.07989501953125,
  143.40621948242188, 143.7122802734375, 144.0317840576172, 144.35467529296875,
  144.70297241210938, 145.07452392578125, 145.46685791015625,
  145.84982299804688, 146.2038116455078, 146.536865234375, 146.87168884277344,
  147.20941162109375, 147.5641326904297, 147.91563415527344, 147.9454345703125,
  148.21775817871094, 148.5959930419922, 148.95025634765625, 149.32913208007812,
  149.7109375, 150.09193420410156, 150.40882873535156, 150.59019470214844,
  150.6272430419922, 150.63865661621094, 150.95201110839844, 151.5502471923828,
  152.09664916992188, 152.50860595703125, 152.8940887451172, 153.27626037597656,
  153.61337280273438, 153.8909149169922, 154.15078735351562, 154.47532653808594,
  154.91436767578125, 155.37530517578125, 155.7747344970703, 156.1138458251953,
  156.46104431152344, 156.77452087402344, 157.00157165527344,
  157.10877990722656, 157.2987823486328, 157.86061096191406, 158.34579467773438,
  158.6914825439453, 159.0580291748047, 159.44619750976562, 159.79393005371094,
  160.02288818359375, 160.083740234375, 159.99642944335938, 159.95054626464844,
  160.28854370117188, 160.8487548828125, 161.4145965576172, 161.79283142089844,
  162.14134216308594, 162.46170043945312, 162.70855712890625, 162.9603729248047,
  163.36375427246094, 163.72372436523438, 164.0477294921875, 164.37562561035156,
  164.67135620117188, 164.96580505371094, 165.25189208984375,
  165.55345153808594, 165.84547424316406, 166.0246124267578, 166.03860473632812,
  165.8845977783203, 165.67967224121094, 165.44146728515625, 165.16319274902344,
  164.7947998046875, 164.24600219726562, 163.37498474121094, 162.18453979492188,
  160.7871856689453, 159.31060791015625, 157.8269500732422, 156.35696411132812,
  155.0670928955078, 153.7385711669922, 152.36819458007812, 150.88661193847656,
  149.3969268798828, 147.9256134033203, 146.46217346191406, 145.01345825195312,
  143.65447998046875, 142.30697631835938, 140.89549255371094,
  139.47169494628906, 138.11489868164062, 136.749755859375, 135.28567504882812,
  133.84507751464844, 132.44789123535156, 131.18038940429688,
  130.02932739257812, 128.86451721191406, 127.7573013305664, 126.71572875976562,
  125.63213348388672, 124.56871032714844, 123.55426025390625,
  122.62271881103516, 121.71826934814453, 120.8243408203125, 119.95500183105469,
  119.1095199584961, 118.27613830566406, 117.46359252929688, 116.66856384277344,
  115.91020965576172, 115.17111206054688, 114.42334747314453,
  113.84590911865234, 113.23109436035156, 112.62998962402344,
  112.02416229248047, 111.46756744384766, 110.93777465820312,
  110.42475128173828, 109.93399047851562, 109.45336151123047,
  109.03312683105469, 108.64086151123047, 108.2804946899414, 107.94371795654297,
  107.64321899414062, 107.38976287841797, 107.12152862548828, 106.8417739868164,
  106.5512924194336, 106.26937103271484, 105.99964141845703, 105.7243423461914,
  105.4569320678711, 105.19794464111328, 104.93890380859375, 104.69367218017578,
  104.46695709228516, 104.23982238769531, 103.9959716796875, 103.743896484375,
  103.48509216308594, 103.22653198242188, 102.98088073730469,
  102.73961639404297, 102.49413299560547, 102.2433090209961, 101.99061584472656,
  101.74278259277344, 101.49420166015625, 101.24121856689453,
  100.97400665283203, 100.67522430419922, 100.34907531738281, 100.0134506225586,
  99.67988586425781, 99.34060668945312, 98.98757934570312, 98.62599182128906,
  98.2715072631836, 97.92902374267578, 97.59742736816406, 97.25898742675781,
  96.88092803955078, 96.42915344238281, 95.91622924804688, 95.35803985595703,
  94.91033172607422, 94.51718139648438, 94.18521118164062, 93.83065795898438,
  93.49324035644531, 93.14764404296875, 92.76751708984375, 92.38206481933594,
  92.03717041015625, 91.74627685546875, 91.480224609375, 91.29571533203125,
  91.21358489990234, 91.17796325683594, 91.16603088378906, 91.1916732788086,
  91.20870208740234, 91.2152328491211, 91.27084350585938, 91.38998413085938,
  91.55965423583984, 91.70536804199219, 91.83769226074219, 91.99395751953125,
  92.18070983886719, 92.36190795898438, 92.5392837524414, 92.739013671875,
  92.95909881591797, 93.18406677246094, 93.4033203125, 93.64666748046875,
  93.89666748046875, 94.15750122070312, 94.41825866699219, 94.6751480102539,
  94.93223571777344, 95.19772338867188, 95.47583770751953, 95.76713562011719,
  96.07234954833984, 96.37918090820312, 96.69503021240234, 97.02363586425781,
  97.36337280273438, 97.72600555419922, 98.10779571533203, 98.50779724121094,
  98.90625762939453, 99.29190826416016, 99.66159057617188, 100.02860260009766,
  100.40570831298828, 100.79359436035156, 101.19172668457031,
  101.59175109863281, 101.991455078125, 102.39266967773438, 102.80364990234375,
  103.22506713867188, 103.64241790771484, 104.04149627685547, 104.445556640625,
  104.86589050292969, 105.28755187988281, 105.70289611816406,
  106.09757995605469, 106.5050048828125, 106.92656707763672, 107.34554290771484,
  107.76912689208984, 108.17750549316406, 108.60387420654297,
  109.02246856689453, 109.47933197021484, 109.95336151123047,
  110.43164825439453, 110.91763305664062, 111.42973327636719,
  111.95330047607422, 112.48385620117188, 113.01190185546875,
  113.54158020019531, 114.074951171875, 114.61581420898438, 115.15327453613281,
  115.69357299804688, 116.22299194335938, 116.74755859375, 117.27369689941406,
  117.80669403076172, 118.31901550292969, 118.81664276123047,
  119.28850555419922, 119.75973510742188, 120.22639465332031,
  120.69966888427734, 121.1806640625, 121.65607452392578, 122.13458251953125,
  122.61209869384766, 123.08821105957031, 123.55272674560547, 124.0205078125,
  124.4999771118164, 124.9967269897461, 125.50481414794922, 126.00244140625,
  126.49498748779297, 126.9697036743164, 127.44879150390625, 127.9452896118164,
  128.46807861328125, 129.03240966796875, 129.52294921875, 129.96224975585938,
  130.51171875, 130.95838928222656, 131.4662322998047, 131.85865783691406,
  132.29103088378906, 132.61911010742188, 132.95901489257812, 133.316162109375,
  133.80897521972656, 134.22366333007812, 134.5371551513672, 134.96954345703125,
  135.4429168701172, 135.9224090576172, 136.40988159179688, 136.8313751220703,
  137.0507354736328, 137.3019561767578, 137.72901916503906, 138.26296997070312,
  138.75657653808594, 139.20736694335938, 139.64968872070312,
  140.08358764648438, 140.52207946777344, 140.93051147460938,
  141.30169677734375, 141.6951141357422, 142.13278198242188, 142.5579376220703,
  142.9091033935547, 143.23565673828125, 143.10597229003906, 143.03472900390625,
  143.3037872314453, 143.8253631591797, 144.3824920654297, 144.7652130126953,
  145.14617919921875, 145.58038330078125, 146.0034942626953, 146.41615295410156,
  146.84088134765625, 147.2749481201172, 147.70001220703125, 148.1224822998047,
  148.53469848632812, 148.9681396484375, 149.42750549316406, 149.80841064453125,
  150.1859893798828, 150.5794677734375, 151.04515075683594, 151.37075805664062,
  151.72119140625, 152.18386840820312, 152.56781005859375, 152.8447723388672,
  153.34664916992188, 153.7293243408203, 153.993408203125, 154.41273498535156,
  154.8152618408203, 155.19992065429688, 155.6702117919922, 156.02166748046875,
  156.36932373046875, 156.7989044189453, 157.15219116210938, 157.57164001464844,
  157.9708709716797, 158.3583221435547, 158.72683715820312, 159.1109161376953,
  159.5045928955078, 159.8998565673828, 160.28277587890625, 160.65444946289062,
  161.02784729003906, 161.412109375, 161.7972869873047, 162.17552185058594,
  162.544921875, 162.9187469482422, 163.29107666015625, 163.66014099121094,
  164.02061462402344, 164.38345336914062, 164.74746704101562,
  165.11302185058594, 165.4708709716797, 165.82342529296875, 166.17108154296875,
  166.52484130859375, 166.92251586914062, 167.2386474609375, 167.59231567382812,
  167.972900390625, 168.2837371826172, 168.63453674316406, 168.98019409179688,
  169.36465454101562, 169.70733642578125, 170.0470733642578, 170.36962890625,
  170.6638641357422, 171.03128051757812, 171.36834716796875, 171.67344665527344,
  172.0079345703125, 172.32728576660156, 172.64248657226562, 172.97970581054688,
  173.31007385253906, 173.62738037109375, 173.94129943847656,
  174.25914001464844, 174.6085968017578, 174.91415405273438, 175.1890106201172,
  175.49514770507812, 175.81068420410156, 176.12635803222656,
  176.43002319335938, 176.72462463378906, 177.01522827148438, 177.3068084716797,
  177.6061248779297, 177.90142822265625, 178.19175720214844, 178.4812469482422,
  178.76077270507812, 179.0499267578125, 179.33502197265625, 179.6046600341797,
  179.88912963867188, 180.16783142089844, 180.41868591308594, 180.25146484375,
  180.12362670898438, 180.3325653076172, 180.7788543701172, 181.16567993164062,
  181.36083984375, 181.6258087158203, 181.98721313476562, 182.28712463378906,
  182.55889892578125, 182.8632354736328, 183.16453552246094, 183.45526123046875,
  183.748046875, 184.04965209960938, 184.33848571777344, 184.61756896972656,
  184.91062927246094, 185.21502685546875, 185.5091094970703, 185.7910919189453,
  186.0851287841797, 186.36940002441406, 186.65243530273438, 186.92245483398438,
  187.2051544189453, 187.49574279785156, 187.7797088623047, 188.06851196289062,
  188.35107421875, 188.6246795654297, 188.9072723388672, 189.21360778808594,
  189.46310424804688, 189.73944091796875, 190.03173828125, 190.28298950195312,
  190.5592803955078, 190.8317108154297, 191.10552978515625, 191.37847900390625,
  191.63845825195312, 191.90740966796875, 192.1713409423828, 192.44285583496094,
];
