import { Container, createStyles } from "@mantine/core";
import CallToAction from "../components/CallToAction";

const useStyle = createStyles((theme) => ({
  container: {
    [theme.fn.smallerThan("md")]: {
      width: "100%",
    },
  },
}));

export default function CTASection() {
  const { classes } = useStyle();

  return (
    <Container fluid className={classes.container}>
      <CallToAction />
    </Container>
  );
}
