import { Group, Text } from "@mantine/core";
import { CSSProperties, ReactNode } from "react";
import CardWrapper from "./Card";

interface ITitleCard {
  title: string;
  description?: string;
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
}

export default function TitleCard({
  title,
  description,
  children,
  style,
  className,
}: ITitleCard) {
  return (
    <CardWrapper style={style} className={className}>
      <Group mb={10}>
        <Text size="lg" style={{ lineHeight: 1 }} weight={500}>
          {title}
        </Text>
        {description && (
          <Text size="xs" color="dimmed">
            {description}
          </Text>
        )}
      </Group>

      {children}
    </CardWrapper>
  );
}
