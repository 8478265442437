import { Center } from "@mantine/core";
import DownloadButton from "./DownloadButton";
import SectionTitle from "./SectionTitle";

export default function CallToAction() {
  return (
    <>
      <SectionTitle title="Try it now, its Free!" />
      <Center>
        <DownloadButton />
      </Center>
    </>
  );
}
