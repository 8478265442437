import { Accordion, Container, createStyles, rem } from "@mantine/core";
import SectionTitle from "../components/SectionTitle";
import { useState } from "react";

interface IFAQ {
  value: string;
  question: string;
  answer: string;
}

const useStyle = createStyles((theme) => ({
  container: {
    maxWidth: rem(700),
    width: "50%",

    [theme.fn.smallerThan("md")]: {
      width: "80%",
    },

    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },

  item: {
    width: "100%",
    borderRadius: theme.radius.md,
    margin: `${theme.spacing.xl} 0`,
    boxShadow:
      "0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05),rgba(0, 0, 0, 0.05) 0 1.25rem 1.5625rem -0.3125rem,rgba(0, 0, 0, 0.04) 0 0.625rem 0.625rem -0.3125rem",
  },

  control: {
    borderRadius: `${theme.radius.md} ${theme.radius.md} 0 0`,
  },

  controlActive: {
    border: `1px solid ${theme.fn.primaryColor()}`,
    borderBottom: 0,
  },

  panel: {
    borderRadius: `0 0 ${theme.radius.md} ${theme.radius.md}`,
    border: `1px solid ${theme.fn.primaryColor()}`,
    borderTop: 0,
  },

  panelNotActive: {
    border: 0,
  },
}));

const QUESTIONS: IFAQ[] = [
  {
    value: "supported-games",
    question: "What games are supported?",
    answer: "Currently the only supported game is Assetto Corsa Competizione",
  },
  {
    value: "overlay-does-not-show",
    question: "Why does the Overlay not show up?",
    answer:
      "Overlays can only be displayed, if the game runs in windowed mode. Make sure the game does not run in fullscreen mode",
  },
  {
    value: "report-a-bug",
    question: "I found a bug, how can i report it?",
    answer:
      "If you want to report a bug, please do so in the corresponding channel on our discord server. Thanks for your help!",
  },
  {
    value: "sharing-laptimes",
    question: "Where can i find my telemetry files?",
    answer:
      'You can find the telemetry files in the "Documents/Lockup Telemetry" folder',
  },
];

export default function FAQSection() {
  const [value, setValue] = useState<string | null>(null);

  const { classes, cx } = useStyle();

  return (
    <Container className={classes.container}>
      <SectionTitle title="Frequently Asked Questions" />
      <Accordion variant="separated" value={value} onChange={setValue}>
        {QUESTIONS.map((element, index) => (
          <Accordion.Item
            value={element.value}
            className={classes.item}
            key={index}
          >
            <Accordion.Control
              className={cx(classes.control, {
                [classes.controlActive]: value === element.value,
              })}
            >
              {element.question}
            </Accordion.Control>
            <Accordion.Panel
              className={cx(classes.panel, {
                [classes.panelNotActive]: value !== element.value,
              })}
            >
              {element.answer}
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </Container>
  );
}
