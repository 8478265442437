import { useEffect, useRef } from "react";
import debounce from "../utils/debounce";
import { useResizeObserver } from "@mantine/hooks";

interface IViewportResizeObserver {
  onResize: (rect: unknown) => void;
}

export default function useViewportResizeObserver({
  onResize,
}: IViewportResizeObserver) {
  const [refObserver, rect] = useResizeObserver();
  const ref = useRef(false);

  useEffect(() => {
    const mainElement = document.getElementById("root");

    refObserver.current = mainElement;

    //eslint-disable-next-line
  }, []);

  useEffect(
    () => {
      if (!isDomRect(rect)) return;

      if (!ref.current) {
        ref.current = true;
        return;
      }

      const debounced = debounce({
        func: () => {
          onResize(rect);
          //console.log("onResize");
        },
        wait: 500,
      });

      return () => debounced.cancel();
    },

    //eslint-disable-next-line
    [rect]
  );
}

function isDomRect(rect: any): rect is DOMRectReadOnly {
  const proto = Object.getPrototypeOf(rect);

  return "toJSON" in proto;
}
