import {
  Footer,
  Image,
  createStyles,
  Container,
  useMantineTheme,
} from "@mantine/core";

//860px * 258px
import logo from "../assets/logoFontWhite.png";
import DiscordLink from "./DiscordLink";
import LegalInfo from "./LegalInfo";
import { useMediaQuery } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  inner: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    width: "50%",
    height: "100%",
    alignItems: "center",
    padding: `${theme.spacing.xl} calc(${theme.spacing.xl} * 4)`,
    zIndex: 10,

    [theme.fn.smallerThan("lg")]: { width: "80%" },

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
      padding: `${theme.spacing.md} ${theme.spacing.xs}`,
    },
  },

  clip: {
    height: "var(--mantine-footer-height)",
    zIndex: -10,
    position: "relative",
  },
}));

export default function AppFooter() {
  const { classes } = useStyles();

  const theme = useMantineTheme();
  const sm = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  return (
    <Footer height={sm ? 200 : 100} zIndex={1}>
      <Container fluid className={classes.inner}>
        <DiscordLink />
        <Image
          fit="contain"
          src={logo}
          height={40}
          alt="Lockup Telemetry Logo"
          style={{ width: "inherit" }}
        />
        <LegalInfo />
      </Container>
    </Footer>
  );
}

export function AppFooterClip() {
  const { classes } = useStyles();

  return <div className={classes.clip} />;
}
