interface Idebounce {
  func: Function;
  wait: number;
}

export default function debounce({ func, wait }: Idebounce) {
  const timeout = setTimeout(func, wait);

  function cancel() {
    clearTimeout(timeout);
  }

  return { cancel };
}
