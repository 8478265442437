import { Button, createStyles, rem } from "@mantine/core";
import { useOs } from "@mantine/hooks";
import { useMemo } from "react";
import { BrandWindows } from "tabler-icons-react";

const useStyles = createStyles((theme) => ({
  control: {
    transition: theme.other.buttonAnimationLength,

    "&:active": {
      transform: "scale(0.98)",
    },

    "&:before": {
      transition: `opacity ease-in-out ${theme.other.buttonAnimationLength}`,

      content: '""',
      zIndex: -10,
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: `linear-gradient(-45deg, ${theme.colors.pink[7]} 0%, ${theme.colors.grape[7]} 100% )`,
      transform: "translate3d(0px, 20px, 0) scale(0.97)",
      filter: "blur(20px)",
      opacity: 0,
      borderRadius: "inherit",
    },

    "&:hover": {
      "&:before": {
        opacity: 0.7,
      },
    },

    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },
}));

export default function DownloadButton() {
  const os = useOs();
  const { classes } = useStyles();

  const disabled = useMemo(() => os !== "windows", [os]);

  return (
    <Button
      component="a"
      href="https://download.lockup-telemetry.com/Lockup-Telemetry-Setup-0.12.0.exe"
      leftIcon={<BrandWindows size={rem(35)} />}
      variant="gradient"
      gradient={{ from: "purple", to: "pink" }}
      size="xl"
      className={classes.control}
      disabled={disabled}
    >
      available for Windows
    </Button>
  );
}
