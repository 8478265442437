import { ActionIcon, useMantineTheme } from "@mantine/core";
import { BrandDiscord } from "tabler-icons-react";

export default function DiscordLink() {
  const theme = useMantineTheme();
  return (
    <ActionIcon component="a" href={theme.other.discordLink} target="_blank">
      <BrandDiscord/>
    </ActionIcon>
  );
}
