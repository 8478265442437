import { MantineColor } from "@mantine/core";
import { SPEED_DATA } from "./SpeedData";
import { TRACK_TICKS } from "./TrackTicks";
import { GAS_DATA } from "./GasData";
import { BRAKE_DATA } from "./BrakeData";
import { FL_TEMP_DATA } from "./FLTempData";
import { FR_TEMP_DATA } from "./FRTempData";
import { RL_TEMP_DATA } from "./RLTempData";
import { RR_TEMP_DATA } from "./RRTempData";

export type formatYAxisType = (domainValue: number, index: number) => string;

export interface ITelemetryDataElement {
  title: string;
  description?: string;
  color: MantineColor[];
  data: number[][];
  trackTicks: number[];
  formatYAxis?: formatYAxisType;
  yMin?: number;
  yMax?: number;
  yPadding?: number;
}

export const TELEMETRY_DATA: ITelemetryDataElement[] = [
  {
    title: "Pedals",
    description: "in %",
    yMin: 0,
    yMax: 1,
    color: ["teal", "red"],
    data: [GAS_DATA, BRAKE_DATA],
    trackTicks: TRACK_TICKS,
    formatYAxis: (value) => (value * 100).toFixed(0),
  },
  {
    title: "Speed",
    description: "in km/h",
    color: ["blue"],
    data: [SPEED_DATA],
    trackTicks: TRACK_TICKS,
    yPadding: 20,
  },
  {
    title: "Tyre Core Temp",
    description: "in °C",
    data: [FL_TEMP_DATA, FR_TEMP_DATA, RL_TEMP_DATA, RR_TEMP_DATA],
    color: ["teal", "blue", "yellow", "red"],
    trackTicks: TRACK_TICKS,
    yPadding: 2,
  },
];
