import ReactDOM from "react-dom/client";
import App from "./App";
import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import CustomFonst from "./components/CustomFonts";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <MantineProvider
    withGlobalStyles
    withNormalizeCSS
    theme={{
      colorScheme: "dark",
      primaryColor: "pink",
      fontFamily: "Greycliff CF, sans-serif",
      other: {
        buttonAnimationLength: "0.2s",
        discordLink: "https://discord.gg/rXryJY6Pua",
      },
      globalStyles: (theme) => ({
        "::selection": {
          background: theme.fn.primaryColor(),
        },
      }),
    }}
  >
    <ModalsProvider>
      <CustomFonst />
      <App />
    </ModalsProvider>
  </MantineProvider>
);
