export const RL_TEMP_DATA = [
  95.36945343017578, 95.36945343017578, 95.36981201171875, 95.3701171875,
  95.37073516845703, 95.37137603759766, 95.3718490600586, 95.37234497070312,
  95.37261199951172, 95.37287902832031, 95.37328338623047, 95.37390899658203,
  95.37456512451172, 95.37547302246094, 95.37640380859375, 95.3772201538086,
  95.37789916992188, 95.37824249267578, 95.37847900390625, 95.37879180908203,
  95.37907409667969, 95.3792953491211, 95.37952423095703, 95.3798599243164,
  95.38026428222656, 95.3804931640625, 95.38062286376953, 95.38085174560547,
  95.38113403320312, 95.38137817382812, 95.38170623779297, 95.3819580078125,
  95.38213348388672, 95.38229370117188, 95.38257598876953, 95.38285064697266,
  95.38331604003906, 95.38384246826172, 95.38424682617188, 95.3843765258789,
  95.38432312011719, 95.38412475585938, 95.3837890625, 95.3835678100586,
  95.38335418701172, 95.38321685791016, 95.38306427001953, 95.38282012939453,
  95.38248443603516, 95.3821792602539, 95.38189697265625, 95.38168334960938,
  95.38167572021484, 95.38186645507812, 95.38213348388672, 95.38217163085938,
  95.38197326660156, 95.3816909790039, 95.38133239746094, 95.38097381591797,
  95.38072967529297, 95.38067626953125, 95.38070678710938, 95.38053131103516,
  95.38017272949219, 95.37982940673828, 95.37960815429688, 95.37947845458984,
  95.37974548339844, 95.3802490234375, 95.38056945800781, 95.3805160522461,
  95.38031768798828, 95.38008117675781, 95.37981414794922, 95.37950897216797,
  95.37911224365234, 95.37877655029297, 95.37852478027344, 95.3782730102539,
  95.3780746459961, 95.37800598144531, 95.37808227539062, 95.3781967163086,
  95.3783187866211, 95.37825775146484, 95.37806701660156, 95.37793731689453,
  95.3778305053711, 95.37767791748047, 95.37734985351562, 95.37694549560547,
  95.37647247314453, 95.37613677978516, 95.3758544921875, 95.37555694580078,
  95.37527465820312, 95.37501525878906, 95.37478637695312, 95.37451934814453,
  95.3742446899414, 95.37399291992188, 95.3739013671875, 95.37410736083984,
  95.37450408935547, 95.37508392333984, 95.37560272216797, 95.3759536743164,
  95.37614440917969, 95.37615203857422, 95.37611389160156, 95.37612915039062,
  95.37626647949219, 95.37653350830078, 95.3769302368164, 95.37742614746094,
  95.37798309326172, 95.37860107421875, 95.37919616699219, 95.37974548339844,
  95.3802719116211, 95.38077545166016, 95.38127899169922, 95.38170623779297,
  95.38211059570312, 95.382568359375, 95.38319396972656, 95.3841323852539,
  95.38522338867188, 95.38642883300781, 95.38760375976562, 95.3886489868164,
  95.38945007324219, 95.39009857177734, 95.39064025878906, 95.3912353515625,
  95.39202880859375, 95.39276123046875, 95.39349365234375, 95.39437866210938,
  95.39542388916016, 95.39649200439453, 95.39747619628906, 95.39840698242188,
  95.3993148803711, 95.40021514892578, 95.40108489990234, 95.40193176269531,
  95.40277099609375, 95.40351104736328, 95.40422821044922, 95.4049301147461,
  95.40576934814453, 95.40682983398438, 95.40802764892578, 95.40924835205078,
  95.41035461425781, 95.41130828857422, 95.41207885742188, 95.41275024414062,
  95.41341400146484, 95.41419219970703, 95.4151840209961, 95.41632080078125,
  95.4174575805664, 95.41859436035156, 95.41967010498047, 95.42072296142578,
  95.4218521118164, 95.42296600341797, 95.42416381835938, 95.4254379272461,
  95.4267349243164, 95.42809295654297, 95.42948913574219, 95.4310073852539,
  95.43251037597656, 95.43402099609375, 95.43548583984375, 95.43695068359375,
  95.43846893310547, 95.44001007080078, 95.44160461425781, 95.44320678710938,
  95.44485473632812, 95.4465560913086, 95.44831085205078, 95.4500732421875,
  95.45182037353516, 95.45357513427734, 95.4552993774414, 95.45700073242188,
  95.45869445800781, 95.46031188964844, 95.46186065673828, 95.46338653564453,
  95.46487426757812, 95.46633911132812, 95.46784973144531, 95.46939086914062,
  95.47107696533203, 95.47289276123047, 95.47473907470703, 95.47660827636719,
  95.47844696044922, 95.480224609375, 95.48190307617188, 95.48368072509766,
  95.4854507446289, 95.48717498779297, 95.48890686035156, 95.49068450927734,
  95.49250793457031, 95.4943618774414, 95.49624633789062, 95.49810791015625,
  95.49996185302734, 95.50180053710938, 95.50363159179688, 95.50545501708984,
  95.50728607177734, 95.5091552734375, 95.51102447509766, 95.5129623413086,
  95.51486206054688, 95.5167007446289, 95.51848602294922, 95.52029418945312,
  95.52215576171875, 95.52411651611328, 95.52609252929688, 95.52810668945312,
  95.53016662597656, 95.53221893310547, 95.53424835205078, 95.5362548828125,
  95.5381851196289, 95.54004669189453, 95.54191589355469, 95.54387664794922,
  95.54586029052734, 95.54789733886719, 95.54999542236328, 95.552001953125,
  95.55400085449219, 95.5560531616211, 95.55818939208984, 95.5604019165039,
  95.5627212524414, 95.5650405883789, 95.56742095947266, 95.56980895996094,
  95.57212829589844, 95.57457733154297, 95.5771484375, 95.5797119140625,
  95.58241271972656, 95.58509826660156, 95.58769226074219, 95.59029388427734,
  95.5928726196289, 95.59540557861328, 95.59796905517578, 95.6009521484375,
  95.6042709350586, 95.6078109741211, 95.61146545410156, 95.61531066894531,
  95.61945343017578, 95.62347412109375, 95.62735748291016, 95.63072204589844,
  95.63377380371094, 95.63724517822266, 95.64093780517578, 95.64480590820312,
  95.64886474609375, 95.65339660644531, 95.65790557861328, 95.66172790527344,
  95.66522216796875, 95.668701171875, 95.67217254638672, 95.67577362060547,
  95.67955017089844, 95.68377685546875, 95.68795776367188, 95.69188690185547,
  95.69552612304688, 95.6992416381836, 95.70287322998047, 95.70636749267578,
  95.70985412597656, 95.71360778808594, 95.71739959716797, 95.72117614746094,
  95.72489166259766, 95.72843933105469, 95.73181915283203, 95.73507690429688,
  95.73831939697266, 95.74220275878906, 95.74630737304688, 95.7506103515625,
  95.75467681884766, 95.75830841064453, 95.7617416381836, 95.76494598388672,
  95.76791381835938, 95.77081298828125, 95.77371978759766, 95.77674102783203,
  95.77997589111328, 95.78345489501953, 95.7869644165039, 95.79041290283203,
  95.79376220703125, 95.79728698730469, 95.80079650878906, 95.80415344238281,
  95.80743408203125, 95.8106918334961, 95.81412506103516, 95.81783294677734,
  95.82140350341797, 95.82470703125, 95.82787322998047, 95.83109283447266,
  95.83428955078125, 95.83744812011719, 95.8406753540039, 95.8438949584961,
  95.84707641601562, 95.85014343261719, 95.85333251953125, 95.85646057128906,
  95.85945129394531, 95.86236572265625, 95.86524963378906, 95.86811065673828,
  95.87086486816406, 95.87358856201172, 95.87639617919922, 95.87933349609375,
  95.8823471069336, 95.8853530883789, 95.88825988769531, 95.89102172851562,
  95.89368438720703, 95.89630889892578, 95.899169921875, 95.90217590332031,
  95.90518951416016, 95.90828704833984, 95.91090393066406, 95.9133071899414,
  95.91565704345703, 95.91818237304688, 95.92098999023438, 95.92382049560547,
  95.92658996582031, 95.92937469482422, 95.93212127685547, 95.93487548828125,
  95.93766021728516, 95.94033813476562, 95.94287872314453, 95.9453353881836,
  95.94783782958984, 95.95022583007812, 95.95259857177734, 95.9551773071289,
  95.95782470703125, 95.9604263305664, 95.96295166015625, 95.96537780761719,
  95.9676513671875, 95.96978759765625, 95.97188568115234, 95.9739761352539,
  95.9760971069336, 95.97844696044922, 95.98091125488281, 95.98339080810547,
  95.98589324951172, 95.98843383789062, 95.99085235595703, 95.99312591552734,
  95.99539947509766, 95.99761962890625, 95.999755859375, 96.00176239013672,
  96.00376892089844, 96.00572204589844, 96.00778198242188, 96.00990295410156,
  96.01207733154297, 96.01431274414062, 96.01652526855469, 96.01860046386719,
  96.0204849243164, 96.02216339111328, 96.023681640625, 96.02516174316406,
  96.02653503417969, 96.0279769897461, 96.02958679199219, 96.03119659423828,
  96.03292846679688, 96.03469848632812, 96.03644561767578, 96.0380859375,
  96.03962707519531, 96.04115295410156, 96.04264068603516, 96.04395294189453,
  96.0452651977539, 96.04667663574219, 96.04804229736328, 96.04935455322266,
  96.0506591796875, 96.05197143554688, 96.05329132080078, 96.05457305908203,
  96.05587768554688, 96.0572280883789, 96.05859375, 96.05986022949219,
  96.06108856201172, 96.06229400634766, 96.06348419189453, 96.06455993652344,
  96.06558990478516, 96.0666732788086, 96.06777954101562, 96.06895446777344,
  96.07011413574219, 96.07125854492188, 96.07242584228516, 96.07355499267578,
  96.07465362548828, 96.07575988769531, 96.07682800292969, 96.07794189453125,
  96.07901763916016, 96.08008575439453, 96.0811996459961, 96.082275390625,
  96.08333587646484, 96.08438110351562, 96.08543395996094, 96.08650970458984,
  96.08758544921875, 96.08869171142578, 96.08979034423828, 96.09087371826172,
  96.09199523925781, 96.0931396484375, 96.09431457519531, 96.09544372558594,
  96.09656524658203, 96.09769439697266, 96.09883117675781, 96.0999984741211,
  96.10115051269531, 96.1023178100586, 96.1034927368164, 96.10468292236328,
  96.10587310791016, 96.1070785522461, 96.10831451416016, 96.10955810546875,
  96.11080169677734, 96.11205291748047, 96.11331176757812, 96.11458587646484,
  96.1158447265625, 96.11712646484375, 96.11840057373047, 96.11969757080078,
  96.12098693847656, 96.12227630615234, 96.12358093261719, 96.12490844726562,
  96.1262435913086, 96.1275634765625, 96.12889099121094, 96.13024139404297,
  96.13159942626953, 96.13296508789062, 96.13432312011719, 96.13570404052734,
  96.1371078491211, 96.13854217529297, 96.1399917602539, 96.1414566040039,
  96.14290618896484, 96.14435577392578, 96.1457748413086, 96.1472396850586,
  96.1487045288086, 96.15018463134766, 96.15167236328125, 96.15316009521484,
  96.1546630859375, 96.15617370605469, 96.15767669677734, 96.15916442871094,
  96.16064453125, 96.1621322631836, 96.16362762451172, 96.16512298583984,
  96.16661834716797, 96.1681137084961, 96.16961669921875, 96.17111206054688,
  96.17262268066406, 96.17412567138672, 96.1756362915039, 96.1771469116211,
  96.17864227294922, 96.18013000488281, 96.18162536621094, 96.1831283569336,
  96.18463897705078, 96.18614196777344, 96.1876449584961, 96.18914031982422,
  96.19064331054688, 96.19216918945312, 96.19369506835938, 96.19522857666016,
  96.19678497314453, 96.1983413696289, 96.19989776611328, 96.20144653320312,
  96.20301055908203, 96.20457458496094, 96.2061538696289, 96.2077407836914,
  96.20929718017578, 96.21083068847656, 96.21236419677734, 96.21392059326172,
  96.21550750732422, 96.21711730957031, 96.21873474121094, 96.2203369140625,
  96.221923828125, 96.22350311279297, 96.2250747680664, 96.22663879394531,
  96.22821044921875, 96.22981262207031, 96.23139953613281, 96.23300170898438,
  96.23460388183594, 96.23621368408203, 96.23784637451172, 96.2394790649414,
  96.2411117553711, 96.24275207519531, 96.24439239501953, 96.24604034423828,
  96.2476806640625, 96.24932861328125, 96.25096893310547, 96.25260925292969,
  96.2542724609375, 96.25594329833984, 96.25762939453125, 96.25932312011719,
  96.26103210449219, 96.26274871826172, 96.26445770263672, 96.26615142822266,
  96.26785278320312, 96.2695541381836, 96.27124786376953, 96.27293395996094,
  96.27462005615234, 96.27631378173828, 96.27799987792969, 96.2796859741211,
  96.28136444091797, 96.28302764892578, 96.28468322753906, 96.28634643554688,
  96.28800201416016, 96.28966522216797, 96.29134368896484, 96.2930679321289,
  96.29483795166016, 96.29662322998047, 96.29840850830078, 96.30018615722656,
  96.30197143554688, 96.3037338256836, 96.30550384521484, 96.30725860595703,
  96.30903625488281, 96.31080627441406, 96.31258392333984, 96.31436157226562,
  96.31611633300781, 96.31790924072266, 96.31975555419922, 96.32162475585938,
  96.32349395751953, 96.32538604736328, 96.32730102539062, 96.32925415039062,
  96.33120727539062, 96.33314514160156, 96.3350830078125, 96.33702087402344,
  96.3389663696289, 96.34092712402344, 96.34293365478516, 96.344970703125,
  96.34700775146484, 96.34907531738281, 96.35118103027344, 96.35332489013672,
  96.35545349121094, 96.35759735107422, 96.3597640991211, 96.36193084716797,
  96.36408996582031, 96.36624145507812, 96.36837768554688, 96.37049102783203,
  96.37254333496094, 96.3746109008789, 96.37672424316406, 96.37886047363281,
  96.38105010986328, 96.38326263427734, 96.38545227050781, 96.38761901855469,
  96.38978576660156, 96.3919677734375, 96.39414978027344, 96.39630126953125,
  96.3984375, 96.40057373046875, 96.4026870727539, 96.40479278564453,
  96.40692901611328, 96.40912628173828, 96.41132354736328, 96.41349029541016,
  96.4156494140625, 96.41779327392578, 96.41992950439453, 96.42207336425781,
  96.4242172241211, 96.42633819580078, 96.4284896850586, 96.43070220947266,
  96.43287658691406, 96.43506622314453, 96.43722534179688, 96.43937683105469,
  96.44154357910156, 96.4437484741211, 96.44593811035156, 96.44812774658203,
  96.45033264160156, 96.45252990722656, 96.45474243164062, 96.45695495605469,
  96.45915222167969, 96.46135711669922, 96.46356201171875, 96.46575164794922,
  96.46796417236328, 96.47015380859375, 96.47233581542969, 96.4745101928711,
  96.4766845703125, 96.47882843017578, 96.48094940185547, 96.48310852050781,
  96.48529052734375, 96.48748016357422, 96.48970031738281, 96.49189758300781,
  96.49403381347656, 96.4962387084961, 96.49842834472656, 96.5005874633789,
  96.50276184082031, 96.50495147705078, 96.50712585449219, 96.50927734375,
  96.51142120361328, 96.51353454589844, 96.51561737060547, 96.51773071289062,
  96.51985931396484, 96.5219955444336, 96.52413177490234, 96.52618408203125,
  96.52815246582031, 96.53016662597656, 96.53238677978516, 96.53416442871094,
  96.53614807128906, 96.53814697265625, 96.54005432128906, 96.54194641113281,
  96.5438232421875, 96.54562377929688, 96.54744720458984, 96.54926300048828,
  96.55107116699219, 96.55281066894531, 96.55455780029297, 96.55634307861328,
  96.55811309814453, 96.55982208251953, 96.56143951416016, 96.56304931640625,
  96.56483459472656, 96.56627655029297, 96.56793212890625, 96.5696029663086,
  96.57131958007812, 96.57305145263672, 96.57486724853516, 96.5762710571289,
  96.57778930664062, 96.57926940917969, 96.58074188232422, 96.58222198486328,
  96.58364868164062, 96.58507537841797, 96.58650207519531, 96.58790588378906,
  96.58930206298828, 96.59075164794922, 96.59217834472656, 96.59363555908203,
  96.5952377319336, 96.59664154052734, 96.59782409667969, 96.59920501708984,
  96.6006088256836, 96.60211944580078, 96.60368347167969, 96.60519409179688,
  96.60668182373047, 96.6077651977539, 96.60892486572266, 96.61007690429688,
  96.61128997802734, 96.61257934570312, 96.61405944824219, 96.61531066894531,
  96.61666107177734, 96.6178970336914, 96.6191177368164, 96.62030792236328,
  96.62163543701172, 96.62276458740234, 96.62413024902344, 96.62553405761719,
  96.62696838378906, 96.62834167480469, 96.6296615600586, 96.6310806274414,
  96.6323013305664, 96.6336898803711, 96.6351089477539, 96.63654327392578,
  96.63790893554688, 96.6391372680664, 96.6401596069336, 96.64102935791016,
  96.64185333251953, 96.64263916015625, 96.6434555053711, 96.6442642211914,
  96.64506530761719, 96.6458740234375, 96.64672088623047, 96.64752960205078,
  96.64837646484375, 96.64918518066406, 96.64993286132812, 96.65065002441406,
  96.65135192871094, 96.65203094482422, 96.65267944335938, 96.65333557128906,
  96.65396881103516, 96.65457153320312, 96.65514373779297, 96.65569305419922,
  96.65621948242188, 96.65673065185547, 96.65726470947266, 96.65777587890625,
  96.65828704833984, 96.65877532958984, 96.65925598144531, 96.65973663330078,
  96.6601791381836, 96.66059875488281, 96.6610107421875, 96.66142272949219,
  96.66184997558594, 96.66226959228516, 96.66268920898438, 96.66310119628906,
  96.66348266601562, 96.6638412475586, 96.6641845703125, 96.66448974609375,
  96.66475677490234, 96.66500854492188, 96.66526794433594, 96.66549682617188,
  96.66571807861328, 96.66588592529297, 96.66606140136719, 96.66629791259766,
  96.66655731201172, 96.66676330566406, 96.66690063476562, 96.6670150756836,
  96.66715240478516, 96.6673355102539, 96.66751098632812, 96.66766357421875,
  96.66783142089844, 96.6679916381836, 96.66812896728516, 96.66824340820312,
  96.6683578491211, 96.66844940185547, 96.66850280761719, 96.66854095458984,
  96.66857147216797, 96.66859436035156, 96.66859436035156, 96.66857147216797,
  96.66854858398438, 96.66853332519531, 96.66851043701172, 96.66844940185547,
  96.66837310791016, 96.6682357788086, 96.66815185546875, 96.6680679321289,
  96.66801452636719, 96.66790771484375, 96.66785430908203, 96.66780853271484,
  96.667724609375, 96.6676025390625, 96.66748046875, 96.66732788085938,
  96.66712951660156, 96.66692352294922, 96.66670989990234, 96.66647338867188,
  96.66620635986328, 96.66593170166016, 96.66568756103516, 96.66546630859375,
  96.66525268554688, 96.66500091552734, 96.66472625732422, 96.6644287109375,
  96.66413879394531, 96.66383361816406, 96.66356658935547, 96.66331481933594,
  96.66303253173828, 96.66278076171875, 96.66252136230469, 96.6622314453125,
  96.66194152832031, 96.66165924072266, 96.6613998413086, 96.6611099243164,
  96.66082000732422, 96.6605224609375, 96.66023254394531, 96.65995788574219,
  96.65967559814453, 96.65938568115234, 96.6590805053711, 96.65875244140625,
  96.65843963623047, 96.6581802368164, 96.657958984375, 96.65776824951172,
  96.65751647949219, 96.65718078613281, 96.65678405761719, 96.65641784667969,
  96.65612030029297, 96.65587615966797, 96.65574645996094, 96.65563201904297,
  96.6554946899414, 96.65510559082031, 96.65464782714844, 96.6540756225586,
  96.65351867675781, 96.65294647216797, 96.6524429321289, 96.65222930908203,
  96.65206146240234, 96.65214538574219, 96.65196228027344, 96.65177154541016,
  96.6514663696289, 96.65106201171875, 96.65062713623047, 96.65020751953125,
  96.6498031616211, 96.6493911743164, 96.64897918701172, 96.64861297607422,
  96.64827728271484, 96.64795684814453, 96.64759063720703, 96.64714813232422,
  96.64664459228516, 96.64611053466797, 96.6455307006836, 96.64496612548828,
  96.64446258544922, 96.6440200805664, 96.64360046386719, 96.6431655883789,
  96.64271545410156, 96.64226531982422, 96.64178466796875, 96.64127349853516,
  96.64082336425781, 96.64038848876953, 96.63984680175781, 96.63922119140625,
  96.63880920410156, 96.63827514648438, 96.63778686523438, 96.63744354248047,
  96.63680267333984, 96.63630676269531, 96.63568878173828, 96.63502502441406,
  96.63436126708984, 96.63369750976562, 96.63308715820312, 96.63249969482422,
  96.63187408447266, 96.63127899169922, 96.63072204589844, 96.63014221191406,
  96.62944793701172, 96.62870788574219, 96.62802124023438, 96.62740325927734,
  96.6268539428711, 96.62626647949219, 96.62567138671875, 96.62496185302734,
  96.6241226196289, 96.6233901977539, 96.62271881103516, 96.62208557128906,
  96.62145233154297, 96.62078857421875, 96.6200942993164, 96.6193618774414,
  96.61863708496094, 96.6180419921875, 96.61754608154297, 96.61703491210938,
  96.6164321899414, 96.61569213867188, 96.61487579345703, 96.6141128540039,
  96.61336517333984, 96.6126480102539, 96.6119155883789, 96.61103820800781,
  96.61014556884766, 96.60928344726562, 96.60847473144531, 96.60777282714844,
  96.60708618164062, 96.6063003540039, 96.60533142089844, 96.6044921875,
  96.60348510742188, 96.60269927978516, 96.60179138183594, 96.60103607177734,
  96.60018920898438, 96.59927368164062, 96.59835052490234, 96.59740447998047,
  96.5962905883789, 96.59535217285156, 96.59423828125, 96.59330749511719,
  96.59230041503906, 96.59125518798828, 96.59013366699219, 96.5888442993164,
  96.58778381347656, 96.586669921875, 96.5855941772461, 96.58453369140625,
  96.58344268798828, 96.58228302001953, 96.58113861083984, 96.58002471923828,
  96.57888793945312, 96.57772827148438, 96.57659149169922, 96.57543182373047,
  96.57430267333984, 96.57318115234375, 96.57203674316406, 96.57089233398438,
  96.5696792602539, 96.56846618652344, 96.56712341308594, 96.56573486328125,
  96.56439971923828, 96.56304931640625, 96.56172943115234, 96.56051635742188,
  96.55931091308594, 96.55812072753906, 96.55687713623047, 96.55554962158203,
  96.55426788330078, 96.55280303955078, 96.55145263671875, 96.55018615722656,
  96.54899597167969, 96.5478515625, 96.54663848876953, 96.54532623291016,
  96.5439224243164, 96.54236602783203, 96.54084014892578, 96.53937530517578,
  96.53804779052734, 96.53667449951172, 96.53533935546875, 96.53398895263672,
  96.53269958496094, 96.53131103515625, 96.53010559082031, 96.5290298461914,
  96.52769470214844, 96.52622985839844, 96.5246353149414, 96.52297973632812,
  96.5213623046875, 96.51976013183594, 96.51829528808594, 96.51695251464844,
  96.51566314697266, 96.51438903808594, 96.5130844116211, 96.51165771484375,
  96.51020812988281, 96.5087890625, 96.50728607177734, 96.50558471679688,
  96.5038833618164, 96.50209045410156, 96.50052642822266, 96.49919128417969,
  96.49781799316406, 96.49637603759766, 96.49481201171875, 96.4932632446289,
  96.49154663085938, 96.48985290527344, 96.4880599975586, 96.48627471923828,
  96.48451232910156, 96.48284912109375, 96.48123168945312, 96.47954559326172,
  96.47776794433594, 96.47587585449219, 96.473876953125, 96.47187042236328,
  96.4698257446289, 96.46781921386719, 96.46588897705078, 96.46398162841797,
  96.46205139160156, 96.46015167236328, 96.45818328857422, 96.45602416992188,
  96.45378875732422, 96.45148468017578, 96.44921875, 96.44705963134766,
  96.44498443603516, 96.44279479980469, 96.44060516357422, 96.43836212158203,
  96.4361801147461, 96.4339599609375, 96.43168640136719, 96.42938995361328,
  96.42705535888672, 96.4247055053711, 96.42229461669922, 96.4198989868164,
  96.41743469238281, 96.41496276855469, 96.41251373291016, 96.41007232666016,
  96.40760803222656, 96.40512084960938, 96.40263366699219, 96.4001693725586,
  96.3977279663086, 96.39525604248047, 96.39278411865234, 96.39029693603516,
  96.38778686523438, 96.38525390625, 96.38272094726562, 96.38018798828125,
  96.37767791748047, 96.37519073486328, 96.37266540527344, 96.37007904052734,
  96.36744689941406, 96.36476135253906, 96.36210632324219, 96.35955810546875,
  96.35710144042969, 96.35465240478516, 96.35214233398438, 96.34953308105469,
  96.34679412841797, 96.34398651123047, 96.34121704101562, 96.33853912353516,
  96.33595275878906, 96.33335876464844, 96.33070373535156, 96.32801055908203,
  96.32527923583984, 96.32247924804688, 96.31964874267578, 96.31684875488281,
  96.31407165527344, 96.31130981445312, 96.30851745605469, 96.30569458007812,
  96.3028335571289, 96.2999267578125, 96.29702758789062, 96.29415130615234,
  96.29129791259766, 96.28846740722656, 96.28563690185547, 96.2827377319336,
  96.27976989746094, 96.27676391601562, 96.27376556396484, 96.27082824707031,
  96.26792907714844, 96.26502990722656, 96.26212310791016, 96.25914764404297,
  96.2561264038086, 96.25306701660156, 96.2500228881836, 96.24696350097656,
  96.24388122558594, 96.24079132080078, 96.23770141601562, 96.234619140625,
  96.2315444946289, 96.22846221923828, 96.22537994384766, 96.22229766845703,
  96.21917724609375, 96.21601867675781, 96.21285247802734, 96.2096939086914,
  96.20654296875, 96.20338439941406, 96.20022583007812, 96.19708251953125,
  96.19393920898438, 96.19076538085938, 96.18758392333984, 96.18438720703125,
  96.18121337890625, 96.1780776977539, 96.17495727539062, 96.17179107666016,
  96.1685791015625, 96.16532135009766, 96.16206359863281, 96.1588363647461,
  96.15560913085938, 96.15242767333984, 96.14925384521484, 96.14605712890625,
  96.14286041259766, 96.1396255493164, 96.13630676269531, 96.13298797607422,
  96.12973022460938, 96.12654113769531, 96.12337493896484, 96.1202392578125,
  96.11713409423828, 96.11396026611328, 96.1107406616211, 96.1075210571289,
  96.10429382324219, 96.10102844238281, 96.0974349975586, 96.0945053100586,
  96.09125518798828, 96.08792114257812, 96.08455657958984, 96.081298828125,
  96.0780258178711, 96.0746841430664, 96.07132720947266, 96.0679931640625,
  96.06460571289062, 96.0611343383789, 96.0577163696289, 96.0543212890625,
  96.05096435546875, 96.04764556884766, 96.04443359375, 96.0411605834961,
  96.03789520263672, 96.03462219238281, 96.03130340576172, 96.0279769897461,
  96.024658203125, 96.02134704589844, 96.01805114746094, 96.01473999023438,
  96.01146697998047, 96.00821685791016, 96.00501251220703, 96.00180053710938,
  95.9985580444336, 95.99529266357422, 95.9920425415039, 95.98880767822266,
  95.98563385009766, 95.98253631591797, 95.97946166992188, 95.97635650634766,
  95.97329711914062, 95.97022247314453, 95.96711730957031, 95.96402740478516,
  95.96097564697266, 95.95800018310547, 95.95507049560547, 95.95215606689453,
  95.94918823242188, 95.94609069824219, 95.94300079345703, 95.94001007080078,
  95.93714141845703, 95.93438720703125, 95.931640625, 95.92887115478516,
  95.92609405517578, 95.92333221435547, 95.92059326171875, 95.91783905029297,
  95.91508483886719, 95.91236114501953, 95.90965270996094, 95.90699768066406,
  95.90434265136719, 95.90170288085938, 95.89908599853516, 95.896484375,
  95.89387512207031, 95.89128112792969, 95.8886947631836, 95.88615417480469,
  95.88360595703125, 95.88102722167969, 95.8785171508789, 95.87603759765625,
  95.87353515625, 95.87100982666016, 95.86848449707031, 95.86598205566406,
  95.86346435546875, 95.8609619140625, 95.85847473144531, 95.85606384277344,
  95.853759765625, 95.85152435302734, 95.84928131103516, 95.84696197509766,
  95.84455871582031, 95.84202575683594, 95.83944702148438, 95.83688354492188,
  95.83448791503906, 95.83221435546875, 95.83007049560547, 95.82789611816406,
  95.82553100585938, 95.82307434082031, 95.82067108154297, 95.8182601928711,
  95.8158950805664, 95.81363677978516, 95.81149291992188, 95.80936431884766,
  95.80711364746094, 95.80477905273438, 95.80248260498047, 95.80028533935547,
  95.79817962646484, 95.79598999023438, 95.79373931884766, 95.79154968261719,
  95.78938293457031, 95.78718566894531, 95.7850341796875, 95.78292846679688,
  95.78073120117188, 95.77853393554688, 95.77644348144531, 95.77434539794922,
  95.77214813232422, 95.76993560791016, 95.76775360107422, 95.76561737060547,
  95.76348876953125, 95.76131439208984, 95.75910186767578, 95.7568130493164,
  95.7545166015625, 95.7522964477539, 95.7501449584961, 95.74800872802734,
  95.7458724975586, 95.74363708496094, 95.74142456054688, 95.73920440673828,
  95.7369384765625, 95.73465728759766, 95.732421875, 95.73031616210938,
  95.72805786132812, 95.72573852539062, 95.72350311279297, 95.72129821777344,
  95.71916961669922, 95.71709442138672, 95.71492767333984, 95.71271514892578,
  95.71050262451172, 95.70823669433594, 95.70600891113281, 95.70364379882812,
  95.7012710571289, 95.69893646240234, 95.69657135009766, 95.69407653808594,
  95.69164276123047, 95.68933868408203, 95.68712615966797, 95.6849365234375,
  95.6828384399414, 95.68071746826172, 95.67845916748047, 95.67613220214844,
  95.67361450195312, 95.6709976196289, 95.66845703125, 95.66594696044922,
  95.66349029541016, 95.66111755371094, 95.65860748291016, 95.65657043457031,
  95.65425872802734, 95.65190887451172, 95.64915466308594, 95.64688873291016,
  95.64445495605469, 95.64213562011719, 95.63993072509766, 95.63768768310547,
  95.63540649414062, 95.6330795288086, 95.63069152832031, 95.62821197509766,
  95.62561798095703, 95.62284088134766, 95.62065124511719, 95.61804962158203,
  95.615966796875, 95.61366271972656, 95.61114501953125, 95.60908508300781,
  95.60676574707031, 95.60443115234375, 95.60203552246094, 95.59963989257812,
  95.59716796875, 95.59465789794922, 95.59219360351562, 95.58963012695312,
  95.58711242675781, 95.58466339111328, 95.58214569091797, 95.57968139648438,
  95.5772933959961, 95.57469177246094, 95.57256317138672, 95.57015228271484,
  95.56773376464844, 95.5652847290039, 95.56283569335938, 95.56034088134766,
  95.5578842163086, 95.55537414550781, 95.55290985107422, 95.55037689208984,
  95.54786682128906, 95.54537200927734, 95.54289245605469, 95.5403823852539,
  95.53787994384766, 95.53536224365234, 95.5328598022461, 95.53036499023438,
  95.52789306640625, 95.5253677368164, 95.52281188964844, 95.52024841308594,
  95.51776885986328, 95.5152816772461, 95.51271057128906, 95.51019287109375,
  95.50775909423828, 95.50529479980469, 95.50279998779297, 95.5002670288086,
  95.49774932861328, 95.49524688720703, 95.49279022216797, 95.4903564453125,
  95.4878921508789, 95.48545837402344, 95.48301696777344, 95.48050689697266,
  95.47761535644531, 95.47528839111328, 95.47250366210938, 95.47025299072266,
  95.46765899658203, 95.46509552001953, 95.46249389648438, 95.45995330810547,
  95.45745086669922, 95.4549560546875, 95.45249938964844, 95.44999694824219,
  95.44749450683594, 95.44499969482422, 95.44247436523438, 95.43997192382812,
  95.43755340576172, 95.43511199951172, 95.4326400756836, 95.43016052246094,
  95.42774200439453, 95.42538452148438, 95.42315673828125, 95.42089080810547,
  95.4185791015625, 95.41619873046875, 95.4137954711914, 95.41150665283203,
  95.40923309326172, 95.40702819824219, 95.40477752685547, 95.40245819091797,
  95.40005493164062, 95.3976058959961, 95.39514923095703, 95.39273071289062,
  95.39025115966797, 95.38780212402344, 95.38541412353516, 95.38316345214844,
  95.380859375, 95.37844848632812, 95.37592315673828, 95.37342834472656,
  95.37103271484375, 95.36872863769531, 95.36646270751953, 95.36416625976562,
  95.36178588867188, 95.35933685302734, 95.35684967041016, 95.35440826416016,
  95.35208129882812, 95.34986877441406, 95.34778594970703, 95.3456802368164,
  95.34347534179688, 95.34107971191406, 95.338623046875, 95.33621215820312,
  95.33391571044922, 95.33161926269531, 95.32935333251953, 95.32707977294922,
  95.32476043701172, 95.3223648071289, 95.32000732421875, 95.31771850585938,
  95.31549072265625, 95.3132553100586, 95.31098937988281, 95.30870056152344,
  95.30645751953125, 95.30426025390625, 95.30207061767578, 95.2996597290039,
  95.29718780517578, 95.2947769165039, 95.29240417480469, 95.29000091552734,
  95.28761291503906, 95.28533935546875, 95.28307342529297, 95.28071594238281,
  95.27826690673828, 95.27574157714844, 95.27317810058594, 95.27068328857422,
  95.26815795898438, 95.26557922363281, 95.26299285888672, 95.26042175292969,
  95.25782012939453, 95.25524139404297, 95.25264739990234, 95.25013732910156,
  95.2475814819336, 95.24507904052734, 95.2425308227539, 95.23998260498047,
  95.2374496459961, 95.23495483398438, 95.23241424560547, 95.22989654541016,
  95.22737121582031, 95.22476196289062, 95.22201538085938, 95.21920013427734,
  95.21643829345703, 95.21377563476562, 95.21116638183594, 95.2085189819336,
  95.20581817626953, 95.20307159423828, 95.2003173828125, 95.19760131835938,
  95.19498443603516, 95.19235229492188, 95.18960571289062, 95.18673706054688,
  95.18380737304688, 95.18077087402344, 95.17787170410156, 95.17513275146484,
  95.17243194580078, 95.16967010498047, 95.16687774658203, 95.16392517089844,
  95.16108703613281, 95.15847778320312, 95.15584564208984, 95.15310668945312,
  95.15023803710938, 95.14740753173828, 95.14464569091797, 95.14189147949219,
  95.1391372680664, 95.13630676269531, 95.13358306884766, 95.13079833984375,
  95.12801361083984, 95.12527465820312, 95.1226577758789, 95.11990356445312,
  95.11707305908203, 95.11415100097656, 95.11113739013672, 95.10807800292969,
  95.10509490966797, 95.1021728515625, 95.09932708740234, 95.096435546875,
  95.09356689453125, 95.09071350097656, 95.08780670166016, 95.08476257324219,
  95.08161926269531, 95.0783920288086, 95.07511138916016, 95.07196044921875,
  95.06889343261719, 95.06591033935547, 95.06295013427734, 95.05995178222656,
  95.0570297241211, 95.05422973632812, 95.05149841308594, 95.04869842529297,
  95.04582977294922, 95.0426025390625, 95.03945922851562, 95.0362777709961,
  95.03301239013672, 95.02979278564453, 95.02645111083984, 95.02304077148438,
  95.01995086669922, 95.01705932617188, 95.0142822265625, 95.01155853271484,
  95.00869750976562, 95.00557708740234, 95.00225830078125, 94.99883270263672,
  94.99540710449219, 94.9920883178711, 94.98890686035156, 94.98583984375,
  94.9827880859375, 94.97977447509766, 94.97673034667969, 94.97366333007812,
  94.97062683105469, 94.9675521850586, 94.9643325805664, 94.96102905273438,
  94.95767974853516, 94.95430755615234, 94.95091247558594, 94.947509765625,
  94.94417572021484, 94.9409408569336, 94.93782043457031, 94.93475341796875,
  94.93170928955078, 94.92864990234375, 94.92557525634766, 94.92242431640625,
  94.91922760009766, 94.91602325439453, 94.91275024414062, 94.90956115722656,
  94.90640258789062, 94.90325927734375, 94.900146484375, 94.89708709716797,
  94.89400482177734, 94.89102935791016, 94.88799285888672, 94.88495635986328,
  94.88184356689453, 94.878662109375, 94.87553405761719, 94.87235260009766,
  94.86915588378906, 94.86603546142578, 94.86285400390625, 94.85951232910156,
  94.85604095458984, 94.85258483886719, 94.84925079345703, 94.84595489501953,
  94.84275817871094, 94.83966827392578, 94.83660888671875, 94.8334732055664,
  94.83035278320312, 94.82728576660156, 94.82432556152344, 94.82151794433594,
  94.81874084472656, 94.81629943847656, 94.8138427734375, 94.8114013671875,
  94.80886840820312, 94.80631256103516, 94.80368041992188, 94.80094146728516,
  94.7982406616211, 94.79534912109375, 94.79230499267578, 94.78931427001953,
  94.78646850585938, 94.78399658203125, 94.78172302246094, 94.77948760986328,
  94.77721405029297, 94.7748031616211, 94.7723159790039, 94.76979064941406,
  94.76725006103516, 94.76461791992188, 94.7618408203125, 94.75910186767578,
  94.756591796875, 94.75405883789062, 94.75174713134766, 94.7496109008789,
  94.7475814819336, 94.74547576904297, 94.7432632446289, 94.74098205566406,
  94.73873901367188, 94.73653411865234, 94.73452758789062, 94.73249053955078,
  94.7303695678711, 94.7280502319336, 94.72566986083984, 94.72337341308594,
  94.72110748291016, 94.71894836425781, 94.7169189453125, 94.71489715576172,
  94.71269989013672, 94.71037292480469, 94.7079086303711, 94.70529174804688,
  94.7024154663086, 94.69961547851562, 94.69684600830078, 94.6941146850586,
  94.69143676757812, 94.68875885009766, 94.68626403808594, 94.68395233154297,
  94.6815414428711, 94.67888641357422, 94.67607116699219, 94.6731948852539,
  94.67024993896484, 94.66722106933594, 94.66411590576172, 94.6610336303711,
  94.65797424316406, 94.65493774414062, 94.6518783569336, 94.6488265991211,
  94.64582824707031, 94.64275360107422, 94.63971710205078, 94.63675689697266,
  94.63394165039062, 94.63114166259766, 94.62846374511719, 94.62586212158203,
  94.6232681274414, 94.62076568603516, 94.6184310913086, 94.6161880493164,
  94.61396026611328, 94.61175537109375, 94.60954284667969, 94.60726928710938,
  94.60498809814453, 94.60273742675781, 94.6005630493164, 94.59844207763672,
  94.59635925292969, 94.59434509277344, 94.59231567382812, 94.59024810791016,
  94.58814239501953, 94.58607482910156, 94.58412170410156, 94.58231353759766,
  94.58060455322266, 94.57894134521484, 94.5772705078125, 94.5756607055664,
  94.57402038574219, 94.57234954833984, 94.57064056396484, 94.56889343261719,
  94.56719207763672, 94.56561279296875, 94.56412506103516, 94.56267547607422,
  94.56120300292969, 94.55976867675781, 94.55833435058594, 94.55689239501953,
  94.55543518066406, 94.55400848388672, 94.5526123046875, 94.55123901367188,
  94.54983520507812, 94.54842376708984, 94.547119140625, 94.5460433959961,
  94.54502868652344, 94.54398345947266, 94.54292297363281, 94.5418930053711,
  94.54094696044922, 94.54006958007812, 94.53932189941406, 94.53864288330078,
  94.53802490234375, 94.53741455078125, 94.53682708740234, 94.53620147705078,
  94.53564453125, 94.53497314453125, 94.53429412841797, 94.53364562988281,
  94.53306579589844, 94.53256225585938, 94.53208923339844, 94.53166961669922,
  94.5312728881836, 94.53089141845703, 94.53056335449219, 94.53028106689453,
  94.52999114990234, 94.5296630859375, 94.52936553955078, 94.52914428710938,
  94.5289535522461, 94.52880859375, 94.52869415283203, 94.52857208251953,
  94.52835083007812, 94.52810668945312, 94.52787780761719, 94.52761840820312,
  94.52738952636719, 94.52719116210938, 94.52709197998047, 94.52703857421875,
  94.52699279785156, 94.52688598632812, 94.5268325805664, 94.52682495117188,
  94.52677917480469, 94.5268325805664, 94.52690124511719, 94.52694702148438,
  94.5270004272461, 94.52703094482422, 94.52708435058594, 94.52716064453125,
  94.52723693847656, 94.52732849121094, 94.52747344970703, 94.5276870727539,
  94.52796173095703, 94.52825164794922, 94.52864837646484, 94.529052734375,
  94.52947235107422, 94.52991485595703, 94.5303726196289, 94.53089141845703,
  94.53134155273438, 94.53187561035156, 94.53246307373047, 94.53314971923828,
  94.53389739990234, 94.53468322753906, 94.53548431396484, 94.53632354736328,
  94.53717041015625, 94.53800201416016, 94.53881072998047, 94.53963470458984,
  94.54046630859375, 94.54135131835938, 94.54235076904297, 94.54348754882812,
  94.54468536376953, 94.54586791992188, 94.5469741821289, 94.54802703857422,
  94.54905700683594, 94.55008697509766, 94.5511474609375, 94.55220794677734,
  94.55328369140625, 94.55433654785156, 94.55535125732422, 94.55632781982422,
  94.55725860595703, 94.55818939208984, 94.55915069580078, 94.56016540527344,
  94.5611572265625, 94.56214141845703, 94.56311798095703, 94.5641098022461,
  94.56515502929688, 94.56621551513672, 94.56725311279297, 94.56822967529297,
  94.56914520263672, 94.57000732421875, 94.57088470458984, 94.5717544555664,
  94.5726089477539, 94.5734634399414, 94.5743179321289, 94.57518768310547,
  94.57603454589844, 94.57688903808594, 94.57774353027344, 94.57860565185547,
  94.5794448852539, 94.58027648925781, 94.58119201660156, 94.58201599121094,
  94.58287811279297, 94.58372497558594, 94.58448791503906, 94.58523559570312,
  94.58594512939453, 94.5866470336914, 94.58736419677734, 94.58805084228516,
  94.5887680053711, 94.58955383300781, 94.59037780761719, 94.59112548828125,
  94.59180450439453, 94.59246063232422, 94.59325408935547, 94.59407806396484,
  94.59480285644531, 94.59548950195312, 94.5961685180664, 94.59683990478516,
  94.59754180908203, 94.59829711914062, 94.59906005859375, 94.59976959228516,
  94.60050964355469, 94.60135650634766, 94.6022720336914, 94.6031723022461,
  94.60395812988281, 94.60466766357422, 94.60539245605469, 94.606201171875,
  94.60694885253906, 94.60768127441406, 94.60845184326172, 94.60919952392578,
  94.60989379882812, 94.61051177978516, 94.61103057861328, 94.61156463623047,
  94.61210632324219, 94.61260223388672, 94.61321258544922, 94.61400604248047,
  94.61471557617188, 94.61532592773438, 94.61583709716797, 94.61637115478516,
  94.61688232421875, 94.61737823486328, 94.61786651611328, 94.61831665039062,
  94.61873626708984, 94.61905670166016, 94.61931610107422, 94.61956787109375,
  94.61984252929688, 94.6201171875, 94.62039947509766, 94.62069702148438,
  94.62106323242188, 94.62139129638672, 94.62169647216797, 94.62200927734375,
  94.622314453125, 94.62256622314453, 94.62279510498047, 94.623046875,
  94.62333679199219, 94.62369537353516, 94.6240463256836, 94.62434387207031,
  94.62460327148438, 94.62483978271484, 94.62506103515625, 94.6252670288086,
  94.62545776367188, 94.62562561035156, 94.62581634521484, 94.62599182128906,
  94.62616729736328, 94.62635803222656, 94.62657165527344, 94.62670135498047,
  94.62665557861328, 94.62664794921875, 94.62671661376953, 94.62682342529297,
  94.62698364257812, 94.6271743774414, 94.62726593017578, 94.62720489501953,
  94.62703704833984, 94.62701416015625, 94.6271743774414, 94.62735748291016,
  94.62747192382812, 94.62747192382812, 94.62742614746094, 94.62740325927734,
  94.6273422241211, 94.6272964477539, 94.62732696533203, 94.62735748291016,
  94.6273422241211, 94.62727355957031, 94.62718963623047, 94.62715911865234,
  94.62720489501953, 94.62724304199219, 94.6271743774414, 94.62688446044922,
  94.62638092041016, 94.62577819824219, 94.62504577636719, 94.62416076660156,
  94.6233901977539, 94.62284088134766, 94.62244415283203, 94.62203979492188,
  94.6216049194336, 94.62105560302734, 94.62039184570312, 94.61979675292969,
  94.61929321289062, 94.61882781982422, 94.61840057373047, 94.61795043945312,
  94.61746215820312, 94.61701202392578, 94.61660766601562, 94.61612701416016,
  94.61556243896484, 94.61497497558594, 94.61437225341797, 94.61382293701172,
  94.61335754394531, 94.61288452148438, 94.61248779296875, 94.61204528808594,
  94.61157989501953, 94.6111068725586, 94.61066436767578, 94.61029052734375,
  94.61003112792969, 94.60981750488281, 94.60958862304688, 94.60932159423828,
  94.60902404785156, 94.6087646484375, 94.60858154296875, 94.60844421386719,
  94.60836029052734, 94.6083755493164, 94.60834503173828, 94.6082534790039,
  94.6081314086914, 94.60800170898438, 94.60782623291016, 94.60760498046875,
  94.6075210571289, 94.60762786865234, 94.60791778564453, 94.60836029052734,
  94.60884094238281, 94.60920715332031, 94.6093978881836, 94.60941314697266,
  94.60935974121094, 94.60919952392578, 94.60911560058594, 94.60918426513672,
  94.60939025878906, 94.6097640991211, 94.6102294921875, 94.6106948852539,
  94.6111068725586, 94.61137390136719, 94.61151123046875, 94.61157989501953,
  94.61166381835938, 94.61193084716797, 94.61247253417969, 94.61322784423828,
  94.61408233642578, 94.61492919921875, 94.6156005859375, 94.6161117553711,
  94.61647033691406, 94.61674499511719, 94.61712646484375, 94.61781311035156,
  94.6187744140625, 94.61994171142578, 94.62091827392578, 94.62165069580078,
  94.62223052978516, 94.62258911132812, 94.62281799316406, 94.62315368652344,
  94.62371063232422, 94.62454223632812, 94.62551879882812, 94.62647247314453,
  94.62742614746094, 94.62823486328125, 94.62895965576172, 94.62967681884766,
  94.63038635253906, 94.63105010986328, 94.63162231445312, 94.63217163085938,
  94.63275909423828, 94.63351440429688, 94.63448333740234, 94.63556671142578,
  94.63677215576172, 94.63795471191406, 94.63902282714844, 94.64012908935547,
  94.64140319824219, 94.64273834228516, 94.64398193359375, 94.6450424194336,
  94.64600372314453, 94.64680480957031, 94.64762115478516, 94.64864349365234,
  94.64996337890625, 94.65147399902344, 94.65298461914062, 94.65445709228516,
  94.65574645996094, 94.65667724609375, 94.65763092041016, 94.65846252441406,
  94.65946960449219, 94.66069030761719, 94.6620864868164, 94.66360473632812,
  94.66519165039062, 94.666748046875, 94.668212890625, 94.66957092285156,
  94.67082977294922, 94.67204284667969, 94.67333221435547, 94.6747055053711,
  94.67623901367188, 94.6778793334961, 94.67950439453125, 94.680908203125,
  94.68243408203125, 94.68396759033203, 94.68545532226562, 94.6869125366211,
  94.68828582763672, 94.68968200683594, 94.69107818603516, 94.69258880615234,
  94.6941909790039, 94.69584655761719, 94.69759368896484, 94.699462890625,
  94.70114135742188, 94.70264434814453, 94.70415496826172, 94.70578002929688,
  94.70733642578125, 94.70880126953125, 94.71028137207031, 94.71179962158203,
  94.7133560180664, 94.71490478515625, 94.71647644042969, 94.71807098388672,
  94.7197265625, 94.721435546875, 94.72313690185547, 94.72480773925781,
  94.72650146484375, 94.7281723022461, 94.72986602783203, 94.7315902709961,
  94.73341369628906, 94.73528289794922, 94.73707580566406, 94.7387924194336,
  94.74040222167969, 94.7419662475586, 94.74356079101562, 94.74524688720703,
  94.74707794189453, 94.74899291992188, 94.75090026855469, 94.75273132324219,
  94.75442504882812, 94.7560043334961, 94.75772094726562, 94.75910949707031,
  94.76071166992188, 94.76237487792969, 94.76410675048828, 94.7657241821289,
  94.76718139648438, 94.76846313476562, 94.76969909667969, 94.77073669433594,
  94.77207946777344, 94.77375030517578, 94.77554321289062, 94.77738189697266,
  94.77918243408203, 94.78101348876953, 94.78288269042969, 94.78470611572266,
  94.78642272949219, 94.7881088256836, 94.7898178100586, 94.79153442382812,
  94.79322814941406, 94.79486846923828, 94.79647827148438, 94.79808044433594,
  94.79972839355469, 94.80148315429688, 94.80323028564453, 94.80492401123047,
  94.80643463134766, 94.80780792236328, 94.80919647216797, 94.81070709228516,
  94.8123550415039, 94.8140640258789, 94.81591033935547, 94.81777954101562,
  94.81963348388672, 94.82135009765625, 94.82316589355469, 94.82502746582031,
  94.82649230957031, 94.82782745361328, 94.82911682128906, 94.83045959472656,
  94.83183288574219, 94.83328247070312, 94.83489990234375, 94.83662414550781,
  94.83844757080078, 94.84027099609375, 94.8420639038086, 94.84378814697266,
  94.84539794921875, 94.84687042236328, 94.84833526611328, 94.8498764038086,
  94.85147094726562, 94.85304260253906, 94.85477447509766, 94.85635375976562,
  94.85779571533203, 94.85946655273438, 94.86128234863281, 94.8632583618164,
  94.86479949951172, 94.86640167236328, 94.86791229248047, 94.8693618774414,
  94.87083435058594, 94.87235260009766, 94.87388610839844, 94.87545013427734,
  94.87706756591797, 94.8789291381836, 94.88050842285156, 94.88240814208984,
  94.88436889648438, 94.88630676269531, 94.88822174072266, 94.89020538330078,
  94.89187622070312, 94.89370727539062, 94.89558410644531, 94.8974380493164,
  94.8993911743164, 94.90098571777344, 94.90298461914062, 94.90462493896484,
  94.906494140625, 94.90838623046875, 94.9102554321289, 94.91220092773438,
  94.91425323486328, 94.91634368896484, 94.91850280761719, 94.92066192626953,
  94.9230728149414, 94.92513275146484, 94.92733001708984, 94.92958068847656,
  94.9318618774414, 94.93414306640625, 94.93640899658203, 94.93875122070312,
  94.94100952148438, 94.94315338134766, 94.94532775878906, 94.94758605957031,
  94.94994354248047, 94.95252227783203, 94.95523834228516, 94.95804595947266,
  94.96084594726562, 94.96357727050781, 94.96617889404297, 94.96861267089844,
  94.97087097167969, 94.97317504882812, 94.97555541992188, 94.97808837890625,
  94.98074340820312, 94.98348236083984, 94.98628234863281, 94.98905944824219,
  94.99193572998047, 94.9947738647461, 94.9975357055664, 95.00025939941406,
  95.00287628173828, 95.00545501708984, 95.00789642333984, 95.01036834716797,
  95.0129165649414, 95.01554870605469, 95.01826477050781, 95.02104187011719,
  95.0239028930664, 95.02684783935547, 95.02986145019531, 95.03275299072266,
  95.03543853759766, 95.03797912597656, 95.04037475585938, 95.0426254272461,
  95.04470825195312, 95.04669189453125, 95.04869079589844, 95.05078887939453,
  95.052978515625, 95.05534362792969, 95.05773162841797, 95.06002044677734,
  95.06228637695312, 95.06439971923828, 95.06656646728516, 95.06855773925781,
  95.07061004638672, 95.07267761230469, 95.07482147216797, 95.07691192626953,
  95.07896423339844, 95.08116149902344, 95.08341979980469, 95.08562469482422,
  95.0877914428711, 95.08990478515625, 95.09200286865234, 95.0941162109375,
  95.09624481201172, 95.09841918945312, 95.10065460205078, 95.10289001464844,
  95.10517120361328, 95.1075439453125, 95.1099624633789, 95.11235046386719,
  95.11471557617188, 95.11702728271484, 95.11909484863281, 95.12100219726562,
  95.12297058105469, 95.12503051757812, 95.12730407714844, 95.12982177734375,
  95.1325454711914, 95.1353759765625, 95.13817596435547, 95.14089965820312,
  95.14353942871094, 95.1460952758789, 95.1485366821289, 95.15078735351562,
  95.15290832519531, 95.15504455566406, 95.15731811523438, 95.15985870361328,
  95.16259002685547, 95.16555786132812, 95.1687240600586, 95.17186737060547,
  95.17494201660156, 95.17786407470703, 95.1806640625, 95.18333435058594,
  95.18587493896484, 95.18839263916016, 95.19100189208984, 95.1938247680664,
  95.19683837890625, 95.199951171875, 95.20306396484375, 95.20623779296875,
  95.20957946777344, 95.21293640136719, 95.2162857055664, 95.21974182128906,
  95.2234115600586, 95.22718811035156, 95.23078918457031, 95.23407745361328,
  95.2371826171875, 95.2400894165039, 95.24299621582031, 95.2463607788086,
  95.2501220703125, 95.25426483154297, 95.25859832763672, 95.2625503540039,
  95.2662353515625, 95.26982879638672, 95.2723159790039, 95.27489471435547,
  95.27765655517578, 95.28011322021484, 95.28228759765625, 95.2848892211914,
  95.28730010986328, 95.29000854492188, 95.29356384277344, 95.29759216308594,
  95.30216979980469, 95.30769348144531, 95.31261444091797, 95.31672668457031,
  95.3198013305664, 95.3224105834961, 95.3255386352539, 95.32804870605469,
  95.33079528808594, 95.33348846435547, 95.33647155761719, 95.33966827392578,
  95.34320831298828, 95.3480224609375, 95.35240936279297, 95.35806274414062,
  95.36286163330078, 95.36665344238281, 95.3709487915039, 95.37437438964844,
  95.37760925292969, 95.38047790527344, 95.38362121582031, 95.38670349121094,
  95.38973999023438, 95.39302062988281, 95.39698791503906, 95.40103149414062,
  95.40599822998047, 95.41133117675781, 95.41676330566406, 95.42174530029297,
  95.42674255371094, 95.4306640625, 95.43490600585938, 95.43814849853516,
  95.44165802001953, 95.44561004638672, 95.44918823242188, 95.45392608642578,
  95.4581069946289, 95.46307373046875, 95.46868896484375, 95.47318267822266,
  95.4784927368164, 95.48320007324219, 95.48733520507812, 95.49190521240234,
  95.4969711303711, 95.50167846679688, 95.50597381591797, 95.51081848144531,
  95.51564025878906, 95.52049255371094, 95.52540588378906, 95.53081512451172,
  95.53524017333984, 95.54010772705078, 95.54495239257812, 95.54981231689453,
  95.55461883544922, 95.55944061279297, 95.5642318725586, 95.56903076171875,
  95.57386016845703, 95.5787124633789, 95.58353424072266, 95.58832550048828,
  95.59310913085938, 95.59783172607422, 95.60252380371094, 95.60717010498047,
  95.61177825927734, 95.6163101196289, 95.62085723876953, 95.62548828125,
  95.62999725341797, 95.63440704345703, 95.63874053955078, 95.64308166503906,
  95.64781188964844, 95.65205383300781, 95.65631866455078, 95.66056060791016,
  95.66436767578125, 95.66854858398438, 95.67321014404297, 95.67694091796875,
  95.68102264404297, 95.68511962890625, 95.68922424316406, 95.69373321533203,
  95.69737243652344, 95.70140075683594, 95.70539093017578, 95.70948028564453,
  95.7135009765625, 95.71746826171875, 95.7213134765625, 95.72511291503906,
  95.7288589477539, 95.73265838623047, 95.73643493652344, 95.74029541015625,
  95.74409484863281, 95.74786376953125, 95.75155639648438, 95.7553482055664,
  95.7595443725586, 95.76292419433594, 95.76659393310547, 95.77021026611328,
  95.7738265991211, 95.77757263183594, 95.78141784667969, 95.78528594970703,
  95.7891845703125, 95.79300689697266, 95.79666900634766, 95.80042266845703,
  95.80401611328125, 95.80758666992188, 95.81118774414062, 95.81476593017578,
  95.81815338134766, 95.82138061523438, 95.82454681396484, 95.82767486572266,
  95.83076477050781, 95.83385467529297, 95.8368911743164, 95.83990478515625,
  95.84300994873047, 95.84611511230469, 95.84917449951172, 95.85228729248047,
  95.85539245605469, 95.8584213256836, 95.86140441894531, 95.86431121826172,
  95.86724090576172, 95.87019348144531, 95.873046875, 95.87577819824219,
  95.87847137451172, 95.88115692138672, 95.88385009765625, 95.88648223876953,
  95.88908386230469, 95.89171600341797, 95.89434051513672, 95.89698028564453,
  95.89955139160156, 95.90199279785156, 95.90450286865234, 95.9070053100586,
  95.90948486328125, 95.91191101074219, 95.91429138183594, 95.91661834716797,
  95.91895294189453, 95.92134094238281, 95.92373657226562, 95.9261474609375,
  95.92855072021484, 95.93095397949219, 95.93330383300781, 95.93558502197266,
  95.93782806396484, 95.94005584716797, 95.94229125976562, 95.9445571899414,
  95.94673156738281, 95.94886779785156, 95.95104217529297, 95.95326232910156,
  95.95545196533203, 95.95758056640625, 95.95970916748047, 95.96183776855469,
  95.96395111083984, 95.96611785888672, 95.96835327148438, 95.97057342529297,
  95.97274017333984, 95.974853515625, 95.97692108154297, 95.97894287109375,
  95.98088073730469, 95.98287963867188, 95.98503112792969, 95.9872817993164,
  95.98921203613281, 95.99091339111328, 95.99258422851562, 95.9943618774414,
  95.99642181396484, 95.99824523925781, 96.00013732910156, 96.00177764892578,
  96.0035629272461, 96.00537109375, 96.00712585449219, 96.00885772705078,
  96.0106201171875, 96.01241302490234, 96.0142822265625, 96.01614379882812,
  96.01781463623047, 96.01947021484375, 96.02147674560547, 96.02345275878906,
  96.02509307861328, 96.02676391601562, 96.02831268310547, 96.0299301147461,
  96.03168487548828, 96.03351593017578, 96.03533935546875, 96.03718566894531,
  96.03900909423828, 96.0408706665039, 96.04266357421875, 96.04439544677734,
  96.0460433959961, 96.04759979248047, 96.04911804199219, 96.0506362915039,
  96.05210876464844, 96.05376434326172, 96.0552749633789, 96.05682373046875,
  96.05815124511719, 96.05963897705078, 96.06080627441406, 96.06208038330078,
  96.06333923339844, 96.06461334228516, 96.06600952148438, 96.06729888916016,
  96.06853485107422, 96.06975555419922, 96.07086944580078, 96.07209014892578,
  96.07330322265625, 96.0744857788086, 96.07564544677734, 96.07682800292969,
  96.07795715332031, 96.07906341552734, 96.08013153076172, 96.08118438720703,
  96.08228302001953, 96.08348846435547, 96.084716796875, 96.0859375,
  96.08707427978516, 96.08812713623047, 96.0891342163086, 96.09014892578125,
  96.09122467041016, 96.09239959716797, 96.09366607666016, 96.09496307373047,
  96.09622192382812, 96.09749603271484, 96.09883117675781, 96.1001968383789,
  96.10155487060547, 96.10287475585938, 96.1041259765625, 96.10543823242188,
  96.10686492919922, 96.10832214355469, 96.10969543457031, 96.11097717285156,
  96.1122817993164, 96.1136245727539, 96.1150894165039, 96.11676788330078,
  96.11863708496094, 96.12052917480469, 96.12234497070312, 96.12399291992188,
  96.12549591064453, 96.12699890136719, 96.12852478027344, 96.13008117675781,
  96.13167572021484, 96.13334655761719, 96.13507080078125, 96.1369857788086,
  96.13870239257812, 96.14046478271484, 96.14204406738281, 96.14385986328125,
  96.14564514160156, 96.14734649658203, 96.14889526367188, 96.15035247802734,
  96.15180969238281, 96.15324401855469, 96.15475463867188, 96.15641784667969,
  96.15817260742188, 96.15997314453125, 96.16175079345703, 96.16346740722656,
  96.16510772705078, 96.16673278808594, 96.16841888427734, 96.16972351074219,
  96.17115783691406, 96.17257690429688, 96.17400360107422, 96.17558288574219,
  96.1771011352539, 96.17852020263672, 96.18033599853516, 96.18183135986328,
  96.18339538574219, 96.18482971191406, 96.18612670898438, 96.18734741210938,
  96.18859100341797, 96.1899185180664, 96.19147491455078, 96.19278717041016,
  96.19420623779297, 96.19558715820312, 96.19693756103516, 96.19831085205078,
  96.19971466064453, 96.20108032226562, 96.20240020751953, 96.20378875732422,
  96.20503997802734, 96.2062759399414, 96.20741271972656, 96.20880126953125,
  96.20990753173828, 96.21123504638672, 96.21233367919922, 96.21370697021484,
  96.21483612060547, 96.21621704101562, 96.21728515625, 96.21836853027344,
  96.2193832397461, 96.22042846679688, 96.22149658203125, 96.2226333618164,
  96.22381591796875, 96.22500610351562, 96.22622680664062, 96.22737884521484,
  96.22846221923828, 96.22944641113281, 96.2303695678711, 96.2313232421875,
  96.23228454589844, 96.23324584960938, 96.23419189453125, 96.23518371582031,
  96.23609161376953, 96.23703002929688, 96.23793029785156, 96.23878479003906,
  96.2396240234375, 96.24054718017578, 96.24153137207031, 96.24246215820312,
  96.24346160888672, 96.24433898925781, 96.24514770507812, 96.24591064453125,
  96.24665069580078, 96.24736785888672, 96.2480697631836, 96.24871826171875,
  96.24937438964844, 96.25015258789062, 96.2509536743164, 96.25174713134766,
  96.25249481201172, 96.25320434570312, 96.2538833618164, 96.25458526611328,
  96.25524139404297, 96.255859375, 96.25647735595703, 96.25708770751953,
  96.2576675415039, 96.2582778930664, 96.25891876220703, 96.25963592529297,
  96.2602310180664, 96.26089477539062, 96.26148986816406, 96.26200866699219,
  96.26248168945312, 96.262939453125, 96.26334381103516, 96.2637939453125,
  96.26420593261719, 96.26464080810547, 96.26508331298828, 96.26557159423828,
  96.26609802246094, 96.26663970947266, 96.2671127319336, 96.2675552368164,
  96.26785278320312, 96.26809692382812, 96.268310546875, 96.26854705810547,
  96.2688217163086, 96.26914978027344, 96.2695541381836, 96.27001190185547,
  96.27041625976562, 96.27052307128906, 96.27059173583984, 96.27072143554688,
  96.27101135253906, 96.27139282226562, 96.27175903320312, 96.27201080322266,
  96.27210235595703, 96.27217102050781, 96.272216796875, 96.2723388671875,
  96.27253723144531, 96.27272033691406, 96.27287292480469, 96.2729721069336,
  96.27307891845703, 96.27311706542969, 96.27313232421875, 96.27308654785156,
  96.27304077148438, 96.27302551269531, 96.27293395996094, 96.27283477783203,
  96.27276611328125, 96.2727279663086, 96.27247619628906, 96.27216339111328,
  96.27214050292969, 96.27234649658203, 96.27256774902344, 96.27269744873047,
  96.27263641357422, 96.27239990234375, 96.27208709716797, 96.27169799804688,
  96.27143859863281, 96.27127075195312, 96.27113342285156, 96.27095031738281,
  96.27088165283203, 96.27091979980469, 96.2705078125, 96.2701187133789,
  96.26976013183594, 96.26930236816406, 96.26869201660156, 96.26823425292969,
  96.26782989501953, 96.26744079589844, 96.26720428466797, 96.26689147949219,
  96.26634216308594, 96.2658920288086, 96.2657470703125, 96.26569366455078,
  96.26558685302734, 96.2653579711914, 96.26524353027344, 96.2650375366211,
  96.26469421386719, 96.26420593261719, 96.26362609863281, 96.2630615234375,
  96.2626953125, 96.26250457763672, 96.26244354248047, 96.26248168945312,
  96.26241302490234, 96.26231384277344, 96.26231384277344, 96.26232147216797,
  96.26223754882812, 96.26184844970703, 96.26116943359375, 96.26039123535156,
  96.25947570800781, 96.25859069824219, 96.25782012939453, 96.25729370117188,
  96.25714111328125, 96.25708770751953, 96.25711059570312, 96.25708770751953,
  96.25682830810547, 96.25664520263672, 96.25653839111328, 96.25611877441406,
  96.25525665283203, 96.25418853759766, 96.25308227539062, 96.25255584716797,
  96.25213623046875, 96.25186920166016, 96.25162506103516, 96.2513656616211,
  96.25102233886719, 96.25054168701172, 96.24993133544922, 96.2491683959961,
  96.24835205078125, 96.24748992919922, 96.2466812133789, 96.24586486816406,
  96.2449722290039, 96.2440185546875, 96.24304962158203, 96.24207305908203,
  96.24108123779297, 96.24002838134766, 96.23905181884766, 96.2380599975586,
  96.23687744140625, 96.23593139648438, 96.23497772216797, 96.23383331298828,
  96.23270416259766, 96.2315902709961, 96.2303695678711, 96.22907257080078,
  96.22796630859375, 96.22697448730469, 96.22586822509766, 96.22477722167969,
  96.2238998413086, 96.22309875488281, 96.22225952148438, 96.22132110595703,
  96.22045135498047, 96.21980285644531, 96.2193374633789, 96.2189712524414,
  96.21853637695312, 96.2178955078125, 96.21707916259766, 96.21611785888672,
  96.215087890625, 96.21411895751953, 96.21327209472656, 96.21257781982422,
  96.2120361328125, 96.2115707397461, 96.21112060546875, 96.21062469482422,
  96.21014404296875, 96.20963287353516, 96.20912170410156, 96.20863342285156,
  96.2081298828125, 96.20767974853516, 96.20734405517578, 96.20706176757812,
  96.20679473876953, 96.20647430419922, 96.20610046386719, 96.20575714111328,
  96.2054672241211, 96.20515441894531, 96.20479583740234, 96.2044448852539,
  96.20417785644531, 96.20401000976562, 96.20391845703125, 96.2037582397461,
  96.20359802246094, 96.20338439941406, 96.20313262939453, 96.20291137695312,
  96.2026596069336, 96.20242309570312, 96.20220184326172, 96.20203399658203,
  96.2019271850586, 96.2018814086914, 96.20186614990234, 96.20182800292969,
  96.20178985595703, 96.2017593383789, 96.20178985595703, 96.20188903808594,
  96.20203399658203, 96.2021713256836, 96.2022933959961, 96.20246124267578,
  96.20256042480469, 96.20260620117188, 96.20264434814453, 96.20274353027344,
  96.20285034179688, 96.20303344726562, 96.20328521728516, 96.20357513427734,
  96.20384216308594, 96.20407104492188, 96.2042465209961, 96.20438385009766,
  96.20453643798828, 96.20469665527344, 96.20487976074219, 96.20509338378906,
  96.20533752441406, 96.20559692382812, 96.2058334350586, 96.20609283447266,
  96.20635986328125, 96.2066421508789, 96.20686340332031, 96.20709991455078,
  96.20736694335938, 96.20767211914062, 96.2080307006836, 96.20843505859375,
  96.20883178710938, 96.20927429199219, 96.20974731445312, 96.21024322509766,
  96.21075439453125, 96.21127319335938, 96.21179962158203, 96.21237182617188,
  96.21296691894531, 96.21357727050781, 96.2142333984375, 96.2148666381836,
  96.21549224853516, 96.21612548828125, 96.21676635742188, 96.2174301147461,
  96.21815490722656, 96.2188720703125, 96.21952056884766, 96.22013854980469,
  96.2207260131836, 96.2213363647461, 96.22200012207031, 96.22273254394531,
  96.22354125976562, 96.224365234375, 96.22515869140625, 96.2259292602539,
  96.22666931152344, 96.2274169921875, 96.22821807861328, 96.22909545898438,
  96.23001098632812, 96.23100280761719, 96.23207092285156, 96.23320007324219,
  96.23430633544922, 96.2353515625, 96.2364273071289, 96.2375259399414,
  96.23870086669922, 96.2398910522461, 96.24104309082031, 96.24221801757812,
  96.24336242675781, 96.24449157714844, 96.2456283569336, 96.24683380126953,
  96.24809265136719, 96.24932098388672, 96.2505111694336, 96.25170135498047,
  96.25291442871094, 96.25415802001953, 96.2554702758789, 96.25679779052734,
  96.25817108154297, 96.25957489013672, 96.2609634399414, 96.2623291015625,
  96.26368713378906, 96.26508331298828, 96.26646423339844, 96.26790618896484,
  96.2693862915039, 96.27093505859375, 96.27246856689453, 96.27397155761719,
  96.275390625, 96.27672576904297, 96.27812194824219, 96.2795639038086,
  96.28114318847656, 96.28263854980469, 96.28414154052734, 96.2856674194336,
  96.28721618652344, 96.28880310058594, 96.2905044555664, 96.29222106933594,
  96.29393768310547, 96.2956771850586, 96.29740905761719, 96.2990951538086,
  96.30070495605469, 96.30236053466797, 96.30404663085938, 96.3058090209961,
  96.30758666992188, 96.30941772460938, 96.31132507324219, 96.31330108642578,
  96.31524658203125, 96.31713104248047, 96.31892395019531, 96.32064819335938,
  96.32243347167969, 96.3242416381836, 96.32597351074219, 96.32769775390625,
  96.3294677734375, 96.33120727539062, 96.3330078125, 96.33487701416016,
  96.3367919921875, 96.33879089355469, 96.34085845947266, 96.3428955078125,
  96.34484100341797, 96.34676361083984, 96.34873962402344, 96.3506851196289,
  96.35260772705078, 96.35456085205078, 96.35645294189453, 96.35835266113281,
  96.36028289794922, 96.3622817993164, 96.36429595947266, 96.36636352539062,
  96.368408203125, 96.3704605102539, 96.3724365234375, 96.37437438964844,
  96.37629699707031, 96.37818145751953, 96.38004302978516, 96.38179779052734,
  96.3835220336914, 96.38534545898438, 96.38722229003906, 96.38922119140625,
  96.39128112792969, 96.39330291748047, 96.39531707763672, 96.3971939086914,
  96.39894104003906, 96.40070343017578, 96.40254211425781, 96.40442657470703,
  96.40634155273438, 96.40823364257812, 96.41012573242188, 96.41199493408203,
  96.4138412475586, 96.41566467285156, 96.41743469238281, 96.41923522949219,
  96.4211196899414, 96.42314910888672, 96.42524719238281, 96.42729949951172,
  96.42932891845703, 96.43131256103516, 96.4332504272461, 96.43521118164062,
  96.4371337890625, 96.4391098022461, 96.44110107421875, 96.44307708740234,
  96.4450912475586, 96.44709014892578, 96.44886779785156, 96.4504623413086,
  96.45198059082031, 96.45368957519531, 96.4555435180664, 96.45758819580078,
  96.4596176147461, 96.46165466308594, 96.4636459350586, 96.46553802490234,
  96.46736907958984, 96.46918487548828, 96.470947265625, 96.47251892089844,
  96.4740982055664, 96.47572326660156, 96.47750854492188, 96.47931671142578,
  96.48116302490234, 96.48287963867188, 96.48455047607422, 96.48627471923828,
  96.48799896240234, 96.48954772949219, 96.49118041992188, 96.49296569824219,
  96.49480438232422, 96.4966812133789, 96.49853515625, 96.50021362304688,
  96.50190734863281, 96.50353240966797, 96.50508117675781, 96.50674438476562,
  96.50846862792969, 96.51006317138672, 96.5116195678711, 96.51327514648438,
  96.51490020751953, 96.51660919189453, 96.51832580566406, 96.51973724365234,
  96.52106475830078, 96.52250671386719, 96.52412414550781, 96.5257797241211,
  96.52749633789062, 96.52930450439453, 96.5311508178711, 96.53294372558594,
  96.53459167480469, 96.53601837158203, 96.53760528564453, 96.53897094726562,
  96.54046630859375, 96.5420150756836, 96.54357147216797, 96.54518127441406,
  96.54688262939453, 96.54843139648438, 96.54985809326172, 96.55126953125,
  96.55270385742188, 96.55413818359375, 96.55560302734375, 96.55714416503906,
  96.558837890625, 96.56040954589844, 96.56205749511719, 96.56364440917969,
  96.56524658203125, 96.5667953491211, 96.56836700439453, 96.56978607177734,
  96.57113647460938, 96.57234191894531, 96.5734634399414, 96.57453155517578,
  96.57567596435547, 96.57693481445312, 96.57826232910156, 96.57967376708984,
  96.58096313476562, 96.58198547363281, 96.58285522460938, 96.58364868164062,
  96.58454132080078, 96.58548736572266, 96.58631896972656, 96.58732604980469,
  96.58859252929688, 96.5899658203125, 96.59133911132812, 96.59244537353516,
  96.59347534179688, 96.59429168701172, 96.5948715209961, 96.59541320800781,
  96.5959701538086, 96.59677124023438, 96.5977783203125, 96.59876251220703,
  96.59955596923828, 96.6002426147461, 96.60104370117188, 96.60199737548828,
  96.60311889648438, 96.60424041748047, 96.60546875, 96.60682678222656,
  96.60807037353516, 96.60897827148438, 96.6096420288086, 96.61015319824219,
  96.61060333251953, 96.61102294921875, 96.61124420166016, 96.61174774169922,
  96.61279296875, 96.61381530761719, 96.61492919921875, 96.61570739746094,
  96.61615753173828, 96.6164321899414, 96.61668395996094, 96.61708068847656,
  96.61759185791016, 96.61820220947266, 96.6189193725586, 96.61968994140625,
  96.62060546875, 96.62142944335938, 96.62215423583984, 96.62271118164062,
  96.62317657470703, 96.62358856201172, 96.62403869628906, 96.62445831298828,
  96.6250228881836, 96.62557983398438, 96.626220703125, 96.62687683105469,
  96.62760925292969, 96.62842559814453, 96.62925720214844, 96.63021850585938,
  96.63133239746094, 96.63241577148438, 96.63345336914062, 96.63433074951172,
  96.63534545898438, 96.63597869873047, 96.63664245605469, 96.63726043701172,
  96.63792419433594, 96.638671875, 96.63945770263672, 96.64015197753906,
  96.64057159423828, 96.640869140625, 96.6409683227539, 96.64094543457031,
  96.64096069335938, 96.64108276367188, 96.64139556884766, 96.64200592041016,
  96.64270782470703, 96.64334106445312, 96.64405059814453, 96.64481353759766,
  96.6457290649414, 96.64651489257812, 96.64694213867188, 96.64727783203125,
  96.64741516113281, 96.64759826660156, 96.6478042602539,
];
