import { Container, Stack, createStyles } from "@mantine/core";
import { TELEMETRY_DATA } from "../data/TelemetryData";
import TelemetryGraph from "../components/TelemetryGraph/TelemetryGraph";
import SlideInComponent from "../components/SlideInComponent";
import { useRef } from "react";
import SectionTitle from "../components/SectionTitle";

const useStyle = createStyles((theme) => ({
  container: {
    [theme.fn.smallerThan("md")]: {
      width: "100%",
    },
  },
}));

export default function TelemetrySection() {
  const root = useRef(null);

  const { classes } = useStyle();

  return (
    <Container fluid ref={root} className={classes.container}>
      <SectionTitle
        title="Effective Telemetry"
        description="A variety of telemetry channels to improve your laptimes"
      />
      <Stack spacing="xl">
        {TELEMETRY_DATA.map((element, index) => (
          <SlideInComponent
            duration={400}
            key={index}
            transition="slide-right"
            target={root}
            delay={index * 200}
            component={(props) => (
              <TelemetryGraph {...element} key={index} style={props.style} />
            )}
            styleCleanup={(styles) =>
              styles.display === "none"
                ? {
                    opacity: 0,
                    transform: "translateX(-100%)",
                  }
                : { ...styles }
            }
          />
        ))}
      </Stack>
    </Container>
  );
}
