import { ScaleLinear } from "d3-scale";

const MIN_BOTTOM_TICK_DISTANCE = 32;

export default function filterTurnTicks(
  tickArray: number[],
  walkX: ScaleLinear<number, number, never>
) {
  let lastTickPos: number | null = null;

  const filteredTicks = tickArray.filter((element) => {
    if (!lastTickPos) {
      lastTickPos = walkX(element);
      return true;
    }

    const pos = walkX(element);
    const distance = Math.abs(pos - lastTickPos);
    if (distance < MIN_BOTTOM_TICK_DISTANCE) return false;

    lastTickPos = pos;

    return true;
  });

  return filteredTicks;
}
