export const BRAKE_DATA = [
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0.03420001268386841, 0.21410000324249268, 0.703249990940094,
  0.8667999505996704, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  0.9768499732017517, 0.9634499549865723, 0.95455002784729, 0.9277999997138977,
  0.9128999710083008, 0.8594000339508057, 0.8311499953269958,
  0.8177499771118164, 0.802899956703186, 0.7850500345230103, 0.7820500135421753,
  0.7775999903678894, 0.7627500295639038, 0.7583000063896179, 0.743399977684021,
  0.734499990940094, 0.722599983215332, 0.703249990940094, 0.6854000091552734,
  0.6809499859809875, 0.6586499810218811, 0.6393499970436096,
  0.6259499788284302, 0.6244499683380127, 0.6200000047683716,
  0.6170499920845032, 0.6036499738693237, 0.5724499821662903,
  0.5486500263214111, 0.5337499976158142, 0.5188999772071838,
  0.5010499954223633, 0.48019999265670776, 0.4609000086784363,
  0.44155001640319824, 0.4251999855041504, 0.4237000048160553,
  0.39845001697540283, 0.3790999948978424, 0.36124998331069946,
  0.350849986076355, 0.3404499888420105, 0.333050012588501, 0.32109999656677246,
  0.31220000982284546, 0.2914000153541565, 0.28245002031326294,
  0.27950000762939453, 0.26759999990463257, 0.26315000653266907,
  0.2572000026702881, 0.2512500286102295, 0.2438499927520752,
  0.2423500120639801, 0.2379000186920166, 0.23045000433921814,
  0.21705001592636108, 0.20515000820159912, 0.1977500021457672,
  0.1724500060081482, 0.1620500087738037, 0.1531500220298767,
  0.16500002145767212, 0.16950002312660217, 0.16054999828338623,
  0.15015000104904175, 0.14720001816749573, 0.14419999718666077,
  0.13530001044273376, 0.1323000192642212, 0.12040001153945923,
  0.10405001044273376, 0.09365001320838928, 0.08474999666213989,
  0.07285001873970032, 0.07135000824928284, 0.06540000438690186,
  0.04610002040863037, 0.025250017642974854, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0.020800024271011353, 0.025250017642974854, 0.028250008821487427,
  0.028250008821487427, 0.028250008821487427, 0.028250008821487427,
  0.028250008821487427, 0.028250008821487427, 0.026749998331069946,
  0.022300004959106445, 0.020800024271011353, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0.023800015449523926, 0.03270000219345093, 0.03865000605583191,
  0.07135000824928284, 0.19920000433921814, 0.4609000086784363,
  0.802899956703186, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  0.9411499500274658, 0.8831999897956848, 0.7925000190734863,
  0.7656999826431274, 0.7240999937057495, 0.715149998664856, 0.6958500146865845,
  0.6898999810218811, 0.6779999732971191, 0.6616500020027161,
  0.6288999915122986, 0.6244499683380127, 0.6170499920845032,
  0.6125999689102173, 0.6036499738693237, 0.5992000102996826,
  0.5976999998092651, 0.6007000207901001, 0.6111000180244446,
  0.6200000047683716, 0.6125999689102173, 0.6036499738693237,
  0.5976999998092651, 0.5813499689102173, 0.5605499744415283,
  0.5367500185966492, 0.5233500003814697, 0.4846999943256378,
  0.4682999849319458, 0.44600000977516174, 0.42820000648498535,
  0.4043999910354614, 0.39399999380111694, 0.37165001034736633,
  0.36720001697540283, 0.35234999656677246, 0.341949999332428,
  0.333050012588501, 0.32109999656677246, 0.301800012588501, 0.270550012588501,
  0.23190000653266907, 0.1933000087738037, 0.18285000324249268,
  0.17840000987052917, 0.16950002312660217, 0.1576000154018402,
  0.15459999442100525, 0.15015000104904175, 0.14419999718666077,
  0.13975000381469727, 0.13825002312660217, 0.14125001430511475,
  0.14125001430511475, 0.13825002312660217, 0.13975000381469727,
  0.13975000381469727, 0.13975000381469727, 0.13379999995231628,
  0.1308000087738037, 0.12040001153945923, 0.11445000767707825,
  0.10405001044273376, 0.0877000093460083, 0.056500017642974854,
  0.04610002040863037, 0.023800015449523926, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0.037150025367736816, 0.062450021505355835, 0.0788000226020813,
  0.0832500159740448, 0.0788000226020813, 0.07285001873970032,
  0.057999998331069946, 0.028250008821487427, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0.028250008821487427, 0.12935000658035278, 0.3241000175476074,
  0.5873000025749207, 0.8579000234603882, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 0.9649499654769897, 0.8995000123977661,
  0.8519499897956848, 0.8058500289916992, 0.7493500113487244,
  0.7017999887466431, 0.6467499732971191, 0.6155499815940857,
  0.5783500075340271, 0.5382499694824219, 0.5055000185966492,
  0.47280001640319824, 0.44155001640319824, 0.41179999709129333,
  0.40290001034736633, 0.36124998331069946, 0.3255999982357025,
  0.301800012588501, 0.289900004863739, 0.270550012588501, 0.26910001039505005,
  0.270550012588501, 0.26464998722076416, 0.261650025844574, 0.2542499899864197,
  0.24085000157356262, 0.22894999384880066, 0.2155500054359436,
  0.20070001482963562, 0.1768999993801117, 0.14125001430511475,
  0.11300000548362732, 0.0877000093460083, 0.06690001487731934,
  0.05205002427101135, 0.037150025367736816, 0.025250017642974854, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.022300004959106445, 0.04905000329017639,
  0.09665000438690186, 0.29585000872612, 0.40584999322891235,
  0.8549000024795532, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  0.9634499549865723, 0.9307500123977661, 0.8162499666213989,
  0.7805999517440796, 0.694350004196167, 0.6601499915122986, 0.6274499893188477,
  0.6066499948501587, 0.5798500180244446, 0.5530999898910522,
  0.5263500213623047, 0.4980500042438507, 0.47874999046325684,
  0.4653500020503998, 0.4490000009536743, 0.4251999855041504,
  0.4043999910354614, 0.3790999948978424, 0.3315500020980835,
  0.30774998664855957, 0.2869499921798706, 0.26910001039505005,
  0.249750018119812, 0.23340001702308655, 0.22150000929832458,
  0.2111000120639801, 0.20960000157356262, 0.19920000433921814,
  0.19179999828338623, 0.18729999661445618, 0.1768999993801117,
  0.16054999828338623, 0.1590999960899353, 0.1620500087738037,
  0.1665000021457672, 0.1680000126361847, 0.1635500192642212,
  0.1576000154018402, 0.15459999442100525, 0.14570000767707825,
  0.1308000087738037, 0.11149999499320984, 0.07580000162124634,
  0.060950011014938354, 0.03865000605583191, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0.06390002369880676, 0.249750018119812, 0.5590500235557556,
  0.9039999842643738, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.9991499781608582,
  0.9574999809265137, 0.9247999787330627, 0.9054499864578247,
  0.8831999897956848, 0.8445000052452087, 0.8251999616622925,
  0.8206999897956848, 0.802899956703186, 0.7775999903678894, 0.7389500141143799,
  0.7240999937057495, 0.706250011920929, 0.6884000301361084, 0.666100025177002,
  0.6407999992370605, 0.6200000047683716, 0.6007000207901001,
  0.5961999893188477, 0.5873000025749207, 0.5649999976158142,
  0.5486500263214111, 0.5292999744415283, 0.5159500241279602,
  0.5055000185966492, 0.4876500070095062, 0.4653500020503998,
  0.4505000114440918, 0.43709999322891235, 0.4192500114440918,
  0.4088500142097473, 0.40139999985694885, 0.39399999380111694,
  0.39100000262260437, 0.3761500120162964, 0.3731499910354614,
  0.3702000081539154, 0.3657500147819519, 0.35830000042915344,
  0.350849986076355, 0.34345000982284546, 0.3360000252723694,
  0.3360000252723694, 0.333050012588501, 0.32705000042915344,
  0.30924999713897705, 0.2973499894142151, 0.292900025844574,
  0.2914000153541565, 0.281000018119812, 0.26910001039505005,
  0.2601500153541565, 0.2512500286102295, 0.2438499927520752,
  0.23340001702308655, 0.22745001316070557, 0.21854999661445618,
  0.2081499993801117, 0.20070001482963562, 0.17395001649856567,
  0.1620500087738037, 0.14570000767707825, 0.12935000658035278,
  0.10705000162124634, 0.09960001707077026, 0.08619999885559082,
  0.07430002093315125, 0.07135000824928284, 0.06540000438690186,
  0.057999998331069946, 0.047600001096725464, 0.04160001873970032,
  0.031200021505355835, 0.026749998331069946, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0.020800024271011353, 0.07135000824928284, 0.12190002202987671,
  0.16950002312660217, 0.19179999828338623, 0.18435001373291016,
  0.1754000186920166, 0.1635500192642212, 0.1308000087738037,
  0.11445000767707825, 0.06690001487731934, 0.037150025367736816,
  0.026749998331069946, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0.12935000658035278, 0.6007000207901001, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  0.9931999444961548, 0.9366999864578247, 0.8995000123977661,
  0.8579000234603882, 0.786549985408783, 0.7523499727249146, 0.7195999622344971,
  0.6958500146865845, 0.673550009727478, 0.642300009727478, 0.6080999970436096,
  0.602150022983551, 0.5902500152587891, 0.5530999898910522, 0.5367500185966492,
  0.4995500147342682, 0.48019999265670776, 0.47874999046325684,
  0.47574999928474426, 0.4682999849319458, 0.4549500048160553,
  0.4221999943256378, 0.4088500142097473, 0.4043999910354614,
  0.39544999599456787, 0.38655000925064087, 0.36720001697540283,
  0.3345000147819519, 0.2973499894142151, 0.2869499921798706,
  0.27950000762939453, 0.26759999990463257, 0.26315000653266907,
  0.22745001316070557, 0.2037000060081482, 0.17840000987052917,
  0.1323000192642212, 0.1189500093460083, 0.08920001983642578,
  0.0788000226020813, 0.07580000162124634, 0.06690001487731934,
  0.04309999942779541, 0.03865000605583191, 0.028250008821487427,
  0.023800015449523926, 0.031200021505355835, 0.037150025367736816,
  0.04160001873970032, 0.04015001654624939, 0.04015001654624939,
  0.03420001268386841, 0.026749998331069946, 0.035650014877319336,
  0.04160001873970032, 0.04610002040863037, 0.047600001096725464,
  0.047600001096725464, 0.04905000329017639, 0.04460000991821289,
  0.035650014877319336, 0.022300004959106445, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.020800024271011353,
  0.028250008821487427, 0.04309999942779541, 0.06984999775886536,
  0.1323000192642212, 0.19475001096725464, 0.26759999990463257,
  0.3360000252723694, 0.39250001311302185, 0.44304999709129333,
  0.4876500070095062, 0.5307999849319458, 0.5396999716758728,
  0.5575500130653381, 0.5575500130653381, 0.5575500130653381,
  0.5471500158309937, 0.5337499976158142, 0.5188999772071838,
  0.4921000003814697, 0.4623500108718872, 0.4147999882698059,
  0.3255999982357025, 0.26315000653266907, 0.21705001592636108,
  0.17840000987052917, 0.14274999499320984, 0.10260000824928284,
  0.06840002536773682, 0.020800024271011353, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.026749998331069946,
  0.281000018119812, 0.5590500235557556, 0.8251999616622925, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.9723999500274658,
  0.9158999919891357, 0.8549000024795532, 0.8251999616622925,
  0.7850500345230103, 0.7329999804496765, 0.682449996471405, 0.6438000202178955,
  0.5873000025749207, 0.5546000003814697, 0.5248500108718872,
  0.5010499954223633, 0.47574999928474426, 0.44600000977516174,
  0.42669999599456787, 0.4043999910354614, 0.39544999599456787,
  0.3686999976634979, 0.3598000109195709, 0.3315500020980835,
  0.31220000982284546, 0.2973499894142151, 0.281000018119812,
  0.2601500153541565, 0.2572000026702881, 0.24085000157356262,
  0.22300001978874207, 0.2081499993801117, 0.18880000710487366,
  0.18285000324249268, 0.18135002255439758, 0.17990002036094666,
  0.16950002312660217, 0.1590999960899353, 0.1531500220298767,
  0.14570000767707825, 0.13530001044273376, 0.11445000767707825,
  0.09960001707077026, 0.08025002479553223, 0.07285001873970032,
  0.05349999666213989, 0.037150025367736816, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.09515002369880676,
  0.32705000042915344, 0.5307999849319458, 0.786549985408783, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.9381999969482422, 0.8445000052452087,
  0.7508499622344971, 0.6779999732971191, 0.6036499738693237,
  0.5679500102996826, 0.5322999954223633, 0.5159500241279602,
  0.5025500059127808, 0.47874999046325684, 0.4549500048160553,
  0.4445500075817108, 0.4177500009536743, 0.39399999380111694,
  0.3761500120162964, 0.3686999976634979, 0.35680001974105835,
  0.33899998664855957, 0.31814998388290405, 0.2854500114917755,
  0.27649998664855957, 0.24085000157356262, 0.23190000653266907,
  0.22595000267028809, 0.21705001592636108, 0.19920000433921814,
  0.18285000324249268, 0.1576000154018402, 0.1308000087738037,
  0.10555002093315125, 0.08474999666213989, 0.06840002536773682,
  0.059450000524520874, 0.047600001096725464, 0.03270000219345093,
  0.028250008821487427, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.06690001487731934,
  0.11000001430511475, 0.1278499960899353, 0.10109999775886536,
  0.057999998331069946, 0, 0, 0, 0, 0.026749998331069946, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
];
